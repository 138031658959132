<div class='col-sm-9 col-sm-offset-3 new-benefit-container'>
  <div class="row">
    <h3 class="col-sm-4 heading padding-0">{{exchangeBenefitId ? 'Modify' : 'New'}} Exchange Benefit</h3>
  </div>
  <div class="row new-benefit-form">
    <form [formGroup]="benefitForm">
      <!-- <div class="row">
        <div class="col-sm-4 form-group custom-dropdown">
          <label>Catalog*</label>
          <div class="btn-group col-xs-12 padding-0 select-icon" dropdown #dropdown="bs-dropdown" [autoClose]="true">
            <button dropdownToggle type="button" [title]="benefitForm.get('catalogName').value" class="btn dropdown-toggle form-control" [ngClass]="(benefitForm.get('catalogId').hasError('required') && catalogId) ? 'err':''" (click)="catalogId = true">
              {{benefitForm.get('catalogName').value ? benefitForm.get('catalogName').value : 'Select Catalog'}}
            </button>
            <ul id="dropdown-triggers-manual" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-triggers-manual">
              <li role="menuitem" class="hand">
                <a class="dropdown-item" [class.active]="!benefitForm.get('catalogId').value">Select Catalog</a>
              </li>
              <li *ngFor="let catalog of catalogs" role="menuitem" class="hand" (click)="selectedValue('catalog', catalog)">
                <a class="dropdown-item" [class.active]="benefitForm.get('catalogId').value == catalog.catalogId">{{catalog.catalogName}}</a>
              </li>
            </ul>
          </div>
        </div>
      </div> -->
      <div class="row">
        <div class="col-sm-4 form-group custom-dropdown">
          <label>Benefit*</label>
          <div class="btn-group col-xs-12 padding-0 select-icon" dropdown #dropdown="bs-dropdown" [autoClose]="true">
            <button dropdownToggle type="button" [title]="benefitForm.get('benefitName').value" class="btn dropdown-toggle form-control" [class.disabled]="exchangeBenefit?.exchanged" [ngClass]="(benefitForm.get('benefitId').hasError('required') && benefitId) ? 'err':''" (click)="benefitId = true">
              {{benefitForm.get('benefitName').value ? benefitForm.get('benefitName').value : 'Select Benefit'}}
            </button>
            <ul id="dropdown-triggers-manual" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-triggers-manual">
              <li role="menuitem" class="hand" [class.disabled]="benefitForm.get('benefitId').value">
                <a class="dropdown-item" [class.active]="!benefitForm.get('benefitId').value">Select Benefit</a>
              </li>
              <li *ngFor="let benefit of benefits" role="menuitem" class="hand" (click)="selectedValue('benefit', benefit)">
                <a class="dropdown-item" [class.active]="benefitForm.get('benefitId').value == benefit.rewardCatalogId">{{benefit.productNameEN}}</a>
              </li>
            </ul>
          </div>
          <a class="add-hotel-btn" (click)="goTo('/marketing/rewards-catalog/new-reward')">
            <img src='assets/images/plus.svg' class='pull-left' />
          </a>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 form-group custom-dropdown">
          <label>Hotel*</label>
          <div class="btn-group col-xs-12 padding-0 select-icon" dropdown #dropdown="bs-dropdown" [autoClose]="true">
            <button dropdownToggle type="button" [title]="benefitForm.get('hotelName').value" class="btn dropdown-toggle form-control" [ngClass]="(benefitForm.get('hotel').hasError('required') && hotel) ? 'err':''" (click)="hotel = true" [class.disabled]="!benefitForm.get('benefitId').value || exchangeBenefit?.exchanged">
              {{benefitForm.get('hotelName').value ? benefitForm.get('hotelName').value : 'Select Hotel'}}
            </button>
            <ul id="dropdown-triggers-manual" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-triggers-manual">
              <li role="menuitem" class="hand" [class.disabled]="benefitForm.get('hotel').value">
                <a class="dropdown-item" [class.active]="!benefitForm.get('hotel').value">Select Hotel</a>
              </li>
              <li *ngFor="let hotel of hotels" role="menuitem" class="hand" (click)="selectedValue('hotel', hotel)">
                <a class="dropdown-item" [class.active]="benefitForm.get('hotel').value == hotel.hotelId">{{hotel.hotelName}}</a>
              </li>
            </ul>
          </div>
          <a class="add-hotel-btn" (click)="goTo('/hotel-management/hotel/add')">
            <img src='assets/images/plus.svg' class='pull-left' />
          </a>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 form-group custom-dropdown">
          <label>Employee*</label>
          <div class="btn-group col-xs-12 padding-0">
            <input type="text" class="form-control" data-toggle='dropdown' formControlName="empCode" (keyup)="employeeTerm$.next($event.target.value)" onkeydown="javascript: return event.keyCode == 69 ? false : true" [ngClass]="(benefitForm.get('empCode').hasError('required') && empCode) ? 'err':''" (click)="empCode = true"  [disabled]="exchangeBenefit?.exchanged" [class.disabled]="exchangeBenefit?.exchanged"
            />
            <!-- <button dropdownToggle type="button" [title]="benefitForm.get('hotelName').value" class="btn dropdown-toggle form-control">
                {{benefitForm.get('hotelName').value ? benefitForm.get('hotelName').value : 'Select Hotel'}}
              </button> -->
            <ul class="dropdown-menu" role="menu" *ngIf="searchedEmployees.length">
              <!-- <li role="menuitem" class="hand" (click)="selectedValue('hotel', null)">
                <a class="dropdown-item" [class.active]="!benefitForm.get('hotel').value">Select Hotel</a>
              </li> -->
              <li *ngFor="let empCode of searchedEmployees" role="menuitem" class="hand" (click)="selectedValue('employee', empCode)" [ngClass]="isSelectedEmployee ? 'err' : ''">
                <a class="dropdown-item" [class.active]="benefitForm.get('empCode').value == empCode">{{empCode}}</a>
              </li>
            </ul>
          </div>
          <a class="add-hotel-btn">
            <img src='assets/images/search.svg' class='pull-left' />
          </a>
        </div>
      </div>
      <div class="row">
        <div class='form-group col-sm-2 checkbox-list2' [class.disabled]="exchangeBenefit?.exchanged">
          <input type='checkbox' name="redeem" id='r1' value="exchanged" formControlName="exchanged"/>
          <label for='r1'>
            <span class="text">Redeemed</span> &nbsp;
            <span class='check-box'></span>
          </label>
        </div>
      </div>
      <div class="row buttons-container padding-0">
        <div class="col-sm-8" *ngIf="exchangeBenefitId && exchangeBenefit && !exchangeBenefit?.exchanged">
          <button class="btn btn-record col-sm-4 pull-left" [disabled]="isDirty" (click)="printVochuer(exchangeBenefit)">Generate Voucher</button>
          <button class="btn send-btn col-sm-5 pull-left"  [disabled]="isDirty" (click)="sendVoucher(exchangeBenefit.id)">Generate And Send Voucher</button>
        </div>
      </div>
      <div class="row buttons-container">
        <div class="col-sm-8" *ngIf="!exchangeBenefitId">
          <button class="btn btn-cancel col-sm-2 pull-left" (click)="confirmClose()">Cancel</button>
          <button class="btn btn-record col-sm-2 pull-right" [disabled]="!benefitForm.valid || isSelectedEmployee" (click)="addExchange(false)">Record</button>
          <button class="btn btn-add col-sm-5 pull-right" [disabled]="!benefitForm.valid || isSelectedEmployee" (click)="addExchange(true)">Record and Add Another</button>
        </div>
        <div class="col-sm-8" *ngIf="exchangeBenefitId">
          <button class="btn btn-cancel col-sm-2 pull-left" (click)="confirmClose()">Cancel</button>
          <button class="btn btn-record col-sm-3 pull-right" [disabled]="!benefitForm.valid || exchangeBenefit?.exchanged || isSelectedEmployee" (click)="addExchange(false)">Save Changes</button>
        </div>
      </div>
      <p class="col-sm-12 error text-center" *ngIf="errorMsg">{{errorMsg}}</p>
      <p class="col-sm-12 success text-center" *ngIf="successMsg">{{successMsg}}</p>
    </form>
  </div>
</div>
