<div class="table-responsive booking-container">
  <h3 *ngIf="!bookingList?.length" class="text-center">No Booking History found</h3>
  <table *ngIf='bookingList?.length' class="table">
    <!-- <thead>
        <tr>
          <th>Locator</th>
          <th>Origin</th>
          <th>Agency</th>
          <th>Treatment</th>
          <th>Hotel</th>
          <th>Check In</th>
          <th>Check Out</th>
          <th>Status</th>
        </tr>
      </thead> -->
    <thead>
      <tr>
        <th *ngFor="let header of tableHeader; let idx = index" class="pointer" (click)="sortTable(header.property, ['firstName'])">{{header.key}}
          <span class="sort-icons up pointer" *ngIf="header.direction != null && header.direction == 'asc'"> &#9660;</span>
          <span class="sort-icons down pointer" *ngIf="header.direction != null && header.direction == 'desc'"> &#9650;</span>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let booking of bookingList | paginate: { itemsPerPage: size, currentPage: p, totalItems: totalCount }">
        <td>
         <a [routerLink]="['/members/modify', members?.loyaltyMemberId, 'booking']" *ngFor="let members of booking.members;let last = last">{{members.firstName}} {{members.lastName}}<span *ngIf="!last">,</span><br></a>
        </td>
        <td>{{booking.locator}}</td>
        <td>{{booking.origin}}</td>
        <td>{{booking.agency}}</td>
        <td>{{booking.treatment}}</td>
        <td>{{booking.hotelName}}</td>
        <td>{{booking.checkInDate}}</td>
        <td>{{booking.checkOutDate}}</td>
        <td [ngClass]="booking.status == 'Active' ? 'activate' : 'inactivate'">
          {{booking.status}}
        </td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <td class="tfooter" colspan="8">
          <pagination-controls class="pull-right" (pageChange)="pageChanged($event)"></pagination-controls>
        </td>
      </tr>
    </tfoot>
  </table>
</div>
