<div class="col-sm-10">
  <div class='editContainer'>
    <h3>Add New Administrator Account</h3>
    <form novalidate [formGroup]="user" class='form-group'>
      <div class='row'>
        <div class='formDiv'>
          <div class="form-group col-sm-5">
            <label>First Name*</label>
            <input type="text" placeholder="" formControlName="firstName" class='form-control' [ngClass]="user.get('firstName').hasError('required') && user.get('firstName').touched ? 'err':''"
              (focus)="errorDetails = ''">
          </div>
          <div class="form-group col-sm-5">
            <label>Last Name*</label>
            <input type="text" placeholder="" formControlName="lastName1" class='form-control' [ngClass]="user.get('lastName1').hasError('required') && user.get('lastName1').touched ? 'err':''"
              (focus)="errorDetails = ''">
          </div>
        </div>
        <div class='formDiv'>
          <div class="form-group col-sm-5">
            <label>Phone Number</label>
            <input type="tel" placeholder="" formControlName="mobileNumber" class='form-control' [ngClass]="user.get('mobileNumber').hasError('pattern') || user.get('mobileNumber').hasError('minlength') || user.get('mobileNumber').hasError('maxlength') || user.get('mobileNumber').hasError('required') && user.get('mobileNumber').touched ?'err':''"
              (focus)="errorDetails = ''">
              <!-- <div class="error" style="width:288px;margin-top:0" *ngIf="user.get('mobileNumber').hasError('pattern')">
                  Incorrect Phone number
              </div> -->
          </div>
          <div class="form-group col-sm-5">
            <label>Email ID*</label>
            <input type="email" placeholder="" formControlName="email" class='form-control' [ngClass]="(user.get('email').hasError('pattern') || user.get('email').hasError('required')) && user.get('email').touched?'err':''"
              (focus)="errorDetails = ''">
          </div>
        </div>
      </div>
    </form>
    <div class='buttonsDiv text-right col-sm-10'>
      <button [routerLink]="['/user-management']"  class='btn btn-default col-sm-3 col-xs-12'>Cancel</button>
      <button (click)="saveUserInfo(user)" class='btn btn-default col-sm-3 col-xs-12' [disabled]="!user.valid">Save</button>
    </div>
    <div class="error col-xs-12" *ngIf="errorDetails">{{errorDetails}}</div>
  </div>
</div>
