<!-- SignIn Form -->
<div class="container">
  <!-- <div class="pull-right">
     <button class="btn btn-default" (click)="translate.use('en')">English</button>
    <button class="btn btn-default" (click)="translate.use('es')">Español</button>
  </div> -->
  <div class="model col-md-4 col-md-offset-4 col-sm-6 col-sm-offset-3 col-xs-12" *ngIf="signInForm">
    <h3 class="content">{{'SIGN_IN_TITLE' | translate}}</h3>
    <form novalidate [formGroup]="user">
      <div class='form-group clear'>
        <label>{{'COMMON.EMAIL' | translate}}</label>
        <input type="email" (focus)="errordetails = ''" class="col-xs-12 form-control" placeholder="{{'COMMON.EMAIL' | translate}}" formControlName="username">
      </div>
      <div class="divErrors">
        <div class="error" *ngIf="user.get('username').hasError('required') && user.get('username').touched">
          {{error.email_req}}
        </div>
        <div class="error" *ngIf="user.get('username').hasError('pattern')">
          {{error.email_valid}}
        </div>
      </div>
      <div class='form-group clear'>
        <label>{{'COMMON.PASSWORD' | translate}}</label>
        <input type="password" (focus)="errordetails = ''" class="col-xs-12 form-control" placeholder="{{'COMMON.PASSWORD' | translate}}" formControlName="password">
      </div>
      <div class="divErrors">
        <div class="error" *ngIf="user.get('password').hasError('required') && user.get('password').touched">
          {{error.password_req}}
        </div>
        <!-- <div class="error" *ngIf="user.get('password').hasError('pattern')">
            {{error.password_valid}}
          </div> -->
      </div>
      <div class="forgot-password clear">
        <a (click)="forgotPassword()">{{'COMMON.FORGOT_PASSWORD' | translate}}</a>
      </div>
      <button type="submit" class="col-xs-12" (click)="login(user)" [disabled]="!user.valid">{{'SIGN_IN_TITLE' | translate}}</button>
      <div class="error margin-top9" *ngIf="errordetails">
        {{errordetails}}
      </div>
    </form>
  </div>
  <!-- Authentation Form -->
  <!-- <div class="model col-md-4 col-md-offset-4 col-sm-6 col-sm-offset-3 col-xs-12" *ngIf="authenticationForm">
    <h3 class="content">{{'AUTHORIZATION_CODE.TITLE' | translate}}</h3>
    <div class="authText">
      {{'AUTHORIZATION_CODE.MESSAGE' | translate}}
    </div>
    <div>
      <input type="text" class="text-center col-xs-12" formControlName='authorizationCode'>
    </div>
    <button type="submit" class="col-xs-12  authSubmit">{{'SIGN_IN_TITLE' | translate}}</button>
  </div> -->

  <!-- Reset Password Form -->
  <div class="model col-md-4 col-md-offset-4 col-sm-6 col-sm-offset-3 col-xs-12" *ngIf="resetPassword">
    <h3 class="content">{{'RESET_PASSWORD.TITLE' | translate}}</h3>
    <div class="authText">
      {{'RESET_PASSWORD.MESSAGE' | translate}}
    </div>
    <form novalidate [formGroup]="resetEmail">
      <div class="form-group clear">
        <label>{{'COMMON.EMAIL' | translate}}</label>
        <input class="text-left col-xs-12 form-control" (focus)="errordetails = ''" type="email" placeholder="{{'COMMON.EMAIL' | translate}}" formControlName="email">
      </div>
      <div class="divErrors">
        <div class="error" *ngIf="resetEmail.get('email').hasError('required') && resetEmail.get('email').touched">
          {{error.email_req}}
        </div>
        <div class="error" *ngIf="resetEmail.get('email').hasError('pattern')">
          {{error.email_valid}}
        </div>
      </div>
      <button type="submit" class=" authSubmit col-xs-12" (click)="sendEmail(resetEmail)" [disabled]="!resetEmail.valid">{{'SUBMIT' | translate}}</button>
      <div class="error margin-top9" *ngIf="errordetails">
        {{errordetails}}
      </div>
    </form>
  </div>
  <!-- Creating new Password -->
  <div class="model col-md-4 col-md-offset-4 col-sm-6 col-sm-offset-3 col-xs-12" *ngIf="createNewPassword">
    <h3 class="content">{{'CREATE_PASSWORD.TITLE' | translate}}</h3>
    <form novalidate [formGroup]="createPassword">
      <div class="form-group clear">
        <label>{{'COMMON.PASSWORD' | translate}}</label>
        <input type="password" class="form-control" (focus)="errordetails = ''"  placeholder="{{'COMMON.PASSWORD' | translate}}" formControlName="password_new">
      </div>
      <div class="error" *ngIf="createPassword.get('password_new').hasError('required') && createPassword.get('password_new').touched">
        {{error.password_req}}
      </div>
      <div class="error" *ngIf="createPassword.get('password_new').hasError('pattern')">
        {{error.password_valid}}
      </div>
      <div class="form-group clear">
        <label>{{'CREATE_PASSWORD.PASSWORD_AGAIN' | translate}}</label>
        <input type="password" class="form-control" (focus)="errordetails = ''"  placeholder="{{'COMMON.PASSWORD' | translate}}" formControlName="password_confirm">
      </div>
      <div class="error" *ngIf="createPassword.get('password_confirm').hasError('required') && createPassword.get('password_confirm').touched">
        {{error.password_req}}
      </div>
      <div class="error" *ngIf="createPassword.get('password_confirm').hasError('pattern')">
        {{error.password_valid}}
      </div>
      <div class="error" *ngIf="createPassword.hasError('matchPassword') && createPassword.get('password_confirm').dirty">
        {{error.passwords_mismatch}}
      </div>
      <div class="error margin-top9" *ngIf="errordetails">
        {{errordetails}}
      </div>
      <button type="submit" (click)="creatingNewPassword(createPassword)" class="submit-button col-xs-12" [disabled]="!createPassword.valid">{{'SUBMIT' | translate}}</button>
    </form>
  </div>
  <!-- Set a new Password -->
  <div class='model col-md-4 col-md-offset-4 col-sm-6 col-sm-offset-3 col-xs-12 newPassword' *ngIf="setNewPassword">
    <h3 class='content'>{{'SET_NEW_PASSWORD.TITLE' | translate}}</h3>
    <h5>{{'SET_NEW_PASSWORD.ERROR' | translate}}</h5>
    <form novalidate [formGroup]="setPasswordNew">
      <div class="form-group clear">
        <label>{{'SET_NEW_PASSWORD.CURRENT_PASSWORD' | translate}}</label>
        <input type='password' class="col-xs-12 form-control" (focus)="errordetails = ''" placeholder="{{'SET_NEW_PASSWORD.CURRENT_PASSWORD' | translate}}" formControlName='password'>
      </div>
      <div class="error" *ngIf="setPasswordNew.get('password').hasError('required') && setPasswordNew.get('password').touched">
        {{error.password_req}}
      </div>
      <div formGroupName="passwords">
        <div class="form-group clear">
          <label>{{'SET_NEW_PASSWORD.NEW_PASSWORD' | translate}}</label>
          <input type='password' class="col-xs-12 form-control" (focus)="errordetails = ''" placeholder="{{'SET_NEW_PASSWORD.NEW_PASSWORD' | translate}}" formControlName='password_new'
          />
        </div>
        <div class="error" *ngIf="setPasswordNew.get('passwords').get('password_new').hasError('required') && setPasswordNew.get('passwords').get('password_new').touched">
          {{error.password_req}}
        </div>
        <div class="error" *ngIf="setPasswordNew.get('passwords').get('password_new').hasError('pattern')">
          {{error.password_valid}}
        </div>
        <div class="form-group clear">
          <label>{{'SET_NEW_PASSWORD.NEW_PASSWORD_AGAIN' | translate}}</label>
          <input type="password" class="col-xs-12 form-control" (focus)="errordetails = ''" placeholder="{{'SET_NEW_PASSWORD.NEW_PASSWORD_AGAIN' | translate}}"
            formControlName='password_confirm'>
        </div>
        <div class="error" *ngIf="setPasswordNew.get('passwords').get('password_confirm').hasError('required') && setPasswordNew.get('passwords').get('password_confirm').touched">
          {{error.password_req}}
        </div>
        <div class="error" *ngIf="setPasswordNew.get('passwords').hasError('matchPassword') && setPasswordNew.get('passwords').get('password_confirm').dirty">
          {{error.passwords_mismatch}}
        </div>
      </div>
      <button type="submit"  class="col-xs-12" (click)="settingNewPassword(setPasswordNew)" [disabled]="!setPasswordNew.valid">{{'SUBMIT' | translate}}</button>
      <div class="error margin-top9" *ngIf="errordetails">
        {{errordetails}}
      </div>
    </form>
  </div>
  <div class='model col-md-4 col-md-offset-4 col-sm-6 col-sm-offset-3 col-xs-12' *ngIf="emailSentSuccess">
    <h3 class='content'>{{'RESET_PASSWORD_SUCCESS.TITLE' | translate}}</h3>
    <p class='text-center'>{{'RESET_PASSWORD_SUCCESS.MESSAGE' | translate}}</p>
  </div>
  <div class='model col-md-4 col-md-offset-4 col-sm-6 col-sm-offset-3 col-xs-12' *ngIf="resetPasswordSuccess">
    <h3 class='content'>{{'PASSWORD_RESET.TITLE' | translate}}</h3>
    <p class='text-center'>{{'PASSWORD_RESET.MESSAGE' | translate}}</p>
    <button type="submit" (click)="backToLogin()" class="submit-button col-xs-12">{{'PASSWORD_RESET.BUTTON' | translate}}</button>
  </div>
  <div class='model col-md-4 col-md-offset-4 col-sm-6 col-sm-offset-3 col-xs-12' *ngIf="checkEmailStatus">
    <p class='text-center margin-top9'>{{emailLinkError}}</p>
  </div>

</div>
