<div class='col-sm-10 col-sm-offset-2 bookings-container'>
    <div class='row header'>
      <div class="col-sm-5 heading">
        <h3 class="h">Bookings</h3>
      </div>
      <div class='col-sm-7 buttonsDiv text-right'>
        <!-- <button class='btn btn-default btn-add-booking import' (click)="importBookingsModal()">
          <i class="glyphicon glyphicon-open" aria-hidden="true"></i> Import
        </button> -->
        <!-- <button class='btn btn-default btn-add-booking' [routerLink]="['add']">
        <button class='btn btn-default btn-add-booking'>
          <img src='assets/images/plus-white.svg' class='pull-left' /> New Booking
        </button> -->
      </div>
    </div>

    <div class='row menuDiv'>
      <!-- filter dropdown start -->
      <div class='col-sm-offset-1 col-sm-2 dropdown filter-btn filter'>
        <form [formGroup]="filterForm">
          <button class='btn btn-default dropdown-toggle filter filterBtnWidth' [disabled]="!isFiltered && !bookingsList.length" [ngClass]="isFilterOn ? 'on' : 'off'"
            data-toggle="dropdown" (click)='dropDown($event)'>
            <span class="filter">
              <span>Filter {{isFilterOn ? '(On)' : '(Off)'}} &nbsp;{{isFilterOn ? (totalCount ? totalCount+' result(s)' : '0 result(s)')
                : ''}}
              </span>
            </span>
          </button>
          <ul class="dropdown-menu filter-menu" id='ul'>
            <!-- <li class='form-group'>
              <a>
                <label>Active</label>
                <select class='form-control' formControlName="status">
                  <option value="" selected>All</option>
                  <option [value]="status.statusId" *ngFor="let status of filterLookUpData?.status">{{status.statusDesc}}</option>
                </select>
              </a>
            </li>
            <li class='divider'></li> -->
            <li class='form-group'>
              <a>
                <label>Direct</label>
                <select class='form-control' formControlName="direct">
                  <option value="" selected>All</option>
                  <option [value]="direct" *ngFor="let direct of filterLookUpData?.direct">{{direct}}</option>
                </select>
              </a>
            </li>
            <li class='divider'></li>
            <li class='form-group'>
              <a>
                <label>State</label>
                <select class='form-control' formControlName="stateId">
                  <option value="" selected>All</option>
                  <option [value]="state.id" *ngFor="let state of filterLookUpData?.state">{{state.state}}</option>
                </select>
              </a>
            </li>
            <li class='divider'></li>
            <li class='form-group dropdown'>
                <a>
                  <label>Origin</label>
                  <div class="form-control custom-dropdown">
                      <div class="btn-group col-xs-12 padding-0 select-icon" dropdown #dropdown="bs-dropdown" [autoClose]="true">
                        <button dropdownToggle type="button" class="btn dropdown-toggle form-control">
                            {{(filterForm.get('origins').value.indexOf(0) > -1) ? 'All Origins Selected' : getSelectedStr(filterForm.get('origins').value.length,'Origin')}}
                        </button>
                        <ul id="dropdown-triggers-manual" *dropdownMenu class="dropdown-menu checkbox-list" role="menu" aria-labelledby="button-triggers-manual">
                          <li role="menuitem" class="hand dropdown-item check-list">
                              <input type='checkbox' id='origin0' name='origin' value="origin0" [checked]="" [checked]="filterForm.get('origins').value.indexOf(0) > -1"
                              (change)="checkSelected($event.target.checked, 'origins', 'origin', 'origin', 0)" />
                            <label for='origin0'>
                              <span class='ip-text'>ALL</span>
                              <span class='check-box'></span>
                            </label>
                          </li>
                          <li *ngFor="let origin of filterLookUpData?.origin" role="menuitem" class="hand dropdown-item check-list">
                              <input type='checkbox' [id]='"origin"+origin' name='origin' [value]="origin" [checked]="filterForm.get('origins').value.indexOf(origin) >= 0"
                              (change)="checkSelected($event.target.checked, 'origins', 'origin', 'origin', origin)" />
                            <label [for]='"origin"+origin'>
                              <span class='ip-text'>{{origin}}</span>
                              <span class='check-box'></span>
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                </a>
              </li>
            <li class='divider'></li>
            <li class='form-group dropdown'>
              <a>
                <label>Hotel</label>
                <div class="form-control custom-dropdown">
                    <div class="btn-group col-xs-12 padding-0 select-icon" dropdown #dropdown="bs-dropdown" [autoClose]="true">
                      <button dropdownToggle type="button" class="btn dropdown-toggle form-control">
                          {{(filterForm.get('hotelIds').value.indexOf(0) > -1) ? 'All Hotels Selected' : getSelectedStr(filterForm.get('hotelIds').value.length,'Hotel')}}
                      </button>
                      <ul id="dropdown-triggers-manual" *dropdownMenu class="dropdown-menu checkbox-list" role="menu" aria-labelledby="button-triggers-manual">
                        <li role="menuitem" class="hand dropdown-item check-list">
                            <input type='checkbox' id='hotel0' name='hotel' value="hotel0" [checked]="" [checked]="filterForm.get('hotelIds').value.indexOf(0) > -1"
                            (change)="checkSelected($event.target.checked, 'hotelIds', 'hotels', 'hotelId', 0)" />
                          <label for='hotel0'>
                            <span class='ip-text'>ALL</span>
                            <span class='check-box'></span>
                          </label>
                        </li>
                        <li *ngFor="let hotel of filterLookUpData?.hotels" role="menuitem" class="hand dropdown-item check-list">
                            <input type='checkbox' [id]='"hotel"+hotel.hotelId' name='hotel' [value]="hotel.hotelId" [checked]="filterForm.get('hotelIds').value.indexOf(hotel.hotelId) >= 0"
                            (change)="checkSelected($event.target.checked, 'hotelIds', 'hotels', 'hotelId', hotel.hotelId)" />
                          <label [for]='"hotel"+hotel.hotelId'>
                            <span class='ip-text'>{{hotel.hotelName}}</span>
                            <span class='check-box'></span>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>
              </a>
            </li>
            <li class='divider'></li>
            <li class='form-group'>
              <a class="close-position">
                <label>Created Between</label>
                <input type="text" placeholder="Created between" placement="top" formControlName="range" class="form-control search-box"
                  onkeydown='return event.which == 8 || event.which == 46 || event.which == 37 || event.which == 39' bsDaterangepicker>
                <button class="close-icon" (click)="clearDate()"></button>
              </a>
            </li>
          </ul>
        </form>
      </div>
      <!-- filter dropdown end -->

      <!-- Action dropdown start -->
      <div class='col-sm-4 dropdown filter-btn'>
        <button [disabled]="!selectedBookings.length" [ngClass]="selectedBookings.length ? 'on' : 'off'" class='btn btn-default dropdown-toggle btn-action'
          data-toggle="dropdown">
          <span>
            <span>Actions</span>
            <span class='glyphicon glyphicon-chevron-down'></span>
          </span>
        </button>
        <ul class="dropdown-menu action-menu">
          <!-- <li class='plus pointer' (click)="openStatusModal(this.selectedBookings, 1)">
            <a class="activate">
              <img src='assets/images/success.svg' class='pull-left' />
              <span class='pull-left activate'>Activate Selected Bookings</span>
            </a>
          </li> -->
          <li class='plus pointer' (click)="openStatusModal(this.selectedBookings, 0)">
            <a class="delete">
              <img src='assets/images/delete.svg' class='pull-left' />
              <span class='pull-left deactivate'>Delete Selected Bookings</span>
            </a>
          </li>
          <li class='plus pointer' (click)="calculateDiscount(this.selectedBookings)">
            <a class="activate">
              <img src='assets/images/success.svg' class='pull-left' />
              <span class='pull-left activate'>Calculate discount points</span>
            </a>
          </li>
        </ul>
        <span [class.hide-ele]="" class='action-text' [hidden]="!isFiltered && !bookingsList.length">{{selectedBookings.length}} of {{bookingsList.length}} selected</span>
      </div>
      <!-- Action dropdown end -->

      <div class='col-sm-2 search-text text-right' [ngClass]="" [hidden]="!isFiltered && !bookingsList.length">
        <span>{{totalCount ? totalCount : 0}} results</span>
      </div>
      <form [formGroup]="searchForm">
        <div class="col-sm-3 form-group pull-right flex">
          <input
            type="search"
            class="form-control"
            placeholder="Search Bookings"
            formControlName="searchTerm"
            [class.disabled]='!isFiltered && !bookingsList.length'
          />
          <button (click)="searchTerm()" class="btn btn-default margin-left5 activeBtn">
            {{'BUTTONS.SEARCH' | translate}}
          </button>
        </div>
        </form>
    </div>

    <!-- rewards empty state with filters -->
    <div class='message text-center' *ngIf="isFiltered && !bookingsList.length">
      <h3>No results found</h3>
    </div>

    <!-- Empty state -->
    <div class='message text-center' *ngIf="!isFiltered && !bookingsList.length">
      <h3>You haven’t created any bookings yet. Click on “+ New Booking” above to get started.</h3>
    </div>

  <!--  Booking List -->
    <div class="table-responsive" *ngIf="bookingsList.length">
      <table class="table row  table-catalog ">
        <thead>
          <tr>
            <th class='table-checkbox'>
              <input type='checkbox' id='booking0' name='booking' value="booking0" [(ngModel)]="allBookingsSelected" (change)="selectBookings('all')"
              />
              <label for='booking0' class='margin-0'>
                <span class='check-box'></span>
              </label>
            </th>
            <th *ngFor="let header of tableHeader; let idx = index" class="pointer" (click)="sortTable(header.property, ['pmsBonus', 'agency', 'pmsEntitler'])">{{header.key}}
              <span class="sort-icons up pointer" *ngIf="header.direction != null && header.direction == 'asc'"> &#9660;</span>
              <span class="sort-icons down pointer" *ngIf="header.direction != null && header.direction == 'desc'"> &#9650;</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let booking of bookingsList | paginate: { itemsPerPage: size, currentPage: p, totalItems: totalCount }">
            <td class='table-checkbox'>
              <input type='checkbox' [id]='"booking"+booking.bookingId' name='promo' value="'booking'+booking.bookingId" [(ngModel)]="booking.checked"
                (change)="selectBookings('single')" />
              <label [for]='"booking"+booking.bookingId'>
                <span class='check-box'></span>
              </label>
            </td>
            <td class="break-word">{{booking.locator ? booking.locator : 'N/A'}}</td>
            <td class="break-word">{{booking.manualUpdatedDate ? booking.manualUpdatedDate : 'N/A'}}</td>
            <td class="break-word">{{booking?.origin ? booking?.origin : 'N/A'}}</td>
            <td class="break-word">{{booking?.pmsId ? booking?.pmsId : 'N/A'}}</td>
            <td class="break-word">{{booking?.pmsBonus ? booking?.pmsBonus : 'N/A'}}</td>
            <td class="break-word">{{booking?.agency ? booking?.agency : 'N/A'}}</td>
            <td class="break-word">{{booking?.pmsEntitler ? booking?.pmsEntitler : 'N/A'}}</td>
            <td class="break-word">{{booking?.treatment ? booking?.treatment : 'N/A'}}</td>
            <td>{{booking?.hotelName ? booking?.hotelName : 'N/A'}}</td>
            <td>{{booking?.checkInDate ? booking?.checkInDate : 'N/A'}}</td>
            <td>{{booking?.checkOutDate ? booking?.checkOutDate : 'N/A'}}</td>
            <td>{{booking?.stateDescription ? booking?.stateDescription : 'N/A'}}</td>
            <td>{{booking?.agentName ? booking?.agentName : 'N/A'}}</td>
            <td class="">
              <div class='dropdown status'>
                <!-- <span [ngClass]="booking.statusId == 1 ? 'activate' : 'inactivate'">
                  {{booking.statusId == 1 ? 'Active' : 'Inactive'}}
                </span> -->
                <button type='button' class='btn btn-link dropdown-toggle' data-toggle="dropdown">
                  <img src='assets/images/overflow.svg' />
                </button>
                <ul class="dropdown-menu statusList">
                  <li class="pointer">
                    <a [routerLink]="['edit', booking.bookingId]">
                      <img class='pull-left' src='assets/images/edit.svg' />
                      <span class='pull-left'>Modify</span>
                    </a>
                  </li>
                  <li class="pointer">
                    <a class='deactivate' *ngIf="booking.statusId == 1" (click)="openStatusModal([booking.bookingId], !booking.statusId)">
                      <img class='pull-left' src='assets/images/delete.svg' />
                      <span class='pull-left'>Delete</span>
                    </a>
                  </li>
                  <li class="pointer">
                    <a class='activate col-xs-12' (click)="calculateDiscount([booking.bookingId]);">
                      <img class='pull-left' src='assets/images/success.svg' />
                      <span class='pull-left'>Calculate discount points</span>
                    </a>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td class="tfooter" colspan="13">
              <pagination-controls class="pull-right" (pageChange)="pageChanged($event)"></pagination-controls>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
