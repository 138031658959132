<div class='col-sm-8 col-sm-offset-3 main-container' *ngIf='hotelBrands.length'>
  <ul class="nav nav-tabs brand-tabs">
    <li *ngFor="let brand of hotelBrands; let i=index;" [ngClass]="brand==hotelBrands[0] ? 'active':''">
      <a id={{i}} class="pointer" *ngIf="!isDirty" data-toggle="tab" href="#home" (click)="getBrandDetailsByBrandId(brand,i)">{{brand.brandName}}</a>
      <a class="pointer" *ngIf="isDirty" (click)="stayOnPage(brand, i)">{{brand.brandName}}</a>
    </li>
  </ul>
  <div class="tab-content col-sm-11">
    <div id="home" class="tab-pane fade in active brand-container">
      <p>Hotel Brand Details</p>
      <form novalidate [formGroup]="brand">
        <div class='row'>
          <div class='formDiv'>
            <div class="form-group col-sm-6">
              <label>Hotel Brand Name*</label>
              <input class='form-control' type='text' formControlName="brandName" [ngClass]="brand.get('brandName').hasError('required') && brand.get('brandName').touched ? 'err':''"
                (focus)="errorDetails = ''" />
            </div>
            <div class="form-group col-sm-6 select-icon select-div">
              <label>Available Language* (Choose at least 1)</label>
              <select type="multiselect" class='form-control' formControlName="languageId" (focus)="errorDetails = ''" [ngClass]="brand.get('languageId').hasError('required') && brand.get('languageId').touched ? 'err':''">
                <option value="">Choose Language</option>
                <option *ngFor="let language of languages" [value]="language.id">{{language.language}}</option>
              </select>
              <!-- <div class='select dropdown-toggle' data-toggle='dropdown' [class.err]="levelsError" (click)="checkError(brand.get('languagesList'))"
                [ngClass]="!brand.get('languagesList').value.length ? 'err':''">
                  {{brand.get('languagesList').value.length}} Language{{brand.get('languagesList').value.length == 1 ? '' : 's'}} selected
              </div>
              <ul class='dropdown-menu checkbox-list' (click)="$event.stopPropagation()">
                <li class='check-list' *ngFor="let language of languages">
                  <input type='checkbox' [id]='language.id' name='cb' [value]="language.id" [checked]="brand.get('languagesList').value.indexOf(language.id) >= 0" />
                  <label [for]='language.id' (click)="toggleSelection(language)">
                    <span class='ip-text'>{{language.language}}</span>
                    <span class='check-box'></span>
                  </label>
                </li>
              </ul> -->
            </div>
          </div>
          <div class='formDiv margin-bottom25'>
            <div class="form-group col-sm-6">
              <label>Brand Website URL*</label>
              <textarea class='form-control' formControlName="website" (focus)="errorDetails = ''" [ngClass]="(brand.get('website').hasError('pattern') || brand.get('website').hasError('required')) && brand.get('website').touched ? 'err':''"></textarea>
            </div>
            <div class="form-group col-sm-6">
              <label>Brand Blog URL</label>
              <textarea class='form-control' formControlName="blogUrl" (focus)="errorDetails = ''" [ngClass]="brand.get('blogUrl').hasError('pattern') && brand.get('blogUrl').touched ? 'err':''"></textarea>
            </div>
          </div>
          <div class='formDiv'>
            <div class="form-group col-sm-6">
              <label>Smart Code*</label>
              <input type="text" class='form-control' formControlName="smartCode" (focus)="errorDetails = ''" [ngClass]="brand.get('smartCode').hasError('maxlength') || brand.get('smartCode').hasError('required') && brand.get('smartCode').touched ? 'err':''" />
            </div>
            <div class="form-group col-sm-6">
              <label>Is Membership Required?*</label>
              <div>
                <label class="radio">
                  <input id="radio1" type="radio" [value]="true" formControlName="isMemberShipRequired">
                  <span class="outer"></span>
                  <span class='radio-text'>Yes</span>
                </label>
                <label class="radio">
                  <input id="radio1" type="radio" [value]="false" formControlName="isMemberShipRequired">
                  <span class="outer"></span>
                  <span class='radio-text'>No</span>
                </label>
              </div>
              <div class="success col-xs-12 text-left" *ngIf="loginSuccessMsg">{{loginSuccessMsg}}</div>
              <div class="error col-xs-12 text-left" *ngIf="loginErrorMsg">{{loginErrorMsg}}</div>
            </div>
          </div>
          <div class='formDiv'>
            <div class="form-group col-sm-6" [ngClass]="{'voucheremail': checkForVoucherBrand()}">
              <label>Voucher Email</label>
              <span  class="instruction" *ngIf="checkForVoucherBrand()">( The default email is <b>"{{getBrandDefaultVoucherEmailId()}}"</b>, if the field is empty )</span>
              <input type="text" class='form-control' formControlName="voucherEmailId" (focus)="errorDetails = ''"/>
            </div>

            <div class='form-group col-sm-6 select-icon select-div'>
              <label>Countries</label>
              <div class='select dropdown-toggle' data-toggle='dropdown'>
                  {{brand.get('physicalCardCountries').value.length}} {{brand.get('physicalCardCountries').value.length == 1 ? 'Country' : 'Countries'}} selected
              </div>
              <ul class='dropdown-menu checkbox-list' (click)="$event.stopPropagation()">
                <li class='check-list' *ngFor="let country of countries">
                  <input type='checkbox' [id]='country.countryId' name='cb' [value]="country.countryId" [checked]="brand.get('physicalCardCountries').value.indexOf(country.countryId) >= 0" (click)="checkSelected($event.target.checked, 'physicalCardCountries', country.countryId)"/>
                  <label [for]='country.countryId'>
                    <span class='ip-text'>{{country.name}}</span>
                    <span class='check-box'></span>
                  </label>
                </li>
              </ul>
            </div>
          </div>
          <div class='logo-container formDiv'>
            <p class='logos-heading padding-left-15'>Upload Brand Logo and Favicon</p>
            <div class='row formDiv'>
              <div class="form-group col-sm-6 xrow xmargin-right-15 xpadding-right-0">
                <div class='col-xs-7'>
                  <label>Logo (Optional)</label>
                  <span *ngIf='!imageFile_logo.imageUrl' class='image-name'>no file chosen</span>
                  <span *ngIf='imageFile_logo.name && imageFile_logo.imageUrl' class='image-name'>{{imageFile_logo.name}}</span>
                  <input class='form-control' type='file' #uploadFile (change)="uploadImage($event,'logo')" (focus)="errorDetails = ''" />
                  <button *ngIf='imageFile_logo.imageUrl' (click)="uploadFile.click()" class='btn-file'>Change File</button>
                  <button *ngIf='!imageFile_logo.imageUrl' (click)="uploadFile.click()" class='btn-file'>Choose File</button>
                  <p *ngIf="logoError" class="error upload col-xs-12" (click)="logoError = false">Please upload Image only.</p>
                  <p *ngIf="logoSizeError" class="error upload col-xs-12" (click)="logoSizeError = false">Image size should be less than 1MB.</p>
                </div>
                <div class='col-xs-5 xpadding-right-0 xtext-right'>
                  <div *ngIf='imageFile_logo.imageUrl'>
                    <img class="image" [src]="imageFile_logo.imageUrl" />
                    <button class='btn btn-default delete-image'>
                      <img src='assets/images/delete.svg' (click)="deleteImage('logo')" />
                    </button>
                  </div>
                  <!-- <span *ngIf='!imageFile_logo.imageUrl' class='no-file'>no file choosen</span> -->
                </div>
              </div>
              <div class="form-group col-sm-6 xrow xpadding-right-0 xmargin-right-15">
                <div class='col-xs-7'>
                  <label>Favicon (Optional)</label>
                  <span *ngIf='!imageFile_favicon.imageUrl' class='image-name'>no file chosen</span>
                  <span *ngIf='imageFile_favicon.name && imageFile_favicon.imageUrl' class='image-name'>{{imageFile_favicon.name}}</span>
                  <input class='form-control' type='file' #uploadFile2 (change)="uploadImage($event,'favicon')" (focus)="errorDetails = ''"
                  />
                  <button *ngIf='imageFile_favicon.imageUrl' (click)="uploadFile2.click()" class='btn-file'>Change File</button>
                  <button *ngIf='!imageFile_favicon.imageUrl' (click)="uploadFile2.click()" class='btn-file'>Choose File</button>
                  <p *ngIf="favError" class="error upload col-xs-12" (click)="favError = false">Please upload Image only.</p>
                  <p *ngIf="favSizeError" class="error upload col-xs-12" (click)="favSizeError = false">Image size should be less than 1MB.</p>
                </div>
                <div class='col-xs-5 xtext-right xpadding-right-0'>
                  <div *ngIf='imageFile_favicon.imageUrl'>
                    <img class="image" [src]="imageFile_favicon.imageUrl" />
                    <button class='btn btn-default delete-image'>
                      <img src='assets/images/delete.svg' (click)="deleteImage('favicon')" />
                    </button>
                  </div>
                  <!-- <span *ngIf='!imageFile_favicon.imageUrl' class='no-file'>no file choosen</span> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      <div class='buttonsDiv row'>
        <!-- <button [routerLink]="['/settings/brand']" class='btn btn-default col-sm-3 col-xs-12'>Cancel</button> -->
        <div class='col-sm-6'>
          <button class='btn btn-default' [disabled]="!brand.get('brandId').value || isDirty" (click)="addBrand()">
            <img src='assets/images/plus-white.svg' class='pull-left' />Add Brand</button>
        </div>
        <div class='col-sm-6'>
          <button class='btn btn-default btn-cancel' (click)="discardChanges()">Cancel</button>
          <button (click)="updateBrandDetails()" *ngIf="brand.get('brandId').value" class='btn btn-default pull-right' [disabled]="!brand.valid">Save</button>
          <button (click)="createBrand()" *ngIf="!brand.get('brandId').value" class='btn btn-default pull-right' [disabled]="!brand.valid">Save</button>
        </div>
      </div>
    </div>
  </div>
  <div class="success col-xs-10" *ngIf="successMsg">{{successMsg}}</div>
  <div class="error col-xs-10" *ngIf="errorDetails">{{errorDetails}}</div>
</div>

<div class='col-sm-9 col-sm-offset-3 main-container' *ngIf='!hotelBrands.length'>
  <div class='message'>
    <h3>You haven't created any brands yet.</h3>
  </div>
</div>