<div class="table-responsive profile-changes-container">
  <h3 *ngIf="!getProfileChangesList?.length" class="text-center">No Profile Changes found</h3>
  <table *ngIf='getProfileChangesList?.length' class="table">
    <thead>
      <tr>
        <th>Date Requested</th>
        <th>Old Email</th>
        <th>New Email</th>
        <th>Status</th>
        <th>Resend</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let profileChange of getProfileChangesList | paginate: { itemsPerPage: size, currentPage: page, totalItems: totalCount }">
        <td>{{profileChange.createdDate ? utilService.dateFormat(profileChange.createdDate, 'MM/DD/YYYY') : 'N/A'}}</td>
        <td>{{profileChange.email && profileChange.email.old ? profileChange.email.old : 'N/A'}}</td>
        <td>{{profileChange.email && profileChange.email.new ? profileChange.email.new : 'N/A'}}</td>
        <td>{{profileChange.status ? profileChange.status : 'N/A'}}</td>
        <td>
          <span *ngIf="profileChange && profileChange.status == 'BLOCKED-PRESENT'">N/A</span>
          <span class="resendLink" *ngIf="(profileChange.email && profileChange.email.new) && (profileChange.status != 'BLOCKED-PRESENT')" (click)="resendVerification(profileChange.id, profileChange.email.new);">Resend Verification</span>
        </td>
        <td>
            <span *ngIf="(profileChange.email && profileChange.email.new) && (profileChange.status === 'BLOCKED-PRESENT')"><a class="activate btn"  (click)="allowAccountsMerge(profileChange.id);">Accept </a> |
            <a class="deactivate btn"  (click)="rejectAccountsMerge(profileChange.id);">Reject</a></span>
        </td>
      </tr>
    </tbody>
    <tfoot *ngIf="totalCount > 10">
      <tr>
        <td class="tfooter" colspan="8"> <pagination-controls class="pull-right"  (pageChange)="getProfileChangesData($event)"></pagination-controls></td>
      </tr>
    </tfoot>
  </table>
</div>
