<div class='col-sm-9 col-sm-offset-3 new-benefit-container'>
  <div class="row">
    <h3 class="col-sm-4 heading padding-0">{{isNewBenefit ? 'New' : 'Modify'}} Benefit</h3>
  </div>
  <div class="row new-benefit-form">
    <form [formGroup]="benefitForm">
      <div class="row">
        <div class="col-sm-4 form-group">
          <label>Benefit Name*</label>
          <input type="text" class="form-control" placeholder="Benefit Name" formControlName="benefitName" [ngClass]="benefitForm.get('benefitName').hasError('maxlength') || (benefitForm.get('benefitName').hasError('whitespace') || benefitForm.get('benefitName').hasError('required')) && benefitForm.get('benefitName').touched ? 'err' : ''" />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 form-group">
          <label>Points*</label>
          <input type="number" class="form-control" placeholder="Points" onkeypress='return event.charCode >= 48 && event.charCode <= 57'
          formControlName="points" [ngClass]="benefitForm.get('points').hasError('max') || benefitForm.get('points').hasError('required') && benefitForm.get('points').touched ? 'err' : ''" />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 form-group custom-dropdown">
          <label>Hotels*</label>
          <div class='form-control dropdown-toggle select-icon' data-toggle='dropdown' [ngClass]="(benefitForm.get('hotels').hasError('minLengthArray') && touched) ? 'err':''" (click)="touched = true">
              {{templateString('Hotel', benefitForm.get('hotels').value.length)}}
            </div>
            <a class="add-hotel-btn" [routerLink]="['/hotel-management', 'hotel', 'add']">
              <img src='assets/images/plus.svg' class='pull-left' />
            </a>
            <ul class='dropdown-menu checkbox-list' onClick='event.stopPropagation();'>
              <li class='check-list' *ngFor="let hotel of hotels; let i = index">
                <input type='checkbox' [id]="'hotel'+ i+1" [checked]="benefitForm.get('hotels').value.indexOf(hotel.hotelId) >= 0" (change)="checkSelection($event.target.checked, hotel.hotelId);"/>
                <label [for]="'hotel'+ i+1">
                  <span class="ip-text">
                      {{hotel.hotelName}}
                  </span>
                  <span class="check-box"></span>
                </label>
                </li>
            </ul>
        </div>
      </div>
      <div class="row buttons-container">
        <div class="col-sm-8" *ngIf="isNewBenefit">
          <button class="btn btn-cancel col-sm-2 pull-left" (click)="confirmClose()">Cancel</button>
          <button class="btn btn-record col-sm-2 pull-right" [disabled]="!benefitForm.valid" (click)="addBenefit(false)">Record</button>
          <!-- <button class="btn btn-record col-sm-2 pull-right" (click)="addPromo(false)">Record</button> -->
          <button class="btn btn-add col-sm-5 pull-right" [disabled]="!benefitForm.valid" (click)="addBenefit(true)">Record and Add Another</button>
        </div>
        <div class="col-sm-8" *ngIf="!isNewBenefit">
          <button class="btn btn-activate col-sm-3 pull-left" *ngIf="benefitForm.get('status').value == 0"   (click)="openStatusModal([benefitForm.get('benefitId').value], !benefitForm.get('status').value)">Activate</button>
          <button class="btn btn-deactivate col-sm-3 pull-left" *ngIf="benefitForm.get('status').value == 1" (click)="openStatusModal([benefitForm.get('benefitId').value], !benefitForm.get('status').value)">Deactivate</button>
          <button class="btn btn-record col-sm-3 pull-right" [disabled]="!benefitForm.valid" (click)="addBenefit(false)">
            <img src='assets/images/save.svg' class='pull-left'/>Save Changes</button>
          <button class="btn btn-cancel col-sm-2 pull-right" (click)="confirmClose()">Cancel</button>
        </div>
      </div>
      <p class="col-sm-12 error text-center" *ngIf="errorMsg">{{errorMsg}}</p>
      <p class="col-sm-12 success text-center" *ngIf="successMsg">{{successMsg}}</p>
    </form>
  </div>
</div>
