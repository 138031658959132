<!-- <div class="col-xs-12 sample-header" [innerHtml]="headerHtml"></div> -->

<div>
  <div class='row'>
    <div class='col-sm-9 padding-right-10'>
      <ul class='nav nav-tabs rules-tabs'>
        <li class="active">
          <a id="english" *ngIf="!isDirtyEs" data-toggle="tab" href="#lEng">
            <span>English</span>
          </a>
          <a *ngIf="isDirtyEs" class="pointer" (click)="initialise()">
            <span>English</span>
          </a>
        </li>
        <li class="">
          <a id="spanish" *ngIf="!isDirtyEn" data-toggle="tab" href="#lSp">
            <span>Español</span>
          </a>
          <a *ngIf="isDirtyEn" class="pointer" (click)="initialise()">
            <span>Español</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
  <div class="tab-content">
    <!-- English -->
    <div class="tab-pane active" id="lEng">
      <div class="centered-content">
        <div>
          <div class="col-sm-9 tabPaneBorder">
            <div class='editContainer col-sm-9'>
              <form novalidate [formGroup]="loyaltyFormEn">
                <div class='row formDiv margin-top-35'>
                  <div class="col-xs-12">
                    <label class="adminheadingLabelStyle margin-bottom25">
                      <!-- {{'LOYALTY-WEBSITE.TAB1-CUSTOMIZATION' | translate}} -->
                      Header & Footer Customization
                    </label>
                  </div>
                </div>
                <div class='row formDiv'>
                  <div class='col-xs-12 form-group'>
                    <label class='labelStyle'>
                      <!-- {{'LOYALTY-WEBSITE.HEADER-HTML' | translate}} -->
                      Header HTML
                    </label>
                    <button class='refresh-button pull-right' (click)="resetField('headerHtml', 'loyaltyFormEn')">
                      <img src='assets/images/refresh.png' class='refresh-image' title="Reset" />
                    </button>
                    <textarea class='inputTextStyle form-control' formControlName="headerHtml" placeholder="Paste your HTML here..." (focus)="errorDetails = ''"></textarea>
                  </div>
                </div>
                <div class='row formDiv'>
                  <div class='col-xs-12 form-group'>
                    <label class='labelStyle'>
                      <!-- {{'LOYALTY-WEBSITE.HEADER-CSS' | translate}} -->
                      Header CSS
                    </label>
                    <button class='refresh-button pull-right' (click)="resetField('headerCss', 'loyaltyFormEn')">
                      <img src='assets/images/refresh.png' class='refresh-image' title="Reset" />
                    </button>
                    <textarea class='inputTextStyle form-control' formControlName="headerCss" placeholder="Paste your CSS here..." (focus)="errorDetails = ''"></textarea>
                  </div>
                </div>
                <div class='row formDiv'>
                  <div class='col-xs-12 form-group'>
                    <label class='labelStyle'>
                      <!-- {{'LOYALTY-WEBSITE.FOOTER-HTML' | translate}} -->
                      Footer HTML
                    </label>
                    <button class='refresh-button pull-right' (click)="resetField('footerHtml', 'loyaltyFormEn')">
                      <img src='assets/images/refresh.png' class='refresh-image' title="Reset" />
                    </button>
                    <textarea class='inputTextStyle form-control' formControlName="footerHtml" placeholder="Paste your HTML here..." (focus)="errorDetails = ''"></textarea>
                  </div>
                </div>
                <div class='row formDiv'>
                  <div class='col-xs-12 form-group'>
                    <label class='labelStyle'>
                      <!-- {{'LOYALTY-WEBSITE.FOOTER-CSS' | translate}} -->
                      Footer CSS
                    </label>
                    <button class='refresh-button pull-right' (click)="resetField('footerCss', 'loyaltyFormEn')">
                      <img src='assets/images/refresh.png' class='refresh-image' title="Reset" />
                    </button>
                    <textarea class='inputTextStyle form-control' formControlName="footerCss" placeholder="Paste your CSS here..." (focus)="errorDetails = ''"></textarea>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class='col-sm-9 padding-right-0'>
            <div class='buttons-div xtext-right row'>
              <!-- <button class='btn btn-default btn-cancel' (click)="cancel('english')">
                  Cancel
                </button> -->
              <div class="col-sm-6">
                <div class="success col-xs-12 success-align" *ngIf="successMsg">{{successMsg}}</div>
                <div class="error col-xs-12 error-align" *ngIf="errorDetails">{{errorDetails}}</div>
              </div>
              <div class="col-sm-6 text-right">
                <button class='btn btn-default btn-save' (click)="saveForm()">
                  <img src='assets/images/save.svg' class='pull-left' />
                  <span class='pull-left'>
                    <!-- {{'BUTTONS.SAVE' | translate}} -->
                    Save
                  </span>
                </button>
              </div>

              <!-- <button class='btn btn-default btn-save' (click)="openPreview()">
                  <span>Preview</span>
                </button> -->

            </div>
            <!-- <div class="error col-xs-12" *ngIf="errorDetails">{{errorDetails}}</div> -->
            <!-- <div class="success col-xs-12" *ngIf="successMsg">{{successMsg}}</div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- English -->

    <!-- Spanish -->
    <div class="tab-pane" id="lSp">
      <div class="centered-content">
        <div>
          <div class="col-sm-9 tabPaneBorder">
            <div class='editContainer col-sm-9'>
              <form novalidate [formGroup]="loyaltyFormEs">
                <div class='row formDiv margin-top-35'>
                  <div class="col-xs-12">
                    <label class="adminheadingLabelStyle margin-bottom25">
                      <!-- {{'LOYALTY-WEBSITE.TAB1-CUSTOMIZATION' | translate}} -->
                      Encabezado y pie de página Personalización
                    </label>
                  </div>
                </div>
                <div class='row formDiv'>
                  <div class='col-xs-12 form-group'>
                    <label class='labelStyle'>
                      <!-- {{'LOYALTY-WEBSITE.HEADER-HTML' | translate}} -->
                      Encabezamiento HTML
                    </label>
                    <button class='refresh-button pull-right' (click)="resetField('headerHtml', 'loyaltyFormEs')">
                      <img src='assets/images/refresh.png' class='refresh-image' title="Reiniciar" />
                    </button>
                    <textarea class='inputTextStyle form-control' formControlName="headerHtml" placeholder="Pega tu HTML aquí ..." (focus)="errorDetails = ''"></textarea>
                  </div>
                </div>
                <div class='row formDiv'>
                  <div class='col-xs-12 form-group'>
                    <label class='labelStyle'>
                      <!-- {{'LOYALTY-WEBSITE.HEADER-CSS' | translate}} -->
                      Encabezamiento CSS
                    </label>
                    <button class='refresh-button pull-right' (click)="resetField('headerCss', 'loyaltyFormEs')">
                      <img src='assets/images/refresh.png' class='refresh-image' title="Reiniciar" />
                    </button>
                    <textarea class='inputTextStyle form-control' formControlName="headerCss" placeholder="Pega tu CSS aquí ..." (focus)="errorDetails = ''"></textarea>
                  </div>
                </div>
                <div class='row formDiv'>
                  <div class='col-xs-12 form-group'>
                    <label class='labelStyle'>
                      <!-- {{'LOYALTY-WEBSITE.FOOTER-HTML' | translate}} -->
                      Pie de página HTML
                    </label>
                    <button class='refresh-button pull-right' (click)="resetField('footerHtml', 'loyaltyFormEs')">
                      <img src='assets/images/refresh.png' class='refresh-image' title="Reiniciar" />
                    </button>
                    <textarea class='inputTextStyle form-control' formControlName="footerHtml" placeholder="Pega tu HTML aquí ..." (focus)="errorDetails = ''"></textarea>
                  </div>
                </div>
                <div class='row formDiv'>
                  <div class='col-xs-12 form-group'>
                    <label class='labelStyle'>
                      <!-- {{'LOYALTY-WEBSITE.FOOTER-CSS' | translate}} -->
                      Pie de página CSS
                    </label>
                    <button class='refresh-button pull-right' (click)="resetField('footerCss', 'loyaltyFormEs')">
                      <img src='assets/images/refresh.png' class='refresh-image' title="Reiniciar" />
                    </button>
                    <textarea class='inputTextStyle form-control' formControlName="footerCss" placeholder="Pega tu CSS aquí ..." (focus)="errorDetails = ''"></textarea>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class='col-sm-9 padding-right-0'>
            <div class='row buttons-div text-right'>
              <!-- <button class='btn btn-default btn-cancel' (click)="cancel('spanish')">
                  Cancelar
                </button> -->
              <div class="col-sm-6">
                <div class="error col-xs-12 error-align" *ngIf="errorDetails">{{errorDetails}}</div>
                <div class="success col-xs-12 success-align" *ngIf="successMsg">{{successMsg}}</div>
              </div>
              <div class="col-sm-6">
                <button class='btn btn-default btn-save' (click)="saveForm()">
                  <img src='assets/images/save.svg' class='pull-left' />
                  <span class='pull-left'>
                    <!-- {{'BUTTONS.SAVE' | translate}} -->
                    Salvar
                  </span>
                </button>
              </div>
            </div>
            <!-- <button class='btn btn-default btn-save' (click)="openPreview()">
                  <span>Avance</span>
                </button> -->
          </div>
          <!-- <div class="error col-xs-12" *ngIf="errorDetails">{{errorDetails}}</div>
            <div class="success col-xs-12" *ngIf="successMsg">{{successMsg}}</div> -->
        </div>
      </div>
    </div>
  </div>
  <!-- Spanish -->



  <!-- <div class="col-xs-12 sample-footer" [innerHtml]="footerHtml"></div> -->
</div>
<!-- </div> -->