<!-- Navbar Container -->
<app-navbar></app-navbar>

<div class='col-sm-12 col-sm-offset-3 new-report-container'>
  <h3>Traceability Reports</h3>
  <form novalidate [formGroup]="report">
    <div class='row formDiv'>
      <h4 class="padding-left-15 margin-bottom25">
        <b>Generate reports for the following parameters.</b>
      </h4>
      <div class='form-group col-sm-12 row'>
        <div class="row">
          <div class='col-sm-3'>
            <label>Date From*</label>
            <my-date-picker
              name="dateFrom"
              formControlName="dateFrom"
              placeholder="Start Date"
              [options]="myDatePickerOptions"
              [ngClass]="report.hasError('datesOverlapped') || report.get('dateFrom').hasError('dateRangeError') || report.get('dateFrom').hasError('required') && report.get('dateFrom').touched ? 'err' : ''"
              (dateChanged)="onDateChanged($event, 'dateFrom')"></my-date-picker>
          </div>
          <div class='col-sm-3'>
            <label>Date To*</label>
            <my-date-picker
              name="dateTo"
              formControlName="dateTo"
              placeholder="End Date"
              [options]="myDatePickerOptions"
              [ngClass]="report.hasError('datesOverlapped') || report.get('dateTo').hasError('dateRangeError') || report.get('dateTo').hasError('required') && report.get('dateTo').touched ? 'err' : ''"
              (dateChanged)="onDateChanged($event, 'dateTo')"></my-date-picker>
          </div>
          <p class="error col-xs-9 text-center" *ngIf="report.get('dateTo').hasError('dateRangeError') || report.get('dateFrom').hasError('dateRangeError')">Date From must not be greater than Date To</p>
        </div>
        <div class="row">
          <div class="form-group col-sm-2 select-icon">
            <label>Operation type *</label>
            <select class='form-control' formControlName="operationType" (focus)="errorDetails = ''" [ngClass]="report.get('operationType').hasError('required') && report.get('operationType').touched ? 'err':''">
              <option value="">Choose Operation type</option>
              <option *ngFor="let operation of reportOperations" [value]="operation.id">{{operation.name}}</option>
            </select>
          </div>
          <div class="form-group col-sm-2 select-icon">
            <label>Fiscal Origin *</label>
            <select class='form-control' formControlName="fiscalOrigin" (focus)="errorDetails = ''" [ngClass]="report.get('fiscalOrigin').hasError('required') && report.get('fiscalOrigin').touched ? 'err':''">
              <option value="">Choose Fiscal Origin</option>
              <option *ngFor="let origin of fiscalOrigins" [value]="origin.id">{{origin.name}}</option>
            </select>
          </div>
          <div class="form-group col-sm-2 select-icon" *ngIf="report.value.operationType == 'EXC'">
            <label>Fiscal Destination *</label>
            <select class='form-control' formControlName="fiscalDestination" (focus)="errorDetails = ''" [ngClass]="report.get('fiscalDestination').hasError('required') && report.get('fiscalDestination').touched ? 'err':''">
              <option value="">Choose Fiscal Destination</option>
              <option *ngFor="let destination of fiscalOrigins" [value]="destination.id">{{destination.name}}</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="buttonsDiv form-group col-sm-6 text-right">
            <button class='btn btn-default' (click)="generateReport()" [disabled]="!report.valid">Send</button>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="text-danger" *ngIf="errorDetails">{{errorDetails}}</div>
  <div *ngIf="documentUrl">
    <h4>You can download the report <a target="_blank" href="{{documentUrl}}">here</a>.</h4>
  </div>
</div>
