<div class="table-responsive booking-container">
  <h3 *ngIf="!bookingList?.length" class="text-center">No Booking History found</h3>
  <table *ngIf='bookingList?.length' class="table">
    <thead>
      <tr>
        <th>Locator</th>
        <th>Origin</th>
        <th>Agency</th>
        <th>Treatment</th>
        <th>Hotel</th>
        <th>Check In</th>
        <th>Check Out</th>
        <th>Status</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let booking of bookingList | paginate: { itemsPerPage: size, currentPage: p, totalItems: totalCount }">
        <td>{{booking.locator}}</td>
        <td>{{booking.origin ? booking.origin : 'N/A'}}</td>
        <td>{{booking.agency ? booking.agency : 'N/A'}}</td>
        <td>{{booking.treatment}}</td>
        <td>{{booking.hotelName}}</td>
        <td>{{booking.checkInDate}}</td>
        <td>{{booking.checkOutDate}}</td>
        <td class="">
            <div class='dropdown status'>
              <span [ngClass]="booking.status == 'Active' ? 'activate' : 'inactivate' ">{{booking.status}}</span>
                <button type='button' class='btn btn-link dropdown-toggle' data-toggle="dropdown">
                  <img src='assets/images/overflow.svg' />
                </button>
                <ul class="dropdown-menu statusList">
                  <li class="pointer">
                    <a class='deactivate' *ngIf="booking.statusId == 1" (click)="openModalActivateDeactivate(booking.bookingId,'Active')">
                      <img class='pull-left' src='assets/images/delete.svg' />
                      <span class='pull-left'>Deactivate</span>
                    </a>
                  </li>
                  <li class="pointer">
                    <a class='activate' *ngIf="booking.statusId == 0" (click)="openModalActivateDeactivate(booking.bookingId,'Inactive')">
                      <img class='pull-left' src='assets/images/success.svg' />
                      <span class='pull-left'>Activate</span>
                    </a>
                  </li>
                </ul>
              </div>
        </td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <td class="tfooter" colspan="8"> <pagination-controls class="pull-right"  (pageChange)="getBookingData($event)"></pagination-controls></td>
      </tr>
    </tfoot>
  </table>
</div>
