<div class='col-sm-9 col-sm-offset-3 loyalty-programs-container'>
    <div class='heading'>
        <a class="dropdown-toggle" data-toggle="dropdown">{{selectedBrand && selectedBrand.brandName ? selectedBrand.brandName : ''}}
            <span class="glyphicon glyphicon-chevron-down pointer"></span>
        </a>
        <ul class="dropdown-menu custom-dropdown-menu">
            <li *ngFor="let brand of hotelBrands" class="pointer" (click)="selectBrand(brand)" [class.active-brand]="selectedBrand.brandId == brand.brandId">
                <a>
                    <img *ngIf="selectedBrand.brandId == brand.brandId" src='assets/images/success.svg' class='pull-right success' /> {{brand.brandName}}
                </a>
            </li>
        </ul>
    </div>
    <ul class="nav nav-tabs loyalty-tabs">
        <li class="active">
            <!-- <a data-toggle="tab" href="#header">{{'LOYALTY-WEBSITE.TAB1' | translate}}</a> -->
            <a data-toggle="tab" href="#header" (click)="changeTabs()" *ngIf="hotelBrands.length">{{'Header & Footer'}}</a>
        </li>
        <li>
            <!-- <a data-toggle="tab" href="#font">{{'LOYALTY-WEBSITE.TAB2' | translate}}</a> -->
            <a data-toggle="tab" href="#font" (click)="changeTabs()" *ngIf="hotelBrands.length">{{'Font & Color Scheme'}}</a>
        </li>
        <li>
            <!-- <a data-toggle="tab" href="#landing">{{'LOYALTY-WEBSITE.TAB3' | translate}}</a> -->
            <a data-toggle="tab" href="#landing" (click)="changeTabs()" *ngIf="hotelBrands.length">{{'Landing Page'}}</a>
        </li>
        <li>
            <!-- <a data-toggle="tab" href="#others">{{'LOYALTY-WEBSITE.TAB4' | translate}}</a> -->
            <a data-toggle="tab" href="#others" (click)="changeTabs()" *ngIf="hotelBrands.length">{{'Other Pages'}}</a>
        </li>
    </ul>

    <div class="tab-content">
        <div id="header" class="tab-pane fade in active" *ngIf="hotelBrands.length">
            <app-hca-loyalty-website-header-footer  *ngFor="let r of reset"></app-hca-loyalty-website-header-footer>
        </div>
        <div id='font' class='tab-pane fade' *ngIf="hotelBrands.length">
            <app-hca-loyalty-website-font-color *ngFor="let r of reset"></app-hca-loyalty-website-font-color>
        </div>
        <div id='landing' class='tab-pane fade' *ngIf="hotelBrands.length">
            <app-hca-loyalty-website-landing-page *ngFor="let r of reset"></app-hca-loyalty-website-landing-page>
        </div>
        <div id='others' class='tab-pane fade'  *ngIf="hotelBrands.length">
            <app-loyalty-website-other-pages *ngFor="let r of reset"></app-loyalty-website-other-pages>
        </div>
    </div>
</div>