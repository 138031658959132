<div class="add-comment-container">
  <div class="modal-body">
    <p class='heading'>Add New Comment</p>
    <form [formGroup]="comments">
      <div class="form-group">
        <label class="labelStyle">Comment*</label>
        <textarea class="form-control" rows='4' formControlName="comments" [ngClass]="comments.get('comments').hasError('maxlength') || comments.get('comments').hasError('required') && comments.get('comments').touched ? 'err': ''"></textarea>
      </div>
      <div class="form-group">
        <label class="labelStyle">Tags</label>
        <input [(ngModel)]="tagInputText" [ngModelOptions]="{standalone: true}" style="display: none" />
        <tag-input formControlName='tags' [(inputText)]="tagInputText" [(ngModel)]="items" [onTextChangeDebounce]="300" [theme]="'minimal'"
          [onlyFromAutocomplete]="true" [identifyBy]="'hotelTagId'" [displayBy]="'name'" [placeholder]="''" (keydown)="searchTerm$.next($event.target.value)"
          (focus)="errorDetails = ''; errorUpload = false">
          <tag-input-dropdown appendToBody=false [showDropdownIfEmpty]="false" [focusFirstElement]="true" [displayBy]="'name'" [identifyBy]="'hotelTagId'"
            [autocompleteObservable]="hotelTags">
            <ng-template let-item="item" let-index="index">
              {{ item.name }}
            </ng-template>
          </tag-input-dropdown>
        </tag-input>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button class="btn btn-cancel" (click)='closeModal()'>Cancel</button>
    <button class="btn btn-record" [disabled]="!comments.valid" (click)='save()'>
      <span>Done</span>
    </button>
    <p class="col-sm-12 text-center success" *ngIf="successMsg">{{successMsg}}</p>
    <p class="col-sm-12 text-center error" *ngIf="errorMsg">{{errorMsg}}</p>
  </div>
</div>