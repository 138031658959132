<div class="col-sm-10">
  <div class='editContainer'>
    <h3>Modify Hotel Chain</h3>
    <form novalidate [formGroup]="hotelChain">
      <div class='row'>
        <div class='formDiv'>
          <div class="form-group col-sm-5">
            <label>Hotel Chain Name*</label>
            <input type="text" placeholder="" formControlName="hotelChainName" class='form-control' [ngClass]="hotelChain.get('hotelChainName').hasError('required') && hotelChain.get('hotelChainName').touched ? 'err':''"
              (focus)="errorDetails = ''">
          </div>
          <div class="form-group col-sm-5">
            <label>Email ID*</label>
            <input type="email" placeholder="" formControlName="contactEmail" class='form-control' disabled>
          </div>
        </div>
        <div class='formDiv'>
          <div class="form-group col-sm-5">
            <label>First Name*</label>
            <input type="text" placeholder="" formControlName="firstName" class='form-control' [ngClass]="hotelChain.get('firstName').hasError('required') && hotelChain.get('firstName').touched ? 'err':''"
              (focus)="errorDetails = ''">
          </div>
          <div class="form-group col-sm-5">
            <label>Last Name*</label>
            <input type="text" placeholder="" formControlName="lastName" class='form-control' [ngClass]="hotelChain.get('lastName').hasError('required') && hotelChain.get('lastName').touched ? 'err':''"
              (focus)="errorDetails = ''">
          </div>
        </div>
        <div class='formDiv'>
          <div class="form-group col-sm-5">
            <label>Phone Number</label>
            <input type="tel" placeholder="" formControlName="contactMobile" class='form-control' [ngClass]="hotelChain.get('contactMobile').hasError('pattern') || hotelChain.get('contactMobile').hasError('minlength') || hotelChain.get('contactMobile').hasError('maxlength')?'err':''"
              (focus)="errorDetails = ''">
            <!-- <div class="error" style="width:288px;margin-top:0" *ngIf="hotelChain.get('contactMobile').hasError('pattern')">
                Incorrect Phone number
            </div> -->
          </div>
          <div class="form-group col-sm-5">
            <label>Hotel Chain Website URL</label>
            <input type="text" placeholder="" formControlName="websiteUrl" class='form-control' (focus)="errorDetails = ''" readonly>
          </div>
        </div>
        <div class='formDiv'>
          <div class="form-group col-sm-5">
            <label>Address</label>
            <textarea formControlName="address" class='form-control' rows="3" (focus)="errorDetails = ''"></textarea>
          </div>
        </div>
        <div class='formDiv'>
          <div class="form-group col-sm-5 logo-container row">
            <div class='col-xs-7'>
              <h5>Hotel Chain Logo</h5>
              <label class="image-name">{{imageFile.imageUrl ? imageFile.name : '(no file chosen)'}}</label>
              <input type="file" id="file_upload" class="form-control" #uploadFile (focus)="errorDetails = ''" (change)="uploadImage($event)">
              <button (click)="uploadFile.click()" class=" btn-file">{{imageFile.imageUrl ? 'Change File' : 'Choose File'}}</button>
              <!-- <button *ngIf="!imageFile.imageUrl" (click)="uploadFile.click()" class='btn-add btn-file'>Choose File</button>
              <button *ngIf="imageFile.imageUrl" (click)="uploadFile.click()" class='btn-add btn-file'>Change File</button> -->
              <p *ngIf="errorUpload" class="error upload col-xs-12" (click)="imageFile.errorUpload = false; errorUpload = false;">Please upload images only.</p>
              <p *ngIf="fileSizeError" class="error upload col-xs-12" (click)="imageFile.fileSizeError = false; fileSizeError = false;">Image size should be less than 1MB.</p>
            </div>
            <div class='col-xs-5 text-right' *ngIf="imageFile && imageFile.name && imageFile.imageUrl && !imageFile.errorUpload && !imageFile.fileSizeError">
              <div>
                <img class='image' [src]="imageFile.imageUrl" />
                <button class='btn btn-default delete-image'>
                  <img src='assets/images/delete.svg' (click)="deleteImage()" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class='buttonsDiv col-sm-10'>
      <button *ngIf="hcaStatus == 'Active'" class='btn btn-default col-sm-4 col-xs-12 in-active' data-toggle="modal" data-target="#myModal">Deactivate</button>
      <button *ngIf="hcaStatus == 'Inactive'" class='btn btn-default col-sm-4 col-xs-12 activate' data-toggle="modal" data-target="#myActivateModal">Activate</button>
      <button [routerLink]="['/hca-management']" class='btn btn-default col-xs-12 col-sm-3'>Cancel</button>
      <button (click)="modifyHotelchain()" class='btn btn-default col-sm-4 col-xs-12' [disabled]="!hotelChain.valid">Save Changes</button>
    </div>
    <div class="error col-xs-12" *ngIf="errorDetails">{{errorDetails}}</div>
  </div>
  <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title" id="exampleModalLabel">Deactivate HCA Account</h3>
          <p>Are you sure you want to deactivate this account?</p>
        </div>
        <div class="modal-footer text-center">
          <button type="button" class="btn btn-default" id="deleteModal" data-dismiss="modal">Cancel</button>
          <button type="button" (click)="changeHCAStatus('Deactivate')" class="btn btn-default">Deactivate</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="myActivateModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title" id="exampleModalLabel">Activate HCA Account</h3>
          <p>Are you sure you want to activate this account?</p>
        </div>
        <div class="modal-footer text-center">
          <button type="button" class="btn btn-default" id="activateModal" data-dismiss="modal">Cancel</button>
          <button type="button" (click)="changeHCAStatus('Activate')" class="btn btn-default">Activate</button>
        </div>
      </div>
    </div>
  </div>