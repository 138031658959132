<div class="transaction-container">
  <div class="row">
    <div class="col-sm-6 dropdown">
    </div>
    <div class="col-sm-6 text-right">
      <button class="btn btn-add-transaction" (click)="openModalAddComment()">
        <img src='assets/images/plus.svg' class="pull-left" />
        <span>Add Comment</span>
      </button>
    </div>
  </div>

  <div class="table-responsive">
    <h3 *ngIf="!commentsList?.length" class="text-center">No Comments found</h3>
    <table class="table transaction-table" *ngIf="commentsList?.length">
      <thead>
        <tr>
          <th *ngFor="let header of tableHeader; let idx = index" class="pointer" (click)="sortTable(header.property)">{{header.key}}
            <span class="sort-icons up pointer" *ngIf="header.direction != null && header.direction == 'asc'"> &#9660;</span>
            <span class="sort-icons down pointer" *ngIf="header.direction != null && header.direction == 'desc'"> &#9650;</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let comment of commentsList | paginate: { itemsPerPage: size, currentPage: p, totalItems: totalCount }">
          <td>{{comment.createdDate}}</td>
          <td>{{comment.comments}}</td>
          <!-- <td>{{comment.transactionId ? comment.transactionId : 'N/A'}}</td>
          <td>{{comment.bookingLocator ? comment.bookingLocator : 'N/A'}}</td>
          <td>{{comment.points ? comment.points : 0}}</td> -->
          <td>
            {{comment.name}}
          </td>
          <td>
            <button class="btn btn-delete" (click)="openStatusModal(comment?.id, 0)">
              <img src='assets/images/delete.svg' />
            </button>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td class="tfooter" colspan="8">
            <pagination-controls class="pull-right" (pageChange)="pageChanged($event)"></pagination-controls>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</div>
