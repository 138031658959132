<div class="transaction-container">
  <div class="table-responsive">
    <h3 *ngIf="!transactionList?.length" class="text-center">No Transaction History found</h3>
    <table class="table transaction-table" *ngIf="transactionList?.length">
      <thead>
        <tr>
          <th *ngFor="let header of tableHeader; let idx = index" class="pointer" (click)="sortTable(header.property, ['transactionTypeName', 'points', 'cashPaid', 'quantity', 'nights'])">{{header.key}}
            <span class="sort-icons up pointer" *ngIf="header.direction != null && header.direction == 'asc'"> &#9660;</span>
            <span class="sort-icons down pointer" *ngIf="header.direction != null && header.direction == 'desc'"> &#9650;</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let transaction of transactionList | paginate: { itemsPerPage: size, currentPage: p, totalItems: totalCount }">
          <td>
            <span>{{transaction.transactionDateStr}}</span>
            <br/>
            <span [ngClass]="(transaction.status?.statusId == 4 || transaction.status?.statusId == 0) ? 'inactive' : (transaction.status?.statusDesc == 3 ? 'pending' : 'active')">{{transaction.status?.statusDesc}}</span>
          </td>
          <td>
            <span>{{transaction.transactionTypeName}}</span>
            <br/>
            <span>{{transaction.hotelName}}</span>
          </td>
          <td>{{transaction.points ? transaction.points : 0}}</td>
          <td>${{transaction.cashPaid ? transaction.cashPaid : 0}}</td>
          <td>{{transaction.quantity ? transaction.quantity : 0}}</td>
          <td>{{transaction.nights ? transaction.nights : 'N/A'}}</td>
          <td>{{transaction.updatedDateStr}}</td>
          <!-- <td class="dropdown">
            <button class="btn btn-link dropdown-toggle" data-toggle='dropdown'>
              <img src='assets/images/overflow.svg' />
            </button>
            <ul class="dropdown-menu custom-dropdown-menu pointer">
              <li *ngIf="transaction.status?.statusId != 0" (click)="openStatusModal(transaction?.pointTransactionId, 0)">
                <a class="inactive">Deactivate Transaction</a>
              </li>
              <li *ngIf="transaction.status?.statusId == 0" (click)="openStatusModal(transaction?.pointTransactionId, 1)">
                <a class="active">Activate Transaction</a>
              </li>
            </ul>
          </td> -->
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td class="tfooter" colspan="7">
            <pagination-controls class="pull-right" (pageChange)="pageChanged($event)"></pagination-controls>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</div>