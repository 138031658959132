<div class="add-transaction">
  <div class="modal-body">
    <p class="heading">Add Transaction</p>
    <form [formGroup]="transactionForm">
      <div class="row select">
        <div class="col-sm-8 form-group select-icon">
          <label class="labelStyle">Transaction Type*</label>
          <select class="form-control" formControlName="pointsTransTypeId" (change)="selectTransactionType($event.target.value)" [ngClass]="transactionForm.get('pointsTransTypeId').hasError('required') && transactionForm.get('pointsTransTypeId').touched ? 'err': ''"  >
            <option value=null selected disabled>Select Transaction Type</option>
            <option *ngFor="let transaction of transactionTypes" [value]="transaction.id">{{transaction.transactionTypeEn}}</option>
          </select>
        </div>
      </div>
      <div class="col-sm-6 form-group select-icon" *ngIf="isRegularization" >
        <label class="labelStyle">Fiscal Origin</label>
        <select class="form-control" formControlName="fiscalOrigin">
          <option value="null" selected disabled>Select Fiscal Origin</option>
          <option class="form-control" value="CENTRAL MX"  >CENTRAL MX</option>
          <option value="EPI ESPANA" class="form-control" >EPI ESPANA</option>
        </select>
      </div>
      <div class="row">
        <div class="col-sm-5 form-group">
          <label class="labelStyle">Point Amount*</label>
          <input type='number' class="form-control" formControlName="points" [ngClass]="transactionForm.get('points').hasError('pattern') || transactionForm.get('points').hasError('min') || transactionForm.get('points').hasError('max') || transactionForm.get('points').hasError('required') && transactionForm.get('points').touched ? 'err': ''" onkeydown="javascript: return event.keyCode == 69 ? false : true" [class.disabled]="transactionForm.get('pointsTransTypeId').value == 1"/>
          <!-- <input type='number' class="form-control" formControlName="points" [ngClass]="transactionForm.get('points').hasError('required') && transactionForm.get('points').touched ? 'err': ''" onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57"/> -->
        </div>
        <!--
        <div class="form-group col-sm-5" *ngIf="isReservation">
          <div class="icon">
            <label class="labelStyle">Surname*</label>
            <input type="text" class="form-control"  [ngClass]="isRelatedBookingError ? 'err' : ''" formControlName="pmsSurname1" onkeydown="javascript: return /^[\w\-\s]*$/.test(event.key) ? true : false"/>
          </div>
        </div> -->
      </div>
      <div class="row">
        <div class="col-xs-12 form-group">
          <label class="labelStyle">Comments</label>
          <textarea class="form-control" placeholder="Text..." rows='3' formControlName="description" [ngClass]="transactionForm.get('description').hasError('maxlength') ? 'err' : ''"></textarea>
        </div>
      </div>
      <div class="row search">
        <div class="form-group col-sm-6 dropdown select-div">
          <div class="icon">
            <label class="labelStyle">Related Transaction</label>
            <!-- <input type="number" class="form-control select dropdown-toggle" [ngClass]="isRelatedTransError ? 'err' : ''" data-toggle='dropdown' (keyup)="transactionTerm$.next($event.target.value)" formControlName="relatedTransactionId" onkeydown="javascript: return event.keyCode == 69 ? false : true"/> -->
            <input type="text" class="form-control select dropdown-toggle" [ngClass]="isRelatedTransError ? 'err' : ''" data-toggle='dropdown' (keyup)="transactionTerm$.next($event.target.value)" formControlName="relatedTransactionId" onkeydown="javascript: return /^[\w\-\s]*$/.test(event.key) ? true : false"/>
            <ul class="dropdown-menu checkbox-list col-sm-6" style="left: 13px;" *ngIf="searchedTransactions?.length">
              <ng-container *ngFor="let transaction of searchedTransactions; let i = index" >
                <li class="check-list col-sm-12" (click)="selectDropdown(transaction.pointTransactionId, 'transaction')" [class.active]="transactionForm.get('relatedTransactionId').value == transaction" style="padding: 4px 5px;">
                  <label class="col-sm-10 padding-0">
                    <p class="ip-text"><strong>Locator:&nbsp;</strong><span>{{transaction?.locator}}</span></p>
                    <p class="ip-text"><strong>Transaction Type:&nbsp;</strong><span>{{transaction?.transactionTypeName}}</span></p>
                    <p class="ip-text"><strong>Points:&nbsp;</strong><span>{{transaction?.points}}</span></p>
                    <p class="ip-text"><strong>Status:&nbsp;</strong>
                      <span [ngClass]="(transaction.status?.statusId == 4 || transaction.status?.statusId == 0) ? 'inactive' : (transaction.status?.statusId == 3 ? 'pending' : 'active')">{{transaction?.status?.statusDesc}}</span>
                    </p>
                  </label>
                  <span class="active-icon">
                    <img class='pull-right success' src='assets/images/success.svg' *ngIf="transactionForm.get('relatedTransactionId').value == transaction" />
                  </span>
                </li>
                <li class="divider" *ngIf="i < searchedTransactions.length - 1"></li>
              </ng-container>
            </ul>
          </div>
        </div>
          <div class="form-group col-sm-6 dropdown select-div">
            <div class="icon">
              <label class="labelStyle">Booking</label>
              <input type="text" class="form-control select dropdown-toggle" [ngClass]="isRelatedBookingError ? 'err' : ''" data-toggle='dropdown' (keyup)="bookingTerm$.next($event.target.value)" formControlName="relatedBookingId" onkeydown="javascript: return /^[\w\-\s]*$/.test(event.key) ? true : false"/>
              <ul class="dropdown-menu checkbox-list col-sm-6" style="left: 13px;" *ngIf="searchedBookings?.length">
                <ng-container *ngFor="let booking of searchedBookings; let i = index">
                  <li class="check-list col-sm-12" (click)="selectDropdown(booking?.bookingId, 'booking')" [class.active]="transactionForm.get('relatedBookingId').value == booking" style="padding: 4px 5px;" *ngIf="booking?.locator || booking?.pmsBonus || booking?.pmsEntitler">
                    <label class="col-sm-10 padding-0">
                      <p class="ip-text" *ngIf="booking?.locator"><strong>Locator:&nbsp;</strong><span>{{booking?.locator}}</span></p>
                      <p class="ip-text" *ngIf="booking?.pmsBonus"><strong>PMS Bonus:&nbsp;</strong><span>{{booking?.pmsBonus}}</span></p>
                      <p class="ip-text" *ngIf="booking?.pmsEntitler"><strong>PMS Title:&nbsp;</strong><span>{{booking?.pmsEntitler}}</span></p>
                    </label>
                    <span class="active-icon">
                      <img class='pull-right success' src='assets/images/success.svg' *ngIf="transactionForm.get('relatedBookingId').value == booking"/>
                    </span>
                  </li>
                  <li class="divider" *ngIf="i < searchedBookings.length - 1"></li>
                </ng-container>
              </ul>
            </div>
          </div>
      </div>
      <!-- <div class="row select">
        <div class="col-sm-6 form-group select-icon">
          <label class="labelStyle">Person type</label>
          <select class="form-control" formControlName="personType">
            <option value="1">Adult</option>
          </select>
        </div>
      </div> -->
    </form>
  </div>
  <div class="modal-footer">
    <button class="btn btn-cancel" (click)='closeModal()'>Cancel</button>
    <button class="btn btn-record save" (click)="save(false)" [disabled]="!transactionForm.valid || isRelatedTransError || isRelatedBookingError">
      <img src='assets/images/save.svg' class="pull-left"/>
      <span>Record</span>
    </button>
    <ng-container *ngIf="pointsTransTypeData ">

      <button class="btn btn-record" (click)="save(true)" [disabled]="!transactionForm.valid || isRelatedTransError || isRelatedBookingError">
        <img src='assets/images/approve_white.svg' class="pull-left"/>
        <span>Approve</span>
      </button>
    </ng-container>
    <p class="col-sm-12 text-center success" *ngIf="successMsg">{{successMsg}}</p>
    <p class="col-sm-12 text-center error" *ngIf="errorMsg">{{errorMsg}}</p>
  </div>
</div>
