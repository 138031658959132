<div class='col-sm-9 col-sm-offset-3 loyalty-programs-container'>
  <div class='row formDiv'>
    <form [formGroup]="loyalty">
      <div class='col-sm-5 form-group'>
        <label class="labelStyle">Loyalty Program Name*</label>
        <input type='text' class='form-control inputTextStyle' [ngClass]="loyalty.get('programmeName').hasError('required') && loyalty.get('programmeName').touched ? 'err':''"
               formControlName="programmeName" />
      </div>
    </form>
  </div>

  <ul class="nav nav-tabs loyalty-tabs">
    <li class="active"><a data-toggle="tab" href="#home">Name + Point Rules</a></li>
    <li><a data-toggle="tab" href="#levels">Membership Levels</a></li>
  </ul>

    <div class="tab-content">

        <!-- Name + Point Rules -->

        <div id="home" class="tab-pane fade in active">
            <ul class='nav nav-tabs rules-tabs'>
                <li class="" #consumerProgram>
                    <a 
                        data-toggle="tab" 
                        href="#consumerProgram" 
                        [ngClass]="loyalty.controls['programType'].value.indexOf(1) > -1 ? '' : 'disabled'"
                    >
                        <span>Consumer Program</span>
                    </a>
                </li>
                <span 
                    *ngIf="loyalty.controls['programType'].value.length 
                        && loyalty.controls['programType'].value.indexOf(1) > -1" 
                    class="pull-left icon"
                    (click)="$event.preventDefault();
                        addFormControl('programType', {loyaltyProgramTypeId: 1}, false, false)"
                >
                    <img class="pull-left" src="assets/images/minus-orange.svg">
                </span>
                <span 
                    *ngIf="loyalty.controls['programType'].value.indexOf(1) == -1" 
                    class="pull-left icon plus" 
                    (click)="$event.preventDefault();
                        addFormControl('programType', {loyaltyProgramTypeId: 1}, true, false)"
                >
                    <img class="pull-left" src="assets/images/plus-orange.svg">
                </span>
                <li #employeeProgram>
                    <a 
                        data-toggle="tab" 
                        href="#employeeProgram" 
                        [ngClass]="loyalty.controls['programType'].value.indexOf(2) > -1 ? '' : 'disabled'"
                    >
                        <span>Employee Program</span>
                    </a>
                </li>
                <span 
                    *ngIf="loyalty.controls['programType'].value.length 
                        && loyalty.controls['programType'].value.indexOf(2) > -1" 
                    class="pull-left icon"
                    (click)="$event.preventDefault();
                        addFormControl('programType', {loyaltyProgramTypeId: 2}, false, false)"
                >
                    <img class="pull-left" src="assets/images/minus-orange.svg">
                </span>
                <span 
                    *ngIf=" loyalty.controls['programType'].value.indexOf(2) == -1" 
                    class="pull-left icon plus" 
                    (click)="$event.preventDefault();
                        addFormControl('programType', {loyaltyProgramTypeId: 2}, true, false)"
                >
                    <img class="pull-left" src="assets/images/plus-orange.svg">
                </span>
                <li #travelAgent>
                    <a 
                        data-toggle="tab" 
                        href="#travelAgent" 
                        [ngClass]="loyalty.controls['programType'].value.indexOf(3) > -1 ? '' : 'disabled'"
                    >
                        <span>Travel Agent</span>
                    </a>
                </li>
                <span 
                    *ngIf="loyalty.controls['programType'].value.length 
                        && loyalty.controls['programType'].value.indexOf(3) > -1" 
                    class="pull-left icon"
                    (click)="$event.preventDefault();
                        addFormControl('programType', {loyaltyProgramTypeId: 3}, false, false)"
                >
                    <img class="pull-left" src="assets/images/minus-orange.svg">
                </span>
                <span 
                    *ngIf=" loyalty.controls['programType'].value.indexOf(3) == -1" 
                    class="pull-left icon plus" 
                    (click)="$event.preventDefault();
                        addFormControl('programType', {loyaltyProgramTypeId: 3}, true, false)"
                >
                    <img class="pull-left" src="assets/images/plus-orange.svg">
                </span>
            </ul>
            <div class="tab-content">

                <!-- loyalty consumer tab -->
                <div #consumerContentProgram class="tab-pane active" id="consumerProgram">
                    <div class="centered-content">
                        <div>
                            <div class="col-sm-12 tabPaneBorder step-2">
                                <div class='editContainer col-sm-12'>
                                    <form [formGroup]="consumerProgramType">
                                        <div class='row formDiv margin-top-35'>
                                            <div class="col-sm-8 form-group">
                                                <label class="adminheadingLabelStyle margin-bottom25">
                                                    Choose the type of bookings guests are awarded points for (check at least one)*
                                                </label>
                                                <div class='checkbox-list row'>
                                                    <div *ngFor="let type of bookingTypesForConsumer">
                                                        <input
                                                            type='checkbox' 
                                                            name="booking" 
                                                            (focus)="errorDetails = ''" 
                                                            [id]="'booking'+type.bookingTypeId" 
                                                            [checked]="loyalty.controls['bookingType'].value.indexOf(type.bookingTypeId) > -1"
                                                            [value]="type.bookingType" 
                                                            (change)="addFormControl('bookingType', type, $event.target.checked, false)"
                                                        />
                                                        <label [for]="'booking'+type.bookingTypeId" class='col-sm-4'>
                                                            <span class='check-box'></span>
                                                            <span>{{type.bookingType}}</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class='row formDiv margin-top'>
                                            <div class='col-sm-8 form-group'>
                                                <p class="adminheadingLabelStyle">How Points are calculated</p>
                                                <p class="adminPermissionDivText">
                                                    Setup rules for how loyalty points are calculated and which guests can earn them. Points are calculated based on (B% of Cost
                                                    + E% of Cost of Extras) / Number of valid people in booking / Coefficient
                                                </p>
                                            </div>
                                        </div>
                                        <div *ngIf="loyalty.controls['bookingType'].value.indexOf(1) > -1" formGroupName="directBooking">

                                            <div class='formDiv row' formGroupName="points">
                                                <p class='padding-left-15 margin-top25'>
                                                    <strong>Direct Bookings*</strong>
                                                </p>

                                                <div class="form-group col-sm-4">
                                                    <label class="labelStyle">% of Cost of Booking (B)</label>
                                                    <input 
                                                        type="number" 
                                                        onkeypress='return event.charCode >= 48 && event.charCode <= 57' 
                                                        placeholder="" 
                                                        class='form-control inputTextStyle'
                                                        [ngClass]="consumerProgramType.get('directBooking').get('points').get('costOfBooking').hasError('max') 
                                                            || consumerProgramType.get('directBooking').get('points').get('costOfBooking').hasError('required') 
                                                            && consumerProgramType.get('directBooking').get('points').get('costOfBooking').touched ? 'err':''"
                                                        formControlName="costOfBooking">
                                                </div>

                                                <div class="form-group col-sm-4">
                                                    <label class="labelStyle">% of Cost of Extras (E)</label>
                                                    <input 
                                                        type="number" 
                                                        onkeypress='return event.charCode >= 48 && event.charCode <= 57' 
                                                        placeholder="" class='form-control inputTextStyle'
                                                        [ngClass]="consumerProgramType.get('directBooking').get('points').get('costOfExtras').hasError('max') 
                                                            || consumerProgramType.get('directBooking').get('points').get('costOfExtras').hasError('required') 
                                                            && consumerProgramType.get('directBooking').get('points').get('costOfExtras').touched ? 'err':''"
                                                        formControlName="costOfExtras"
                                                    >
                                                </div>

                                                <div class="form-group col-sm-4">
                                                    <label class="labelStyle">% Coefficient (decimal value)</label>
                                                    <input 
                                                        type="number" 
                                                        placeholder="" 
                                                        class='form-control inputTextStyle' 
                                                        [ngClass]="consumerProgramType.get('directBooking').get('points').get('coeficient').hasError('pattern') 
                                                            || consumerProgramType.get('directBooking').get('points').get('coeficient').hasError('max') 
                                                            || consumerProgramType.get('directBooking').get('points').get('coeficient').hasError('required') 
                                                            && consumerProgramType.get('directBooking').get('points').get('coeficient').touched ? 'err':''"
                                                        formControlName="coeficient"
                                                    >
                                                </div>
                                            </div>

                                            <div class='formDiv row' formGroupName="guests">
                                                <p class='padding-left-15'>
                                                    <strong>Types of guests that earn points (check at least one)*</strong>
                                                </p>
                                                <div class='checkbox-list padding-left-15 row'>
                                                    <input type='checkbox' name="cb" id='chb1' formControlName="isSeniors" />
                                                    <label for='chb1' class='col-sm-4'>
                                                        <span class='check-box'></span>
                                                        <span>Seniors (65+)</span>
                                                    </label>
                                                    <input type='checkbox' name="cb" id='chb2' formControlName="isAdults" />
                                                    <label for='chb2' class='col-sm-4'>
                                                        <span class='check-box'></span>
                                                        <span>Adults (18-64)</span>
                                                    </label>
                                                    <input type='checkbox' name="cb" id='chb3' formControlName="isYouth" />
                                                    <label for='chb3' class='col-sm-4'>
                                                        <span class='check-box'></span>
                                                        <span>
                                                            <!-- Youth (12-17) -->
                                                            Youth (13-17)
                                                        </span>
                                                    </label>
                                                    <input type='checkbox' name="cb" id='chb4' formControlName="isChild" />
                                                    <label for='chb4' class='col-sm-4'>
                                                        <span class='check-box'></span>
                                                        <span>
                                                            <!-- Child (2-12) -->
                                                            Children (3-12)
                                                        </span>
                                                    </label>
                                                    <input type='checkbox' name="cb" id='chb5' formControlName="isInfants" />
                                                    <label for='chb5' class='col-sm-4'>
                                                        <span class='check-box'></span>
                                                        <span>Infants (2 and
                                                            <)</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>


                                        <div 
                                            *ngIf="loyalty.controls['bookingType'].value.indexOf(2) > -1" 
                                            formGroupName="externalBooking"
                                        >
                                            <div class='formDiv row' formGroupName="points">
                                                <p class='padding-left-15 margin-top25'>
                                                    <strong>External Bookings*</strong>
                                                </p>

                                                <div class="form-group col-sm-4">
                                                    <label class="labelStyle">% of Cost of Booking (B)</label>
                                                    <input 
                                                        type="number" 
                                                        onkeypress='return event.charCode >= 48 && event.charCode <= 57' 
                                                        placeholder="" 
                                                        class='form-control inputTextStyle'
                                                        [ngClass]="consumerProgramType.get('externalBooking').get('points').get('costOfBooking').hasError('max') 
                                                            || consumerProgramType.get('externalBooking').get('points').get('costOfBooking').hasError('required') 
                                                            && consumerProgramType.get('externalBooking').get('points').get('costOfBooking').touched ? 'err':''"
                                                        formControlName="costOfBooking"
                                                    >
                                                </div>

                                                <div class="form-group col-sm-4">
                                                    <label class="labelStyle">% of Cost of Extras (E)</label>
                                                    <input 
                                                        type="number" 
                                                        onkeypress='return event.charCode >= 48 && event.charCode <= 57' 
                                                        placeholder="" class='form-control inputTextStyle'
                                                        [ngClass]="consumerProgramType.get('externalBooking').get('points').get('costOfExtras').hasError('max') 
                                                            || consumerProgramType.get('externalBooking').get('points').get('costOfExtras').hasError('required') 
                                                            && consumerProgramType.get('externalBooking').get('points').get('costOfExtras').touched ? 'err':''"
                                                        formControlName="costOfExtras"
                                                    >
                                                </div>

                                                <div class="form-group col-sm-4">
                                                    <label class="labelStyle">% Coefficient (decimal value)</label>
                                                    <input 
                                                        type="number" 
                                                        placeholder="" 
                                                        class='form-control inputTextStyle' 
                                                        [ngClass]="consumerProgramType.get('externalBooking').get('points').get('coeficient').hasError('max') 
                                                            || consumerProgramType.get('externalBooking').get('points').get('coeficient').hasError('pattern') 
                                                            || consumerProgramType.get('externalBooking').get('points').get('coeficient').hasError('required') 
                                                            && consumerProgramType.get('externalBooking').get('points').get('coeficient').touched ? 'err':''"
                                                        formControlName="coeficient"
                                                    >
                                                </div>
                                            </div>

                                            <div class='formDiv row' formGroupName="guests">
                                                <p class='padding-left-15'>
                                                    <strong>Types of guests that earn points (check at least one)*</strong>
                                                </p>
                                                <div class='checkbox-list row padding-left-15'>
                                                    <input type='checkbox' name="cb" id='d1' formControlName="isSeniors" />
                                                    <label for='d1' class='col-sm-4'>
                                                        <span class='check-box'></span>
                                                        <span>Seniors (65+)</span>
                                                    </label>
                                                    <input type='checkbox' name="cb" id='d2' formControlName="isAdults" />
                                                    <label for='d2' class='col-sm-4'>
                                                        <span class='check-box'></span>
                                                        <span>Adults (18-64)</span>
                                                    </label>
                                                    <input type='checkbox' name="cb" id='d3' formControlName="isYouth" />
                                                    <label for='d3' class='col-sm-4'>
                                                        <span class='check-box'></span>
                                                        <span>
                                                            <!-- Youth (12-17) -->
                                                            Youth (13-17)
                                                        </span>
                                                    </label>
                                                    <input type='checkbox' name="cb" id='d4' formControlName="isChild" />
                                                    <label for='d4' class='col-sm-4'>
                                                        <span class='check-box'></span>
                                                        <span>
                                                            <!-- Child (2-12) -->
                                                            Children (3-12)
                                                        </span>
                                                    </label>
                                                    <input type='checkbox' name="cb" id='d5' formControlName="isInfants" />
                                                    <label for='d5' class='col-sm-4'>
                                                        <span class='check-box'></span>
                                                        <span>Infants (2 and
                                                            < )</span>
                                                    </label>
                                                </div>
                                            </div>

                                        </div>

                                        <div class='row formDiv'>
                                            <p class='padding-left-15 margin-top25'>
                                                <strong>Points Distribution*</strong>
                                            </p>
                                            <div class='form-group col-sm-4'>
                                                <label class='labelStyle'>% of points to Buyer</label>
                                                <input 
                                                    type='number' 
                                                    class='form-control inputTextStyle' 
                                                    [ngClass]="consumerProgramType.get('buyerPoints').hasError('max') 
                                                        || consumerProgramType.get('buyerPoints').hasError('pattern') 
                                                        || consumerProgramType.get('buyerPoints').hasError('required') 
                                                        && consumerProgramType.get('buyerPoints').touched ? 'err':''"
                                                    formControlName="buyerPoints" 
                                                    onkeydown='return event.which != 189' 
                                                />
                                            </div>
                                            <div class='form-group col-sm-4'>
                                                <label class='labelStyle'>% of points to Guest</label>
                                                <input 
                                                    type='number' 
                                                    class='form-control inputTextStyle' 
                                                    [ngModel]="consumerProgramType 
                                                        && consumerProgramType.get('buyerPoints').value == 0 ? 100 : consumerProgramType.get('guestPoints').value"
                                                    formControlName="guestPoints" 
                                                    readOnly
                                                />
                                            </div>
                                        </div>

                                        <div class='formDiv'>
                                            <div>
                                                <p class="adminPermHeading">How points are earned</p>
                                                <div class="adminPermissionDivText">Select which actions awards guests with points</div>
                                            </div>
                                            <div formArrayName="transactionTypesData">
                                            <div class='margin-top' *ngFor="let transaction of consumerProgramType.controls['transactionTypesData']['controls']; let i=index">
                                                <div formGroupName="{{i}}" class="col-sm-12 padding-left-0">
                                                <div class='checkbox-list col-sm-4 points-div'>
                                                    <input type='checkbox' name="cb" [id]='"c"+i' formControlName="isEnabled" [checked]="" (click)="setTransactionValidations(transaction.value.isEnabled ? false : true, 'consumerProgramType', i)"
                                                    />
                                                    <label [for]='"c"+i'>
                                                        <span class='check-box'></span>
                                                        <span>{{transaction.value.transactionTypeEn}}</span>
                                                    </label>
                                                </div>
                                                <div class='checkbox-list col-sm-8  form-group'>
                                                    <div class='col-sm-6'>
                                                        <label class='labelStyle'>Points Earned</label>
                                                        <input type='number' onkeypress='return event.charCode >= 48 && event.charCode <= 57' class='form-control inputTextStyle'
                                                        [ngClass]="consumerProgramType.controls['transactionTypesData'].at(i).controls['points'].hasError('required') && consumerProgramType.controls['transactionTypesData'].at(i).controls['points'].touched ? 'err' : ''"
                                                            formControlName="points" [class.disabled]="!transaction.value.isEnabled"
                                                        />
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>

                                        <div class='row formDiv'>
                                          <div class='form-group col-sm-4 down-arrow select-icon'>
                                            <label class='labelStyle'>Points Expire After*</label>
                                            <select class='form-control  inputTextStyle' formControlName="expirePoints"
                                              [ngClass]="consumerProgramType.get('expirePoints').hasError('required') && consumerProgramType.get('expirePoints').touched ? 'err':''">
                                              <option value=null disabled="true" selected>Choose Year</option>
                                              <option value="Never Expire">Never Expire</option>
                                              <option value=1>1 Year</option>
                                              <option value=2>2 Years</option>
                                              <option value=3>3 Years</option>
                                              <option value=4>4 Years</option>
                                              <option value=5>5 Years</option>
                                              <option value=6>6 Years</option>
                                              <option value=7>7 Years</option>
                                              <option value=8>8 Years</option>
                                              <option value=9>9 Years</option>
                                              <option value=10>10 Years</option>
                                            </select>
                                          </div>
                                        </div>

                                        <div class='row formDiv'>
                                          <div class='form-group col-sm-4 down-arrow select-icon'>
                                            <label class='labelStyle'>Booking Expire*</label>
                                            <select class='form-control inputTextStyle' formControlName="bookingExpire"
                                              [ngClass]="consumerProgramType.get('bookingExpire').hasError('required') && consumerProgramType.get('bookingExpire').touched ? 'err':''">
                                              <option value=null disabled="true" selected>Choose Year</option>
                                              <option value="Never Expire">Never Expire</option>
                                              <option value=1>1 Year</option>
                                              <option value=2>2 Years</option>
                                              <option value=3>3 Years</option>
                                              <option value=4>4 Years</option>
                                              <option value=5>5 Years</option>
                                              <option value=6>6 Years</option>
                                              <option value=7>7 Years</option>
                                              <option value=8>8 Years</option>
                                              <option value=9>9 Years</option>
                                              <option value=10>10 Years</option>
                                            </select>
                                          </div>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- loyalty consumer tab -->

                <!-- loyalty employee tab -->
                <div #employeeContentProgram class="tab-pane" id="employeeProgram">
                    <div class="centered-content">
                        <div>
                            <div class="col-sm-12 tabPaneBorder step-2">
                                <div class='editContainer col-sm-11'>
                                    <form [formGroup]="employeeProgramType">
                                        <div class='row formDiv margin-top25'>
                                            <div class='col-sm-4 form-group'>
                                                <label class='labelStyle'>% of Cost of Booking*</label>
                                                <input type='number' onkeypress='return event.charCode >= 48 && event.charCode <= 57' class='form-control inputTextStyle'
                                                    [ngClass]="employeeProgramType.get('costOfBooking').hasError('max') || employeeProgramType.get('costOfBooking').hasError('required') && employeeProgramType.get('costOfBooking').touched ? 'err':''"
                                                    formControlName="costOfBooking" />
                                            </div>
                                        </div>
                                        <div class='row formDiv margin-top25'>
                                            <div class='form-group'>
                                              <p class='padding-left-15'>
                                                <strong>Types of employee referral points (select any one)*</strong>
                                              </p>
                                              <div class="col-xs-12">
                                                <label class="radio" *ngFor="let rule of employeePointRules">
                                                  <input id="radio1" type="radio" [value]="rule.id" formControlName="pointsRuleId" [ngClass]="employeeProgramType.get('pointsRuleId').hasError('required') && employeeProgramType.get('pointsRuleId').touched ? 'err':''">
                                                  <span class="outer"></span>
                                                  <span class='radio-text'>{{rule.ruleName}}</span>
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                          <div class='row formDiv margin-top25' *ngIf="employeeProgramType.get('pointsRuleId').value && (employeeProgramType.get('pointsRuleId').value == 1 || employeeProgramType.get('pointsRuleId').value == 2)">
                                            <div class='col-sm-4 form-group'>
                                              <label class='labelStyle'>Employee Referral Points</label>
                                              <input type='number' maxlength="5" onkeypress='return event.charCode >= 48 && event.charCode <= 57' class='form-control inputTextStyle'
                                                [ngClass]="employeeProgramType.get('referralPoints').hasError('max') || employeeProgramType.get('referralPoints').hasError('required') && employeeProgramType.get('referralPoints').touched ? 'err':''"
                                                formControlName="referralPoints" />
                                            </div>
                                          </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- loyalty employee tab -->

                <!-- loyalty travel agent tab -->
                <div #travelContentAgent class="tab-pane" id="travelAgent">
                    <div class="centered-content">
                        <div>
                            <div class="col-sm-12 tabPaneBorder step-2">
                                <div class='editContainer col-sm-11'>
                                    <form [formGroup]="travelAgentType">
                                        <div class='row formDiv margin-top-35'>
                                            <div class="col-sm-8 form-group">
                                                <label class="adminheadingLabelStyle margin-bottom25">
                                                    Choose the type of bookings guests are awarded points for (check at least one)*
                                                </label>
                                                <div class='checkbox-list row'>
                                                    <div *ngFor="let type of bookingTypesForAgents">
                                                        <input
                                                        type='checkbox' 
                                                        name="bookinga" 
                                                        (focus)="errorDetails = ''" 
                                                        [id]="'bookinga'+type.bookingTypeId" 
                                                        [checked]="loyalty.controls['bookingType'].value.indexOf(type.bookingTypeId) > -1"
                                                        [value]="type.bookingType" 
                                                        (change)="addFormControl('bookingType', type, $event.target.checked, false)"
                                                        />
                                                        <label [for]="'bookinga'+type.bookingTypeId" class='col-sm-4'>
                                                            <span class='check-box'></span>
                                                            <span>{{type.bookingType}}</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class='row formDiv margin-top'>
                                            <div class='col-sm-8 form-group'>
                                                <p class="adminheadingLabelStyle">How Points are calculated</p>
                                                <p class="adminPermissionDivText">
                                                    Setup rules for how loyalty points are calculated and which guests can earn them. 
                                                    Points are calculated based on (B% of Cost+ E% of Cost of Extras) / Number of valid people in booking / Coefficient
                                                </p>
                                            </div>
                                        </div>
                                        <div 
                                            *ngIf="loyalty.controls['bookingType'].value.indexOf(1) > -1" 
                                            formGroupName="directBooking"
                                        >
                                            <div class='formDiv row' formGroupName="points">
                                                <p class='padding-left-15 margin-top25'>
                                                    <strong>Direct Bookings*</strong>
                                                </p>

                                                <div class="form-group col-sm-4">
                                                    <label class="labelStyle">% of Cost of Booking (B)</label>
                                                    <input 
                                                        type="number" 
                                                        onkeypress='return event.charCode >= 48 && event.charCode <= 57' 
                                                        placeholder="" 
                                                        class='form-control inputTextStyle'
                                                        [ngClass]="travelAgentType.get('directBooking').get('points').get('costOfBooking').hasError('max') 
                                                            || travelAgentType.get('directBooking').get('points').get('costOfBooking').hasError('required') 
                                                            && travelAgentType.get('directBooking').get('points').get('costOfBooking').touched ? 'err':''"
                                                        formControlName="costOfBooking"
                                                    >
                                                </div>

                                                <div class="form-group col-sm-4">
                                                    <label class="labelStyle">% of Cost of Extras (E)</label>
                                                    <input 
                                                        type="number" 
                                                        onkeypress='return event.charCode >= 48 && event.charCode <= 57' 
                                                        placeholder="" class='form-control inputTextStyle'
                                                        [ngClass]="travelAgentType.get('directBooking').get('points').get('costOfExtras').hasError('max') 
                                                            || travelAgentType.get('directBooking').get('points').get('costOfExtras').hasError('required') 
                                                            && travelAgentType.get('directBooking').get('points').get('costOfExtras').touched ? 'err':''"
                                                        formControlName="costOfExtras">
                                                </div>

                                                <div class="form-group col-sm-4">
                                                    <label class="labelStyle">% Coefficient (decimal value)</label>
                                                    <input 
                                                        type="number" 
                                                        placeholder="" 
                                                        class='form-control inputTextStyle' 
                                                        [ngClass]="travelAgentType.get('directBooking').get('points').get('coeficient').hasError('pattern') 
                                                            || travelAgentType.get('directBooking').get('points').get('coeficient').hasError('max') 
                                                            || travelAgentType.get('directBooking').get('points').get('coeficient').hasError('required') 
                                                            && travelAgentType.get('directBooking').get('points').get('coeficient').touched ? 'err':''"
                                                        formControlName="coeficient">
                                                </div>
                                            </div>
                                        </div>

                                        <div class='formDiv'>
                                            <div>
                                                <p class="adminPermHeading">How points are earned</p>
                                                <div class="adminPermissionDivText">Select which actions awards guests with points</div>
                                            </div>
                                            <div formArrayName="transactionTypesData">
                                            <div 
                                                class='margin-top' 
                                                *ngFor="let transaction of travelAgentType.controls['transactionTypesData']['controls']; let i=index"
                                            >
                                                <div formGroupName="{{i}}" class="col-sm-12 padding-left-0">
                                                <div class='checkbox-list col-sm-4 points-div'>
                                                    <input 
                                                        type='checkbox' 
                                                        name="acb" 
                                                        [id]='"a"+i' 
                                                        formControlName="isEnabled" 
                                                        [checked]="" 
                                                        (click)="setTransactionValidations(
                                                                    transaction.value.isEnabled ? false : true, 
                                                                    'travelAgentType', 
                                                                    i
                                                                )"
                                                    />
                                                    <label [for]='"a"+i'>
                                                        <span class='check-box'></span>
                                                        <span>{{transaction.value.transactionTypeEn}}</span>
                                                    </label>
                                                </div>
                                                <div class='checkbox-list col-sm-8  form-group'>
                                                    <div class='col-sm-6'>
                                                        <label class='labelStyle'>Points Earned</label>
                                                        <input 
                                                            type='number' 
                                                            onkeypress='return event.charCode >= 48 && event.charCode <= 57' 
                                                            class='form-control inputTextStyle'
                                                            [ngClass]="travelAgentType.controls['transactionTypesData'].at(i).controls['points'].hasError('required') 
                                                                && travelAgentType.controls['transactionTypesData'].at(i).controls['points'].touched ? 'err' : ''"
                                                            formControlName="points" 
                                                            [class.disabled]="!transaction.value.isEnabled"
                                                        />
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>

                                        <div class='row formDiv'>
                                          <div class='form-group col-sm-4 down-arrow select-icon'>
                                            <label class='labelStyle'>Points Expire After*</label>
                                            <select class='form-control  inputTextStyle' formControlName="expirePoints"
                                              [ngClass]="travelAgentType.get('expirePoints').hasError('required') && travelAgentType.get('expirePoints').touched ? 'err':''">
                                              <option value=null disabled="true" selected>Choose Year</option>
                                              <option value="Never Expire">Never Expire</option>
                                              <option value=1>1 Year</option>
                                              <option value=2>2 Years</option>
                                              <option value=3>3 Years</option>
                                              <option value=4>4 Years</option>
                                              <option value=5>5 Years</option>
                                              <option value=6>6 Years</option>
                                              <option value=7>7 Years</option>
                                              <option value=8>8 Years</option>
                                              <option value=9>9 Years</option>
                                              <option value=10>10 Years</option>
                                            </select>
                                          </div>
                                        </div>

                                        <div class='row formDiv'>
                                          <div class='form-group col-sm-4 down-arrow select-icon'>
                                            <label class='labelStyle'>Booking Expire*</label>
                                            <select class='form-control inputTextStyle' formControlName="bookingExpire"
                                              [ngClass]="travelAgentType.get('bookingExpire').hasError('required') && travelAgentType.get('bookingExpire').touched ? 'err':''">
                                              <option value=null disabled="true" selected>Choose Year</option>
                                              <option value="Never Expire">Never Expire</option>
                                              <option value=1>1 Year</option>
                                              <option value=2>2 Years</option>
                                              <option value=3>3 Years</option>
                                              <option value=4>4 Years</option>
                                              <option value=5>5 Years</option>
                                              <option value=6>6 Years</option>
                                              <option value=7>7 Years</option>
                                              <option value=8>8 Years</option>
                                              <option value=9>9 Years</option>
                                              <option value=10>10 Years</option>
                                            </select>
                                          </div>
                                        </div>






                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- loyalty travel agent tab -->

            </div>
        </div>
        <div id="levels" class="tab-pane fade">
            <ul class='nav nav-tabs rules-tabs'>
                <li #consumerMemberProgram class="">
                    <a 
                        data-toggle="tab" 
                        href="#consumerMemberProgram" 
                        [ngClass]="loyalty.controls['programType'].value.indexOf(1) > -1 ? '' : 'disabled'"
                    >Consumer Program</a>
                </li>
                <span 
                    *ngIf="loyalty.controls['programType'].value.length 
                        && loyalty.controls['programType'].value.indexOf(1) > -1" 
                    class="pull-left icon"
                    (click)="$event.preventDefault();
                        addFormControl('programType', {loyaltyProgramTypeId: 1}, false, false)"
                >
                    <img class="pull-left" src="assets/images/minus-orange.svg">
                </span>
                <span 
                    *ngIf="loyalty.controls['programType'].value.indexOf(1) == -1" 
                    class="pull-left icon plus" (click)="$event.preventDefault();
                        addFormControl('programType', {loyaltyProgramTypeId: 1}, true, false)"
                >
                    <img class="pull-left" src="assets/images/plus-orange.svg">
                </span>
                <li #employeeMemberProgram>
                    <a 
                        data-toggle="tab" 
                        href="#employeeMemberProgram" 
                        [ngClass]="loyalty.controls['programType'].value.indexOf(2) > -1 ? '' : 'disabled'"
                    >Employee Program</a>
                </li>
                <span 
                    *ngIf="loyalty.controls['programType'].value.length 
                        && loyalty.controls['programType'].value.indexOf(2) > -1" 
                    class="pull-left icon"
                    (click)="$event.preventDefault();
                        addFormControl('programType', {loyaltyProgramTypeId: 2}, false, false)"
                >
                    <img class="pull-left" src="assets/images/minus-orange.svg">
                </span>
                <span 
                    *ngIf="loyalty.controls['programType'].value.indexOf(2) == -1" 
                    class="pull-left icon plus" (click)="$event.preventDefault();
                        addFormControl('programType', {loyaltyProgramTypeId: 2}, true, false)"
                >
                    <img class="pull-left" src="assets/images/plus-orange.svg">
                </span>
                <li #travelAgentMember>
                    <a 
                        data-toggle="tab" 
                        href="#travelAgentMember" 
                        [ngClass]="loyalty.controls['programType'].value.indexOf(3) > -1 ? '' : 'disabled'"
                    >Travel Agent</a>
                </li>
                <span 
                    *ngIf="loyalty.controls['programType'].value.length 
                        && loyalty.controls['programType'].value.indexOf(3) > -1" 
                    class="pull-left icon"
                    (click)="$event.preventDefault();
                        addFormControl('programType', {loyaltyProgramTypeId: 3}, false, false)"
                >
                    <img class="pull-left" src="assets/images/minus-orange.svg">
                </span>
                <span 
                    *ngIf="loyalty.controls['programType'].value.indexOf(3) == -1" 
                    class="pull-left icon plus" 
                    (click)="$event.preventDefault();
                        addFormControl('programType', {loyaltyProgramTypeId: 3}, true, false)"
                >
                    <img class="pull-left" src="assets/images/plus-orange.svg">
                </span>
            </ul>
            <div class="tab-content">

                <!-- loyalty membership first step start -->
                <div #consumerMemberContentProgram class="tab-pane" id="consumerMemberProgram">
                    <div class="centered-content">
                        <div>
                            <div class="col-sm-12 tabPaneBorder">
                                <div class='editContainer col-sm-12'>
                                    <div>
                                        <form novalidate [formGroup]="consumerMemberProgramType">
                                            <div formArrayName="membershipLevelToList">
                                                <div *ngFor="let memberShipLevel of consumerMemberProgramType.controls['membershipLevelToList']['controls']; let i=index">
                                                    <div formGroupName="{{i}}">
                                                        <div class='row margin-top-35'>
                                                            <div class='col-sm-6'>
                                                                <p class="adminheadingLabelStyle">Membership Level {{i+1}}</p>
                                                            </div>
                                                        </div>
                                                        <div class='formDiv row margin-top'>
                                                            <div class='form-group col-sm-4'>
                                                                <label class="labelStyle">Membership Name*</label>
                                                                <input 
                                                                    type="text" 
                                                                    placeholder="" 
                                                                    class='form-control inputTextStyle' 
                                                                    [ngClass]="memberShipLevel.get('memberShipName').hasError('uniqueBy') 
                                                                        || memberShipLevel.get('memberShipName').hasError('required') 
                                                                        && memberShipLevel.get('memberShipName').touched ? 'err':''"
                                                                    formControlName="memberShipName" 
                                                                />
                                                                <p 
                                                                    class="error" 
                                                                    *ngIf="memberShipLevel.get('memberShipName').hasError('uniqueBy')"
                                                                >Membership Name must be unique</p>
                                                            </div>
                                                            <div class='form-group col-sm-4'>
                                                                <label class="labelStyle">Membership level*</label>
                                                                <input
                                                                  type='text'
                                                                  class='form-control inputTextStyle'
                                                                  [ngModel]="consumerMemberProgramType.value.membershipLevelToList[i].memberShipLevel"
                                                                  formControlName="memberShipLevel"
                                                                  readonly
                                                                />
                                                            </div>
                                                            <div 
                                                                class='col-sm-4 padding-top-25 text-right' 
                                                                *ngIf="consumerMemberProgramType.controls['membershipLevelToList'].length > 1"
                                                            >
                                                                <button 
                                                                    type="button" 
                                                                    class='btn btn-default remove-btn' 
                                                                    (click)="remove('consumerMemberProgramType', 'membershipLevelToList', i, 'membership')"
                                                                >
                                                                    <img src='assets/images/minus.svg' class='pull-left' />
                                                                    <span> Remove</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div class='formDiv row'>
                                                          <div class="form-group margin-bottom45 col-sm-4">
                                                              <label class="adminheadingLabelStyle">
                                                                  Does the guest have to Opt-In?*
                                                              </label>
                                                              <br>
                                                              <label class="radio">
                                                                  <input id="'gt'+(i+1)" type="radio" [value]="true" formControlName="isGuestOptIn">
                                                                  <span class="outer"></span>
                                                                  <span class='radio-text'>Yes</span>
                                                              </label>
                                                              <label class="radio">
                                                                  <input id="'gf'+(i+1)" type="radio" [value]="false" formControlName="isGuestOptIn">
                                                                  <span class="outer"></span>
                                                                  <span class='radio-text'>No</span>
                                                              </label>
                                                          </div>
                                                          <div class="form-group margin-bottom45 col-sm-2">
                                                              <label class="labelStyle">
                                                                Percent to Grant
                                                              </label>
                                                                <input 
                                                                    id="" 
                                                                    type="number" 
                                                                    min="1" 
                                                                    max="100" 
                                                                    formControlName="pointsGrantedPercentage" 
                                                                    class='form-control inputTextStyle' 
                                                                    [ngClass]="memberShipLevel.get('pointsGrantedPercentage').hasError('uniqueBy') 
                                                                        || memberShipLevel.get('pointsGrantedPercentage').hasError('required') 
                                                                        && memberShipLevel.get('pointsGrantedPercentage').touched ? 'err':''"
                                                                >
                                                            </div>
                                                            <div class="form-group margin-bottom45 col-sm-2">
                                                                <label class="labelStyle">
                                                                Exchange factor pts/usd
                                                                </label>
                                                                    <input 
                                                                        id="" 
                                                                        type="number" 
                                                                        min="0" 
                                                                        formControlName="exchangeRatePointsGranted" 
                                                                        class='form-control inputTextStyle' 
                                                                        [ngClass]="memberShipLevel.get('exchangeRatePointsGranted').hasError('uniqueBy') 
                                                                            || memberShipLevel.get('exchangeRatePointsGranted').hasError('required') 
                                                                            && memberShipLevel.get('exchangeRatePointsGranted').touched ? 'err':''"
                                                                    >
                                                            </div>
                                                        </div>
                                                        <div class='formDiv row'>
                                                                <div class="form-group margin-bottom45 col-sm-4">
                                                                    <label class="adminheadingLabelStyle">
                                                                      Is there a cost to join this membership level?*
                                                                    </label>
                                                                    <br>
                                                                    <label class="radio">
                                                                        <input 
                                                                            id="'mt'+(i+1)" 
                                                                            type="radio"
                                                                            [value]=true 
                                                                            formControlName="isCostToJoin" 
                                                                            (change)="subscribeMemeberCostChanges($event, 'consumerMemberProgramType', i)"
                                                                        >
                                                                        <span class="outer"></span>
                                                                        <span class='radio-text'>Yes</span>
                                                                    </label>
                                                                    <label class="radio">
                                                                        <input 
                                                                            id="'mf'+(i+1)" 
                                                                            type="radio" 
                                                                            [value]=false 
                                                                            formControlName="isCostToJoin" 
                                                                            (change)="subscribeMemeberCostChanges($event, 'consumerMemberProgramType', i)"
                                                                        >
                                                                        <span class="outer"></span>
                                                                        <span class='radio-text'>No</span>
                                                                    </label>
                                                                </div>
                                                                <div class="form-group margin-bottom45 col-sm-2">
                                                                    <label class="labelStyle">
                                                                     Max % discount
                                                                    </label>
                                                                        <input 
                                                                            id="" 
                                                                            type="number" 
                                                                            min="1" 
                                                                            max="100" 
                                                                            formControlName="maxDiscountPercentage" 
                                                                            class='form-control inputTextStyle' 
                                                                            [ngClass]="memberShipLevel.get('maxDiscountPercentage').hasError('uniqueBy') 
                                                                                || memberShipLevel.get('maxDiscountPercentage').hasError('required') 
                                                                                && memberShipLevel.get('maxDiscountPercentage').touched ? 'err':''"
                                                                        >
                                                                </div>
                                                                <div class="form-group margin-bottom45 col-sm-2">
                                                                  <label class="labelStyle">
                                                                    Factor pts/usd
                                                                  </label>
                                                                    <input 
                                                                        id="" 
                                                                        type="number" 
                                                                        min="0" 
                                                                        formControlName="exchangeRatePointsExpended" 
                                                                        class='form-control inputTextStyle' 
                                                                        [ngClass]="memberShipLevel.get('exchangeRatePointsExpended').hasError('uniqueBy') 
                                                                            || memberShipLevel.get('exchangeRatePointsExpended').hasError('required') 
                                                                            && memberShipLevel.get('exchangeRatePointsExpended').touched ? 'err':''"
                                                                    >
                                                                </div>
                                                        </div>

                                                        <div class='formDiv row' >
                                                            <p class='padding-left-15 margin-top25'>
                                                                <strong>Direct Bookings*</strong>
                                                            </p>
                                                            <div class="form-group margin-bottom45 col-sm-3">
                                                                <label class="labelStyle">
                                                                    % of Cost of Booking (B)
                                                                </label>
                                                                    <input 
                                                                        id="" 
                                                                        type="number" 
                                                                        min="1" 
                                                                        max="100" 
                                                                        formControlName="costOfBookings" 
                                                                        class='form-control inputTextStyle' 
                                                                        [ngClass]="memberShipLevel.get('costOfBookings').hasError('uniqueBy') 
                                                                            || memberShipLevel.get('costOfBookings').hasError('required') 
                                                                            && memberShipLevel.get('costOfBookings').touched ? 'err':''"
                                                                    >
                                                            </div>
                                                            <div class="form-group margin-bottom45 col-sm-3">
                                                                <label class="labelStyle">
                                                                    % of Cost of Extras (E)
                                                                </label>
                                                                <input
                                                                    id="" 
                                                                    type="number" 
                                                                    min="1" 
                                                                    max="100" 
                                                                    formControlName="costOfExtras" 
                                                                    class='form-control inputTextStyle' 
                                                                    [ngClass]="memberShipLevel.get('costOfExtras').hasError('uniqueBy') 
                                                                        || memberShipLevel.get('costOfExtras').hasError('required') 
                                                                        && memberShipLevel.get('costOfExtras').touched ? 'err':''"
                                                                >
                                                            </div>
                                                            <div class="form-group margin-bottom45 col-sm-3">
                                                                <label class="labelStyle">
                                                                    % Coefficient (decimal value)
                                                                </label>
                                                                <input 
                                                                    id="" 
                                                                    type="number" 
                                                                    min="0" 
                                                                    step="0,1" 
                                                                    formControlName="coeficient" 
                                                                    class='form-control inputTextStyle' 
                                                                    [ngClass]="memberShipLevel.get('coeficient').hasError('uniqueBy') 
                                                                        || memberShipLevel.get('coeficient').hasError('required') 
                                                                        && memberShipLevel.get('coeficient').touched ? 'err':''"
                                                                >
                                                            </div>
                                                        </div>


                                                        <div 
                                                            class='formDiv row' 
                                                            *ngIf="consumerMemberProgramType.controls['membershipLevelToList'].at(i).controls['isCostToJoin'].value"
                                                        >
                                                            <div class='form-grop col-sm-2'>
                                                                <label class="labelStyle">Cost to join*</label>
                                                                <input 
                                                                    type="number" 
                                                                    placeholder="" 
                                                                    class='form-control inputTextStyle' 
                                                                    [ngClass]="memberShipLevel.get('costToJoin').hasError('pattern') 
                                                                        || memberShipLevel.get('costToJoin').hasError('required') ? 'err':''"
                                                                    formControlName="costToJoin" 
                                                                    onkeydown='return event.which != 189'
                                                                >
                                                            </div>
                                                            <div class='form-grop col-sm-2 down-arrow select-icon'>
                                                                <label class="labelStyle">Currency*</label>
                                                                <select 
                                                                    class='form-control inputTextStyle' 
                                                                    formControlName="currencyType" 
                                                                    [ngClass]="memberShipLevel.get('currencyType').hasError('required') ? 'err':''"
                                                                >
                                                                    <option *ngFor="let cy of currencies" [value]="cy.currencyId">{{cy.currencyName}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class='formDiv row margin-top25'>
                                                            <div class='form-group col-sm-4'>
                                                                <label class="labelStyle"># of stays needed to upgrade per year*</label>
                                                                <input 
                                                                    type="number" 
                                                                    onkeypress='return event.charCode >= 48 && event.charCode <= 57' 
                                                                    placeholder="" 
                                                                    class='form-control inputTextStyle'
                                                                    [ngClass]="memberShipLevel.get('noOfStaysNeedToUpgrade').hasError('required') 
                                                                        && memberShipLevel.get('noOfStaysNeedToUpgrade').touched ? 'err':''"
                                                                    formControlName="noOfStaysNeedToUpgrade"
                                                                >
                                                            </div>
                                                            <div class='form-group col-sm-4'>
                                                                <label class="labelStyle"># of nights needed to upgrade per year*</label>
                                                                <input 
                                                                    type="number" 
                                                                    onkeypress='return event.charCode >= 48 && event.charCode <= 57' 
                                                                    placeholder="" class='form-control inputTextStyle'
                                                                    [ngClass]="memberShipLevel.get('noOfNightsNeedToUpgrade').hasError('required') 
                                                                        && memberShipLevel.get('noOfNightsNeedToUpgrade').touched ? 'err':''"
                                                                    formControlName="noOfNightsNeedToUpgrade">
                                                            </div>
                                                        </div>
                                                        <div class='formDiv row'>
                                                            <div class='form-group col-sm-4'>
                                                                <label class="labelStyle"># of points issued on enrollment*</label>
                                                                <input 
                                                                    type="number" 
                                                                    onkeypress='return event.charCode >= 48 && event.charCode <= 57' 
                                                                    placeholder="" 
                                                                    class='form-control inputTextStyle'
                                                                    [ngClass]="memberShipLevel.get('noOfPointsIssuedOnEnrolling').hasError('required') 
                                                                        && memberShipLevel.get('noOfPointsIssuedOnEnrolling').touched ? 'err':''"
                                                                    formControlName="noOfPointsIssuedOnEnrolling"
                                                                >
                                                            </div>
                                                            <div class='form-group col-sm-4'>
                                                                <label class="labelStyle">Color picker*</label>
                                                                <input [value]="memberShipLevel.get('colourCode').value" class='form-control inputTextStyle'
                                                                formControlName="colourCode"
                                                                [style.background]="memberShipLevel.get('colourCode').value"
                                                                [cpOKButton]="true"
                                                                [cpSaveClickOutside]="true"
                                                                (colorPickerChange)="onColorPickerChange($event, 'consumerMemberProgramType', i)"
                                                                [cpOKButtonClass]= "'btn btn-primary btn-sm'"
                                                                [ngClass]="memberShipLevel.get('colourCode').hasError('required') 
                                                                    && memberShipLevel.get('colourCode').touched ? 'err':''"
                                                                [(colorPicker)]="color"/>
                                                            </div>
                                                        </div>
                                                        <div class="formDiv row">
                                                            <div class='form-group col-sm-6'>
                                                              <p class="adminheadingLabelStyle">Perk Messages*</p>
                                                              <div formArrayName="perkConfig">
                                                                <div *ngFor="let perk of consumerMemberProgramType.controls['membershipLevelToList'].at(i).controls['perkConfig']['controls']; let i=index">
                                                                  <div>
                                                                    <label class='labelStyle'>
                                                                      Perk Description
                                                                    </label>
                                                                    <input 
                                                                        type="text" 
                                                                        class='form-control inputTextStyle' 
                                                                        rows='1' 
                                                                        formControlName="{{i}}" 
                                                                        [ngClass]="memberShipLevel.get('perkConfig').at(i).hasError('required')
                                                                            && memberShipLevel.get('perkConfig').at(i).touched ? 'err':''" 
                                                                        (focus)="errorDetails = ''"
                                                                    />
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div class='row  step3-footer-buttons'>
                                <div class='col-sm-5'>
                                    <button 
                                        type="button" 
                                        class="btn btn-default" 
                                        class="addButtonStyle step3-btn" 
                                        (click)="addMembershipLevel(null, 'consumerMemberProgramType')" 
                                        [ngClass]="consumerMemberProgramType.get('membershipLevelToList').value.length == 10 ? 'disabled' : '' "
                                    >
                                        <img class='pull-left' src='assets/images/plus-white.svg' />Add Memebership
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- loyalty membership first step end -->

                <!-- loyalty membership second step start -->
                <div #employeeMemberContentProgram class="tab-pane" id="employeeMemberProgram">
                    <div class="centered-content">
                        <div>
                            <div class="col-sm-12 tabPaneBorder">
                                <div class='editContainer col-sm-12'>

                                    <form novalidate [formGroup]="employeeMemberProgramType">
                                            <div formArrayName="membershipLevelToList">
                                                <div *ngFor="let memberShipLevel of employeeMemberProgramType.controls['membershipLevelToList']['controls']; let i=index">
                                                    <div formGroupName="{{i}}">
                                                        <div class='row margin-top-35'>
                                                            <div class='col-sm-6'>
                                                                <p class="adminheadingLabelStyle">Employee Level</p>
                                                            </div>
                                                        </div>
                                                        <div class='formDiv row margin-top'>
                                                            <div class='form-group col-sm-4'>
                                                                <label class="labelStyle">Employee Level Name*</label>
                                                                <input type="text" placeholder="" class='form-control inputTextStyle' [ngClass]="memberShipLevel.get('memberShipName').hasError('uniqueBy') || memberShipLevel.get('memberShipName').hasError('required') && memberShipLevel.get('memberShipName').touched ? 'err':''"
                                                                    formControlName="memberShipName" />
                                                                <p class="error" *ngIf="memberShipLevel.get('memberShipName').hasError('uniqueBy')">Employee Level Name must be unique</p>
                                                            </div>
                                                            <div class='form-group col-sm-4'>
                                                                <label class="labelStyle">Employee level*</label>
                                                                <input type='text' class='form-control inputTextStyle' [ngModel]="'Level '+ (i+1)" formControlName="memberShipLevel" readonly/>
                                                            </div>
                                                            <div class='col-sm-4 padding-top-25 text-right' *ngIf="employeeMemberProgramType.controls['membershipLevelToList'].length > 1">
                                                                <button type="button" class='btn btn-default remove-btn' (click)="remove('employeeMemberProgramType', 'membershipLevelToList', i, 'membership')">
                                                                    <img src='assets/images/minus.svg' class='pull-left' />
                                                                    <span> Remove</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div class='formDiv'>
                                                            <div class="form-group margin-bottom45">
                                                                <label class="adminheadingLabelStyle">
                                                                    Does the guest have to Opt-In?
                                                                </label>
                                                                <br>
                                                                <label class="radio">
                                                                    <input id="'gt'+(i+1)" type="radio" [value]="true" formControlName="isGuestOptIn">
                                                                    <span class="outer"></span>
                                                                    <span class='radio-text'>Yes</span>
                                                                </label>
                                                                <label class="radio">
                                                                    <input id="'gf'+(i+1)" type="radio" [value]="false" formControlName="isGuestOptIn">
                                                                    <span class="outer"></span>
                                                                    <span class='radio-text'>No</span>
                                                                </label>
                                                            </div>
                                                            <div class="form-group margin-bottom45">
                                                                <label class="adminheadingLabelStyle">
                                                                    Is there a cost to join this employee level?
                                                                </label>
                                                                <br>
                                                                <label class="radio">
                                                                    <input id="'mt'+(i+1)" type="radio" [value]=true formControlName="isCostToJoin" (change)="subscribeMemeberCostChanges($event, 'employeeMemberProgramType', i)">
                                                                    <span class="outer"></span>
                                                                    <span class='radio-text'>Yes</span>
                                                                </label>
                                                                <label class="radio">
                                                                    <input id="'mf'+(i+1)" type="radio" [value]=false formControlName="isCostToJoin" (change)="subscribeMemeberCostChanges($event, 'employeeMemberProgramType', i)">
                                                                    <span class="outer"></span>
                                                                    <span class='radio-text'>No</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div class='formDiv row' *ngIf="employeeMemberProgramType.controls['membershipLevelToList'].at(i).controls['isCostToJoin'].value">
                                                            <div class='form-grop col-sm-2'>
                                                                <label class="labelStyle">Cost to join</label>
                                                                <input type="number" placeholder="" class='form-control inputTextStyle' [ngClass]="memberShipLevel.get('costToJoin').hasError('pattern') || memberShipLevel.get('costToJoin').hasError('required') ? 'err':''"
                                                                    formControlName="costToJoin" onkeydown='return event.which != 189'>
                                                            </div>
                                                            <div class='form-grop col-sm-2 down-arrow select-icon'>
                                                                <label class="labelStyle">Currency</label>
                                                                <select class='form-control inputTextStyle' formControlName="currencyType" [ngClass]="memberShipLevel.get('currencyType').hasError('required') ? 'err':''">
                                                                    <option *ngFor="let cy of currencies" [value]="cy.currencyId">{{cy.currencyName}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class='formDiv row margin-top25'>
                                                            <div class='form-group col-sm-4'>
                                                                <label class="labelStyle"># of stays needed to upgrade per year</label>
                                                                <input type="number" onkeypress='return event.charCode >= 48 && event.charCode <= 57' placeholder="" class='form-control inputTextStyle'
                                                                    [ngClass]="memberShipLevel.get('noOfStaysNeedToUpgrade').hasError('required') && memberShipLevel.get('noOfStaysNeedToUpgrade').touched ? 'err':''"
                                                                    formControlName="noOfStaysNeedToUpgrade">
                                                            </div>
                                                            <div class='form-group col-sm-4'>
                                                                <label class="labelStyle"># of nights needed to upgrade per year</label>
                                                                <input type="number" onkeypress='return event.charCode >= 48 && event.charCode <= 57' placeholder="" class='form-control inputTextStyle'
                                                                    [ngClass]="memberShipLevel.get('noOfNightsNeedToUpgrade').hasError('required') && memberShipLevel.get('noOfNightsNeedToUpgrade').touched ? 'err':''"
                                                                    formControlName="noOfNightsNeedToUpgrade">
                                                            </div>
                                                        </div>
                                                        <div class='formDiv row'>
                                                            <div class='form-group col-sm-4'>
                                                                <label class="labelStyle"># of points issued on enrollment</label>
                                                                <input type="number" onkeypress='return event.charCode >= 48 && event.charCode <= 57' placeholder="" class='form-control inputTextStyle'
                                                                    [ngClass]="memberShipLevel.get('noOfPointsIssuedOnEnrolling').hasError('required') && memberShipLevel.get('noOfPointsIssuedOnEnrolling').touched ? 'err':''"
                                                                    formControlName="noOfPointsIssuedOnEnrolling">
                                                            </div>
                                                            <div class='form-group col-sm-4'>
                                                                <label class="labelStyle">Color picker</label>
                                                                <input [value]="memberShipLevel.get('colourCode').value" class='form-control inputTextStyle'
                                                                formControlName="colourCode"
                                                                [style.background]="memberShipLevel.get('colourCode').value"
                                                                [cpOKButton]="true"
                                                                [cpSaveClickOutside]="true"
                                                                (colorPickerChange)="onColorPickerChange($event, 'employeeMemberProgramType', i)"
                                                                [cpOKButtonClass]= "'btn btn-primary btn-sm'"
                                                                [ngClass]="memberShipLevel.get('colourCode').hasError('required') && memberShipLevel.get('colourCode').touched ? 'err':''"
                                                                [(colorPicker)]="color"/>
                                                            </div>
                                                        </div>
                                                        <div class="formDiv row">
                                                            <div class='form-group col-sm-6'>
                                                              <p class="adminheadingLabelStyle">Perk Messages</p>
                                                              <div formArrayName="perkConfig">
                                                                <div *ngFor="let perk of employeeMemberProgramType.controls['membershipLevelToList'].at(i).controls['perkConfig']['controls']; let i=index">
                                                                  <div>
                                                                    <label class='labelStyle'>
                                                                      Perk Description
                                                                    </label>
                                                                    <input type="text" class='form-control inputTextStyle' rows='1' formControlName="{{i}}" [ngClass]="memberShipLevel.get('perkConfig').at(i).hasError('required') && memberShipLevel.get('perkConfig').at(i).touched ? 'err':''" (focus)="errorDetails = ''" />
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- loyalty membership second step end -->

                <!-- loyalty membership third step start -->
                <div #travelAgentContentMember class="tab-pane" id="travelAgentMember">
                    <div class="centered-content">
                        <div>
                            <div class="col-sm-12 tabPaneBorder">
                                <div class='editContainer col-sm-9'>
                                    <form [formGroup]="travelAgentMemberType" class='form-group'>
                                        <div class='formDiv row padding-bottom-13 margin-top-35'>
                                            <div class='form-group col-sm-5'>
                                                <label class="labelStyle"># of points issued on enrollment*</label>
                                                <input 
                                                    type="number" 
                                                    onkeypress='return event.charCode >= 48 && event.charCode <= 57' 
                                                    placeholder="" 
                                                    class='form-control inputTextStyle'
                                                    [ngClass]="travelAgentMemberType.get('noOfPointsIssuedOnEnrolling').hasError('required') 
                                                        && travelAgentMemberType.get('noOfPointsIssuedOnEnrolling').touched ? 'err':''"
                                                    formControlName="noOfPointsIssuedOnEnrolling">
                                            </div>
                                        </div>
                                        <div formArrayName="membershipLevelToList">
                                            <div *ngFor="let memberShipLevel of travelAgentMemberType.controls['membershipLevelToList']['controls']; let i=index">
                                                <div formGroupName="{{i}}">
                                                    <div class='row margin-top-35'>
                                                        <div class='col-sm-6'>
                                                            <p class="adminheadingLabelStyle">Membership Level {{i+1}}</p>
                                                        </div>
                                                    </div>
                                                    <div class='formDiv row margin-top'>
                                                        <div class='form-group col-sm-4'>
                                                            <label class="labelStyle">Membership Name*</label>
                                                            <input 
                                                                type="text" 
                                                                placeholder="" 
                                                                class='form-control inputTextStyle' 
                                                                [ngClass]="memberShipLevel.get('memberShipName').hasError('uniqueBy') 
                                                                    || memberShipLevel.get('memberShipName').hasError('required') 
                                                                    && memberShipLevel.get('memberShipName').touched ? 'err':''"
                                                                formControlName="memberShipName" />
                                                            <p 
                                                                class="error" 
                                                                *ngIf="memberShipLevel.get('memberShipName').hasError('uniqueBy')"
                                                            >Membership Name must be unique</p>
                                                        </div>
                                                        <div class='form-group col-sm-4'>
                                                            <label class="labelStyle">Membership level*</label> <!-- readonly -->
                                                            <input
                                                              type='text'
                                                              class='form-control inputTextStyle'
                                                              [ngModel]="travelAgentMemberType.value.membershipLevelToList[i].memberShipLevel"
                                                              formControlName="memberShipLevel"
                                                              
                                                            />
                                                        </div>
                                                        <div 
                                                            class='col-sm-4 padding-top-25 text-right' 
                                                            *ngIf="travelAgentMemberType.controls['membershipLevelToList'].length > 1"
                                                        >
                                                            <button 
                                                                type="button" 
                                                                class='btn btn-default remove-btn' 
                                                                (click)="remove('travelAgentMemberType', 'membershipLevelToList', i, 'membership')"
                                                            >
                                                                <img src='assets/images/minus.svg' class='pull-left' />
                                                                <span> Remove</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div class='formDiv row'>
                                                      <div class="form-group margin-bottom45 col-sm-4">
                                                          <label class="adminheadingLabelStyle">
                                                              Does the guest have to Opt-In?*
                                                          </label>
                                                          <br>
                                                          <label class="radio">
                                                              <input id="'gt'+(i+1)" type="radio" [value]="true" formControlName="isGuestOptIn">
                                                              <span class="outer"></span>
                                                              <span class='radio-text'>Yes</span>
                                                          </label>
                                                          <label class="radio">
                                                              <input id="'gf'+(i+1)" type="radio" [value]="false" formControlName="isGuestOptIn">
                                                              <span class="outer"></span>
                                                              <span class='radio-text'>No</span>
                                                          </label>
                                                      </div>
                                                      <div class="form-group margin-bottom45 col-sm-2">
                                                          <label class="labelStyle">
                                                            Percent to Grant
                                                          </label>
                                                            <input 
                                                                id="" 
                                                                type="number" 
                                                                min="1" 
                                                                max="100" 
                                                                formControlName="pointsGrantedPercentage" 
                                                                class='form-control inputTextStyle' 
                                                                [ngClass]="memberShipLevel.get('pointsGrantedPercentage').hasError('uniqueBy') 
                                                                    || memberShipLevel.get('pointsGrantedPercentage').hasError('required') 
                                                                    && memberShipLevel.get('pointsGrantedPercentage').touched ? 'err':''"
                                                            >
                                                        </div>
                                                        <div class="form-group margin-bottom45 col-sm-2">
                                                            <label class="labelStyle">
                                                            Exchange factor pts/usd
                                                            </label>
                                                                <input 
                                                                    id="" 
                                                                    type="number" 
                                                                    min="0" 
                                                                    formControlName="exchangeRatePointsGranted" 
                                                                    class='form-control inputTextStyle' 
                                                                    [ngClass]="memberShipLevel.get('exchangeRatePointsGranted').hasError('uniqueBy') 
                                                                        || memberShipLevel.get('exchangeRatePointsGranted').hasError('required') 
                                                                        && memberShipLevel.get('exchangeRatePointsGranted').touched ? 'err':''"
                                                                >
                                                        </div>
                                                    </div>
                                                    <div class='formDiv row'>
                                                            <div class="form-group margin-bottom45 col-sm-4">
                                                                <label class="adminheadingLabelStyle">
                                                                  Is there a cost to join this membership level?*
                                                                </label>
                                                                <br>
                                                                <label class="radio">
                                                                    <input 
                                                                        id="'mt'+(i+1)" 
                                                                        type="radio" 
                                                                        [value]=true 
                                                                        formControlName="isCostToJoin" 
                                                                        (change)="subscribeMemeberCostChanges($event, 'travelAgentMemberType', i)"
                                                                    >
                                                                    <span class="outer"></span>
                                                                    <span class='radio-text'>Yes</span>
                                                                </label>
                                                                <label class="radio">
                                                                    <input 
                                                                        id="'mf'+(i+1)" 
                                                                        type="radio" 
                                                                        [value]=false 
                                                                        formControlName="isCostToJoin" 
                                                                        (change)="subscribeMemeberCostChanges($event, 'travelAgentMemberType', i)"
                                                                    >
                                                                    <span class="outer"></span>
                                                                    <span class='radio-text'>No</span>
                                                                </label>
                                                            </div>
                                                            <div class="form-group margin-bottom45 col-sm-2">
                                                                <label class="labelStyle">
                                                                 Max % discount
                                                                </label>
                                                                    <input 
                                                                        id="" 
                                                                        type="number" 
                                                                        min="1" 
                                                                        max="100" 
                                                                        formControlName="maxDiscountPercentage" 
                                                                        class='form-control inputTextStyle' 
                                                                        [ngClass]="memberShipLevel.get('maxDiscountPercentage').hasError('uniqueBy') 
                                                                            || memberShipLevel.get('maxDiscountPercentage').hasError('required') 
                                                                            && memberShipLevel.get('maxDiscountPercentage').touched ? 'err':''"
                                                                    >
                                                            </div>
                                                            <div class="form-group margin-bottom45 col-sm-2">
                                                              <label class="labelStyle">
                                                                Factor pts/usd
                                                              </label>
                                                                <input 
                                                                    id="" 
                                                                    type="number"
                                                                    min="0" 
                                                                    formControlName="exchangeRatePointsExpended" 
                                                                    class='form-control inputTextStyle' 
                                                                    [ngClass]="memberShipLevel.get('exchangeRatePointsExpended').hasError('uniqueBy') 
                                                                        || memberShipLevel.get('exchangeRatePointsExpended').hasError('required') 
                                                                        && memberShipLevel.get('exchangeRatePointsExpended').touched ? 'err':''"
                                                                >

                                                            </div>
                                                    </div>

                                                    <div class='formDiv row' >
                                                        <p class='padding-left-15 margin-top25'>
                                                            <strong>Direct Bookings*</strong>
                                                        </p>
                                                        <div class="form-group margin-bottom45 col-sm-3">
                                                            <label class="labelStyle">
                                                                % of Cost of Booking (B)
                                                            </label>
                                                                <input 
                                                                    id="" 
                                                                    type="number" 
                                                                    min="1" max="100" 
                                                                    formControlName="costOfBookings" 
                                                                    class='form-control inputTextStyle' 
                                                                    [ngClass]="memberShipLevel.get('costOfBookings').hasError('uniqueBy') 
                                                                        || memberShipLevel.get('costOfBookings').hasError('required') 
                                                                        && memberShipLevel.get('costOfBookings').touched ? 'err':''"
                                                                >
                                                        </div>
                                                        <div class="form-group margin-bottom45 col-sm-3">
                                                            <label class="labelStyle">
                                                                % of Cost of Extras (E)
                                                            </label>
                                                            <input 
                                                                id="" 
                                                                type="number" 
                                                                min="1" 
                                                                max="100" 
                                                                formControlName="costOfExtras" 
                                                                class='form-control inputTextStyle' 
                                                                [ngClass]="memberShipLevel.get('costOfExtras').hasError('uniqueBy') 
                                                                    || memberShipLevel.get('costOfExtras').hasError('required') 
                                                                    && memberShipLevel.get('costOfExtras').touched ? 'err':''"
                                                            >
                                                        </div>
                                                        <div class="form-group margin-bottom45 col-sm-3">
                                                            <label class="labelStyle">
                                                                % Coefficient (decimal value)
                                                            </label>
                                                            <input 
                                                                id="" 
                                                                type="number" 
                                                                min="0" 
                                                                step="0,1" 
                                                                formControlName="coeficient" 
                                                                class='form-control inputTextStyle' 
                                                                [ngClass]="memberShipLevel.get('coeficient').hasError('uniqueBy') 
                                                                    || memberShipLevel.get('coeficient').hasError('required') 
                                                                    && memberShipLevel.get('coeficient').touched ? 'err':''"
                                                            >
                                                        </div>
                                                    </div>


                                                    <div 
                                                        class='formDiv row' 
                                                        *ngIf="travelAgentMemberType.controls['membershipLevelToList'].at(i).controls['isCostToJoin'].value"
                                                    >
                                                        <div class='form-grop col-sm-2'>
                                                            <label class="labelStyle">Cost to join*</label>
                                                            <input 
                                                                type="number" 
                                                                placeholder="" 
                                                                class='form-control inputTextStyle' 
                                                                [ngClass]="memberShipLevel.get('costToJoin').hasError('pattern') 
                                                                    || memberShipLevel.get('costToJoin').hasError('required') ? 'err':''"
                                                                formControlName="costToJoin" 
                                                                onkeydown='return event.which != 189'
                                                            >
                                                        </div>
                                                        <div class='form-grop col-sm-2 down-arrow select-icon'>
                                                            <label class="labelStyle">Currency*</label>
                                                            <select 
                                                                class='form-control inputTextStyle' 
                                                                formControlName="currencyType" 
                                                                [ngClass]="memberShipLevel.get('currencyType').hasError('required') ? 'err':''"
                                                            >
                                                                <option *ngFor="let cy of currencies" [value]="cy.currencyId">{{cy.currencyName}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class='formDiv row'>
                                                        <div class='form-group col-sm-4'>
                                                            <label class="labelStyle"># of points issued on enrollment*</label>
                                                            <input 
                                                                type="number" 
                                                                onkeypress='return event.charCode >= 48 && event.charCode <= 57' 
                                                                placeholder="" 
                                                                class='form-control inputTextStyle'
                                                                [ngClass]="memberShipLevel.get('noOfPointsIssuedOnEnrolling').hasError('required') 
                                                                    && memberShipLevel.get('noOfPointsIssuedOnEnrolling').touched ? 'err':''"
                                                                formControlName="noOfPointsIssuedOnEnrolling"
                                                            >
                                                        </div>
                                                        <div class='form-group col-sm-4'>
                                                            <label class="labelStyle">Color picker*</label>
                                                            <input [value]="memberShipLevel.get('colourCode').value" class='form-control inputTextStyle'
                                                            formControlName="colourCode"
                                                            [style.background]="memberShipLevel.get('colourCode').value"
                                                            [cpOKButton]="true"
                                                            [cpSaveClickOutside]="true"
                                                            (colorPickerChange)="onColorPickerChange($event, 'travelAgentMemberType', i)"
                                                            [cpOKButtonClass]= "'btn btn-primary btn-sm'"
                                                            [ngClass]="memberShipLevel.get('colourCode').hasError('required') 
                                                                && memberShipLevel.get('colourCode').touched ? 'err':''"
                                                            [(colorPicker)]="color"
                                                        />
                                                        </div>
                                                    </div> <!-- 
                                                    <div class='formDiv row margin-top20'>
                                                        <div class='row margin-top-35'>
                                                            <div class='col-sm-6'>
                                                                <p class="adminheadingLabelStyle">Market Upgrade Configs For Agency Schemes</p>
                                                            </div>
                                                        </div>
                                                        <div class='form-group col-sm-4'>
                                                            <label class="labelStyle"># of stays needed to upgrade per year*</label>
                                                            <input 
                                                                type="number" 
                                                                onkeypress='return event.charCode >= 48 && event.charCode <= 57' 
                                                                placeholder="" 
                                                                class='form-control inputTextStyle'
                                                                [ngClass]="memberShipLevel.get('noOfStaysNeedToUpgrade').hasError('required') 
                                                                    && memberShipLevel.get('noOfStaysNeedToUpgrade').touched ? 'err':''"
                                                                formControlName="noOfStaysNeedToUpgrade"
                                                            >
                                                        </div>
                                                        <div class='form-group col-sm-4'>
                                                            <label class="labelStyle"># of nights needed to upgrade per year*</label>
                                                            <input 
                                                                type="number" 
                                                                onkeypress='return event.charCode >= 48 && event.charCode <= 57' 
                                                                placeholder="" 
                                                                class='form-control inputTextStyle'
                                                                [ngClass]="memberShipLevel.get('noOfNightsNeedToUpgrade').hasError('required') 
                                                                    && memberShipLevel.get('noOfNightsNeedToUpgrade').touched ? 'err':''"
                                                                formControlName="noOfNightsNeedToUpgrade">
                                                        </div>
                                                    </div> -->
                                                    <div class="formDiv row">
                                                        <div class='form-group col-sm-6'>
                                                          <p class="adminheadingLabelStyle">Perk Messages*</p>
                                                          <div formArrayName="perkConfig">
                                                            <div *ngFor="let perk of travelAgentMemberType.controls['membershipLevelToList'].at(i).controls['perkConfig']['controls']; let i=index">
                                                              <div>
                                                                <label class='labelStyle'>
                                                                  Perk Description
                                                                </label>
                                                                <input 
                                                                    type="text" 
                                                                    class='form-control inputTextStyle' 
                                                                    rows='1' 
                                                                    formControlName="{{i}}" 
                                                                    [ngClass]="memberShipLevel.get('perkConfig').at(i).hasError('required') 
                                                                        && memberShipLevel.get('perkConfig').at(i).touched ? 'err':''" 
                                                                    (focus)="errorDetails = ''" 
                                                                />
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div class='row  step3-footer-buttons'>
                                <div class='col-sm-5'>
                                    <button 
                                        type="button" 
                                        class="btn btn-default" 
                                        class="addButtonStyle step3-btn" 
                                        (click)="addMembershipLevel(null, 'travelAgentMemberType')" 
                                        [ngClass]="travelAgentMemberType.get('membershipLevelToList').value.length == 10 ? 'disabled' : '' "
                                    >
                                        <img class='pull-left' src='assets/images/plus-white.svg' />Add Memebership
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- loyalty membership third step end -->

            </div>
        </div>

        <div class='xrow col-xs-12 xtext-right buttons-div'>
            <div class="col-sm-6">
                <div class="error col-xs-12 error-align" *ngIf="errorDetails">{{errorDetails}}</div>
                <div class="success col-xs-12 success-align" *ngIf="successMsg">{{successMsg}}</div>
            </div>
            <div class="col-sm-6 padding-right-0 text-right">
                <button 
                    class='btn btn-default' 
                    (click)="updateLoyalty('saveLoyalty')" 
                    [ngClass]="loyalty.valid && consumerProgramType.valid 
                        && consumerMemberProgramType.valid && employeeProgramType.valid 
                        && employeeMemberProgramType.valid && travelAgentType.valid 
                        && travelAgentMemberType.valid ? '' : 'disabled'">Save</button> 
            </div>
        </div>
    </div>
</div>
