<div class='col-sm-9 col-sm-offset-3 main-container'>
  <div class='head-line'>
    <h3>New Administrator Account</h3>
  </div>
  <form novalidate class='form-group' [formGroup]="user">
    <div class='row'>
      <div class='formDiv'>
        <div class="form-group col-sm-5">
          <label>Admin Role*</label>
          <select class='form-control' formControlName="roleId" (focus)="errorDetails = ''" [ngClass]="user.get('roleId').hasError('required') && user.get('roleId').touched ? 'err':''">
            <option value="">Choose Role</option>
            <option *ngFor="let role of hcaRoles" [value]="role.roleId">
              {{role.roleName}}
            </option>
          </select>
        </div>
      </div>
      <div class='formDiv'>
        <div class="form-group col-sm-5">
          <label>Hotel Brand *</label>
          <select class='form-control' formControlName="brandId" (focus)="errorDetails = ''" [ngClass]="user.get('brandId').hasError('required') && user.get('brandId').touched ? 'err':''">
            <option value="" *ngIf="user.get('roleId').value != 12">Choose Brand</option>
            <option value="" *ngIf="user.get('roleId').value == 12">All</option>
            <option *ngFor="let brand of hotelBrands" [value]="brand.brandId">{{brand.brandName}}</option>
          </select>
        </div>
        <div class="form-group col-sm-5">
          <label>Hotels *</label>
          <select class='form-control dropdown-toggle' (focus)="errorDetails = ''" data-toggle="dropdown" formControlName="hotelId"
            [ngClass]="user.get('hotelId').hasError('required') && user.get('hotelId').touched ? 'err':''">
            <option value="" *ngIf="user.get('roleId').value == 14 || user.get('roleId').value == ''">Choose Hotel</option>
            <option value="" *ngIf="user.get('roleId').value == 12 || user.get('roleId').value == 13">All</option>
            <option *ngFor="let hotel of hotels" [value]="hotel.hotelId">{{hotel.hotelName}}</option>
          </select>
        </div>
      </div>
      <div class='formDiv'>
        <div class="form-group col-sm-5">
          <label>First Name *</label>
          <input type="text" placeholder="" formControlName="firstName" (focus)="errorDetails = ''" class='form-control' [ngClass]="user.get('firstName').hasError('required') && user.get('firstName').touched ? 'err':''">
        </div>
        <div class="form-group col-sm-5">
          <label>Last Name *</label>
          <input type="text" placeholder="" (focus)="errorDetails = ''" formControlName="lastName" class='form-control' [ngClass]="user.get('lastName').hasError('required') && user.get('lastName').touched ? 'err':''">
        </div>
      </div>
      <div class='formDiv'>
        <div class="form-group col-sm-5">
          <label>Email ID *</label>
          <input type="email" placeholder="" formControlName="email" (focus)="errorDetails = ''" class='form-control' [ngClass]="(user.get('email').hasError('pattern') || user.get('email').hasError('required')) && user.get('email').touched?'err':''">
        </div>
        <div class="form-group col-sm-5">
          <label>Contact Number</label>
          <input type="tel" placeholder="" (focus)="errorDetails = ''" formControlName="mobileNumber" class='form-control' [ngClass]="user.get('mobileNumber').hasError('pattern') || user.get('mobileNumber').hasError('minlength') || user.get('mobileNumber').hasError('maxlength') ?'err':''">
          <!-- <div class="error" style="width:288px;margin-top:0" *ngIf="user.get('mobileNumber').hasError('pattern')">
              Mobile Number should contain only Numbers
            </div> -->
        </div>
      </div>
      <div class='formDiv'>
        <div class="form-group col-sm-5">
          <label>Skype ID</label>
          <input type="email" placeholder="" formControlName="skypeId" (focus)="errorDetails = ''" class='form-control' [ngClass]="user.get('skypeId').hasError('pattern') && user.get('skypeId').touched ? 'err':''">
        </div>
      </div>
    </div>
  </form>
  <div class='buttonsDiv text-right col-sm-10'>
    <button [routerLink]="['/settings/hca-user-management']" class='btn btn-default'>Cancel</button>
    <button (click)="saveUser(user)" class='btn btn-default' [disabled]="!user.valid">Save</button>
  </div>
  <div class="error col-xs-12" *ngIf="errorDetails">{{errorDetails}}</div>
</div>
