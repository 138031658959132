<div class="col-sm-1 navbar-container">
  <nav class="navbar navbar-custom" [style.min-height.px]="minHeight">
    <div class="navbar-header">
      <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar-collapse" aria-expanded="false">
        <!-- <span class="sr-only">Toggle navigation</span> -->
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
    </div>
    <div class="collapse navbar-collapse text-center navbar-collapse-custom" id="navbar-collapse">
      <ul class="nav nav-stacked dropdown xaffix mainMenu" xdata-spy='affix'>
        <!-- <button class="btn btn-default" (click)="translate.use('en')">English</button>
        <button class="btn btn-default" (click)="translate.use('es')">Español</button> -->
        <li>
          <img class="profile-picture dropdown-toggle" [src]="imageSrc" (error)="errorLoading()" data-toggle="dropdown" />
          <ul class="dropdown-menu navList">
            <li>
              <a routerLink="/profile-management" routerLinkActive="active">
                <img src='assets/images/edit.svg' />My Profile</a>
            </li>
            <li>
              <a (click)="logout()">
                <img src='assets/images/logout.svg' />Logout</a>
            </li>
          </ul>
        </li>
        <ng-container *ngFor="let menuItem of menu">
          <li class="pointer dropdown menu-list"
              *ngIf="role == 'ROLE_LSA' || role === 'ROLE_HCA' || menuItem.role.includes(role)"
              [routerLink]="menuItem.navigate"
              routerLinkActive="hotelChains open"
              [ngClass]="!hotelBrands.length && (menuItem.name == 'Marketing') ? 'disabled' : ''"
              (click)="toggleSubMenu(menuItem.name)">
            <a class="dropdown-toggle main-menu" xdata-toggle="dropdown">
              <img [src]='menuItem.imageSrc' />
              <br> {{menuItem.name}}
            </a>
            <ul *ngIf="activeSubMenu === menuItem.name" class="dropdown-menu dropdown-menu-right sub-menu" role="menu" aria-labelledby="dLabel" onclick="event.stopPropagation();">
              <ng-container *ngFor="let subMenu of menuItem.subMenu">
                <li *ngIf="role == 'ROLE_LSA' || role === 'ROLE_HCA' || subMenu.role.includes(role)"
                    [routerLink]="subMenu.navigateTo"
                    routerLinkActive #rla="routerLinkActive"
                    (click)="closeSubMenu()"
                    [ngClass]="(!hotelBrands.length && (subMenu.name == 'Loyalty Website' || subMenu.name == 'Brand' || subMenu.name == 'Loyalty Program' || subMenu.name == 'User Management')) ? 'disabled' : ''">
                  
                  <a [ngClass]="rla.isActive ? 'active' : 'inactive'">
                    <img class="" [src]="rla.isActive ? subMenu.activeSrc : subMenu.imageSrc" /> {{subMenu.name}}
                  </a>
                </li>
              </ng-container>
            </ul>
          </li>
        </ng-container>
      </ul>
      <div class='logo hidden-xs'>
        <img src='assets/images/sandos-logo-72.png' />
      </div>
    </div>
  </nav>
</div>