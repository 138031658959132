<!-- Navbar Container -->
<app-navbar></app-navbar>

<div class='col-sm-12 col-sm-offset-3 new-report-container'>
  <h3>Audit Dashboard</h3>
  <form novalidate [formGroup]="report">
    <div class='row formDiv'>
      <h4 class="padding-left-15 margin-bottom25">
        <b>Generate reports for the selected dimension.</b>
      </h4>
      <div class="form-group col-sm-5 select-icon">
        <label>Dimension*</label>
        <select class='form-control' formControlName="dimension" (change)="selectDimension($event.target.value)" (focus)="errorDetails = ''" [ngClass]="report.get('dimension').hasError('required') && report.get('dimension').touched ? 'err':''">
          <option value="">Choose Dimension</option>
          <option *ngFor="let origin of dimensions" [value]="origin.id">{{origin.name}}</option>
        </select>
      </div>
  
    <form novalidate [formGroup]="report1">
      <div class='row formDiv'>
        <div class="col-sm-12 form-group select-icon" *ngIf="isDimension1">
          <h4 class="padding-left-15 margin-bottom25">
            <b>Transactions above X amount of coins</b>
          </h4>
          <div class="row">
            <div class="col-sm-2 form-group">
              <label class="labelStyle">Coin Limit*</label>
              <input type='number' class="form-control" formControlName="points" [ngClass]="report1.get('points').hasError('pattern') || report1.get('points').hasError('min') || report1.get('points').hasError('max') || report1.get('points').hasError('required') && report1.get('points').touched ? 'err': ''"/>
            </div>
          </div>
          <div class="row">
            <div class='col-sm-3'>
              <label>Date From*</label>
              <my-date-picker
                name="dateFrom"
                formControlName="dateFrom"
                placeholder="Start Date"
                [options]="myDatePickerOptions"
                [ngClass]="report1.hasError('datesOverlapped') || report1.get('dateFrom').hasError('dateRangeError') || report1.get('dateFrom').hasError('required') && report1.get('dateFrom').touched ? 'err' : ''"
                (dateChanged)="onDateChanged($event, 'dateFrom')"></my-date-picker>
            </div>
            <div class='col-sm-3'>
              <label>Date To*</label>
              <my-date-picker
                name="dateTo"
                formControlName="dateTo"
                placeholder="End Date"
                [options]="myDatePickerOptions"
                [ngClass]="report1.hasError('datesOverlapped') || report1.get('dateTo').hasError('dateRangeError') || report1.get('dateTo').hasError('required') && report1.get('dateTo').touched ? 'err' : ''"
                (dateChanged)="onDateChanged($event, 'dateTo')"></my-date-picker>
            </div>
            <p class="error col-xs-9 text-center" *ngIf="report.get('dateTo').hasError('dateRangeError') || report.get('dateFrom').hasError('dateRangeError')">Date From must not be greater than Date To</p>
          </div>
          <div class='form-group col-sm-12 row'>
            <div class="row">
              <div class="buttonsDiv form-group col-sm-6 text-right">
                <button class='btn btn-default' (click)="generateReport1()" [disabled]="!report1.valid">Send</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>

    <form novalidate [formGroup]="report2">
      <div class='row formDiv'>
        <div class="col-sm-12 form-group select-icon" *ngIf="isDimension2">
          <h4 class="padding-left-15 margin-bottom25">
            <b>List of agents who have approved transactions within a range of X days for a sum of X coins</b>
          </h4>
          <div class="row">
            <div class="col-sm-2 form-group">
              <label class="labelStyle">Coin From* (Transactions above this amount)</label>
              <input type='number' class="form-control" formControlName="points" [ngClass]="report2.get('points').hasError('pattern') || report2.get('points').hasError('min') || report2.get('points').hasError('max') || report2.get('points').hasError('required') && report2.get('points').touched ? 'err': ''"/>
            </div>
            <!--
            <div class="col-sm-2 form-group">
              <label class="labelStyle">Transaction Limit*</label>
              <input type='number' class="form-control" formControlName="transactions" [ngClass]="report2.get('transactions').hasError('pattern') || report2.get('transactions').hasError('min') || report2.get('transactions').hasError('max') || report2.get('transactions').hasError('required') && report2.get('transactions').touched ? 'err': ''"/>
            </div> -->
          </div>
          <div class="row">
            <div class='col-sm-3'>
              <label>Date From*</label>
              <my-date-picker
                name="dateFrom"
                formControlName="dateFrom"
                placeholder="Start Date"
                [options]="myDatePickerOptions"
                [ngClass]="report2.hasError('datesOverlapped') || report2.get('dateFrom').hasError('dateRangeError') || report2.get('dateFrom').hasError('required') && report2.get('dateFrom').touched ? 'err' : ''"
                (dateChanged)="onDateChanged($event, 'dateFrom')"></my-date-picker>
            </div>
            <div class='col-sm-3'>
              <label>Date To*</label>
              <my-date-picker
                name="dateTo"
                formControlName="dateTo"
                placeholder="End Date"
                [options]="myDatePickerOptions"
                [ngClass]="report2.hasError('datesOverlapped') || report2.get('dateTo').hasError('dateRangeError') || report2.get('dateTo').hasError('required') && report2.get('dateTo').touched ? 'err' : ''"
                (dateChanged)="onDateChanged($event, 'dateTo')"></my-date-picker>
            </div>
            <p class="error col-xs-9 text-center" *ngIf="report.get('dateTo').hasError('dateRangeError') || report.get('dateFrom').hasError('dateRangeError')">Date From must not be greater than Date To</p>
          </div>
          <div class='form-group col-sm-12 row'>
            <div class="row">
              <div class="buttonsDiv form-group col-sm-6 text-right">
                <button class='btn btn-default' (click)="generateReport2()" [disabled]="!report2.valid">Send</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>

    <form novalidate [formGroup]="report3">
      <div class='row formDiv'>
        <div class="col-sm-12 form-group select-icon" *ngIf="isDimension3">
          <h4 class="padding-left-15 margin-bottom25">
            <b>Members who obtain transactions within a range of X days for a summed amount of X coins</b>
          </h4>
          <div class="row">
            <div class="col-sm-2 form-group">
              <label class="labelStyle">Coin Limit*</label>
              <input type='number' class="form-control" formControlName="points" [ngClass]="report.get('points').hasError('pattern') || report.get('points').hasError('min') || report.get('points').hasError('max') || report.get('points').hasError('required') && report.get('points').touched ? 'err': ''"/>
            </div>
            <!--
            <div class="col-sm-2 form-group">
              <label class="labelStyle">Transaction Limit*</label>
              <input type='number' class="form-control" formControlName="transactions" [ngClass]="report.get('transactions').hasError('pattern') || report.get('transactions').hasError('min') || report.get('transactions').hasError('max') || report.get('transactions').hasError('required') && report.get('transactions').touched ? 'err': ''"/>
            </div>-->
          </div>
          <div class="row">
            <div class='col-sm-3'>
              <label>Date From*</label>
              <my-date-picker
                name="dateFrom"
                formControlName="dateFrom"
                placeholder="Start Date"
                [options]="myDatePickerOptions"
                [ngClass]="report.hasError('datesOverlapped') || report.get('dateFrom').hasError('dateRangeError') || report.get('dateFrom').hasError('required') && report.get('dateFrom').touched ? 'err' : ''"
                (dateChanged)="onDateChanged($event, 'dateFrom')"></my-date-picker>
            </div>
            <div class='col-sm-3'>
              <label>Date To*</label>
              <my-date-picker
                name="dateTo"
                formControlName="dateTo"
                placeholder="End Date"
                [options]="myDatePickerOptions"
                [ngClass]="report.hasError('datesOverlapped') || report.get('dateTo').hasError('dateRangeError') || report.get('dateTo').hasError('required') && report.get('dateTo').touched ? 'err' : ''"
                (dateChanged)="onDateChanged($event, 'dateTo')"></my-date-picker>
            </div>
            <p class="error col-xs-9 text-center" *ngIf="report.get('dateTo').hasError('dateRangeError') || report.get('dateFrom').hasError('dateRangeError')">Date From must not be greater than Date To</p>
          </div>
          <div class='form-group col-sm-12 row'>
            <div class="row">
              <div class="buttonsDiv form-group col-sm-6 text-right">
                <button class='btn btn-default' (click)="generateReport3()" [disabled]="!report3.valid">Send</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <form novalidate [formGroup]="report4">
      <div class='row formDiv'>
        <div class="col-sm-12 form-group select-icon" *ngIf="isDimension4">
          <h4 class="padding-left-15 margin-bottom25">
            <b>List of members who exceed their wallet by X amount of coins</b>
          </h4>
          <div class="row">
            <div class="col-sm-2 form-group">
              <label class="labelStyle">Coin Limit*</label>
              <input type='number' class="form-control" formControlName="points" [ngClass]="report.get('points').hasError('pattern') || report.get('points').hasError('min') || report.get('points').hasError('max') || report.get('points').hasError('required') && report.get('points').touched ? 'err': ''"/>
            </div>
          </div>
          <div class='form-group col-sm-12 row'>
            <div class="row">
              <div class="buttonsDiv form-group col-sm-6 text-right">
                <button class='btn btn-default' (click)="generateReport4()" [disabled]="!report4.valid">Send</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>  
    </div>
  </form>
  <div class="text-danger" *ngIf="errorDetails">{{errorDetails}}</div>
  <div *ngIf="documentUrl">
    <h4>You can download the report <a target="_blank" href="{{documentUrl}}">here</a>.</h4>
  </div>
</div>
