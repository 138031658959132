<div class='col-sm-9 col-sm-offset-3 new-hotel-container'>
  <h3>{{isNewHotel ? 'New' : 'Modify'}} Hotel</h3>
  <form novalidate [formGroup]="hotel">
    <div class='row'>
      <div class='formDiv'>
        <div class="form-group col-sm-5">
          <label>Hotel Name *</label>
          <input type='text' class='form-control' formControlName='hotelName' [ngClass]="hotel.get('hotelName').hasError('minlength') || hotel.get('hotelName').hasError('maxlength') || hotel.get('hotelName').hasError('required') && hotel.get('hotelName').touched ? 'err':''"
            (focus)="errorDetails = ''" />
        </div>
        <div class="form-group col-sm-5 select-icon">
          <label>Hotel Brand *</label>
          <select class='form-control' formControlName="brandId" (focus)="errorDetails = ''; errorUpload = false" [ngClass]="hotel.get('brandId').hasError('required') && hotel.get('brandId').touched ? 'err':''">
            <option value="">Choose Brand</option>
            <option *ngFor="let brand of hotelBrands" [value]="brand.brandId">{{brand.brandName}}</option>
          </select>
        </div>
      </div>
      <div class='formDiv'>
        <div class="form-group col-sm-5">
          <label>Contact Email *</label>
          <input type='text' class='form-control' formControlName='contactEmail' [ngClass]="hotel.get('contactEmail').hasError('pattern') || hotel.get('contactEmail').hasError('required') && hotel.get('contactEmail').touched ? 'err':''"
            (focus)="errorDetails = ''" />
        </div>
        <div class="form-group col-sm-5">
          <label>Description</label>
          <input type='text' class='form-control' formControlName='description' (focus)="errorDetails = ''; errorUpload = false" />
        </div>
      </div>
      <div class='formDiv'>
        <div class="form-group col-sm-5">
          <label>Hotel Code*</label>
          <input type='text' class='form-control' formControlName='hotelCode' (focus)="errorDetails = ''; errorUpload = false" [ngClass]="hotel.get('hotelCode').hasError('required') && hotel.get('hotelCode').touched ? 'err' : ''"/>
        </div>
        <div class='form-group col-sm-5'>
          <label>Hotel Website URL</label>
          <textarea class='form-control' formControlName="website" [ngClass]="hotel.get('website').hasError('pattern') ? 'err':''"
            (focus)="errorDetails = ''; errorUpload = false"></textarea>
        </div>
      </div>
      <div class='formDiv'>
        <div class="form-group col-sm-5">
          <label>Voucher Email</label>
          <input type='text' class='form-control' formControlName='voucherEmailId' (focus)="errorDetails = ''" />
        </div>
      </div>
      <div class='formDiv' formArrayName="seasonsList">
        <!-- <p class="error" *ngIf="isDatesRepeated">These dates already belong to a season. Please select different dates</p>
        <p class="error" *ngIf="dateRepeated">DateTo must be greater than dateFrom</p> -->
        <!-- <p class="error col-xs-12 text-center" *ngIf="hotel.controls['seasonsList'].hasError('datesOverlapped')">Dates overlapped</p> -->
        <h4 class="padding-left-15 margin-bottom25">
          <b>Create seasons for external booking points</b>
        </h4>
        <div class='form-group col-sm-12 row season-row' *ngFor="let season of hotel.controls['seasonsList'].controls; let i=index">        
          <div formGroupName="{{i}}">
            <div class='col-sm-2'>
              <label>Season*</label>
              <input type='text' class='form-control' formControlName="name" [ngClass]="season.get('name').hasError('uniqueBy') || season.get('name').hasError('required') && season.get('name').touched ? 'err':''" (focus)="errorDetails = ''; errorUpload = false"/>
              <p class="error" *ngIf="season.get('name').hasError('uniqueBy')">Season Name already exists.</p>
            </div>
            <div class='col-sm-2'>
              <label>Date From*</label>
              <my-date-picker name="dateFrom" formControlName="dateFrom" placeholder="Start Date" [options]="myDatePickerOptions" [ngClass]="season.hasError('datesOverlapped') || season.get('dateFrom').hasError('required') && season.get('dateFrom').touched ? 'err' : ''" (dateChanged)="onDateChanged($event, 'dateFrom', i)"></my-date-picker>
              <!-- <input type="date" formControlName="dateFrom" class="form-control inputTextStyle" onkeydown='return event.which == 8 || event.which == 46 || event.which == 37 || event.which == 39'
              placeholder="Start Date" (click)="selectDate($event.target.value, 'dateFrom', i)"  (focus)="errorDetails = ''; errorUpload = false"> -->
              <!-- <input type="text" id="dateFrom" formControlName="dateFrom" class="form-control" onkeydown='return event.which == 8 || event.which == 46 || event.which == 37 || event.which == 39'
                [minDate]="minStartDate" (bsValueChange)="selectDate($event, 'dateFrom', i)" placeholder="Start Date" bsDatepicker
                #dp1="bsDatepicker" placement="auto" [bsConfig]="bsConfig"> -->
            </div>
            <div class='col-sm-2'>
              <label>Date To*</label>
              <my-date-picker name="dateTo" formControlName="dateTo" placeholder="End Date" [options]="myDatePickerOptions" [ngClass]="season.hasError('datesOverlapped') || season.hasError('dateRangeError') || season.get('dateTo').hasError('required') && season.get('dateTo').touched ? 'err' : ''" (dateChanged)="onDateChanged($event, 'dateTo', i)"></my-date-picker>
              <!-- <input type="date" formControlName="dateTo" class="form-control inputTextStyle" onkeydown='return event.which == 8 || event.which == 46 || event.which == 37 || event.which == 39'
              placeholder="End Date" (click)="selectDate($event.target.value, 'dateTo', i)"  (focus)="errorDetails = ''; errorUpload = false"> -->
              <!-- <input type="text" id="dateTo" formControlName="dateTo" class="form-control" onkeydown='return event.which == 8 || event.which == 46 || event.which == 37 || event.which == 39'
                [minDate]="minEndDate" (bsValueChange)="selectDate($event, 'dateTo', i)" placeholder="End Date" bsDatepicker
                #dp2="bsDatepicker" placement="auto" [bsConfig]="bsConfig"> -->
            </div>
            <div class='col-sm-2 padding-right-0'>
              <label>
                <!-- Points -->
                Points per night*
              </label>
              <input type='number' class='form-control' formControlName="points" onkeypress='return event.charCode >= 48 && event.charCode <= 57' [ngClass]="season.get('points').hasError('required') && season.get('points').touched ? 'err' : ''" (focus)="errorDetails = ''; errorUpload = false"/>
            </div>
            <div class="form-group col-sm-2 select-icon">
              <label>Program *</label>
              <select class='form-control' formControlName="programId" (focus)="errorDetails = ''; errorUpload = false" [ngClass]="hotel.get('brandId').hasError('required') && hotel.get('brandId').touched ? 'err':''">
                <option value="">Choose Program</option>
                <option *ngFor="let programType of programTypes" [value]="programType.loyaltyProgramTypeId">{{programType.loyaltyProgramTypeName}}</option>
              </select>
            </div>
            <div class="col-sm-2 text-center" *ngIf="hotel.controls['seasonsList'].value.length > 1">
              <img src='assets/images/delete-orange.svg' class="season-delete" (click)="remove('hotel', 'seasonsList', i, 'season')" />
            </div>
            <p class="error col-xs-12 text-center" *ngIf="season.hasError('datesOverlapped')">Seasons duration overlaps</p>
            <p class="error col-xs-12 text-center" *ngIf="season.hasError('dateRangeError')">Date From must not be greater than Date To</p>
          </div>
        </div>
      </div>
      <div class='formDiv margin-bottom25'>
        <div class='form-group col-sm-4'>
          <button class='btn btn-default btn-add' (click)="addSeason()">Add Season</button>
        </div>
      </div>

      <div class='formDiv'>
        <div class="form-group col-sm-5 profilePic row">
          <div class='col-sm-7'>
            <label>Hotel Hero Banner Image</label>
            <p class='fileName image-name'>{{imageFile.name && imageFile.imageUrl? imageFile.name : 'No file chosen'}}</p>
            <input type="file" id="file_upload" class="form-control" (change)="uploadImage($event)" #uploadFile (focus)="errorDetails = ''">
            <button *ngIf="!imageFile.imageUrl" (click)="uploadFile.click()" class='btn-add btn-file'>Choose File</button>
            <button *ngIf="imageFile.imageUrl" (click)="uploadFile.click()" class='btn-add btn-file'>Change File</button>
            <p *ngIf="errorUpload" class="error upload col-xs-12" (click)="imageFile.errorUpload = false; errorUpload = false">Please upload Image only.</p>
            <p *ngIf="fileSizeError" class="error upload col-xs-12" (click)="imageFile.fileSizeError = false; errorUpload = false">Image size should be less than 1MB.</p>
          </div>
          <div class='col-sm-5'>
            <div *ngIf="imageFile && imageFile.name && imageFile.imageUrl && !imageFile.errorUpload && !imageFile.fileSizeError">
              <img class="image" [src]="imageFile.imageUrl" />
              <button class='btn btn-default delete-image'>
                <img src='assets/images/delete.svg' (click)="deleteImage()" />
              </button>
          </div>
        </div>


        <!-- <label class='btn btn-default fileLabel'>Choose File
            <input type="file" id="file_upload" class="form-control" (change)="fileChanged($event)" style="display: none" #uploadFile
              (focus)="errorDetails = ''">
            <p *ngIf="errorUpload" class="error upload col-xs-12" (click)="errorUpload = false">Please upload Image only.</p>
          </label> -->

      </div>
      <div class="form-group col-sm-5">
        <label>Tags</label>
        <input [(ngModel)]="tagInputText" [ngModelOptions]="{standalone: true}" style="display: none" />
        <tag-input formControlName='tags' [(inputText)]="tagInputText" [(ngModel)]="items" [onTextChangeDebounce]="300" [theme]="'minimal'"
          [onlyFromAutocomplete]="true" [identifyBy]="'hotelTagId'" [displayBy]="'name'" [placeholder]="''" (keydown)="searchTerm$.next($event.target.value)"
          (focus)="errorDetails = ''; errorUpload = false">
          <tag-input-dropdown [showDropdownIfEmpty]="false" [focusFirstElement]="true" [displayBy]="'name'" [identifyBy]="'hotelTagId'"
            [autocompleteObservable]="hotelTags">
            <ng-template let-item="item" let-index="index">
              {{ item.name }}
            </ng-template>
          </tag-input-dropdown>
        </tag-input>
      </div>
    </div>
</div>
</form>
<div class='buttonsDiv col-sm-10 col-xs-12 text-right'>
  <button class='btn btn-default' (click)="confirmClose()">Cancel</button>
  <button class='btn btn-default' (click)="addHotel(hotel)" [disabled]="!hotel.valid || disabled">Save</button>
  <!-- <button class='btn btn-default' (click)="getForm(hotel)" [disabled]="!hotel.valid || disabled">Save</button> -->
</div>
<div class="error col-xs-12" *ngIf="errorDetails">{{errorDetails}}</div>
<div class="success col-xs-12" *ngIf="successMsg">{{successMsg}}</div>
</div>