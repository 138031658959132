<div class='col-sm-10 user-management-container'>
  <div class='row head-line'>
    <div class='col-sm-6'>
      <h3 *ngIf='lsaAdminList.length'>User Management</h3>
    </div>
    <div class='col-sm-6 col-xs-12 text-right buttons'>
      <button class='btn btn-default col-xs-12 col-md-6 pull-right' type='button' routerLink='/user-management/add'>
        <img src='assets/images/plus-white.svg' /> New Admin
      </button>
    </div>
  </div>
  <div class="col-xs-12 tableData" *ngIf='lsaAdminList.length'>
    <table class="table table-bordered">
      <thead>
        <tr>
              <td *ngFor="let header of tableHeader; let idx = index " (click)="sortTable(header.value)">{{header.key}}
            <span class="sort-icons up pointer" *ngIf="header.isDesc"> &#9660;</span>
            <span class="sort-icons down pointer" *ngIf="!header.isDesc"> &#9650;</span>
          </td>
          <!-- <td>Name + Phone Number</td>
          <td>Email ID</td>
          <td>Status</td> -->
        </tr>
      </thead>
      <tbody >
        <tr *ngFor="let lsaadmin of lsaAdminList | sortBy:{property: column, direction: direction}; let i = index">
          <td>
            <p>{{lsaadmin.firstName}} {{lsaadmin.lastName1}}</p>
            <p>{{lsaadmin.mobileNumber}}</p>
          </td>
          <td class="pointer">
              <u>
                <a href="mailto:{{lsaadmin.email}}" target="_top">{{lsaadmin.email}}</a>
              </u>
            </td>
          <td>
            <div class='statusDiv dropdown'>
              <span [ngClass]="lsaadmin.status == 'Active'? 'activate':'inactivate'">{{lsaadmin.status}}</span>
              <button type='button' class='btn btn-link pull-right dropdown-toggle' data-toggle="dropdown">
                <img src='assets/images/overflow.svg' />
              </button>
              <ul class="dropdown-menu statusList">
                <li class="pointer">
                  <a [routerLink]="['/user-management/edit', lsaadmin.loyaltyUserId]" routerLinkActive="active">
                    <img class='pull-left' src='assets/images/edit.svg' />
                    <span class='pull-left'>Modify</span>
                  </a>
                </li>
                <li class="pointer" *ngIf="lsaadmin.status == 'Active'" (click)="deactivateLsaId(lsaadmin.loyaltyUserId,i)">
                  <a class='deactivate' data-toggle="modal" data-target="#myModal">
                    <img class='pull-left' src='assets/images/delete.svg' />
                    <span class='pull-left'>Deactivate</span>
                  </a>
                </li>
                <li class="pointer" *ngIf="lsaadmin.status == 'Inactive'" class="hover " (click)="deactivateLsaId(lsaadmin.loyaltyUserId,i)">
                  <a class='activate' data-toggle="modal" data-target="#myActivateModal">
                    <img class='pull-left' src='assets/images/success.svg' />
                    <span class='pull-left'>Activate</span>
                  </a>
                </li>
              </ul>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- <div *ngIf='!lsaAdminList.length' class='emptyTable text-center'>
        No Records
     </div> -->
  </div>
  <div class="message" *ngIf="!lsaAdminList.length">
    <h3 class="text-center">
      You haven't created any users yet. Click on "+ New Admin" above to get started.
    </h3>
  </div>
  <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title" id="exampleModalLabel">Deactivate LSA Account</h3>
          <p>Are you sure you want to deactivate this account?</p>
        </div>
        <div class="modal-footer text-center">
          <button type="button" class="btn btn-default" id="deleteModal" data-dismiss="modal">Cancel</button>
          <button type="button" (click)="changeLsaStatus('Deactivate')" class="btn btn-default">Deactivate</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="myActivateModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title" id="exampleModalLabel">Activate LSA Account</h3>
          <p>Are you sure you want to activate this account?</p>
        </div>
        <div class="modal-footer text-center">
          <button type="button" class="btn btn-default" id="activateModal" data-dismiss="modal">Cancel</button>
          <button type="button" (click)="changeLsaStatus('Activate')" class="btn btn-default">Activate</button>
        </div>
      </div>
    </div>
  </div>
</div>
