<div class="col-sm-11 col-sm-offset-1 members-container">
  <!--<div class="header text-right">
    <button class="btn btn-activate" *ngIf="memberInfo?.statusId?.statusId == 0" (click)="openStatusModal(memberInfo?.loyaltyMemberId, !memberInfo?.statusId?.statusId)">Activate User</button>
    <button class="btn btn-deactivate" *ngIf="memberInfo?.statusId?.statusId == 1" (click)="openStatusModal(memberInfo?.loyaltyMemberId, !memberInfo?.statusId?.statusId)">Deactivate User</button>
    <select class="form-control" [(ngModel)]="brandSelected">
      <option *ngFor="let brand of brands" [ngValue]="brand">{{brand.brandName}}</option>
    </select>
    <button class="btn btn-login" [disabled]="!memberInfo?.brandId" (click)="loginAsUser(memberInfo?.loyaltyMemberId)">Login as User</button>
  </div> -->

  <div class="row member-details" *ngIf="memberInfo">
    <div class="col-lg-6">
      <div class="row">
        <div class="col-sm-2">
          <img [src]="memberInfo.profilePicture ? getFullUrl(memberInfo.profilePicture) : 'assets/images/profile-default.svg'"
            (error)="errorLoading()" class="img-circle" width="64" height="64" />
        </div>
        <div class="col-sm-9">
          <h3 class="blue opacity_8 bold">
            <!-- {{memberInfo?.firstName}} {{memberInfo?.lastName}}  -->
            {{utilService.getFullName(memberInfo)}}
            <!-- <img class="external-link pointer" src="/assets/images/edit_blue.svg" [routerLink]="['/members/edit', memberInfo?.loyaltyMemberId]" height="20" title="Edit Member"/> -->
            <img *ngIf="memberInfo?.isMember && memberInfo?.isEmployee" class="external-link pointer" src="/assets/images/External_link_awesome.png" [routerLink]="['/employees/employee-list/edit', memberInfo?.loyaltyMemberId, 'guest-booking']" height="20" title="Go to Employee"/>
          </h3>
          <p>
            <span class="opacity_5 bold">Email/Username:</span>
            <span class="blue opacity_8">{{memberInfo?.email}}</span>
          </p>
          <p>
            <span class="opacity_5 bold">Card Code:</span>
            <span>{{memberInfo?.cardCode}}</span>
          </p>
        </div>
      </div>
      <!-- <div class="box container-fluid" *ngIf="memberInfo?.memberPoints"> -->
      <div class="box container-fluid" *ngIf="memberInfo?.brandId">
        <div class="row">
          <div class="col-xs-6">
            <div class="row">
              <div class="col-xs-3">
                <img src='assets/images/coin-large.svg' />
              </div>
              <div class="col-xs-9 padding-right-0">
                <p class="count blue">{{memberInfo?.memberPoints?.totalPoints ? memberInfo?.memberPoints?.totalPoints : 0}}</p>
                <p class="coins-text blue">{{memberInfo?.brandName}} Coins</p>
                <p class="coins-text blue">{{memberInfo?.memberShipName}} Member</p>
              </div>
            </div>
          </div>
          <div class="col-xs-1 d-none d-sm-block">
            <div class="divider"></div>
          </div>
          <div class="col-xs-5">
            <div>
              <p>
                <span class="bold opacity_5">Spent:</span>
                <span>{{memberInfo?.memberPoints?.pointsRedeemed ? memberInfo?.memberPoints?.pointsRedeemed : 0}}</span>
              </p>
              <p>
                <span class="bold opacity_5">Expired:</span>
                <span>{{memberInfo?.memberPoints?.pointsExpired ? memberInfo?.memberPoints?.pointsExpired : 0}}</span>
              </p>
            </div>
            <!-- <button class="btn btn-points">Add Points</button> -->
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="padding-top-40">
        <p>
          <span class="opacity_5 bold">Last Login:</span>
          <span>{{memberInfo?.lastAccessDate}}</span>
        </p>
        <p>
          <span class="opacity_5 bold">Creation Date:</span>
          <span>{{memberInfo?.createdDateStr}}</span>
        </p>
      </div>
      <!-- button to create booking stays 
      <div class="container-fluid">
        <div class="row">
          <div class="col-xs-12 col-sm-8 text-center">
            <button class="btn btn-login btn-booking-stay" [disabled]="!memberInfo?.brandId" (click)="openBookingStay(memberInfo?.loyaltyMemberId)">Booking Stay</button>
          </div>
        </div>
      </div> -->
      <div class="physical-card-block" *ngIf="memberInfo?.loyaltyMemberTrackingCard">
        <div class="row card-request-block">
          <form [formGroup]="physicalCardForm" novalidate>
            <div class='form-group col-sm-12 checkbox-list2'>
              <input type='checkbox' name="redeem" id='r2' formControlName="status" [checked]="memberInfo?.loyaltyMemberTrackingCard?.status == 'Delivered'"/>
              <label for='r2'>
                <span class='check-box'></span>
                <span class="text">Physical Card Request status</span> &nbsp;
              </label>
            </div>
          </form>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <p class="title">Physical Card Information:</p>
            <div class="info-block">
              <div class="info-detail">
                <p style="display: inline-block">Address:</p>
                <p style="display: inline-block">{{memberInfo?.loyaltyMemberTrackingCard?.address ? memberInfo?.loyaltyMemberTrackingCard?.address : 'N/A'}}</p>
              </div>
              <div class="info-detail">
                <p style="display: inline-block">Created Date:</p>
                <p style="display: inline-block">{{memberInfo?.loyaltyMemberTrackingCard?.createdDate ? memberInfo?.loyaltyMemberTrackingCard?.createdDate : 'N/A'}}</p>
              </div>
              <div class="info-detail">
                <p style="display: inline-block">Updated Date:</p>
                <p style="display: inline-block">{{memberInfo?.loyaltyMemberTrackingCard?.updatedDate ? memberInfo?.loyaltyMemberTrackingCard?.updatedDate : 'N/A'}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row member-details">
      <div class="col-lg-12">
          <div *ngIf="duplicateEmailOf && duplicateEmailOf != 0 && duplicateEmailOf.length > 0"><b>Duplicated due to</b>:</div>
          <div *ngFor="let emailObj of duplicateEmailOf"><a href="/members/modify/{{emailObj.id}}/booking">{{emailObj.emailId}}</a></div>
          <div style="padding-top:10px;"></div>
          <div *ngIf="emailDuplicatedBy && emailDuplicatedBy != 0 && emailDuplicatedBy.length > 0"><b>Duplicate(s) of</b>:</div>
          <div *ngFor="let emailObj of emailDuplicatedBy"><a href="/members/modify/{{emailObj.id}}/booking">{{emailObj.emailId}}</a></div>
      </div>
  </div>
  <div>
    <ul class="nav nav-tabs row members-tabs">
      <li class="text-center">
        <a [routerLink]="['booking']" routerLinkActive="active" [ngClass]="memberInfo && memberInfo.bookingEscalation ? 'err-tab' : ''">Bookings</a>
      </li>
      <li class="text-center">
        <a [routerLink]="['old-transaction-history']" routerLinkActive="active" [ngClass]="memberInfo && memberInfo.manualPoints ? 'err-tab' : ''">Old Transaction History</a>
      </li>
      <li class="text-center">
        <a [routerLink]="['transaction-history']" routerLinkActive="active" [ngClass]="memberInfo && memberInfo.manualPoints ? 'err-tab' : ''">Transaction History</a>
      </li>
      <li class="text-center">
        <a [routerLink]="['shopping-cart']" routerLinkActive="active" [ngClass]="memberInfo && memberInfo.nonAutomaticRedemtion ? 'err-tab' : ''">Shopping Cart</a>
      </li>
      <li class="text-center">
        <a [routerLink]="['comments']" routerLinkActive="active">Comments</a>
      </li>
      <li class="text-center">
        <a [routerLink]="['profile-changes']" routerLinkActive="active">Profile Changes</a>
      </li>
    </ul>
    <div class="tab-content">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
