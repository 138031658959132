<div class='col-sm-9 col-sm-offset-3 main-container'>
  <div class='head-line'>
    <h3>Modify Administrator Account</h3>
  </div>
  <form novalidate class='form-group' [formGroup]="user">
    <div class='row'>
      <div class='formDiv'>
        <div class="form-group col-sm-5">
          <label>Admin Role*</label>
          <select class='form-control' formControlName="roleId" (focus)="errorDetails = ''" [ngClass]="user.get('roleId').hasError('required') && user.get('roleId').touched ? 'err':''">
            <option value="">Choose Role</option>
            <option *ngFor="let role of hcaRoles" [value]="role.roleId">
              {{role.roleName}}
            </option>
          </select>
        </div>
      </div>
      <div class='formDiv'>
        <div class="form-group col-sm-5">
          <label>Hotel Brand *</label>
          <select class='form-control' formControlName="brandId" (focus)="errorDetails = ''" [ngClass]="user.get('brandId').hasError('required') && user.get('brandId').touched ? 'err':''">
            <option value="" *ngIf="user.get('roleId').value != 12">Choose Brand</option>
            <option value="" *ngIf="user.get('roleId').value == 12">All</option>
            <option *ngFor="let brand of hotelBrands" [value]="brand.brandId">{{brand.brandName}}</option>
          </select>
        </div>
        <div class="form-group col-sm-5">
          <label>Hotels</label>
          <select class='form-control dropdown-toggle' (focus)="errorDetails = ''" data-toggle="dropdown" formControlName="hotelId"
            [ngClass]="user.get('hotelId').hasError('required') && user.get('hotelId').touched ? 'err':''">
            <option value="" *ngIf="user.get('roleId').value == 14 || user.get('roleId').value == ''">Choose Hotel</option>
            <option value="" *ngIf="user.get('roleId').value == 12 || user.get('roleId').value == 13">All</option>
            <option *ngFor="let hotel of hotels" [value]="hotel.hotelId">{{hotel.hotelName}}</option>
          </select>
        </div>
      </div>
      <div class='formDiv'>
        <div class="form-group col-sm-5">
          <label>First Name *</label>
          <input type="text" placeholder="" formControlName="firstName" class='form-control' [ngClass]="user.get('firstName').hasError('required') && user.get('firstName').touched ? 'err':''"
            (focus)="errorDetails = ''">
        </div>
        <div class="form-group col-sm-5">
          <label>Last Name *</label>
          <input type="text" placeholder="" formControlName="lastName" class='form-control' [ngClass]="user.get('lastName').hasError('required') && user.get('lastName').touched ? 'err':''"
            (focus)="errorDetails = ''">
        </div>
      </div>
      <div class='formDiv'>
        <div class="form-group col-sm-5">
          <label>Email ID *</label>
          <input type="email" placeholder="" formControlName="email" class='form-control' [ngClass]="(user.get('email').hasError('pattern') || user.get('email').hasError('required')) && user.get('email').touched?'err':''"
            (focus)="errorDetails = ''" disabled>
        </div>
        <div class="form-group col-sm-5">
          <label>Contact Number</label>
          <input type="tel" placeholder="" formControlName="mobileNumber" class='form-control' [ngClass]="user.get('mobileNumber').hasError('pattern') || user.get('mobileNumber').hasError('minlength') || user.get('mobileNumber').hasError('maxlength') ?'err':''"
            (focus)="errorDetails = ''">
          <!-- <div class="error" style="width:288px;margin-top:0" *ngIf="user.get('mobileNumber').hasError('pattern')">
                Mobile Number should contain only Numbers
              </div> -->
        </div>
      </div>
      <div class='formDiv'>
        <div class="form-group col-sm-5">
          <label>Skype ID</label>
          <input type="email" placeholder="" formControlName="skypeId" class='form-control' [ngClass]="user.get('skypeId').hasError('pattern') && user.get('skypeId').touched ? 'err':''"
            (focus)="errorDetails = ''">
        </div>
      </div>

    </div>
  </form>
  <div class='buttonsDiv text-right col-sm-10'>
    <!-- <button class='btn btn-default col-sm-4 col-xs-12 in-active'>Deactivate</button> -->
    <button *ngIf="hcaStatus == 'Active'" class='btn btn-default in-active' data-toggle="modal" data-target="#myModal">Deactivate</button>
    <button *ngIf="hcaStatus == 'Inactive'" class='btn btn-default activate' data-toggle="modal" data-target="#myActivateModal">Activate</button>
    <button [routerLink]="['/settings/hca-user-management']" class='btn btn-default'>Cancel</button>
    <button (click)="saveUser(user)" class='btn btn-default' [disabled]="!user.valid">Save</button>
  </div>
  <div class="error col-xs-12" *ngIf="errorDetails">{{errorDetails}}</div>
</div>

<div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="exampleModalLabel">Deactivate {{user.get('roleId').value == 12 ? 'HCA' : (user.get('roleId').value == 13 ? 'BA' : 'HA')}} Account</h3>
        <p>Are you sure you want to deactivate this account?</p>
      </div>
      <div class="modal-footer text-center">
        <button type="button" class="btn btn-default" id="deleteModal" data-dismiss="modal">Cancel</button>
          <button type="button" (click)="updateUserStatue('Inactive')" class="btn btn-default">Deactivate</button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="myActivateModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="exampleModalLabel">Activate {{user.get('roleId').value == 12 ? 'HCA' : (user.get('roleId').value == 13 ? 'BA' : 'HA')}} Account</h3>
        <p>Are you sure you want to activate this account?</p>
      </div>
      <div class="modal-footer text-center">
        <button type="button" class="btn btn-default" id="activateModal" data-dismiss="modal">Cancel</button>
        <button type="button" (click)="updateUserStatue('Activate')" class="btn btn-default">Activate</button>
      </div>
    </div>
  </div>
</div>
