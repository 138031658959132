<div>
  <ul class="nav nav-tabs landing-page-tabs">
    <li class="active col-sm-3">
      <div class='text-center '>
        <a data-toggle="tab" href="#heroBanner">
          <span class='heading'>Hero Banner Setup</span>
          <span class='steps'>1</span>
        </a>
      </div>
    </li>
    <!-- <li class='col-sm-3'>
      <div class='text-center '>
        <a data-toggle="tab" href="#bookingWidget">
          <span class='heading'>Booking Widget</span>
          <span class='steps'>2</span>
        </a>
      </div>
    </li> -->
    <li class='col-sm-3'>
      <div class='text-center '>
        <a data-toggle="tab" href="#catalogBanner">
          <span class='heading'>Catalog Banner Setup</span>
          <span class='steps'>2</span>
        </a>
      </div>
    </li>
    <li class='col-sm-3'>
      <div class='text-center '>
        <a data-toggle="tab" href="#nextStep">
          <span class='heading'>Next Step Banner Setup</span>
          <span class='steps'>3</span>
        </a>
      </div>
    </li>
  </ul>
  <div class="tab-content">
    <div id="heroBanner" class="tab-pane fade in active">
      <app-landing-page-hero-banner></app-landing-page-hero-banner>
    </div>
    <div id="bookingWidget" class="tab-pane fade">
      <app-landing-page-booking-widget></app-landing-page-booking-widget>
    </div>
    <div id="catalogBanner" class="tab-pane fade">
      <app-landing-page-catalog-banner></app-landing-page-catalog-banner>
    </div>
    <div id="nextStep" class="tab-pane fade">
      <app-landing-page-next-step></app-landing-page-next-step>
    </div>
  </div>
</div>