<div class='col-sm-10 col-sm-offset-2 physical-card-container'>
  <div>
    <h3 class="heading">Physical Cards</h3>
  </div>


  <div class='row menuDiv'>
    <!-- filter dropdown start -->
    <div class='col-sm-4 dropdown filter-btn filter'>
      <form [formGroup]="physicalCardForm">
        <button class='btn btn-default dropdown-toggle filter filterBtnWidth' data-toggle="dropdown"
          (click)='dropDown($event)'
          [disabled]="!isFiltered && !cardsList.length" [ngClass]="isFilterOn ? 'on' : 'off'">
          <img class="filter"
            *ngIf="!isFilterOn"
            src="assets/images/filter-inactive.svg" />
          <img class="filter"
          *ngIf="isFilterOn"
            src="assets/images/filter-active.svg" />
          <span class="filter">
            <span>Filter {{isFilterOn ? '(On)' : '(Off)'}} &nbsp;{{isFilterOn ? (totalCount ? totalCount+' result(s)' : '0 result(s)')
                : ''}}</span>
          </span>
        </button>
        <ul class="dropdown-menu filter-menu" id='physical-card-filter'>
          <li class='form-group'>
            <a>
              <label>Status</label>
              <select class='form-control' formControlName="status" *ngIf="memberRulesList">
                <option value="all">All</option>
                <option *ngFor="let status of memberRulesList.status" [value]="status">{{status}}</option>
              </select>
            </a>
          </li>
          <li class='divider'></li>
          <li class='form-group dropdown'>
            <a>
              <label>Country</label>
              <div class='form-control dropdown-toggle brand-select' data-toggle='dropdown'>
                {{physicalCardForm.get('country').value.length}}
                {{physicalCardForm.get('country').value.length != 1 ? 'countries' : 'country'}} selected
              </div>
              <ul class='dropdown-menu checkbox-list' *ngIf="countriesList">
                <li class='check-list' (click)='dropDown($event)' *ngFor="let country of countriesList;let i = index;">
                  <input type='checkbox' [id]='"country"+country.countryId' name='country' value="'country'+country.countryId"
                    (change)="checkSelection($event.target.checked, 'physicalCardForm', 'country', country.countryId);" />
                  <label [for]='"country"+country.countryId'>
                    <span class='ip-text'>{{country.name}}</span>
                    <span class='check-box'></span>
                  </label>
                </li>
              </ul>
            </a>
          </li>
          <li class='divider'></li>
          <li class='form-group dropdown'>
            <a>
              <label>Brand</label>
              <div class='form-control dropdown-toggle brand-select' data-toggle='dropdown'>
                {{physicalCardForm.get('brands').value.length}}
                Brand{{physicalCardForm.get('brands').value.length != 1 ? 's' : ''}} selected
              </div>
              <ul class='dropdown-menu checkbox-list' *ngIf="brandsList">
                <li class='check-list' (click)='dropDown($event)' *ngFor="let brand of brandsList;let i = index;">
                  <input type='checkbox' [id]='"brand"+brand.brandId' name='brand' value="'brand'+brand.brandId"
                    (change)="checkSelection($event.target.checked, 'physicalCardForm', 'brand', brand.brandId);" />
                  <label [for]='"brand"+brand.brandId'>
                    <span class='ip-text'>{{brand.brandName}}</span>
                    <span class='check-box'></span>
                  </label>
                </li>
              </ul>
            </a>
          </li>

          <li class='divider'></li>
          <li class='form-group dropdown' [ngClass]="brandHotels.length <= 0 ? 'disbaleList' : '' ">
            <a>
              <label>Hotel</label>
              <div class="form-control custom-dropdown">
                <div class="btn-group col-xs-12 padding-0 select-icon" dropdown #dropdown="bs-dropdown"
                  [autoClose]="true">
                  <button dropdownToggle type="button" class="btn dropdown-toggle form-control"
                    [ngClass]="{disabled : brandHotels.length <= 0}">
                    {{getSelectedStr(physicalCardForm.get('hotelIds').value.length, 'Hotel')}}
                  </button>
                  <ul id="dropdown-triggers-manual" *dropdownMenu class="dropdown-menu checkbox-list" role="menu"
                    aria-labelledby="button-triggers-manual">
                    <li *ngFor="let hotel of brandHotels" role="menuitem" class="hand dropdown-item check-list">
                      <input type='checkbox' [id]='"hotel"+hotel.hotelId' name='hotel' [value]="hotel.hotelId"
                        [checked]="physicalCardForm.get('hotelIds').value.indexOf(hotel.hotelId) >= 0"
                        (change)="checkSelected($event.target.checked, 'hotelIds', 'hotelId', hotel.hotelId)" />
                      <label [for]='"hotel"+hotel.hotelId'>
                        <span class='ip-text'>{{hotel.hotelName}}</span>
                        <span class='check-box'></span>
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
            </a>
          </li>

          <li class='form-group'>
            <a class="close-position">
              <label>Created Between</label>
              <input type="text" placeholder="Created between" placement="top" class="form-control search-box"
                onkeydown='return event.which == 8 || event.which == 46 || event.which == 37 || event.which == 39'
                bsDaterangepicker formControlName="range">
              <button class="close-icon" (click)="clearDate()"></button>
              <!-- <label>Creation Date (Year)</label>
                <select class='form-control'> -->

              <!-- <option value="">All</option>
                  <option>promo.name</option> -->
              <!-- </select> -->
            </a>
          </li>
        </ul>
      </form>
    </div>
    <!-- filter dropdown end -->

    <div class='col-sm-4 search-text'>
      <span>{{totalCount}} results</span>
    </div>
    <!-- <div class='col-sm-4 form-group'>
        <input type='search' class='form-control' placeholder="Search Members" />
      </div> -->
    <div class='col-sm-4 form-group'>
      <form [formGroup]="searchForm">
        <input type='search' class='form-control' placeholder="Search Physical Cards" [class.disabled]="!isFiltered && !cardsList.length" (keyup)="searchTerm"
           formControlName="searchTerm" />
        <!-- <input type='search' class='form-control' placeholder="Search Members" (keyup)="searchTerm" formControlName="searchTerm" (keypress)="blockSpecialChars($event)"/> -->
      </form>
    </div>
  </div>

  <!-- rewards empty state with filters -->
  <div class='message text-center' *ngIf="isFiltered && !cardsList.length">
    <h3>No results found</h3>
  </div>

  <!-- Empty state -->
  <div class='message text-center' *ngIf="!isFiltered && !cardsList.length">
    <h3>You don't have any cards yet.</h3>
  </div>

  <!--  Cards List -->
  <div class="table-container" *ngIf='cardsList.length'>
    <div class="table-responsive">
      <table class="table table-catalog">
        <thead>
          <tr>
            <th *ngFor="let header of tableHeader; let idx = index" class="pointer"
              (click)="sortTable(header.property, ['status'])">{{header.key}}
              <span class="sort-icons up pointer" *ngIf="header.direction != null && header.direction == 'asc'">
                &#9660;</span>
              <span class="sort-icons down pointer" *ngIf="header.direction != null && header.direction == 'desc'">
                &#9650;</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let card of cardsList | paginate: { itemsPerPage: size, currentPage: p, totalItems: totalCount }">
            <td class="break-word">{{card?.name ? card?.name : 'N/A'}}</td>
            <td class="break-word">{{card?.hotel ? card?.hotel : 'N/A'}}</td>
            <td class="break-word">{{card?.country ? card?.country : 'N/A'}}</td>
            <td class="break-word">{{card?.address ? card?.address : 'N/A'}}</td>
            <td class="">
              <div class='dropdown status'>
                <span [ngClass]="card.status == 'Delivered' ? 'activate' : 'inactivate'">
                    {{card.status}}
                  </span>
                <button type='button' class='btn btn-link dropdown-toggle' data-toggle="dropdown" *ngIf="card?.memberId">
                  <img src='assets/images/overflow.svg' />
                </button>
                <ul class="dropdown-menu statusList">
                  <li class="pointer">
                    <a [routerLink]="['/members', 'modify', card?.memberId, 'booking']">
                      <img class='pull-left' src='assets/images/edit.svg' />
                      <span class='pull-left'>Modify</span>
                    </a>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td class="tfooter" colspan="13">
              <pagination-controls class="pull-right" (pageChange)="pageChanged($event)"></pagination-controls>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</div>
