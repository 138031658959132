<div class='col-sm-10 col-sm-offset-2 benefits-container'>
  <div class="row">
    <p class="col-xs-6 heading text-right padding-0">Benefits</p>
    <div class='col-xs-6 buttonsDiv text-right pull-right'>
        <button class='btn btn-default btn-add-benefit' [routerLink]="['add']">
            <img src='assets/images/plus-white.svg' class='pull-left' /> New Benefit
        </button>
    </div>
  </div>
  <div class='row menuDiv'>
    <div class='col-sm-7 dropdown btn-action text-center padding-left-1'>
        <div class="btn-group padding-0" dropdown #dropdown="bs-dropdown" [autoClose]="true" [class.disabled]="selectedBenefits && selectedBenefits.length == 0 ? 'disabled' : ''" >
          <button [ngClass]="selectedBenefits.length ? 'on' : 'off'" dropdownToggle type="button" class="btn dropdown-toggle">
              <!-- <img [src]="activateAction ? 'assets/images/action-active.svg' : 'assets/images/action-inactive.svg'" /> --> 
              <span>Actions</span><span class='glyphicon glyphicon-chevron-down'></span>
          </button>
          <ul id="dropdown-triggers-manual" *dropdownMenu class="dropdown-menu"
              role="menu" aria-labelledby="button-triggers-manual">
            <li role="menuitem" class="plus pointer">
              <a class="dropdown-item" (click)="openStatusModal(this.selectedBenefits, 1)">
                  <img src='assets/images/success.svg' class='pull-left' />
                  <span class='pull-left activate'>Activate Selected Benefits</span>
              </a>
            </li>
            <li role="menuitem" class='plus pointer'>
                <a class="dropdown-item" (click)="openStatusModal(this.selectedBenefits, 0)">
                    <img src='assets/images/delete.svg' class='pull-left' />
                    <span class='pull-left deactivate'>Deactivate Selected Benefits</span>
                </a>
            </li>
          </ul>
        </div>
      <span class='action-text' *ngIf="benefitsList && benefitsList.length">{{selectedBenefits.length}} of {{benefitsList.length}} selected</span>
    </div>
    <!-- <div class='col-sm-3 search-text'>
      <span>0 results 10 total</span>
    </div>
    <div class='col-sm-3 form-group'>
      <input type='search' class='form-control' [(ngModel)]="searchText" placeholder="Search Products..." />
    </div> -->
  </div>

  <!-- Empty state -->
  <div class='col-sm-offset-4 col-md-offset-3 col-lg-offset-2 col-md-8 empty-state-text text-center' *ngIf='benefitsList && !benefitsList.length'>
      <h3>You haven’t created any Benefits yet. Click on “+ New Benefit” above to get started.</h3>
  </div>
  <!-- Empty state -->

  <div class="col-sm-offset-1 table-responsive" *ngIf='benefitsList && benefitsList.length'>
      <table class="table table-benefits">
          <thead>
              <tr>
                  <th class='table-checkbox text-center'>
                      <input type='checkbox' id='benefit0' name='benefit' value="benefit0" [(ngModel)]="allBenefitsSelected" (change)="selectBenefits('all')" />
                      <label for='benefit0' class='margin-0'>
                        <span class='check-box'></span>
                      </label>
                  </th>
                  <th colspan="5" class="text-left">Benefit</th>
                  <!-- <th>Point Value</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Type</th> -->
                  <th colspan="2" class="text-right status-text">Status</th>
              </tr>
          </thead>
          <tbody>
              <tr *ngFor="let benefit of benefitsList | paginate: { itemsPerPage: size, currentPage: p, totalItems: totalCount }">
              <!-- <tr> -->
                  <td class='table-checkbox text-center'>
                      <input type='checkbox' [id]='"benefit"+benefit.benefitId' name='promo' value='benefit"+benefit.benefitId' [(ngModel)]="benefit.checked" (change)="selectBenefits('single')" />
                      <label [for]='"benefit"+benefit.benefitId'>
                          <span class='check-box'></span>
                      </label>
                  </td>
                  <td class="text-left" colspan="5">{{benefit.benefitName}}</td>
                  <!-- <td>pointValue</td>
                  <td>dateFromStr</td>
                  <td>dateToStr</td>
                  <td>promoCodeTypeName</td> -->
                  <td class="text-right" colspan="2">
                      <div class="dropdown padding-0" dropdown #dropdown="bs-dropdown" [autoClose]="true">
                          <span [ngClass]="benefit.status == 1 ? 'activate' : 'inactivate'">
                            {{benefit.status == 1 ? 'Active' : 'Inactive'}}
                          </span>
                          <button dropdownToggle type="button" class="btn btn-link dropdown-toggle">
                              <img src='assets/images/overflow.svg' />
                          </button>
                          <ul id="dropdown-triggers-manual" *dropdownMenu class="dropdown-menu statusList"
                              role="menu" aria-labelledby="button-triggers-manual">
                            <li role="menuitem" class="plus pointer">
                              <a class="dropdown-item" [routerLink]="['edit', benefit.benefitId]">
                                  <img class='' src='assets/images/edit.svg' />
                                  <span class=''>Modify</span>
                              </a>
                            </li>
                            <li role="menuitem" class="plus pointer" *ngIf="benefit.status == 0">
                              <a class="dropdown-item" (click)="openStatusModal([benefit.benefitId], !benefit.status)">
                                  <img src='assets/images/success.svg' class=''/>
                                  <span class='activate'>Activate</span>
                              </a>
                            </li>
                            <li role="menuitem" class='plus pointer' *ngIf="benefit.status == 1">
                                <a class="delete" (click)="openStatusModal([benefit.benefitId], !benefit.status)">
                                    <img src='assets/images/delete.svg' class='' />
                                    <span class='deactivate'>Deactivate</span>
                                </a>
                            </li>
                          </ul>
                        </div>
                      <!-- <div class='dropdown status'>
                          <span >status</span>
                          <button type='button' class='btn btn-link dropdown-toggle' data-toggle="dropdown">
                              <img src='assets/images/overflow.svg' />
                          </button>
                          <ul class="dropdown-menu statusList">
                              <li class="pointer">
                                  <a>
                                      <img class='pull-left' src='assets/images/edit.svg' />
                                      <span class='pull-left'>Modify</span>
                                  </a>
                              </li>
                              <li class="pointer">
                                  <a class='deactivate'> 
                                      <img class='pull-left' src='assets/images/delete.svg' />
                                      <span class='pull-left'>Deactivate</span>
                                  </a>
                              </li>
                              <li class="pointer">
                                  <a class='activate'>
                                      <img class='pull-left' src='assets/images/success.svg' />
                                      <span class='pull-left'>Activate</span>
                                  </a>
                              </li>
                          </ul>
                      </div> -->
                  </td>
              </tr>
          </tbody>
          <tfoot>
              <tr>
                <td class="tfooter" colspan="8"> <pagination-controls class="pull-right"  (pageChange)="pageChanged($event)"></pagination-controls></td>
              </tr>
            </tfoot>
      </table>
  </div>
</div>
