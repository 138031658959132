
<div class="transaction-container col-sm-10 col-sm-offset-1">
  <h3 class="text-center">Booking Transaction History</h3>
  <div>
    <button (click)="goBack()" class="btn btn-cancel col-sm-2 backBtnStyle"><img class="backArrowStyle" src='assets/images/back-arrow.png' />Back</button>
  </div>
  <div class="table-responsive">
    <!-- <h3 class="text-center noTransactionTxtAlign" *ngIf="!transactionList?.length">No Transaction History found</h3> -->
    <table class="table transaction-table" *ngIf="transactionList">
      <thead>
        <tr>
          <th>Created at/Status</th>
          <th>Member</th>
          <th>Locator</th>
          <th>Description/Hotel</th>
          <th>Coins</th>
          <th>Cash Paid</th>
          <th>Quantity</th>
          <th>Nights</th>
          <th>Updated at</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <span>{{transactionList.transactionDateStr}}</span>
            <br/>
            <span [ngClass]="(transactionList.status?.statusId == 4 || transactionList.status?.statusId == 0) ? 'inactive' : (transactionList.status?.statusId == 3 ? 'pending' : 'active')">{{transactionList.status?.statusDesc}}</span>
          </td>
          <td>{{transactionList.memberName ? transactionList.memberName : 'N/A'}}</td>
          <td>{{transactionList.locator ? transactionList.locator : 'N/A'}}</td>
          <td>
            <span>{{transactionList.description ? transactionList.description+'/' : transactionList.description}}</span>
            <br/>
            <span>{{transactionList.hotelName}}</span>
          </td>
          <td>{{transactionList.points ? transactionList.points : 0}}</td>
          <td>${{transactionList.cashPaid ? transactionList.cashPaid : 0}}</td>
          <td>{{transactionList.quantity ? transactionList.quantity : 0}}</td>
          <td>{{transactionList.nights ? transactionList.nights : 'N/A'}}</td>
          <td>{{transactionList.updatedDateStr}}</td>
          <td class="dropdown">
            <button class="btn btn-link dropdown-toggle" data-toggle='dropdown'>
              <img src='assets/images/overflow.svg' />
            </button>
            <ul class="dropdown-menu custom-dropdown-menu pointer">
              <li *ngIf="transactionList.status?.statusId == 2 || transactionList.status?.statusId == 4 || transactionList.status?.statusId == 5" (click)="openStatusModal(transactionList?.pointTransactionId, 3, 'Cancel')">
                <a class="inactive">Cancel</a>
              </li>
              <li *ngIf="transactionList.status?.statusId == 3" (click)="openStatusModal(transactionList?.pointTransactionId, 5, 'Approve')">
                <a class="active">Approve</a>
              </li>
              <li *ngIf="transactionList.status?.statusId == 3" (click)="openStatusModal(transactionList?.pointTransactionId, 4, 'Reject')">
                <a class="inactive">Reject</a>
              </li>
              <li *ngIf="transactionList.status?.statusId != 0" (click)="openStatusModal(transactionList?.pointTransactionId, 0, 'Delete')">
                <a class="inactive">Delete</a>
              </li>
            </ul>
          </td>
        </tr>
      </tbody>
      <!-- <tfoot>
        <tr>
          <td class="tfooter" colspan="9"> <pagination-controls class="pull-right"  (pageChange)="getTransactionHistory($event)"></pagination-controls></td>
        </tr>
      </tfoot> -->
    </table>
  </div>
</div>
