<div>
  <div class='row'>
    <div class='col-sm-9 padding-right-10'>
      <ul class='nav nav-tabs rules-tabs'>
        <li class="active">
          <a id="en1" *ngIf="!isDirtyEs" data-toggle="tab" href="#nEng">
            <span>English</span>
          </a>
          <a *ngIf="isDirtyEs" class="pointer" (click)="changeLanguage()">
            <span>English</span>
          </a>
        </li>
        <li class="">
          <a id="es1" *ngIf="!isDirtyEn" data-toggle="tab" href="#nSp">
            <span>Español</span>
          </a>
          <a *ngIf="isDirtyEn" class="pointer" (click)="changeLanguage()">
            <span>Español</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
  <div class="tab-content">
    <!-- English -->
    <div id="nEng" class="tab-pane fade in active">
      <div class="centered-content">
        <div>
          <div class="col-sm-9 tabPaneBorder">
            <div class='editContainer col-xs-12'>
              <div class='row'>
                <div class='col-xs-12'>
                  <p class='title'>
                    New Member
                  </p>
                  <form novalidate [formGroup]="newMemberEn" class='member-form'>
                    <div class='row'>
                      <div class='form-group col-sm-9'>
                        <label class='labelStyle'>
                          Heading
                        </label>
                        <textarea class='form-control inputTextStyle' rows='2' formControlName="heading" (focus)="errorDetails = ''"></textarea>
                      </div>
                    </div>
                    <div class='row'>
                      <div class='form-group col-sm-9'>
                        <label class='labelStyle'>
                          Sub-Heading
                        </label>
                        <textarea class='form-control inputTextStyle' rows='2' formControlName="subHeading" (focus)="errorDetails = ''"></textarea>
                      </div>
                    </div>
                    <!-- <div formArrayName="tilesTo">
                      <div class='repeat-fields' *ngFor="let tile of newMemberEn.controls['tilesTo']['controls']; let i=index" xstyle='border-top:1px solid #ddd;padding-top:30px;'>
                        <div formGroupName="{{i}}">
                          <div class='form-group row upload-image-container'>
                            <div class='xform-group col-sm-6 padding-top-7'>
                              <label class='labelStyle'>
                                Image
                              </label>
                              <input class='form-control tile' type='file' [attr.id]="'newMemberEn-'+i" #uploadTileImg (change)="fileChanged($event, 'newMemberEn', i, 'uploadTileImg', newMemberEn.get('tilesTo').value.length)"
                                (focus)="errorDetails = ''" />
                              <button (click)="uploadTileImg.click()" class='btn-file'>
                                Upload Photo
                              </button>
                              <p *ngIf="tile.get('imageError').value" class="error upload">Please upload Image only.</p>
                            </div>
                            <div class='col-sm-3' *ngIf="tile.get('image').value && !tile.get('imageError').value">
                              <img class="image" [src]="tile.get('imageUrl').value ? tile.get('imageUrl').value : getImgStyle(tile.get('id').value, tile.get('image').value)"
                              />
                              <button class='btn btn-default delete-image'>
                                <img src='assets/images/delete.svg' (click)="deleteImage('newMemberEn', i,'english')" />
                              </button>
                            </div>
                            <div class='col-sm-3 text-right pull-right' *ngIf="newMemberEn.get('tilesTo').value.length > 1">
                              <button class='btn btn-default remove-btn' (click)="remove('newMemberEn', 'tilesTo', i,'english')">
                                <img class="pull-left" src="assets/images/minus.svg">
                                <span>Remove</span>
                              </button>
                            </div>
                          </div>
                          <div class='row'>
                            <div class='form-group col-sm-9'>
                              <label class='labelStyle'>
                                Title
                              </label>
                              <textarea class='form-control inputTextStyle' rows='2' formControlName='tilesHeading' (focus)="errorDetails = ''"></textarea>
                            </div>
                          </div>
                          <div class="row">
                            <div class='form-group col-sm-9'>
                              <label class='labelStyle'>
                                Coins
                              </label>
                              <input type='number' class='form-control inputTextStyle buttonText' onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                formControlName='earnPoints' (focus)="errorDetails = ''" />
                            </div>
                          </div>
                          <div class="row">
                            <div class='form-group col-sm-9'>
                              <label class='labelStyle'>
                                Action URL
                              </label>
                              <input type='text' class='form-control inputTextStyle' formControlName='actionUrl' [ngClass]="tile.get('actionUrl').hasError('pattern') ? 'err' : ''"
                                (focus)="errorDetails = ''" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> -->
                    <!-- <button class='btn-file' (click)="addTiles(null, 'newMemberEn')" [ngClass]="newMemberEn.get('tilesTo').value.length == 4 ? 'disabled' : '' ">Add</button> -->
                  </form>
                </div>
              </div>
              <div class='row'>
                <div class='col-xs-12'>
                  <p class='title'>
                    Past Visit
                  </p>
                  <form class="past-form" [formGroup]="pastVisitEn">
                    <div class='row'>
                      <div class='form-group col-sm-9'>
                        <label class='labelStyle'>
                          Heading
                        </label>
                        <textarea class='form-control inputTextStyle' rows='2' formControlName="heading" (focus)="errorDetails = ''"></textarea>
                      </div>
                    </div>
                    <div class='row'>
                      <div class='form-group col-sm-9'>
                        <label class='labelStyle'>
                          Sub-Heading
                        </label>
                        <textarea class='form-control inputTextStyle' rows='2' formControlName="subHeading" (focus)="errorDetails = ''"></textarea>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div class='row'>
                <div class='col-xs-12'>
                  <p class='title'>
                    Upcoming Visit
                  </p>
                  <form [formGroup]="upcomingVisitEn">
                    <div class='row'>
                      <div class='form-group col-sm-9'>
                        <label class='labelStyle'>
                          Heading
                        </label>
                        <textarea class='form-control inputTextStyle' rows='2' formControlName="heading" (focus)="errorDetails = ''"></textarea>
                      </div>
                    </div>
                    <div class="row">
                      <div class='form-group col-sm-9'>
                        <label class='labelStyle'>
                          Sub-Heading
                        </label>
                        <textarea class='form-control inputTextStyle' rows='2' formControlName="subHeading" (focus)="errorDetails = ''"></textarea>
                      </div>
                    </div>
                    <!-- <div class="row">
                      <div class='form-group col-sm-9'>
                        <label class='labelStyle'>
                          Message
                        </label>
                        <textarea class='form-control inputTextStyle' rows='2' formControlName="message" (focus)="errorDetails = ''"></textarea>
                      </div>
                    </div> -->
                    <div class="row">
                      <div class='form-group col-sm-9'>
                        <label class='labelStyle'>
                          Button Text
                        </label>
                        <input type='text' class='form-control inputTextStyle buttonText' placeholder="View Booking" formControlName="buttonText"
                          (focus)="errorDetails = ''" />
                      </div>
                    </div>
                    <div class="row">
                      <div class='form-group col-sm-9'>
                        <label class='labelStyle'>
                          Button Action
                        </label>
                        <label class="radio">
                          <input id="upcomingBanner1" type="radio" value="Book Now" formControlName="buttonActionType" (focus)="errorDetails = ''">
                          <span class="outer"></span>
                          <span class='radio-text'>
                            Book Now
                          </span>
                        </label>
                        <label class="radio">
                          <input id="upcomingBanner2" type="radio" value="External URL" formControlName="buttonActionType" (focus)="errorDetails = ''">
                          <span class="outer"></span>
                          <span class='radio-text'>
                            External URL
                          </span>
                        </label>
                      </div>
                    </div>
                    <div class="row">
                      <div class='form-group col-sm-9' *ngIf="upcomingVisitEn.get('buttonActionType').value == 'External URL'">
                        <label class='labelStyle'>
                          <!-- {{'LANDING-PAGE.EXTERNAL-URL' | translate}} -->
                          External URL
                        </label>
                        <input type="text" class='form-control inputTextStyle l-page' placeholder="External URL" formControlName="externalUrl" (focus)="errorDetails = ''"
                          [ngClass]="upcomingVisitEn.get('externalUrl').hasError('pattern') || upcomingVisitEn.get('externalUrl').hasError('required') ? 'err' : ''">
                      </div>
                    </div>
                    <!-- <div class="row">
                      <div class='form-group col-sm-9'>
                        <p>Perk Messages</p> -->
                        <!-- <label class='labelStyle'>Perk Heading</label>
                        <textarea class='form-control inputTextStyle' rows='2' formControlName="perkHeading" (focus)="errorDetails = ''"></textarea>
                        <label class='labelStyle'>Perk Message</label>
                        <textarea class='form-control inputTextStyle' rows='2' formControlName="perkMessage" (focus)="errorDetails = ''"></textarea> -->
                        <!-- <div formArrayName="perkDescription">
                          <div *ngFor="let perk of upcomingVisitEn.controls['perkDescription']['controls']; let i=index">
                            <div>
                              <label class='labelStyle'>
                                Perk Description
                              </label>
                              <textarea class='form-control inputTextStyle' rows='1' formControlName="{{i}}" (focus)="errorDetails = ''"></textarea>
                            </div>
                          </div>
                        </div>
                      </div> -->
                      <!-- <div class="col-sm-3">
                        <button class='btn-file margin-top25'>Add</button>
                      </div> -->
                    <!-- </div> -->
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class='col-sm-9 padding-right-0'>
            <div class='row buttons-div clear text-right'>
              <!-- <button class='btn btn-default btn-cancel' (click)="cancel()">Cancel</button> -->
              <div class="col-sm-6">
                <div class="error col-xs-12 error-align" *ngIf="errorDetails">{{errorDetails}}</div>
                <div class="success col-xs-12 success-align" *ngIf="successMsg">{{successMsg}}</div>
              </div>
              <div class="col-sm-6">
                <button class='btn btn-default btn-save' [ngClass]="(!newMemberEn.valid || !pastVisitEn.valid || !upcomingVisitEn.valid) ? 'disabled' : ''"
                  (click)="saveForm()">
                  <img src='assets/images/save.svg' class='pull-left' />
                  <span class='pull-left'>Save</span>
                </button>
              </div>
            </div>
            <!-- <div class="error col-xs-12" *ngIf="errorDetails">{{errorDetails}}</div>
            <div class="success col-xs-12" *ngIf="successMsg">{{successMsg}}</div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- English -->

    <!-- Spanish -->
    <div id="nSp" class="tab-pane fade">
      <div class="centered-content">
        <div>
          <div class="col-sm-9 tabPaneBorder">
            <div class='editContainer col-xs-12'>
              <div class='row'>
                <div class='col-xs-12'>
                  <p class='title'>
                    Nuevo miembro
                  </p>
                  <form novalidate [formGroup]="newMemberEs" class='member-form'>
                    <div class='row'>
                      <div class='form-group col-sm-9'>
                        <label class='labelStyle'>
                          Título
                        </label>
                        <textarea class='form-control inputTextStyle' rows='2' formControlName="heading" (focus)="errorDetails = ''"></textarea>
                      </div>
                    </div>
                    <div class='row'>
                      <div class='form-group col-sm-9'>
                        <label class='labelStyle'>
                          Subtítulo
                        </label>
                        <textarea class='form-control inputTextStyle' rows='2' formControlName="subHeading" (focus)="errorDetails = ''"></textarea>
                      </div>
                    </div>
                    <!-- <div formArrayName="tilesTo">
                      <div class='repeat-fields' *ngFor="let tile of newMemberEs.controls['tilesTo']['controls']; let i=index">
                        <div formGroupName="{{i}}">
                          <div class='form-group row upload-image-container'>
                            <div class='xform-group col-sm-6 padding-top-7'>
                              <label class='labelStyle'>
                                Imagen
                              </label>
                              <input class='form-control tile' type='file' [attr.id]="'newMemberEs-'+i" #uploadTileImgEs (change)="fileChanged($event, 'newMemberEs', i, 'uploadTileImgEs', newMemberEs.get('tilesTo').value.length)"
                                (focus)="errorDetails = ''" />
                              <button (click)="uploadTileImgEs.click()" class='btn-file'>
                                Subir foto
                              </button>
                              <p *ngIf="tile.get('imageError').value" class="error upload">Subir solo imagen.</p>
                            </div>
                            <div class='col-sm-3' *ngIf="tile.get('image').value && !tile.get('imageError').value">
                              <img class="image" [src]="tile.get('imageUrl').value ? tile.get('imageUrl').value : getImgStyle(tile.get('id').value, tile.get('image').value)"
                              />
                              <button class='btn btn-default delete-image'>
                                <img src='assets/images/delete.svg' (click)="deleteImage('newMemberEs', i,'spanish')" />
                              </button>
                            </div>
                            <div class='col-sm-3 text-right pull-right' *ngIf="newMemberEs.get('tilesTo').value.length > 1">
                              <button class='btn btn-default remove-btn' (click)="remove('newMemberEs', 'tilesTo', i,'spanish')">
                                <img class="pull-left" src="assets/images/minus.svg">
                                <span>retirar</span>
                              </button>
                            </div>
                          </div>
                          <div class='row'>
                            <div class='form-group col-sm-9'>
                              <label class='labelStyle'>
                                Título
                              </label>
                              <textarea class='form-control inputTextStyle' rows='2' formControlName='tilesHeading' (focus)="errorDetails = ''"></textarea>
                            </div>
                          </div>
                          <div class="row">
                            <div class='form-group col-sm-9'>
                              <label class='labelStyle'>
                                Monedas
                              </label>
                              <input type='number' class='form-control inputTextStyle buttonText' onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                formControlName='earnPoints' (focus)="errorDetails = ''" />
                            </div>
                          </div>
                          <div class="row">
                            <div class='form-group col-sm-9'>
                              <label class='labelStyle'>
                                URL de acción
                              </label>
                              <input type='text' class='form-control inputTextStyle' formControlName='actionUrl' [ngClass]="tile.get('actionUrl').hasError('pattern') ? 'err' : ''"
                                (focus)="errorDetails = ''" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> -->
                    <!-- <button class='btn-file' (click)="addTiles(null, 'newMemberEs')" [ngClass]="newMemberEs.get('tilesTo').value.length == 4 ? 'disabled' : '' ">Añadir</button> -->
                  </form>
                </div>
              </div>
              <div class='row'>
                <div class='col-xs-12'>
                  <p class='title'>
                    Visita pasada
                  </p>
                  <form class="past-form" [formGroup]="pastVisitEs">
                    <div class='row'>
                      <div class='form-group col-sm-9'>
                        <label class='labelStyle'>
                          Título
                        </label>
                        <textarea class='form-control inputTextStyle' rows='2' formControlName="heading" (focus)="errorDetails = ''"></textarea>
                      </div>
                    </div>
                    <div class='row'>
                      <div class='form-group col-sm-9'>
                        <label class='labelStyle'>
                          Subtítulo
                        </label>
                        <textarea class='form-control inputTextStyle' rows='2' formControlName="subHeading" (focus)="errorDetails = ''"></textarea>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div class='row'>
                <div class='col-xs-12'>
                  <p class='title'>
                    Próxima visita
                  </p>
                  <form [formGroup]="upcomingVisitEs">
                    <div class='row'>
                      <div class='form-group col-sm-9'>
                        <label class='labelStyle'>
                          Título
                        </label>
                        <textarea class='form-control inputTextStyle' rows='2' formControlName="heading" (focus)="errorDetails = ''"></textarea>
                      </div>
                    </div>
                    <div class="row">
                      <div class='form-group col-sm-9'>
                        <label class='labelStyle'>
                          Subtítulo
                        </label>
                        <textarea class='form-control inputTextStyle' rows='2' formControlName="subHeading" (focus)="errorDetails = ''"></textarea>
                      </div>
                    </div>
                    <!-- <div class="row">
                      <div class='form-group col-sm-9'>
                        <label class='labelStyle'>
                          Mensaje
                        </label>
                        <textarea class='form-control inputTextStyle' rows='2' formControlName="message" (focus)="errorDetails = ''"></textarea>
                      </div>
                    </div> -->
                    <div class="row">
                      <div class='form-group col-sm-9'>
                        <label class='labelStyle'>
                          Botón de texto
                        </label>
                        <input type='text' class='form-control inputTextStyle buttonText' placeholder="View Booking" formControlName="buttonText"
                          (focus)="errorDetails = ''" />
                      </div>
                    </div>
                    <div class="row">
                      <div class='form-group col-sm-9'>
                        <label class='labelStyle'>
                          Acción del botón
                        </label>
                        <label class="radio">
                          <input id="upcomingBanner1" type="radio" value="Book Now" formControlName="buttonActionType" (focus)="errorDetails = ''">
                          <span class="outer"></span>
                          <span class='radio-text'>
                            Reservar ahora
                          </span>
                        </label>
                        <label class="radio">
                          <input id="upcomingBanner2" type="radio" value="External URL" formControlName="buttonActionType" (focus)="errorDetails = ''">
                          <span class="outer"></span>
                          <span class='radio-text'>
                            URL externa
                          </span>
                        </label>
                      </div>
                    </div>
                    <div class="row">
                      <div class='form-group col-sm-9' *ngIf="upcomingVisitEs.get('buttonActionType').value == 'External URL'">
                        <label class='labelStyle'>
                          <!-- {{'LANDING-PAGE.EXTERNAL-URL' | translate}} -->
                          URL externa
                        </label>
                        <input type="text" class='form-control inputTextStyle l-page' placeholder="URL externa" formControlName="externalUrl" (focus)="errorDetails = ''"
                          [ngClass]="upcomingVisitEs.get('externalUrl').hasError('pattern') || upcomingVisitEs.get('externalUrl').hasError('required') ? 'err' : ''">
                      </div>
                    </div>
                    <!-- <div class="row">
                      <div class='form-group col-sm-9'>
                        <p>Mensajes Perk
                        </p> -->
                        <!-- <label class='labelStyle'>Perk Título</label>
                        <textarea class='form-control inputTextStyle' rows='2' formControlName="perkHeading" (focus)="errorDetails = ''"></textarea>
                        <label class='labelStyle'>Mensaje de Perk</label>
                        <textarea class='form-control inputTextStyle' rows='2' formControlName="perkMessage" (focus)="errorDetails = ''"></textarea> -->
                        <!-- <div formArrayName="perkDescription">
                          <div *ngFor="let perk of upcomingVisitEs.controls['perkDescription']['controls']; let i=index">
                            <div>
                              <label class='labelStyle'>
                                Descripción de Perk
                              </label>
                              <textarea class='form-control inputTextStyle' rows='1' formControlName="{{i}}" (focus)="errorDetails = ''"></textarea>
                            </div>
                          </div>
                        </div>
                      </div> -->
                      <!-- <div class="col-sm-3">
                            <button class='btn-file margin-top25'>Add</button>
                          </div> -->
                    <!-- </div> -->
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class='col-sm-9 padding-right-0'>
            <div class='row buttons-div clear text-right'>
              <!-- <button class='btn btn-default btn-cancel' (click)="cancel()">Cancelar</button> -->
              <div class="col-sm-6">
                <div class="error col-xs-12 error-align" *ngIf="errorDetails">{{errorDetails}}</div>
                <div class="success col-xs-12 success-align" *ngIf="successMsg">{{successMsg}}</div>
              </div>
              <div class="col-sm-6">
                <button class='btn btn-default btn-save' [ngClass]="(!newMemberEs.valid || !pastVisitEs.valid || !upcomingVisitEs.valid) ? 'disabled' : ''"
                  (click)="saveForm()">
                  <img src='assets/images/save.svg' class='pull-left' />
                  <span class='pull-left'>Salvar</span>
                </button>
              </div>
            </div>
            <!-- <div class="error col-xs-12" *ngIf="errorDetails">{{errorDetails}}</div>
            <div class="success col-xs-12" *ngIf="successMsg">{{successMsg}}</div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- Spanish -->



  </div>
</div>