<div class='col-sm-9 col-sm-offset-3 employee-container'>
  <div class="row">
    <h3 class="col-sm-4 heading padding-0">{{!employeeId ? 'New' : 'Modify'}} Employee <img  *ngIf="employeeDetails?.isMember && employeeDetails?.isEmployee" class="external-link pointer" height="20" src="assets/images/External_link_awesome.png" [routerLink]="['/members/modify', employeeDetails?.loyaltyMemberId, 'booking']" title="Go to Member"/></h3>
    <div class="col-sm-8 buttons-container" *ngIf="employeeId">
      <button class="btn btn-activate col-sm-3 pull-left" *ngIf="employeeDetails?.statusId?.statusId == 0" (click)="openStatusModal([employeeDetails.loyaltyMemberId], !employeeDetails?.statusId?.statusId)">Activate</button>
      <button class="btn btn-deactivate col-sm-3 pull-left" *ngIf="employeeDetails?.statusId?.statusId == 1" (click)="openStatusModal([employeeDetails.loyaltyMemberId], !employeeDetails?.statusId?.statusId)">Deactivate</button>
      <button class="btn btn-record col-sm-3 pull-right" [disabled]="!employeeForm.valid" (click)="addEmployee(false)">
        <img src='assets/images/save.svg' class='pull-left' />Save Changes</button>
      <button class="btn btn-cancel col-sm-2 pull-right" (click)="confirmClose()">Cancel</button>
    </div>
  </div>
  <div class="row">
    <form [formGroup]="employeeForm" class="employee-form">
      <!-- Add Employee -->
      <ng-container *ngIf="!employeeId">
        <div class="row">
          <div class="col-sm-4 form-group">
            <label>Title</label>
            <select class="form-control" formControlName="genderSuffix" [ngClass]="employeeForm.get('genderSuffix').hasError('required') && employeeForm.get('genderSuffix').touched ? 'err' : ''">
              <option value="" selected>Select Title</option>
              <option *ngFor="let gender of genderSuffixes" [value]="gender.id">{{gender.suffix}}</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4 form-group">
            <label>First Name*</label>
            <input type="text" class="form-control" placeholder="First Name" formControlName="firstName" [ngClass]="employeeForm.get('firstName').hasError('maxlength') || (employeeForm.get('firstName').hasError('whitespace') || employeeForm.get('firstName').hasError('required')) && employeeForm.get('firstName').touched ? 'err' : ''"
            />
          </div>
          <div class="col-sm-4 form-group">
            <label>Last Name*</label>
            <input type="text" class="form-control" placeholder="Last Name" formControlName="lastName" [ngClass]="employeeForm.get('lastName').hasError('maxlength') || (employeeForm.get('lastName').hasError('whitespace') || employeeForm.get('lastName').hasError('required')) && employeeForm.get('lastName').touched ? 'err' : ''"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4 form-group">
            <label>Email*</label>
            <input type="text" class="form-control" placeholder="Email" formControlName="email" [ngClass]="employeeForm.get('email').hasError('pattern') || (employeeForm.get('email').hasError('whitespace') || employeeForm.get('email').hasError('required')) && employeeForm.get('email').touched ? 'err' : ''"
            />
          </div>
          <div class="col-sm-4 form-group">
            <label>Code*</label>
            <input type="text" class="form-control" placeholder="Code" formControlName="empCode" [ngClass]="employeeForm.get('empCode').hasError('maxlength') || (employeeForm.get('empCode').hasError('whitespace') || employeeForm.get('empCode').hasError('required')) && employeeForm.get('empCode').touched ? 'err' : ''"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4 form-group custom-dropdown">
            <label>Hotel</label>
            <div class="btn-group col-xs-12 padding-0 select-icon" dropdown #dropdown="bs-dropdown" [autoClose]="true">
              <button dropdownToggle type="button" [title]="employeeForm.get('hotelName').value" class="btn dropdown-toggle form-control">
                {{employeeForm.get('hotelName').value ? employeeForm.get('hotelName').value : 'Select Hotel'}}
              </button>
              <ul id="dropdown-triggers-manual" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-triggers-manual">
                <li role="menuitem" class="hand" (click)="selectedHotel(null)">
                  <a class="dropdown-item" [class.active]="!employeeForm.get('hotel').value">Select Hotel</a>
                </li>
                <li *ngFor="let hotel of hotels" role="menuitem" class="hand" (click)="selectedHotel(hotel)">
                  <a class="dropdown-item" [class.active]="employeeForm.get('hotel').value == hotel.hotelId">{{hotel.hotelName}}</a>
                </li>
              </ul>
            </div>
            <a class="add-hotel-btn" (click)="goToHotel()">
              <img src='assets/images/plus.svg' class='pull-left' />
            </a>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4 form-group">
            <label>Department</label>
            <input type="text" class="form-control" placeholder="Department" formControlName="department" [ngClass]="employeeForm.get('department').hasError('maxlength') || (employeeForm.get('department').hasError('whitespace')) && employeeForm.get('department').touched ? 'err' : ''"
            />
          </div>
          <div class="col-sm-4 form-group">
            <label>Position</label>
            <input type="text" class="form-control" placeholder="Position" formControlName="position" [ngClass]="employeeForm.get('position').hasError('maxlength') || (employeeForm.get('position').hasError('whitespace')) && employeeForm.get('position').touched ? 'err' : ''"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4 form-group">
            <label>Area</label>
            <input type="text" class="form-control" placeholder="Area" formControlName="area" [ngClass]="employeeForm.get('area').hasError('maxlength') || (employeeForm.get('area').hasError('whitespace')) && employeeForm.get('area').touched ? 'err' : ''"
            />
          </div>
        </div>
      </ng-container>
      <!-- Add Employee -->

      <!-- Edit Employee -->
      <ng-container *ngIf="employeeId">
        <div class="row">
          <div class="col-sm-3 form-group">
            <label>Title</label>
            <select class="form-control" formControlName="genderSuffix" [ngClass]="employeeForm.get('genderSuffix').hasError('required') && employeeForm.get('genderSuffix').touched ? 'err' : ''">
              <option value=null selected>Select Title</option>
              <option *ngFor="let gender of genderSuffixes" [value]="gender.id">{{gender.suffix}}</option>
            </select>
          </div>
          <div class="col-sm-3 form-group">
            <label>First Name*</label>
            <input type="text" class="form-control" placeholder="First Name" formControlName="firstName" [ngClass]="employeeForm.get('firstName').hasError('maxlength') || (employeeForm.get('firstName').hasError('whitespace') || employeeForm.get('firstName').hasError('required')) && employeeForm.get('firstName').touched ? 'err' : ''"
            />
          </div>
          <div class="col-sm-3 form-group">
            <label>Last Name*</label>
            <input type="text" class="form-control" placeholder="Last Name" formControlName="lastName" [ngClass]="employeeForm.get('lastName').hasError('maxlength') || (employeeForm.get('lastName').hasError('whitespace') || employeeForm.get('lastName').hasError('required')) && employeeForm.get('lastName').touched ? 'err' : ''"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-sm-3 form-group">
            <label>Email*</label>
            <input type="text" class="form-control" placeholder="Email" formControlName="email" [ngClass]="employeeForm.get('email').hasError('pattern') || (employeeForm.get('email').hasError('whitespace') || employeeForm.get('email').hasError('required')) && employeeForm.get('email').touched ? 'err' : ''" 
            />
          </div>
          <div class="col-sm-3 form-group">
            <label>Code*</label>
            <input type="text" class="form-control" placeholder="Code" formControlName="empCode" [ngClass]="employeeForm.get('empCode').hasError('maxlength') || (employeeForm.get('empCode').hasError('whitespace') || employeeForm.get('empCode').hasError('required')) && employeeForm.get('empCode').touched ? 'err' : ''"
            />
          </div>
          <div class="col-sm-3 form-group custom-dropdown">
            <label>Hotel</label>
            <div class="btn-group col-xs-12 padding-0 select-icon" dropdown #dropdown="bs-dropdown" [autoClose]="true">
              <button dropdownToggle type="button" [title]="employeeForm.get('hotelName').value" class="btn dropdown-toggle form-control">
                {{employeeForm.get('hotelName').value ? employeeForm.get('hotelName').value : 'Select Hotel'}}
              </button>
              <ul id="dropdown-triggers-manual" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-triggers-manual">
                <li role="menuitem" class="hand" (click)="selectedHotel(null)">
                  <a class="dropdown-item" [class.active]="!employeeForm.get('hotel').value">Select Hotel</a>
                </li>
                <li *ngFor="let hotel of hotels" role="menuitem" class="hand" (click)="selectedHotel(hotel)">
                  <a class="dropdown-item" [class.active]="employeeForm.get('hotel').value == hotel.hotelId">{{hotel.hotelName}}</a>
                </li>
              </ul>
            </div>
            <a class="add-hotel-btn" (click)="goToHotel()">
              <img src='assets/images/plus.svg' class='pull-left' />
            </a>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-3 form-group">
            <label>Department</label>
            <input type="text" class="form-control" placeholder="Department" formControlName="department" [ngClass]="employeeForm.get('department').hasError('maxlength') || (employeeForm.get('department').hasError('whitespace')) && employeeForm.get('department').touched ? 'err' : ''"
            />
          </div>
          <div class="col-sm-3 form-group">
            <label>Position</label>
            <input type="text" class="form-control" placeholder="Position" formControlName="position" [ngClass]="employeeForm.get('position').hasError('maxlength') || (employeeForm.get('position').hasError('whitespace')) && employeeForm.get('position').touched ? 'err' : ''"
            />
          </div>
          <div class="col-sm-3 form-group">
            <label>Area</label>
            <input type="text" class="form-control" placeholder="Area" formControlName="area" [ngClass]="employeeForm.get('area').hasError('maxlength') || (employeeForm.get('area').hasError('whitespace')) && employeeForm.get('area').touched ? 'err' : ''"
            />
          </div>
        </div>
      </ng-container>
      <!-- Edit Employee -->
      
      <div class="row buttons-container" *ngIf="!employeeId">
        <div class="col-sm-8">
          <button class="btn btn-cancel col-sm-2 pull-left" (click)="confirmClose()">Cancel</button>
          <button class="btn btn-record col-sm-2 pull-right" [disabled]="!employeeForm.valid" (click)="addEmployee(false)">Record</button>
          <button class="btn btn-add col-sm-5 pull-right" [disabled]="!employeeForm.valid" (click)="addEmployee(true)">Record and Add Another</button>
        </div>
      </div>
      <p class="col-sm-12 error text-center" *ngIf="errorMsg">{{errorMsg}}</p>
      <p class="col-sm-12 success text-center" *ngIf="successMsg">{{successMsg}}</p>
    </form>
  </div>

  <div class="row member-points" *ngIf="employeeId">
    <div class="col-sm-3 form-group">
      <button type="button" class="btn btn-success" disabled>
        Members with reservation
        <span class="badge badge-light">{{employeeDetails?.noOfPersonsWithReservations}}</span>
        <span class="sr-only">Members with reservation</span>
      </button>
    </div>
    <div class="col-sm-3 form-group">
      <button type="button" class="btn btn-warning" disabled>
        Active members
        <span class="badge badge-light">{{employeeDetails?.noOfActiveMembers}}</span>
        <span class="sr-only">Active members</span>
      </button>
    </div>
    <div class="col-sm-3 form-group">
      <button type="button" class="btn btn-danger" disabled>
        Inactive members
        <span class="badge badge-light">{{employeeDetails?.noOfInActiveMembers}}</span>
        <span class="sr-only">Inactive members</span>
      </button>
    </div>
    <div class="col-sm-3 form-group">
      <button type="button" class="btn btn-primary" disabled>
        Available points
        <span class="badge badge-light">{{employeeDetails?.memberPoints?.totalPoints ? employeeDetails?.memberPoints?.totalPoints : '0'}}</span>
        <span class="sr-only">Available points</span>
      </button>
    </div>
  </div>
  <div class="members-tabs" *ngIf="employeeId">
    <ul class="nav nav-tabs">
      <li class="col-sm-3 text-center">
        <a [routerLink]="['guest-booking']" routerLinkActive="active">Guest Bookings</a>
      </li>
      <li class="col-sm-3  text-center">
        <a [routerLink]="['transaction-history']" routerLinkActive="active">Transaction History</a>
      </li>
      <!-- <li class="col-sm-3  text-center">
        <a [routerLink]="['exchange-benefits']" routerLinkActive="active">Exchange of Benefits</a>
      </li> -->
      <li class="col-sm-3  text-center">
        <a [routerLink]="['comments']" routerLinkActive="active">Comments</a>
      </li>
    </ul>
    <div class="tab-content">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
