<div class="col-sm-9 tabPaneBorder borderTop">
  <div class='col-sm-9'>
    <form [formGroup]="bookingWidget">
      <div class='row margin-top-35'>
        <div class='form-group'>
          <label class='labelStyle'>Show booking Widget</label>
          <label class="radio">
            <input id="radio1" type="radio" value= 'true' formControlName="showBookingWidget">
            <span class="outer"></span>
            <span class='radio-text'>Yes</span>
          </label>
          <label class="radio">
            <input id="radio1" type="radio" value= 'false' formControlName="showBookingWidget">
            <span class="outer"></span>
            <span class='radio-text'>No</span>
          </label>
        </div>
      </div>
    </form>
  </div>
</div>
<div class='col-sm-9 padding-right-0'>
  <div class='buttons-div clear text-right'>
    <button class='btn btn-default btn-cancel' (click)="cancel()">{{'BUTTONS.CANCEL' | translate}}</button>
    <button class='btn btn-default btn-save' (click)="saveForm()">
      <img src='assets/images/save.svg' class='pull-left' />
      <span class='pull-left'>{{'BUTTONS.SAVE' | translate}}</span>
    </button>
  </div>
</div>

