<div class="shopping-cart-container">
  <div class="table-responsive">
    <h3 *ngIf="!shoppingCartList?.length" class="text-center">No Shopping Cart History found</h3>
    <table class="table transaction-table" *ngIf="shoppingCartList?.length">
      <thead>
        <tr>
          <th>Created On</th>
          <th>Updated On</th>
          <th># of Products</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let cart of shoppingCartList | paginate: { itemsPerPage: size, currentPage: p, totalItems: totalCount }">
          <td>{{cart?.createdDateStr}}</td>
          <td>{{cart?.updatedDateStr}}</td>
          <td>{{cart?.productCount}}</td>
          <td class="dropdown">
            <span [ngClass]="cart?.statusId == 1 ? 'pending' : 'closed'">{{cart?.status}}</span>
            <button class="btn btn-link dropdown-toggle pull-right" data-toggle='dropdown' [disabled]="cart?.statusId == 0">
              <img src='assets/images/overflow.svg' />
            </button>
            <ul class="dropdown-menu custom-dropdown-menu pointer">
              <li *ngIf="cart?.statusId == 0" (click)="openStatusModal(cart?.shoppingCartId, !cart?.statusId)">
                <a class="pending">Pending</a>
              </li>
              <li  *ngIf="cart?.statusId == 1" (click)="openStatusModal(cart?.shoppingCartId, !cart?.statusId)">
                <a class="closed">Closed</a>
              </li>
            </ul>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td class="tfooter" colspan="8"> <pagination-controls class="pull-right"  (pageChange)="getShoppingCartDetails($event)"></pagination-controls></td>
        </tr>
      </tfoot>
    </table>
  </div>
</div>