<div class="import-modal">
  <div class="modal-header">
    <h3 class="modal-title">Import Sandistas</h3>
  </div>
  <div class="modal-body">
    <div class="alert alert-warning">
        Alert: The format should be in the following order:<br>
        tratamiento*, num*, nombre*, apellido1*, apellido2, email*, hotel, departamento, zona, posición
    </div>
    <form [formGroup]="importForm">
    <div class="form-group">
        <div class="">
            <label>Archive:*</label>
            <div class="input-group">
                <label class="input-group-btn">
                    <span class="btn btn-primary">
                        Browse… <input type="file" #uploadFile style="display: none;" (change)="onFileChange($event)" >
                    </span>
                </label>
                <input type="text" class="form-control" readonly="" formControlName="fileName" [ngClass]="importForm.get('fileData').hasError('required') && importForm.get('fileName').touched ? 'err' : ''">
            </div>
            <p class="error text-center" *ngIf="fileError || fileTypeError">{{fileError || fileTypeError}}</p>
        </div>
    </div>
    <div class="form-group">
        <div class="">
            <label>Action:*</label>
            <select class="form-control" formControlName="action" [ngClass]="importForm.get('action').hasError('required') && importForm.get('action').touched ? 'err' : ''">
              <option value="" selected disabled>Select Action</option>
              <option [value]="'add'">Add</option>
              <option [value]="'remove'">Remove</option>
            </select>
        </div>
    </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" data-dismiss="modal" (click)="confirmModalWindow()">Cancel</button>
    <button type="button" (click)="importEmployees()" class="btn btn-default" [disabled]="!importForm.valid">Done</button>
  </div>
  <div class="success" *ngIf="successMsg">{{successMsg}}</div>
  <div class="error" *ngIf="errorMsg">{{errorMsg}}</div>
</div>
