<div class="modal-header" *ngIf="discountPoints">
  <h3 class="modal-title" id="exampleModalLabel">Discount Points</h3>
</div>
<div class="modal-body" *ngIf="discountPoints">
  <div class="table-responsive panel panel-default" *ngIf="discountPoints && discountPoints.length">
    <table class="table table-bordered">
      <thead>
        <tr>
          <th>Locator</th>
          <th>Discount {{discountPercentage ? discountPercentage : '0'}}%</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let points of discountPoints">
          <td>{{points.locator ? points.locator : 'N/A'}}</td>
          <td>{{points.discountPercentValue ? points.discountPercentValue : '0'}}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="alert alert-danger" *ngIf="discountPoints && !discountPoints.length">
      The calculation for the selected booking(s) could not be made.
  </div>
</div>
<div class="modal-footer text-center" *ngIf="discountPoints">
  <button type="button" class="btn btn-default" (click)="confirmClose()" data-dismiss="modal">Cancel</button>
</div>