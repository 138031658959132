<div class='col-sm-9 col-sm-offset-3 marketing-container'>
  <h3 *ngIf="isNewReward">New Reward</h3>
  <h3 *ngIf="!isNewReward">Modify Reward</h3>

  <p class="notice">Notice: The fields with <span class="required">*</span> are required</p>

  <form novalidate [formGroup]="reward">

    <!-- General block -->
    <div class="panel panel-default">
      <div class="panel-heading"><strong>Product</strong></div>
      <div class="panel-body">
        <div class="row">
          <div class="col-sm-4">
            <div class="form-group">
              <p class="margin-0">Name (In English) <span class="required">*</span></p>
              <input type="text" class="form-control" formControlName="productNameEN"
                [ngClass]="addErrClassIfHasError(reward.get('productNameEN'), ['whitespace', 'required', 'maxlength'])" />
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-group">
              <p class="margin-0">Name (In Spanish) <span class="required">*</span></p>
              <input type="text" class="form-control" formControlName="productNameES"
                [ngClass]="addErrClassIfHasError(reward.get('productNameES'), ['whitespace', 'required', 'maxlength'])" />
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-group formDiv">
              <p class="margin-0">Tags</p>
              <input [(ngModel)]="tagInputText" [ngModelOptions]="{standalone: true}" style="display: none" />
              <tag-input formControlName='tagIds' [(inputText)]="tagInputText" [(ngModel)]="items"
                [onTextChangeDebounce]="400" [theme]="'minimal'" [onlyFromAutocomplete]="true"
                [identifyBy]="'hotelTagId'" [displayBy]="'name'" [placeholder]="''"
                (keydown)="searchTerm$.next($event.target.value)">
                <tag-input-dropdown [showDropdownIfEmpty]="false" [focusFirstElement]="true" [displayBy]="'name'"
                  [identifyBy]="'hotelTagId'" [autocompleteObservable]="hotelTags">
                  <ng-template let-item="item" let-index="index">
                    {{ item.name }}
                  </ng-template>
                </tag-input-dropdown>
              </tag-input>
            </div>
          </div>
          <div class="col-sm-4">
            <p class="margin-0">Description (In English)</p>
            <textarea class="form-control" rows="5" formControlName="productDescriptionEN"></textarea>
          </div>
          <div class="col-sm-4">
            <p class="margin-0">Description (In Spanish)</p>
            <textarea class="form-control" rows="5" formControlName="productDescriptionES"></textarea>
          </div>
          <div class="col-sm-4">
            <div class="formDiv">
              <p class="margin-0">Image <span class="required">*</span></p>
              <span *ngIf="!imageFile.imageUrl" class='file-text image-name'>(no file chosen)</span>
              <span *ngIf="imageFile && imageFile.name && imageFile.imageUrl" class="file-text image-name">
                {{ imageFile.name }}
                <br/><img [src]="imageFile.imageUrl" height="80"/>
              </span>
              <input class='form-control' type='file' #uploadFile (change)="uploadImage($event, 'en')" />
              <button *ngIf="!imageFile.imageUrl" (click)="uploadFile.click()" class='btn-add btn-file'>
                Choose File
              </button>
              <button *ngIf="imageFile.imageUrl" (click)="uploadFile.click()" class='btn-add btn-file'>
                Change File
              </button>
              <p *ngIf="imageFile.errorUpload" class="error upload col-xs-12" (click)="imageFile.errorUpload = false">
                Please upload images only.
              </p>
              <p *ngIf="imageFile.fileSizeError" class="error upload" (click)="imageFile.fileSizeError = false">
                Image size should be less than 1MB.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- General block -->

    <!-- Product and Reward -->
    <div class="panel panel-default">
      <div class="panel-heading"><strong>Type & behavior</strong></div>
      <div class="panel-body">
        <div class="row">
          <div class="col-sm-5">
            <div class="form-group formDiv" formGroupName="productTypesTo">
              <p class="margin-0">Product Type <span class="required">*</span></p>
              <label class="radio" *ngFor="let productType of productTypes">
                <input id="radio1" type="radio" [value]="productType.productId" formControlName="productId"
                  (click)="selectProductType(productType)"
                  [ngClass]="addErrClassIfHasError(reward.get('productTypesTo').get('productType'), ['required'])" />
                <span class="outer"></span>
                <span class='radio-text'>{{ productType.productType }}</span>
              </label>
            </div>
          </div>
          <div class="col-sm-2">
            <div class='formDiv' *ngIf="reward.get('productTypesTo').get('productId').value == 1">
              <div class="form-group">
                <p>Discount % <span class="required">*</span></p>
                <input type="number" class="form-control margin-0" formControlName="percentOff"
                  [ngClass]="addErrClassIfHasError(reward.get('percentOff'), ['required', 'max', 'min', 'pattern'])" />
              </div>
            </div>
            <div class='formDiv' *ngIf="reward.get('productTypesTo').get('productId').value != 1">
              <p>Rule</p>
              <div class='form-group checkbox-list2'>
                <input type="checkbox" name="booking" id="inStayField" value="In Stay" formControlName="inStay" />
                <label for='inStayField'>
                  <span class='check-box'></span>
                  <span class="text">In Stay</span>
                </label>
              </div>
            </div>
          </div>
          <div class="col-sm-5">
            <div class="form-group formDiv">
              <p class="margin-0">Reward Type</p>
              <label class="radio radio-reward">
                <input id="radio1" type="radio" value="Automatic" formControlName="rewardType">
                <span class="outer"></span>
                <span class='radio-text'>Automatic</span>
              </label>
              <label class="radio radio-reward">
                <input id="radio2" type="radio" value="NotAutomatic" formControlName="rewardType">
                <span class="outer"></span>
                <span class='radio-text'>Not Automatic</span>
              </label>
            </div>
          </div>
          <div class="col-sm-6" *ngIf="reward.get('productTypesTo').get('productId').value != 1">
            <div class="formDiv perNightPerson">
              <p>Application</p>
              <div class="form-group checkbox-list2">
                <input type="checkbox" name="booking" id="l1" value="Per Night" formControlName="perNight" />
                <label for="l1">
                  <span class="check-box"></span>
                  <span class="text">Per Night</span>
                </label>
              </div>
              <div class="form-group checkbox-list2">
                <input type="checkbox" name="booking" id="l2" value="Per Person" formControlName="perPerson" />
                <label for="l2">
                  <span class="check-box"></span>
                  <span class="text">Per Person</span>
                </label>
              </div>
            </div>
          </div>
          <div [ngClass]="reward.get('productTypesTo').get('productId').value == 1 ? 'col-sm-12': 'col-sm-6'">
            <div class="form-group select-icon">
              <p>Priority</p>
              <select class="form-control" formControlName="rewardPriority" aria-placeholder="Select"
                [ngClass]="addErrClassIfHasError(reward.get('rewardPriority'), ['required'])">
                <option value="" disabled>Select Priority</option>
                <option value="Low">Low</option>
                <option value="Medium">Medium</option>
                <option value="High">High</option>
              </select>
            </div>
          </div>
          <div [ngClass]="reward.get('productTypesTo').get('productId').value == 1 ? 'col-sm-12': 'col-sm-6'">
            <div class="form-group">
              <p>URL</p>
              <input type="text" class="form-control" formControlName="url"
                     [ngClass]="addErrClassIfHasError(reward.get('url'), ['whitespace'])" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Product and Reward -->

    <!-- Catalog -->
    <div class="panel panel-default">
      <div class="panel-heading"><strong>Catalog</strong></div>
      <div class="panel-body" formArrayName="catalogValuesTo">
        <p>
          Select one or multiple catalogs. The description is not required. If not filled, the general description
          is taken, otherwise the catalog's description will be showed.
        </p>

        <div class="alert alert-danger" *ngIf="duplicatedCatalogs">
          <strong>Attention:</strong> Duplicated catalogs with the same membership level is not allowed.
        </div>

        <div class="xtable-responsive form-group formDiv">
          <table class="table table-condensed">
            <thead>
              <tr>
                <th>Catalog <span class="required">*</span></th>
                <th>Points to redeem <span class="required">*</span></th>
                <th>Membership Level <span class="required">*</span></th>
                <th>Description (English)</th>
                <th>Description (Spanish)</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let a of reward.controls.catalogValuesTo.controls; let i=index">
                <td>
                  <div class="form-group dropdown select-icon currency" [formGroupName]="i">
                    <div formGroupName="catalog">
                      <input
                        [ngClass]="(reward.get('catalogValuesTo').at(i).get('catalog').get('catalogId').value == '' || reward.get('catalogValuesTo').at(i).get('catalog').get('catalogId').value == null) && reward.get('catalogValuesTo').at(i).get('catalog').get('catalogName').touched?'err':''"
                        class='form-control dropdown-toggle select' data-toggle='dropdown' formControlName="catalogName"
                        readonly placeholder="Select Catalog">
                      <ul class='dropdown-menu nav-list'>
                        <li *ngFor="let c of catalogs" class="pointer" [value]="c.catalogId"
                          (click)="selectCatalog(c,i)">
                          <a>
                            <img class='pull-right' src='assets/images/success.svg'
                              *ngIf="reward.controls.catalogValuesTo.at(i).controls.catalog.controls.catalogId.value == c.catalogId" />
                            <span>{{c.catalogName}}</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </td>
                <td [formGroupName]="i" width="100">
                  <input *ngIf="reward.get('productTypesTo').get('productId').value !== 1" class='form-control'
                    type='number' formControlName="points"
                    onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.key === "Backspace"'
                    [ngClass]="addErrClassIfHasError(reward.get('catalogValuesTo').at(i).get('points'), ['max', 'required', 'pattern', 'min'])" />
                  <p *ngIf="reward.get('productTypesTo').get('productId').value === 1">
                    Product discount type cannot have points to redeem
                  </p>
                </td>
                <td width="150">
                  <div class="form-group dropdown select-icon currency" [formGroupName]="i">
                    <div formGroupName="membershipLevel">
                      <input
                        [ngClass]="addErrClassIfHasError(reward.get('catalogValuesTo').at(i).get('membershipLevel'), ['required'])"
                        class='form-control dropdown-toggle select' data-toggle='dropdown'
                        formControlName="memberShipName" readonly placeholder="Select loyaltyLevels">
                      <ul class='dropdown-menu nav-list'>
                        <li *ngFor="let l of membershipLevel" class="pointer" [value]="l.loyaltyId"
                          (click)="selectLoyaltyLevel(l,i)">
                          <a>
                            <img class='pull-right' src='assets/images/success.svg'
                              *ngIf="reward.controls.catalogValuesTo.at(i).controls.membershipLevel.controls.loyaltyId.value == l.loyaltyId" />
                            <span>{{l.memberShipName}}</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="form-group" [formGroupName]="i">
                    <textarea class="form-control margin-0" rows="2" formControlName="descriptionEN"></textarea>
                  </div>
                </td>
                <td>
                  <div class="form-group" [formGroupName]="i">
                    <textarea class="form-control margin-0" rows="2" formControlName="descriptionES"></textarea>
                  </div>
                </td>
                <td>
                  <button class="btn btn-default" (click)="removeCatalog(i)">
                    <img src="assets/images/delete.svg" />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <button class="btn btn-add form-control" (click)="addCatalog()">Add Catalog</button>
        </div>

      </div>
    </div>
    <!-- Catalog -->

    <!-- Hotel & Brand -->
    <div class="panel panel-default">
      <div class="panel-heading"><strong>Hotel & Brand</strong></div>
      <div class="panel-body">
        <p>Please, select the brand and hotels on show the product</p>
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group formDiv">
              <p class="margin-0">Brand Name <span class="required">*</span></p>
              <div class='form-control dropdown-toggle select select-list' data-toggle='dropdown'>
                {{ selectedBrands.length }}
                Brand{{selectedBrands.length != 1 ? 's' : ''}} Selected</div>
              <ul class='dropdown-menu checkbox-list' onClick='event.stopPropagation();'>
                <li class='check-list' *ngFor="let brand of hotelBrands">
                  <input type='checkbox' [id]="'brand'+brand.brandId" name="cb" (click)="selectBrands($event,brand)"
                    [checked]="selectedBrands.indexOf(brand.brandId) >= 0" />
                  <label [for]="'brand'+brand.brandId">
                    <span class='ip-text'>{{ brand.brandName }}</span>
                    <span class='check-box'></span>
                  </label>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group col-sm-4 formDiv">
              <p class="margin-0">Hotel Name <span class="required">*</span></p>
              <div>
                <div [ngClass]="selectedBrands.length != 0 && selectedHotels.length == 0 ? 'err':''"
                  class='form-control dropdown-toggle select select-list' data-toggle='dropdown'
                  (click)="getHotelsByBrand()" [class.disabled]="!selectedBrands.length">{{selectedHotels.length}}
                  Hotel{{selectedHotels.length == 1 ? 's' : ''}} Selected</div>
                <ul class='dropdown-menu checkbox-list' onClick='event.stopPropagation();'>
                  <li class='check-list' *ngFor="let hotel of hotels_active">
                    <input type='checkbox' [id]="'hotel'+hotel.hotelId" name='cb3' (click)="selectHotels($event,hotel)"
                      [checked]="selectedHotels.indexOf(hotel.hotelId) >= 0" />
                    <label [for]="'hotel'+hotel.hotelId">
                      <span class='ip-text'>{{ hotel.hotelName }}</span>
                      <span class='check-box'></span>
                    </label>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Hotel & Brand -->

    <!-- Actions -->
    <div class="panel panel-default">
      <div class="panel-body">
        <div class="row">
          <div class="col-sm-4 buttonsDiv">
            <button *ngIf="!isNewReward && reward.get('status').value == 'Active'"
              class='btn btn-default pull-left modify-btn1'
              (click)="openModalActivateDeactivate([reward.rewardId], 'Active')">Deactivate</button>
            <button *ngIf="!isNewReward && reward.get('status').value == 'Inactive'"
              class='btn btn-default pull-left activeBtn'
              (click)="openModalActivateDeactivate([reward.rewardId], 'Inactive')">Activate</button>
          </div>
          <div class="col-sm-8 text-right buttonsDiv">
            <button class='btn btn-default  pull-left btn-cancel' (click)="confirmClose()"
              [ngClass]="!isNewReward ? 'modify-btn2' : '' ">Cancel</button>
            <button *ngIf="isNewReward" class='btn btn-default new-btn2' [disabled]="saveBtnDisabled()"
              (click)="save(reward, true)">Record and Add Another</button>
            <button *ngIf="isNewReward" class='btn btn-default new-btn3' [disabled]="saveBtnDisabled()"
              (click)="save(reward, false)">
              <img src='assets/images/save.svg' class='pull-left' />Record</button>
            <button *ngIf="!isNewReward" class='btn btn-default new-btn3 modify-btn3' [disabled]="saveBtnDisabled()"
              (click)="save(reward)">
              <img src='assets/images/save.svg' class='pull-left' />Save Changes</button>
          </div>
        </div>
      </div>
    </div>
    <!-- Actions -->

  </form>

  <div class="error col-xs-12" *ngIf="errorDetails">{{ errorDetails }}</div>
  <div class="success col-xs-12" *ngIf="successMsg">{{ successMsg }}</div>
</div>
