<div class='col-sm-10 col-sm-offset-2 member-container'>
  <div class="row">
    <h3 class="col-sm-4 heading padding-0">{{!memberId ? 'New' : 'Modify'}} Member</h3>
  </div>
  <div class="row">
    <form [formGroup]="memberForm" class="member-form">
      <div class="row">
        <div class="form-group col-sm-4">
          <label class="labelStyle">Treatment</label>
          <select class="form-control select-icon" formControlName="genderSuffix" [ngClass]="memberForm.get('genderSuffix').hasError('required') && memberForm.get('genderSuffix').touched ? 'err' : ''">
            <option value="null" selected>Select</option>
            <option *ngFor="let suffix of memberRulesList?.genderSuffixesTo" [value]="suffix.id">{{suffix.suffix}}</option>
            <!-- <option value="2" selected>Miss</option>
            <option value="1" selected>Mr.</option>
            <option value="3" selected>Mrs.</option>
            <option value="4" selected>Ms.</option> -->
          </select>
        </div>
        <div class="col-sm-4 form-group">
          <label>First Name*</label>
          <input type="text" class="form-control" placeholder="First Name" formControlName="firstName" [ngClass]="memberForm.get('firstName').hasError('required') && memberForm.get('firstName').touched ? 'err' : ''"/>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 form-group">
          <label>Last Name*</label>
          <input type="text" class="form-control" placeholder="Last Name" formControlName="lastName" [ngClass]="memberForm.get('lastName').hasError('required') && memberForm.get('lastName').touched ? 'err' : ''"/>
        </div>
        <div class="col-sm-4 form-group">
          <label>Second Last Name</label>
          <input type="text" class="form-control" placeholder="Second Last Name" formControlName="secondLastName"/>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 form-group">
          <label>Date of Birth*</label>
          <input type="text" [formControl]="memberForm.controls['dateOfBirth']" [minDate]="minDate" class="form-control date"
            onkeydown='return event.which == 8 || event.which == 46 || event.which == 37 || event.which == 39'
            placeholder="MM/DD/YYYY" bsDatepicker #dp1="bsDatepicker" placement="bottom" [bsConfig]="bsConfig"
            [bsValue]="bsValue">
        </div>
        <div class="form-group col-sm-4">
          <label class="labelStyle">Martial Status</label>
          <select class="form-control select-icon" formControlName="maritalStatus">
            <option value="null" selected>Select Status</option>
            <option *ngFor="let martialStatus of memberRulesList?.maritalStatusTo" [value]="martialStatus.id">{{martialStatus.status}}</option>
            <!-- <option value="4" selected>Divorced</option>
            <option value="2" selected>Married</option>
            <option value="1" selected>Single</option>
            <option value="3" selected>Windowed</option>
            <option value="5" selected>Windower</option> -->
          </select>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-4">
          <label class="labelStyle">Country</label>
          <select class="form-control select-icon" formControlName="country">
            <option value="null" selected>Select Country</option>
            <option *ngFor="let country of memberRulesList?.countryCodeTo" [value]="country.countryId">{{country.name}}</option>
            <!-- <option value="314" selected>India</option>
            <option value="315" selected>United States</option> -->
          </select>
        </div>
        <div class="col-sm-4 form-group">
          <label>Email*</label>
          <input type="text" class="form-control" placeholder="Email" formControlName="email" [ngClass]="memberForm.get('email').hasError('pattern') || memberForm.get('email').hasError('required') && memberForm.get('email').touched ? 'err' : ''"/>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 form-group">
          <label>User Name</label>
          <input type="text" class="form-control" placeholder="User Name" formControlName="userName"/>
        </div>
        <div class="col-sm-4 form-group">
          <label>Phone Number*</label>
          <input type="text" class="form-control" placeholder="9876543210" formControlName="phoneNumber" [ngClass]="memberForm.get('phoneNumber').hasError('minlength') || memberForm.get('phoneNumber').hasError('maxlength') || memberForm.get('phoneNumber').hasError('pattern') || memberForm.get('phoneNumber').hasError('required') && memberForm.get('phoneNumber').touched ? 'err' : ''"/>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 form-group">
          <label>Document Type</label>
          <select class="form-control select-icon" formControlName="documentTypeId">
            <option value="null" selected>Select Document</option>
            <option *ngFor="let document of memberRulesList?.documentsTypesTo" [value]="document.id">{{document.documentType}}</option>
            <!-- <option value="5" selected>DNI</option>
            <option value="3" selected>Document</option>
            <option value="4" selected>Driver's license</option>
            <option value="2" selected>National ID</option>
            <option value="1" selected>Passport</option> -->
          </select>
        </div>
        <div class="col-sm-4 form-group">
          <label>Document Number</label>
          <input type="text" class="form-control" placeholder="Document Number" formControlName="documentNumber"/>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6 form-group">
          <label>Hobbies</label>
          <textarea rows='4' class="form-control textarea" placeholder="Hobbies" formControlName="hobbies"></textarea>
        </div>
      </div>
      <div class="row">
        <div class='form-group col-xs-12 checkbox-list2'>
          <input type='checkbox' name="booking" id='l1' value="Per Night" formControlName="isPromotions" />
          <label for='l1'>
            <span class='check-box'></span>
            <span class="text">News Letter</span>
          </label>
        </div>
      </div>
      <div class="row">
        <!-- <div class="col-sm-4 form-group">
          <label>User Name</label>
          <input type="text" class="form-control" placeholder="User Name" formControlName="userName"/>
        </div> -->
        <div class="col-sm-4 form-group">
          <label>Hotel Chain</label>
          <select class="form-control select-icon" formControlName="hotelChainId">
            <option value="null" selected>Select Hotel Chain</option>
            <!-- <option *ngFor="let hotel of memberRulesList?.hotelsList" [value]="hotel.hotelId">{{hotel.hotelName}}</option> -->
            <option value="6" selected>Sandos Hotels & Resorts</option>
            <option value="4" selected>Marconfort</option>
          </select>
        </div>
        <div class="col-sm-4 form-group">
          <label>Origin</label>
          <select class="form-control select-icon" formControlName="origin">
            <option value="null" selected>Select Origin</option>
            <option *ngFor="let origin of memberRulesList?.originsList" [value]="origin.sourceId">{{origin.name}}</option>
            <!-- <option value="1">Manual</option>
            <option value="2">Web</option>
            <option value="3">Wifi</option> -->
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 form-group">
          <label>Language</label>
          <select class="form-control select-icon" formControlName="preferredLanguage">
            <option value="null" selected>Select Language</option>
            <option *ngFor="let language of memberRulesList?.languageTo" [value]="language.id">{{language.language}}</option>
          </select>
        </div>
        <div class="col-sm-4 form-group">
          <label>Preferred Language</label>
          <select class="form-control select-icon" formControlName="preferredLanguage">
            <option value="null" selected>Select Preferred Language</option>
            <option *ngFor="let language of memberRulesList?.languageTo" [value]="language.id">{{language.language}}</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class='form-group col-xs-12 checkbox-list2'>
          <input type='checkbox' name="booking" id='l1' value="Per Night" formControlName="isActive" [checked]="memberDetails?.statusId?.statusId"/>
          <label for='l1'>
            <span class='check-box'></span>
            <span class="text">Active</span>
          </label>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 form-group">
          <label>Cancellation Account Date</label>
          <input type="text" [formControl]="memberForm.controls['cancellationDate']" [minDate]="minDate" class="form-control date"
            onkeydown='return event.which == 8 || event.which == 46 || event.which == 37 || event.which == 39'
            placeholder="MM/DD/YYYY" bsDatepicker #dp1="bsDatepicker" placement="bottom" [bsConfig]="bsConfig"
            [bsValue]="bsValue">
        </div>
        <div class="col-sm-4 form-group">
          <label>Cancellation Cause</label>
          <textarea rows='4' class="form-control textarea" placeholder="Cancellation Cause" formControlName="cancellationCause"></textarea>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 form-group">
          <label>PMS ID</label>
          <input type="text" class="form-control" placeholder="PMS ID" formControlName="idPms"/>
        </div>
        <div class="col-sm-4 form-group">
          <label>Hotel</label>
          <select class="form-control select-icon" formControlName="hotel">
            <option value="null" selected>Select Hotel</option>
            <option *ngFor="let hotel of memberRulesList?.hotelsList" [value]="hotel.hotelId">{{hotel.hotelName}}</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 form-group">
          <label>Social Id</label>
          <input type="text" class="form-control" placeholder="Social Id" formControlName="social_id"/>
        </div>
        <div class="col-sm-4 form-group">
          <label>Social Token</label>
          <textarea rows='4' class="form-control textarea" placeholder="Social Token" formControlName="social_token"></textarea>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 form-group">
          <label>Alternative Email</label>
          <input type="text" class="form-control" placeholder="Alternative Email" formControlName="alternateEmailId"/>
        </div>
        <div class="col-sm-4 form-group">
          <label>Notes</label>
          <textarea rows='4' class="form-control textarea" placeholder="Notes" formControlName="notes"></textarea>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 form-group">
          <label>Deleted in PMS</label>
          <select class="form-control select-icon" formControlName="deleted_in_pms">
            <option value="">Select</option>
            <option value="1">Yes</option>
            <option value="2">No</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 form-group">
          <label>Creation Date</label>
          <input type="text" class="form-control" placeholder="Creation Date" formControlName="createdDateStr"/>
        </div>
        <div class="col-sm-4 form-group">
          <label>Modification Date</label>
          <input type="text" class="form-control" placeholder="Modification Date" formControlName="modificationDate"/>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 form-group">
          <label>Last Access Date</label>
          <input type="text" class="form-control" placeholder="Last Access Date" formControlName="lastAccessDate"/>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 form-group">
          <label>Friends</label>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 form-group">
          <label>Available Points</label>
          <input type="text" class="form-control" placeholder="Available Points" formControlName="totalPoints"/>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 form-group">
          <label>Points Obtained</label>
          <input type="text" class="form-control" placeholder="Points Obtained" formControlName="pointsEarned"/>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 form-group">
          <label>Points Rewarded</label>
          <input type="text" class="form-control" placeholder="Points Rewarded" formControlName="pointsExpires"/>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 form-group">
          <label>Referral</label>
          <input type="text" class="form-control" placeholder="Referral" formControlName="referral"/>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 form-group">
          <label>Promocode</label>
          <input type="text" class="form-control" placeholder="Promocode" formControlName="promocode"/>
        </div>
      </div>
      <div class="row buttons-container">
          <div class="col-sm-8">
            <button class="btn btn-activate col-sm-3 pull-left" *ngIf="memberDetails?.statusId?.statusId == 0"   (click)="openStatusModal([memberForm.get('loyaltyMemberId').value], !memberDetails?.statusId?.statusId)">Activate</button>
            <button class="btn btn-deactivate col-sm-3 pull-left" *ngIf="memberDetails?.statusId?.statusId == 1" (click)="openStatusModal([memberForm.get('loyaltyMemberId').value], !memberDetails?.statusId?.statusId)">Deactivate</button>
            <button class="btn btn-record col-sm-3 pull-right" [disabled]="!memberForm.valid" (click)="addBenefit(false)">
              <img src='assets/images/save.svg' class='pull-left'/>Save Changes</button>
            <button class="btn btn-cancel col-sm-2 pull-right" (click)="confirmClose()">Cancel</button>
          </div>
      </div>
      <p class="col-sm-12 error text-center" *ngIf="errorMsg">{{errorMsg}}</p>
      <p class="col-sm-12 success text-center" *ngIf="successMsg">{{successMsg}}</p>
    </form>
  </div>
</div>
