<div class="catalog-model">
  <div class="modal-header">
    <h3 class="modal-title margin-bottom-14 margin-top">{{ catalogId ? 'Modify' : 'Add New'}} Catalog</h3>
    <p class="title"><strong>{{catalogId ? 'Modify catalog details below' : 'Create a unique name for the catalog'}}</strong></p>
  </div>
  <div class="modal-body">
    <form novalidate class='form-group' [formGroup]="catalog">
      <div class="form-group catalog">
        <label for="catalog" class="title">Catalog Name*</label>
        <input type="text" formControlName="catalogName" class="form-control" id="catalog" placeholder="Enter catalog name" name="catalog"
          [ngClass]="catalog.get('catalogName').hasError('maxlength') || (catalog.get('catalogName').hasError('whitespace') || catalog.get('catalogName').hasError('required')) && catalog.get('catalogName').touched ? 'err':''">
      </div>

      <div class="form-group  dropdown select-div">
        <label for="programType" class="title">Program Type(Select all that apply)*</label>
        <div class='select col-sm-8 dropdown-toggle' data-toggle='dropdown'>
            Select Program Type
        </div>
        <ul class='dropdown-menu checkbox-list' (click)="$event.stopPropagation()">
          <li class='check-list' *ngFor="let programType of loyaltyProgramTypes">
            <input type="checkbox" 
              [id]="programType.loyaltyProgramTypeId" 
              name="cb" 
              [value]="programType" 
              [checked]="programType.loyaltyProgramTypeId === selectedProgramType"  
              (click)="toggleSelectionForProgramType($event.target.checked, programType)"
            />

            <label [for]='programType.loyaltyProgramTypeId'>
              <span class='ip-text'>{{programType.loyaltyProgramTypeName}}</span>
              <span class='check-box'></span>
            </label>
          </li>
        </ul>
      </div>

      <div class="form-group  dropdown select-div">
        <label for="level" class="title">Membership Levels (Select all that apply)*</label>
        <div class='select col-sm-8 dropdown-toggle' data-toggle='dropdown'>
            {{selectedLevels.length}} level{{selectedLevels.length == 1 ? '' : 's'}} selected
        </div>
        <ul class='dropdown-menu checkbox-list' (click)="$event.stopPropagation()">
          <li class='check-list' *ngFor="let level of loyaltyLevels">
            <input type='checkbox' [id]='level.id' name='cb' [value]="level.id" [checked]="selectedLevels.indexOf(level.id) >= 0"  (click)="toggleSelection($event.target.checked, level)"/>
            <label [for]='level.id'>
              <span class='ip-text'>{{level.name}}: {{level.memberShipName}}</span>
              <span class='check-box'></span>
            </label>
          </li>
        </ul>
      </div>
      <div class="form-group col-xs-12 dates">
        <div class="form-group col-sm-6">
          <label for="startDate" class="title">Start Date</label>
          <input type="text" id="startDate" formControlName="startDate" class="form-control" onkeydown='return event.which == 8 || event.which == 46 || event.which == 37 || event.which == 39'
            [minDate]="minStartDate" placeholder="Start Date" bsDatepicker #dp1="bsDatepicker" placement="auto" [bsConfig]="bsConfig"
            [(bsValue)]="bsValue">
        </div>
        <div class="form-group col-sm-6">
          <label for="endDate" class="title">End Date</label>
          <input type="text"  id="endDate" formControlName="endDate" class="form-control" onkeydown='return event.which == 8 || event.which == 46 || event.which == 37 || event.which == 39'
            [minDate]="minEndDate" placeholder="End Date" bsDatepicker #dp2="bsDatepicker" placement="auto" [bsConfig]="bsConfig">
        </div>
        <p class="col-xs-12 error text-left" *ngIf="catalog.errors">Start Date must not be greater than End Date</p>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" data-dismiss="modal" (click)="confirmModalWindow()">Cancel</button>
    <button type="button" (click)="addCatalog(catalog)" class="btn btn-default" [disabled]="!catalog.valid || !selectedLevels.length">Done</button>
  </div>
  <div class="success" *ngIf="successMsg">{{successMsg}}</div>
  <div class="error" *ngIf="errorDetails">{{errorDetails}}</div>
</div>
