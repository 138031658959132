<div class="add-transaction">
    <div class="modal-body">
      <p class="heading">Add Booking Stay</p>
      <form [formGroup]="bookingForm">
        <div class="row select">
          <div class="col-sm-8 form-group select-icon">
            <label class="labelStyle">Person Type*</label>
            <select class="form-control" formControlName="personType" [ngClass]="bookingForm.get('personType').hasError('required') && bookingForm.get('personType').touched ? 'err': ''">
              <option value=null selected disabled>Select Person Type</option>
              <option *ngFor="let person of personTypes" [value]="person.code">{{person.name}}</option>
            </select>
          </div>
        </div>
        <div class="row">
          <!-- <div class="col-sm-5 form-group">
            <label class="labelStyle">Point Amount*</label>
            <input type='number' class="form-control" formControlName="points" [ngClass]="bookingForm.get('points').hasError('pattern') || bookingForm.get('points').hasError('min') || bookingForm.get('points').hasError('max') || bookingForm.get('points').hasError('required') && bookingForm.get('points').touched ? 'err': ''" onkeydown="javascript: return event.keyCode == 69 ? false : true" [class.disabled]="bookingForm.get('pointsTransTypeId').value == 1"/>
          </div> -->
        </div>
        <!-- <div class="row">
          <div class='form-group col-sm-12 checkbox-list2'>
            <input class="pull-left" type='checkbox' name="redeem" id='r1' formControlName="isCheckout"/>
            <label for='r1'>
              <span class="text">Is Checkout</span> &nbsp;
              <span class='check-box'></span>
            </label>
          </div>
        </div> -->
        <div class="row select">
          <div class="col-sm-8 form-group select-icon">
            <label class="labelStyle">State*</label>
            <select class="form-control" formControlName="state" [ngClass]="bookingForm.get('state').hasError('required') && bookingForm.get('state').touched ? 'err': ''">
              <option value=null selected disabled>Select State</option>
              <option *ngFor="let state of states" [value]="state.id">{{state.state}}</option>
            </select>
          </div>
        </div>
        <div class="row search">
          <!-- <div class="form-group col-sm-6 dropdown select-div">
            <div class="icon">
              <label class="labelStyle">Related Transaction</label>
              <input type="text" class="form-control select dropdown-toggle" [ngClass]="isRelatedTransError ? 'err' : ''" data-toggle='dropdown' (keyup)="transactionTerm$.next($event.target.value)" formControlName="relatedTransactionId" onkeydown="javascript: return /^[\w\-\s]*$/.test(event.key) ? true : false"/>
              <ul class="dropdown-menu checkbox-list col-sm-6" style="left: 13px;" *ngIf="searchedTransactions?.length">
                <ng-container *ngFor="let transaction of searchedTransactions; let i = index" >
                  <li class="check-list col-sm-12" (click)="selectDropdown(transaction.pointTransactionId, 'transaction')" [class.active]="bookingForm.get('relatedTransactionId').value == transaction" style="padding: 4px 5px;">
                    <label class="col-sm-10 padding-0">
                      <p class="ip-text"><strong>Locator:&nbsp;</strong><span>{{transaction?.locator}}</span></p>
                      <p class="ip-text"><strong>Transaction Type:&nbsp;</strong><span>{{transaction?.transactionTypeName}}</span></p>
                      <p class="ip-text"><strong>Points:&nbsp;</strong><span>{{transaction?.points}}</span></p>
                      <p class="ip-text"><strong>Status:&nbsp;</strong>
                        <span [ngClass]="(transaction.status?.statusId == 4 || transaction.status?.statusId == 0) ? 'inactive' : (transaction.status?.statusId == 3 ? 'pending' : 'active')">{{transaction?.status?.statusDesc}}</span>
                      </p>
                    </label>
                    <span class="active-icon">
                      <img class='pull-right success' src='assets/images/success.svg' *ngIf="bookingForm.get('relatedTransactionId').value == transaction" />
                    </span>
                  </li>
                  <li class="divider" *ngIf="i < searchedTransactions.length - 1"></li>
                </ng-container>
              </ul>
            </div>
          </div> -->
            <div class="form-group col-sm-9 dropdown select-div">
              <div class="icon">
                <label class="labelStyle">Booking*</label>
                <input type="text" class="form-control select dropdown-toggle" [ngClass]="isRelatedBookingError ? 'err' : ''" data-toggle='dropdown' (keyup)="bookingTerm$.next($event.target.value)" formControlName="bookings" onkeydown="javascript: return /^[\w\-\s]*$/.test(event.key) ? true : false"/>
                <ul class="dropdown-menu checkbox-list col-sm-6" style="left: 13px;" *ngIf="searchedBookings?.length">
                  <ng-container *ngFor="let booking of searchedBookings; let i = index">
                    <li class="check-list col-sm-12" (click)="selectDropdown(booking?.bookingId, 'booking')" [class.active]="bookingForm.get('bookings').value == booking" style="padding: 4px 5px;" *ngIf="booking?.locator || booking?.pmsBonus || booking?.pmsEntitler">
                      <label class="col-sm-10 padding-0">
                        <p class="ip-text" *ngIf="booking?.locator"><strong>Locator:&nbsp;</strong><span>{{booking?.locator}}</span></p>
                        <p class="ip-text" *ngIf="booking?.pmsBonus"><strong>PMS Bonus:&nbsp;</strong><span>{{booking?.pmsBonus}}</span></p>
                        <p class="ip-text" *ngIf="booking?.pmsEntitler"><strong>PMS Title:&nbsp;</strong><span>{{booking?.pmsEntitler}}</span></p>
                      </label>
                      <span class="active-icon">
                        <img class='pull-right success' src='assets/images/success.svg' *ngIf="bookingForm.get('bookings').value == booking"/>
                      </span>
                    </li>
                    <li class="divider" *ngIf="i < searchedBookings.length - 1"></li>
                  </ng-container>
                </ul>
              </div>
            </div>
            <div class="form-group col-sm-9 dropdown select-div">
              <div class="icon">
                <label class="labelStyle">Surname*</label>
                <input type="text" class="form-control" [ngClass]="isRelatedBookingError ? 'err' : ''" formControlName="pmsSurName1" onkeydown="javascript: return /^[\w\-\s]*$/.test(event.key) ? true : false"/>
              </div>
            </div>
        </div>
        <!-- <div class="row select">
          <div class="col-sm-6 form-group select-icon">
            <label class="labelStyle">Person type</label>
            <select class="form-control" formControlName="personType">
              <option value="1">Adult</option>
            </select>
          </div>
        </div> -->
      </form>
    </div>
    <div class="modal-footer">
      <button class="btn btn-cancel" (click)='closeModal()'>Cancel</button>
      <button class="btn btn-record save" (click)="save()" [disabled]="!bookingForm.valid">
        <img src='assets/images/save.svg' class="pull-left"/>
        <span>Submit</span>
      </button>
      <p class="col-sm-12 text-center success" *ngIf="successMsg">{{successMsg}}</p>
      <p class="col-sm-12 text-center error" *ngIf="errorMsg">{{errorMsg}}</p>
    </div>
  </div>
