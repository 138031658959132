<app-navbar></app-navbar>
<div class="col-sm-10">
  <div class='editContainer'>
    <h3>Edit Profile</h3>
    <form novalidate [formGroup]="user">
      <div class='row'>
        <div class='formDiv'>
          <div class="form-group col-sm-5">
            <label>First Name*</label>
            <input type="text" placeholder="" formControlName="firstName" class='form-control' [ngClass]="user.get('firstName').hasError('required') && user.get('firstName').touched ? 'err':''"
              (focus)="errorDetails = ''">
          </div>
          <div class="form-group col-sm-5">
            <label>Last Name*</label>
            <input type="text" placeholder="" formControlName="lastName1" class='form-control' [ngClass]="user.get('lastName1').hasError('required') && user.get('lastName1').touched ? 'err':''"
              (focus)="errorDetails = ''">
          </div>
        </div>
        <div class='formDiv'>
          <div class="form-group col-sm-5">
            <label>Phone Number</label>
            <input type="tel" placeholder="" formControlName="mobileNumber" class='form-control' [ngClass]="user.get('mobileNumber').hasError('pattern') || user.get('mobileNumber').hasError('minlength') || user.get('mobileNumber').hasError('maxlength') || user.get('mobileNumber').hasError('required') && user.get('mobileNumber').touched ?'err':''"
              (focus)="errorDetails = ''">
            <!-- <div class="error" style="width:288px;margin-top:0" *ngIf="user.get('mobileNumber').hasError('pattern')">
                Incorrect Phone number
            </div> -->
          </div>
          <div class="form-group col-sm-5">
            <label>Email ID*</label>
            <input type="email" placeholder="" formControlName="email" class='form-control' readonly >
          </div>
        </div>
        <!-- <div class='formDiv'>
          <div class='profilePic form-group'>
            <h5>Profile Picture</h5>
            <img class='pull-left' [src]="imageUrl" (error)="errorLoading()"/>
            <input type="file" id="file_upload" class="form-control" (change)="fileChanged($event)"  #uploadFile (focus)="errorDetails = ''">
            <button (click)="uploadFile.click()" class=" col-sm-2" >Change</button>
            <p *ngIf="errorUpload" class="error upload col-xs-12" (click)="errorUpload = false">Please upload Image only.</p>
          </div>
        </div> -->

        <!-- <div class='formDiv'>
          <div class="form-group col-sm-6 xrow xmargin-right-15 xpadding-right-0 profilePic">
            <h5>Profile Picture</h5>
            <div class='col-xs-2 padding-left-0 xtext-right'>
              <div *ngIf="imageFile.imageUrl">
                <img class="image" [src]="imageFile.imageUrl" (error)="errorLoading()"/>
                <button class='btn btn-default delete-image' *ngIf="imageFile.imageUrl">
                  <img src='assets/images/delete.svg' (click)="deleteImage('logo')" />
                </button>
              </div>
              <div *ngIf="!imageFile.imageUrl">
                <img class="image" [src]="imageUrl" (error)="errorLoading()"/>
              </div>
            </div>
            <div class='col-xs-8'>
              <input class='form-control' type='file' #uploadFile (change)="uploadImage($event,'logo')" (focus)="errorDetails = ''" />
              <button *ngIf='imageFile.imageUrl' (click)="uploadFile.click()" class='btn-file'>Change File</button>
              <button *ngIf='!imageFile.imageUrl' (click)="uploadFile.click()" class='btn-file'>Choose File</button>
              <p *ngIf="errorUpload" class="error upload col-xs-12" (click)="errorUpload = false">Please upload Image only.</p>
            </div>
          </div>
        </div> -->
        <div class='formDiv'>
          <div class="form-group col-sm-6 xrow xmargin-right-15 xpadding-right-0">
            <div class='col-xs-7'>
              <label>Profile Picture</label>
              <span *ngIf='!imageFile.imageUrl' class='image-name'>no file chosen</span>
              <span *ngIf='imageFile.name && imageFile.imageUrl' class='image-name'>{{imageFile.name}}</span>
              <input class='form-control' type='file' #uploadFile (change)="uploadImage($event,'logo')" (focus)="errorDetails = ''" />
              <button *ngIf='imageFile.imageUrl' (click)="uploadFile.click()" class='btn-file'>Change File</button>
              <button *ngIf='!imageFile.imageUrl' (click)="uploadFile.click()" class='btn-file'>Choose File</button>
              <p *ngIf="errorUpload" class="error upload col-xs-12" (click)="errorUpload = false">Please upload Image only.</p>
              <p *ngIf="fileSizeError" class="error upload col-xs-12" (click)="fileSizeError = false">Image size should be less than 1MB.</p>
            </div>
            <div class='col-xs-5 xpadding-right-0 xtext-right'>
              <div *ngIf='imageFile.imageUrl'>
                <img class="image" [src]="imageFile.imageUrl" />
                <button class='btn btn-default delete-image'>
                  <img src='assets/images/delete.svg' (click)="deleteImage('logo')" />
                </button>
              </div>
              <div *ngIf='!imageFile.imageUrl'>
                <img class="image" [src]="imageUrl" />
              </div>
              <!-- <span *ngIf='!imageFile.imageUrl' class='no-file'>no file choosen</span> -->
            </div>
          </div>
        </div>

        <div class='formDiv'>
          <h3 class='changePassword'>Change Password</h3>
          <div class="form-group col-sm-5">
            <label>Current Password</label>
            <input type="password" placeholder="" formControlName="oldPwd" class='form-control' (keyup)="enablePassword($event)" (focus)="errorDetails = ''">
          </div>
          <div class="form-group col-sm-5">
            <label>New Password*</label>
            <input type="password" formControlName="newPwd" class='form-control' [ngClass]="[user.get('newPwd').hasError('pattern')?'err':'', newPswsReq ? 'err' : '']" (keyup)="checkNull($event)" (focus)="errorDetails = ''" (blur)="checkNull($event)">
              <div class="error"  *ngIf="user.get('newPwd').hasError('pattern')">
                  Password must be at least 8 characters with one uppercase and one special character
              </div>
          </div>
        </div>

      </div>
      <div  class="mesDiv text-right col-sm-5">
        <p *ngIf="ProfileUpdateSuccessMsg" class="success col-xs-12 ">{{ProfileUpdateSuccessMsg}}</p>
      </div>
      <div class='buttonsDiv text-right col-sm-7'>
        <button [routerLink]="userInfo.userRoleId == 11 ? ['/hca-management'] : ['/hotel-management']" class='btn btn-default col-sm-3 col-xs-12'>Cancel</button>
        <button (click)="saveUserInfo(user)" class='btn btn-default col-sm-3 col-xs-12' [disabled]="!user.valid">Save Changes</button>
      </div>
    </form>
    
    <div class="error col-xs-12" *ngIf="errorDetails">{{errorDetails}}</div>
  </div>
</div>
