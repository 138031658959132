<div class="col-sm-10 hca-main">
  <div class='row head-line'>
    <div class='col-sm-6'>
      <h3 *ngIf='adminList && adminList.length'>Hotel Chain Account Management</h3>
    </div>
    <div class="col-sm-6  col-xs-12 text-right buttons">
      <button routerLink="/hca-management/add" type='button' class="btn btn-default col-xs-12 col-md-6 pull-right">
        <img src='assets/images/plus-white.svg' />New Hotel Chain
      </button>
    </div>
  </div>
  <div class="col-xs-12 tableData" *ngIf='adminList && adminList.length'>
    <table class="table table-bordered">
      <thead>
        <tr>
          <!-- <td *ngFor="let header of tableHeader; let idx = index " (click)="sortHca(header)">{{header.key}}
            <span class="sort-icons up" *ngIf="header.isDesc"> &#9660;</span>
            <span class="sort-icons down" *ngIf="!header.isDesc"> &#9650;</span>
          </td> -->
          <td>Hotel Chain Name</td>
          <td>Name + Phone Number</td>
          <td>Contact Email</td>
          <td>Status</td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let admin of adminList; let i=index;">
          <td class="xpointer">
            <u *ngIf="!admin.websiteUrl">{{admin.hotelChainName}}</u>
            <!-- <u *ngIf="admin.websiteUrl">
              <a href="http://{{admin.websiteUrl}}" target="_blank">{{admin.hotelChainName}}</a>
            </u> -->
            <span *ngIf="admin.websiteUrl">
              {{admin.hotelChainName}}
            </span>
          </td>
          <td>
            <p>{{admin.contactName}}</p>
            <p>{{admin.contactMobile}}</p>
          </td>
          <td class="pointer">
            <u>
              <a href="mailto:{{admin.contactEmail}}" target="_top">{{admin.contactEmail}}</a>
            </u>
          </td>
          <td class="text-center">
            <div class='statusDiv dropdown'>
              <span [ngClass]="admin.status == 'Active'? 'active':'inactive'">{{admin.status}}</span>
              <button type='button' class='btn btn-link pull-right dropdown-toggle' data-toggle="dropdown">
                <img src='assets/images/overflow.svg' />
              </button>
              <ul class="dropdown-menu statusList">
                <li>
                  <a [routerLink]="['/hca-management/edit', admin.hotelChainId]" routerLinkActive="active">
                    <img class='pull-left' src='assets/images/edit.svg' />
                    <span class='pull-left'>Modify</span>
                  </a>
                </li>
                <li class="hover" *ngIf="admin.status === 'Active'" (click)="deactivateHcaId(admin.hotelChainId, i)">
                  <a class='deactivate' data-toggle="modal" data-target="#myModal">
                    <img class='pull-left' src='assets/images/delete.svg' />
                    <span class='pull-left'>Deactivate</span>
                  </a>
                </li>
                <li *ngIf="admin.status === 'Inactive'" class="hover " (click)="deactivateHcaId(admin.hotelChainId, i)">
                  <a class='activate' data-toggle="modal" data-target="#myActivateModal">
                    <img class='pull-left' src='assets/images/success.svg' />
                    <span class='pull-left'>Activate</span>
                  </a>
                </li>
              </ul>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- <div *ngIf='adminList && !adminList.length' class='emptyTable text-center'>
       No Records
    </div> -->
  </div>
  <div class="message" *ngIf="!adminList || adminList.length === 0">
    <h3 class="text-center">
      You haven’t added any hotel chains yet. Click on “+ New Hotel Chain” above to get started.
    </h3>
  </div>
  <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title" id="exampleModalLabel">Deactivate HCA Account</h3>
          <p>Are you sure you want to deactivate this account?</p>
        </div>
        <div class="modal-footer text-center">
          <button type="button" class="btn btn-default" id="deleteModal" data-dismiss="modal">Cancel</button>
          <button type="button" (click)="changeHCAStatus('Deactivate')" class="btn btn-default">Deactivate</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="myActivateModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title" id="exampleModalLabel">Activate HCA Account</h3>
          <p>Are you sure you want to activate this account?</p>
        </div>
        <div class="modal-footer text-center">
          <button type="button" class="btn btn-default" id="activateModal" data-dismiss="modal">Cancel</button>
          <button type="button" (click)="changeHCAStatus('Activate')" class="btn btn-default">Activate</button>
        </div>
      </div>
    </div>
  </div>
</div>
