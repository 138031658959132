<div class="col-sm-11 col-sm-offset-1 list-container">
  <div class="col-xs-12" *ngIf="!hotelBrands.length">
    <div class="col-xs-12 title ">{{hotelChainName}}</div>
    <!-- Hotel Brands Empty state -->
    <div class="col-xs-12 message text-center">
      <h3>
         Click on "+ Setup Loyalty Program" to get started.
      </h3>
      <div class='img text-center'>
        <img src='assets/images/hotel-dark.svg' />
      </div>
      <button class='btn btn-default text-center' routerLink='/hotel-management/brand/add'>
        <img src='assets/images/plus-white.svg' class='pull-left' />
        <span class="padding-left-15">
          <!-- Add Hotel Brand -->
          Setup Loyalty Program
        </span>
      </button>
    </div>

  </div>
  <div class="col-xs-12" *ngIf="hotelBrands.length">
    <div class="col-xs-12 title ">{{hotelChainName}}</div>
    <div class="col-xs-12">
      <div class="dropdown col-sm-10 row">
        <span class="dropdown-toggle headline" data-toggle="dropdown">{{selectedBrand.brandName}}
          <span class="glyphicon glyphicon-chevron-down pointer"></span>
        </span>
        <ul class="dropdown-menu nav-list col-xs-12">
          <li *ngFor="let brand of hotelBrands" class="pointer" (click)="getHotelsByBrand(brand)" [class.active-brand]="selectedBrand.brandId == brand.brandId">
            <a class="text-wrap">
              <img *ngIf="selectedBrand.brandId == brand.brandId" src='assets/images/success.svg' class='pull-right success' /> {{brand.brandName}}
            </a>
          </li>
          <!-- <li class='divider'></li> -->
          <li class='plus' *ngIf="!hotelBrands.length">
            <a xrouterLink='/hotel-management/brand/add' routerLink='/hotel-management/brand/wizard'>
              <img src='assets/images/plus-dark.svg' />
              <span>
                <!-- Add Hotel Brand -->
                Setup Loyalty Program
              </span>
            </a>
          </li>
        </ul>
      </div>
      <div class="col-sm-2 col-xs-12 pull-right text-right padding-right-0">
        <button [class.enabled]="hotelBrands.length" [disabled]="!hotelBrands.length" routerLink="/hotel-management/hotel/add" type='button'
          class='btn btn-default'>
          <img src='assets/images/plus-white.svg' class='pull-left' />
          <span>Add Hotel</span>
        </button>
      </div>
    </div>
    <!-- Hotels Empty state -->
    <div *ngIf="!hotels.length">
      <div class="col-xs-12 message text-center">
        <h3>
          You haven’t added any hotels yet. Get started by clicking the button above.
        </h3>
        <div class='img text-center'>
          <img src='assets/images/hotel-dark.svg' />
        </div>
      </div>
    </div>

    <div class="col-xs-12 margin-top25">
      <div class="row">
        <div class="row">
          <div id="hotels" *ngIf="hotels.length && hotels_active.length" class="col-xs-12">
            <div class="img-container col-md-6" *ngFor="let hotel of hotels_active">
              <div class="hotel-container" [ngStyle]="hotel.banner && hotel.banner != 'null' ? {'background-image': getStyle(hotel)} : {'background': 'url(' + imageUrl + ') no-repeat center center'}">
                <div class='dropdown col-xs-12 padding-top-15'>
                  <span class="pull-right dropdown-toggle pointer" data-toggle="dropdown">
                    <img src='assets/images/icon-rounded.svg' />
                  </span>
                  <ul class="dropdown-menu pull-right statusList">
                    <li class="pointer">
                      <a [routerLink]="['hotel/edit',hotel.hotelId]">
                        <img class='pull-left' src='assets/images/edit.svg' />
                        <span class='pull-left'>Modify</span>
                      </a>
                    </li>
                    <li class="pointer">
                      <a class='deactivate' *ngIf="hotel.status == 'Active' || hotel.status == null" (click)="openModalActivateDeactivate(hotel.hotelId, 'Active')">
                        <img class='pull-left' src='assets/images/delete.svg' />
                        <span class='pull-left'>Deactivate</span>
                      </a>
                    </li>
                    <li class="pointer">
                      <a class='activate' *ngIf="hotel.status == 'Inactive'" (click)="openModalActivateDeactivate(hotel.hotelId, 'Inactive')">
                        <img class='pull-left' src='assets/images/success.svg' />
                        <span class='pull-left'>Activate</span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="hotel-name-container col-xs-12">
                  <span class="hotel-name uppercase text-center">{{hotel.hotelName}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div>
          <div class="col-xs-12 divider margin-bottom25" *ngIf="hotels.length && hotels_active.length && hotels_inactive.length">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="row">
          <div id="hotels2" *ngIf="hotels.length && hotels_inactive.length" class="col-xs-12">
            <div class="img-container col-md-6" *ngFor="let hotel of hotels_inactive">
              <div class="hotel-container" [ngStyle]="hotel.banner && hotel.banner != 'null' ? {'opacity':'0.7','background-image': getStyle(hotel)} : {'background': 'url(' + imageUrl + ') no-repeat center center'}">
                <div class='dropdown col-xs-12 padding-top-15'>
                  <span class="pull-right dropdown-toggle pointer" data-toggle="dropdown">
                    <img src='assets/images/icon-rounded.svg' />
                  </span>
                  <ul class="dropdown-menu pull-right statusList">
                    <li class="pointer">
                      <a [routerLink]="['hotel/edit',hotel.hotelId]">
                        <img class='pull-left' src='assets/images/edit.svg' />
                        <span class='pull-left'>Modify</span>
                      </a>
                    </li>
                    <li class="pointer">
                      <a class='deactivate' *ngIf="hotel.status == 'Active' || hotel.status == null" (click)="openModalActivateDeactivate(hotel.hotelId, 'Active')">
                        <img class='pull-left' src='assets/images/delete.svg' />
                        <span class='pull-left'>Deactivate</span>
                      </a>
                    </li>
                    <li class="pointer">
                      <a class='activate' *ngIf="hotel.status == 'Inactive'" (click)="openModalActivateDeactivate(hotel.hotelId, 'Inactive')" data-toggle="modal"
                        data-target="#myActivateModal">
                        <img class='pull-left' src='assets/images/success.svg' />
                        <span class='pull-left'>Activate</span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="hotel-name-container col-xs-12">
                  <span class="hotel-name uppercase text-center">{{hotel.hotelName}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>