<div class='col-sm-9 col-sm-offset-3 marketing-container' *ngIf="promoForm" #promoView>
  <h3 class="heading" *ngIf="isNewPromo">New Promo</h3>
  <h3 class="heading" *ngIf="!isNewPromo">Modify Promo</h3>
  <form [formGroup]="promoForm">
    <div class="row">
      <div class="col-sm-4 form-group">
        <label>Promo Name*</label>
        <input type="text" class="form-control" placeholder="Promo Name" formControlName="promoName"
               [ngClass]="promoForm.get('promoName')?.hasError('maxlength') || promoForm.get('promoName')?.hasError('required') && promoForm.get('promoName').touched ? 'err':''"
        />
      </div>
    </div>
    <div class="row">
      <div class="form-group col-sm-8">
        <label>Promo Description*</label>
        <textarea rows="4" class="form-control" placeholder="Promo Description" formControlName="promoDesc"
                  [ngClass]="promoForm.get('promoDesc')?.hasError('maxlength') || promoForm.get('promoDesc')?.hasError('required') && promoForm.get('promoDesc').touched ? 'err':''"></textarea>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-sm-4 select-icon icon">
        <label>Transaction Type*</label>
        <select class="form-control" formControlName="transactionTypeId"
                [ngClass]="promoForm.get('transactionTypeId')?.hasError('required') && promoForm.get('transactionTypeId').touched ? 'err':''">
          <option value="" selected disabled>Select Transaction Type</option>
          <option *ngFor="let transaction of transactionTypes"
                  [value]="transaction.id">{{transaction.transactionTypeEn}}</option>
        </select>
      </div>
    </div>


    <div class="row">
      <div class="form-group col-sm-8">
        <p class="sub-heading">Point Value (Select at least one)*</p>
        <label class="radio" *ngFor="let type of pointValueTypes">
          <input type="radio" [value]="type.id" formControlName="pointValueType"
                 [ngClass]="promoForm.get('pointValueType')?.hasError('required') && promoForm.get('pointValueType').touched ? 'err':''">
          <span class="outer"></span>
          <span class='radio-text'>{{type.pointValueType}}</span>
        </label>
      </div>
    </div>
    <div class="row" *ngIf="promoForm.controls['pointValueType'].value">
      <div class="form-group col-sm-4">
        <label>Value*</label>
        <input type="number"
               class="form-control"
               placeholder="Value"
               formControlName="points"
               [ngClass]="promoForm.get('points')?.hasError('maxlength') || promoForm.get('points')?.hasError('required') && promoForm.get('points').touched ? 'err':''"
        />
      </div>
    </div>

    <div class="row" *ngIf="promoData && promoData.promoType.length">
      <div class="form-group col-sm-8">
        <p class="sub-heading">Promotion Type*</p>
        <label class="radio" [class.disabled]="!isNewPromo" *ngFor="let promotion of promoData.promoType;let i=index;">
          <input type="radio" [value]="promotion.promoCodeTypeId" formControlName="promoCodeTypeId">
          <span class="outer"></span>
          <span class='radio-text'>{{promotion.name}}</span>
        </label>
      </div>
    </div>
    <div class="row" *ngIf="promoForm.controls['promoCodeTypeId'].value == 1">
      <div class="form-group col-sm-12">
        <label class="radio">
          <input type="radio" value=true formControlName="isAutomatic">
          <span class="outer"></span>
          <span class='radio-text'>Automatic</span>
        </label>
        <label class="radio">
          <input type="radio" value=false formControlName="isAutomatic">
          <span class="outer"></span>
          <span class='radio-text'>Non Automatic</span>
        </label>
      </div>
      <div formArrayName="codes">
        <div *ngFor="let coupon of promoForm.controls['codes'].controls; let i=index">
          <div formGroupName="{{i}}" class="form-group col-sm-4">
            <label>Coupon Code*</label>
            <input type="text" class="form-control" placeholder="Coupon Code" formControlName="code"
                   [ngClass]="coupon.get('code')?.hasError('maxlength') || coupon.get('code')?.hasError('required') && coupon.get('code').touched ? 'err':''"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="promoForm.controls['promoCodeTypeId'].value == 3">
      <div class="form-group col-sm-4">
        <label>Promotion Code*</label>
        <input type="text" class="form-control" placeholder="Promotion Code" formControlName="codes"
               [ngClass]="promoForm.get('codes')?.hasError('maxlength') || promoForm.get('codes')?.hasError('required') && promoForm.get('codes').touched ? 'err':''"
        />
      </div>
    </div>
    <div class="row form-group" *ngIf="promoForm.controls['promoCodeTypeId'].value == 2">
      <div>
        <div formArrayName="groupCodes">
          <div *ngFor="let code of promoForm.controls['groupCodes'].controls; let i=index">
            <div formGroupName="{{i}}" class="col-sm-12 padding-0 scratch-code-list">
              <div class="col-sm-12 form-group">
                <label class="title">Scratch Code #{{i + 1}}</label>
              </div>
              <div>
                <div class="form-group col-sm-4">
                  <label>Quantity(max 100)*</label>
                  <input type="number" [class.disabled]="!isNewPromo && code.get('groupId').value" class="form-control"
                         placeholder="Quantity" formControlName="quantity"
                         [ngClass]="code.get('quantity')?.hasError('min') || code.get('quantity')?.hasError('max') || (code.get('quantity')?.hasError('required') && code.get('quantity').touched) ? 'err':''"
                         (keyup)="valueChanges('quantity', i)"/>
                </div>
                <div class="form-group col-sm-4">
                  <label>Number Of People To Share(max 10)*</label>
                  <input type="number" class="form-control" [class.disabled]="!isNewPromo && code.get('groupId').value"
                         min="1"
                         max="100"
                         placeholder="Max People To Share" formControlName="maxShare"
                         [ngClass]="code.get('maxShare')?.hasError('min') || code.get('maxShare')?.hasError('max') || (code.get('maxShare')?.hasError('required') && code.get('maxShare').touched) ? 'err':''"
                  />
                </div>
              </div>
              <div class="col-sm-12 form-group padding-0 custom-dropdown">
                <div class="form-group col-sm-4 select-icon icon" style='position:relative'
                     [class.readonly]="!isNewPromo && code.get('groupId').value">
                  <label>Duration (hrs)*</label>
                  <div class="btn-group col-xs-12 padding-0" dropdown #dropdown="bs-dropdown" [autoClose]="true"
                       [class.disabled]="!isNewPromo && code.get('groupId').value">
                    <button dropdownToggle type="button" class="btn dropdown-toggle form-control"
                            [ngClass]="(code.get('duration')?.hasError('required')) ? 'err':''">
                      {{code.get('duration').value ? code.get('duration').value : 'Select Hours'}}
                    </button>
                    <ul id="dropdown-triggers-manual" *dropdownMenu class="dropdown-menu"
                        role="menu" aria-labelledby="button-triggers-manual">
                      <li *ngFor="let hour of getHours" role="menuitem" class="hand"
                          (click)="selectedHour('hour', hour, i);dropdown.hide();valueChanges('duration', i);"><a
                        class="dropdown-item" [class.active]="code.get('duration').value == hour">{{hour}}</a></li>
                    </ul>
                  </div>
                </div>
                <button *ngIf="isNewPromo || !code.get('groupId').value"
                        class="btn btn-warning scratch-btn col-sm-offset-1 col-sm-3" style="margin-top: 25px;"
                        [disabled]="!code.controls['quantity'].valid || !code.controls['maxShare'].valid || !code.controls['duration'].valid || code.controls['duration'].value == 0"
                        (click)="getScratchCode(i)">Generate Scratch Code
                </button>
              </div>
              <div class="col-sm-12 form-group" *ngIf="!isNewPromo && code.get('groupId').value">
                <button class="btn btn-warning scratch-btn col-sm-3" style="margin-top: 25px;"
                        [disabled]="!code.controls['codes'].value || !code.controls['codes'].value.length"
                        (click)="printCards('preview', i)">Show Scratch Cards
                </button>
                <button class="btn btn-warning col-sm-offset-1 col-sm-3" style="margin-top: 25px; background: #f0ad4e;"
                        [disabled]="!code.controls['codes'].value || !code.controls['codes'].value.length"
                        (click)="printCards('print', i)">Print Scratch Cards
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 form-group"
             *ngIf="!isNewPromo && promoForm.controls['groupCodes'].controls.length < 4 && !this.hideAddGroupCode">
          <button class="btn btn-warning scratch-btn col-sm-3" style="margin-top: 25px;" (click)="addGroupCode(null)">
            Add Another Scratch Cards
          </button>
        </div>
      </div>
    </div>
    <div class="row"
         [ngClass]="(!promoForm.controls['isAutomatic'].value || promoForm.controls['isAutomatic'].value == 'false') && promoForm.controls['promoCodeTypeId'].value == 2 ? 'promotion-dates' : ''">
      <div class="col-sm-4">
        <p class="sub-heading">Promotion Start & End Dates*</p>
      </div>
    </div>
    <div class="row">
      <div class="row form-group col-sm-8">
        <div class="form-group col-sm-6">
          <label>Start Date</label>
          <input type="text" [formControl]="promoForm.controls['dateFrom']" [minDate]="minDate"
                 class="form-control date"
                 onkeydown='return event.which === 8 || event.which === 46 || event.which === 37 || event.which === 39'
                 placeholder="Start Date" bsDatepicker #dp1="bsDatepicker" placement="auto" [bsConfig]="bsConfig"
                 [bsValue]="bsValue"
                 [ngClass]="promoForm.get('dateFrom')?.hasError('required') && promoForm.get('dateFrom').touched ? 'err':''">
        </div>
        <div class="form-group col-sm-6">
          <label>End Date</label>
          <input type="text" [formControl]="promoForm.controls['dateTo']" [minDate]="minDate" class="form-control date"
                 onkeydown='return event.which === 8 || event.which === 46 || event.which === 37 || event.which === 39'
                 placeholder="End Date" bsDatepicker #dp2="bsDatepicker" placement="auto" [bsConfig]="bsConfig"
                 [ngClass]="promoForm.get('dateTo')?.hasError('required') && promoForm.get('dateTo').touched ? 'err':''">
        </div>
        <p class="col-xs-12 error text-left" *ngIf="promoForm.errors">Start Date must not be greater than End Date</p>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-sm-5">
        <p class="sub-heading">Hotel & Brand Selection</p>
        <p class="opacity-5">Select which brand(s) and/or hotel(s) you want to associate this product to</p>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-sm-4 select-icon icon">
        <label>Brand Name*</label>
        <select class="form-control" formControlName="brandIds"
                [ngClass]="promoForm.get('brandIds')?.hasError('required') && promoForm.get('brandIds').touched ? 'err':''"
                (change)="getHotelsByBrands()">
          <option value="" selected disabled>Select Brand Name</option>
          <option *ngFor="let brand of brands"
                  [value]="brand.brandId">{{brand.brandName}}</option>
        </select>
      </div>
      <div class="form-group col-sm-4 select-icon icon">
        <label>Hotel Name*</label>
        <select class="form-control" formControlName="hotelIds"
                [ngClass]="promoForm.get('hotelIds')?.hasError('required') && promoForm.get('hotelIds').touched ? 'err':''">
          <option value="" disabled>Select Hotel</option>
          <option *ngFor="let hotel of hotels"
                  [value]="hotel.hotelId">{{hotel.hotelName}}</option>
        </select>
      </div>

    </div>
    <div class="row buttons-container">
      <div class="col-sm-8" *ngIf="isNewPromo">
        <button class="btn btn-cancel col-sm-2 pull-left" (click)="confirmClose()">Cancel</button>
        <button class="btn btn-record col-sm-2 pull-right" [disabled]="!promoForm.valid" (click)="addPromo(false)">
          Record
        </button>
        <button class="btn btn-add col-sm-5 pull-right" [disabled]="!promoForm.valid" (click)="addPromo(true)">Record
          and Add Another
        </button>
      </div>
      <div class="col-sm-8" *ngIf="!isNewPromo">
        <button class="btn btn-activate col-sm-3 pull-left" *ngIf="promoForm.controls['statusId']?.value === 0"
                (click)="openModalActivateDeactivate(promoForm.controls['promoCodeId']?.value,promoForm.controls['statusId']?.value)">
          Activate
        </button>
        <button class="btn btn-deactivate col-sm-3 pull-left" *ngIf="promoForm.controls['statusId']?.value === 1"
                (click)="openModalActivateDeactivate(promoForm.controls['promoCodeId']?.value, promoForm.controls['statusId']?.value)">
          Deactivate
        </button>
        <button class="btn btn-record col-sm-3 pull-right" [disabled]="!promoForm.valid" (click)="addPromo(false)">
          <img src='assets/images/save.svg' class='pull-left' alt=""/>Save Changes
        </button>
        <button class="btn btn-cancel col-sm-2 pull-right" (click)="confirmClose()">Cancel</button>
      </div>
    </div>
    <p class="col-sm-12 error text-center" *ngIf="errorMsg">{{errorMsg}}</p>
    <p class="col-sm-12 success text-center" *ngIf="successMsg">{{successMsg}}</p>
  </form>
</div>
