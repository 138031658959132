<div class='col-sm-9 col-sm-offset-3 main-container'>
  <div class="row header">
    <div class="col-sm-5 heading">
      <h3 class="h">History Changes</h3>
    </div>
  </div>

  <form novalidate class='form-group' [formGroup]="form">
    <div class="row">
      <div class='formDiv'>
        <div class="form-group col-sm-4">
          <label>Entity</label>
          <input type="text" placeholder="" formControlName="entity" class='form-control' />
        </div>
      </div>
      <div class='formDiv'>
        <div class="form-group col-sm-4">
          <label>ID</label>
          <input type="text" placeholder="" formControlName="id" class='form-control' />
        </div>
      </div>
      <div class='formDiv'>
        <div class="form-group col-sm-4">
          <label>Who</label>
          <input type="text" placeholder="" formControlName="who" class='form-control' />
        </div>
      </div>
    </div>
    <div class="row">
      <div class='formDiv'>
        <div class="form-group col-sm-4">
          <label>Timestamp From</label>
          <input type="text" placeholder="" formControlName="timestamp_from" class='form-control' />
        </div>
        <div class="form-group col-sm-4">
          <label>Timestamp To</label>
          <input type="text" placeholder="" formControlName="timestamp_to" class='form-control' />
        </div>
      </div>
    </div>
  </form>
  <div class='buttonsDiv text-right col-sm-12'>
    <button (click)="searchLogs()" class='btn btn-default pull-right'>Search</button>
  </div>
</div>


<div class='col-sm-9 col-sm-offset-3 main-container'>
  <div class='row head-line'>
    <div class='col-sm-6'>
      <h3 *ngIf='logs && logs.length'>History Changes Details</h3>
    </div>
  </div>
  <div class="col-xs-12 table-responsive  tableData" *ngIf='logs && logs.length'>
    <table class="table table-bordered ">
      <thead>
        <tr>
          <td class='col-sm-2'>Before</td>
          <td class='col-sm-2'>After</td>
          <td class='col-sm-2'>When</td>
          <td class='col-sm-2'>Who</td>
          <td class='col-sm-2'>Entity</td>
          <td class='col-sm-2'>Id</td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let log of logs; let i=index;">
          <td>
            {{log.before}}
          </td>
          <td>
            {{log.after}}
          </td>
          <td>
            {{log.when}}
          </td>
          <td>
            {{log.who}}
          </td>
          <td>
            {{log.entity}}
          </td>
          <td>
            {{log.id}}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
