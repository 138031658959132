<!-- <div class="col-xs-12 sample-header" [innerHtml]="headerHtml"></div> -->

<div>
  <div class='row'>
    <div class='col-sm-9 padding-right-10'>
      <ul class='nav nav-tabs rules-tabs'>
        <li class="active">
          <a id="englishOther" *ngIf="!isDirtyEs" data-toggle="tab" href="#oEng">
            <span>English</span>
          </a>
          <a *ngIf="isDirtyEs" class="pointer" (click)="initialise()">
            <span>English</span>
          </a>
        </li>
        <li class="">
          <a id="spanishOther" *ngIf="!isDirtyEn" data-toggle="tab" href="#oSp">
            <span>Español</span>
          </a>
          <a *ngIf="isDirtyEn" class="pointer" (click)="initialise()">
            <span>Español</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
  <div class="tab-content">
    <!-- English -->
    <div class="tab-pane active" id="oEng">
      <div class="centered-content">
        <div>
          <div class="col-sm-9 tabPaneBorder">
            <div class='col-sm-9'>
              <!-- how it works form -->
              <form novalidate [formGroup]="howitworksFormEn">
                <div class='row margin-top-35'>
                  <div class="col-xs-12">
                    <label class="adminheadingLabelStyle margin-bottom-20">
                      How it Works Page
                    </label>
                    <p class="adminPermissionDivText">
                      Configure the content for the page that will describe how your loyalty program works.
                    </p>
                  </div>
                  <div class="col-xs-12 margin-top">
                    <label class="labelStyle">How it Works HTML</label>
                    <button class='refresh-button pull-right' (click)="resetField('howitworksHtml', 'howitworksFormEn')">
                      <img src='assets/images/refresh.png' class='refresh-image' title="Reset" />
                    </button>
                    <textarea class="inputTextStyle form-control" formControlName="howitworksHtml" placeholder="Paste your HTML here..." rows='5'></textarea>
                  </div>
                  <div class="col-xs-12 margin-top">
                    <label class="labelStyle">How it Works CSS</label>
                    <button class='refresh-button pull-right' (click)="resetField('howitworksCss', 'howitworksFormEn')">
                      <img src='assets/images/refresh.png' class='refresh-image' title="Reset" />
                    </button>
                    <textarea class="inputTextStyle form-control" formControlName="howitworksCss" placeholder="Paste your CSS here..." rows='5'></textarea>
                  </div>
                </div>
              </form>
              <!-- <div class="buttons-div text-right">
                <button (click)="saveForm()" class='btn btn-default btn-save'>
                  <img src='assets/images/save.svg' class='pull-left' />
                  <span class='pull-left'>Save</span>
                </button>
              </div> -->

              <!-- earn points form -->
              <form [formGroup]="earnpointsFormEn">
                <div class='row margin-top-20'>
                  <div class="col-xs-12">
                    <label class="adminheadingLabelStyle margin-bottom-20">
                      Earn Points Page
                    </label>
                    <p class="adminPermissionDivText">
                      Set the page title, heading and sub-heading for your earn points page.
                    </p>
                  </div>
                  <div class="col-xs-12 margin-top">
                    <label class="labelStyle">Heading</label>
                    <textarea class="inputTextStyle form-control" formControlName="header" placeholder="Heading Text..." rows='3'></textarea>                    
                  </div>
                  <div class="col-xs-12 margin-top">
                    <label class="labelStyle">Sub-Heading</label>
                    <textarea class="inputTextStyle form-control" formControlName="subheader" placeholder="Sub-Heading Text" rows='3'></textarea>
                  </div>
                </div>
              </form>

              <!-- spend points form -->
              <form [formGroup]="spendpointsFormEn">
                  <div class='row margin-top-20'>
                    <div class="col-xs-12">
                      <label class="adminheadingLabelStyle margin-bottom-20">
                        Spend Points Page
                      </label>
                      <p class="adminPermissionDivText">
                        Set the page title, heading and sub-heading for your spend points page.
                      </p>
                    </div>
                    <div class="col-xs-12 margin-top">
                      <label class="labelStyle">Heading</label>
                      <textarea class="inputTextStyle form-control" formControlName="heading" placeholder="Heading Text..." rows='3'></textarea>                    
                    </div>
                    <div class="col-xs-12 margin-top">
                      <label class="labelStyle">Sub-Heading</label>
                      <textarea class="inputTextStyle form-control" formControlName="subHeading" placeholder="Sub-Heading Text" rows='3'></textarea>
                    </div>
                  </div>
                </form>
            </div>
          </div>
          <div class='col-sm-9 padding-right-0'>
            <div class='buttons-div xtext-right row'>

              <div class="col-sm-6">
                <div class="success col-xs-12 success-align" *ngIf="successMsg">{{successMsg}}</div>
                <div class="error col-xs-12 error-align" *ngIf="errorDetails">{{errorDetails}}</div>
              </div>
              <div class="col-sm-6 text-right">
                <button (click)="saveForm()" class='btn btn-default btn-save'>
                  <img src='assets/images/save.svg' class='pull-left' />
                  <span class='pull-left'>Save</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- English -->

    <!-- Spanish -->
    <div class="tab-pane" id="oSp">
      <div class="centered-content">
        <div>
          <div class="col-sm-9 tabPaneBorder">
            <div class='col-sm-9'>
              <!-- how it works form -->
              <form novalidate [formGroup]="howitworksFormEs">
                <div class='row margin-top-35'>
                  <div class="col-xs-12">
                    <label class="adminheadingLabelStyle margin-bottom-20">
                      Cómo funciona la página
                    </label>
                    <p class="adminPermissionDivText">
                      Configure el contenido de la página que describirá cómo funciona su programa de lealtad.
                    </p>
                  </div>
                  <div class="col-xs-12 margin-top">
                    <label class="labelStyle">Cómo funciona HTML</label>
                    <button class='refresh-button pull-right' (click)="resetField('howitworksHtml', 'howitworksFormEs')">
                      <img src='assets/images/refresh.png' class='refresh-image' title="Reiniciar" />
                    </button>
                    <textarea class="inputTextStyle form-control" formControlName="howitworksHtml" placeholder="Pega tu HTML aquí ..." rows='5'></textarea>
                  </div>
                  <div class="col-xs-12 margin-top">
                    <label class="labelStyle">Cómo funciona CSS</label>
                    <button class='refresh-button pull-right' (click)="resetField('howitworksCss', 'howitworksFormEs')">
                      <img src='assets/images/refresh.png' class='refresh-image' title="Reiniciar" />
                    </button>
                    <textarea class="inputTextStyle form-control" formControlName="howitworksCss" placeholder="Pega tu CSS aquí..." rows='5'></textarea>
                  </div>
                </div>
              </form>

              <!-- earn points form -->
              <form [formGroup]="earnpointsFormEs">
                <div class='row margin-top-20'>
                  <div class="col-xs-12">
                    <label class="adminheadingLabelStyle margin-bottom-20">
                      Gana puntos Página
                    </label>
                    <p class="adminPermissionDivText">                     
                      Establezca el título de la página, el título y el subtítulo de su página de ganar puntos.
                    </p>
                  </div>
                  <div class="col-xs-12 margin-top">
                    <label class="labelStyle">Título</label>
                    <textarea class="inputTextStyle form-control" formControlName="header" placeholder="Título del texto ..." rows='3'></textarea>
                  </div>
                  <div class="col-xs-12 margin-top">
                    <label class="labelStyle">Subtítulo</label>
                    <textarea class="inputTextStyle form-control" formControlName="subheader" placeholder="Texto del subtítulo" rows='3'></textarea>
                  </div>
                </div>
              </form>

              <!-- spend points form -->
              <form [formGroup]="spendpointsFormEs">
                  <div class='row margin-top-20'>
                    <div class="col-xs-12">
                      <label class="adminheadingLabelStyle margin-bottom-20">
                        gastar la página de puntos
                      </label>
                      <p class="adminPermissionDivText">
                        Establezca el título de la página, el título y el subtítulo de su gastar la página de puntos.
                      </p>
                    </div>
                    <div class="col-xs-12 margin-top">
                      <label class="labelStyle">Título</label>
                      <textarea class="inputTextStyle form-control" formControlName="heading" placeholder="Título del texto ..." rows='3'></textarea>
                    </div>
                    <div class="col-xs-12 margin-top">
                      <label class="labelStyle">Subtítulo</label>
                      <textarea class="inputTextStyle form-control" formControlName="subHeading" placeholder="Texto del subtítulo" rows='3'></textarea>
                    </div>
                  </div>
                </form>
            </div>
          </div>
          <div class='col-sm-9 padding-right-0'>
            <div class='buttons-div xtext-right row'>
              <div class="col-sm-6">
                <div class="success col-xs-12 success-align" *ngIf="successMsg">{{successMsg}}</div>
                <div class="error col-xs-12 error-align" *ngIf="errorDetails">{{errorDetails}}</div>
              </div>
              <div class="col-sm-6 text-right">
                <button (click)="saveForm()" class='btn btn-default btn-save'>
                  <img src='assets/images/save.svg' class='pull-left' />
                  <span class='pull-left'>
                    Salvar
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Spanish -->

</div>