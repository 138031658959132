<div class="col-sm-10">
  <div class='editContainer'>
    <h3>Add New Hotel Chain Account</h3>
    <form novalidate [formGroup]="hotelChain" class='form-group'>
      <div class='row '>
        <div class='formDiv'>
          <div class="form-group col-sm-5">
            <label>Hotel Chain Name*</label>
            <input type="text" placeholder="" formControlName="hotelChainName" class='form-control' [ngClass]="hotelChain.get('hotelChainName').hasError('required') && hotelChain.get('hotelChainName').touched ? 'err':''"
              (focus)="errorDetails = ''">
          </div>
          <div class="form-group col-sm-5">
            <label>Email ID*</label>
            <input type="email" placeholder="" formControlName="contactEmail" class='form-control' [ngClass]="(hotelChain.get('contactEmail').hasError('pattern') || hotelChain.get('contactEmail').hasError('required')) && hotelChain.get('contactEmail').touched?'err':''"
              (focus)="errorDetails = ''">
          </div>
        </div>
        <div class='formDiv'>
          <div class="form-group col-sm-5">
            <label>First Name*</label>
            <input type="text" placeholder="" formControlName="firstName" class='form-control' [ngClass]="hotelChain.get('firstName').hasError('required') && hotelChain.get('firstName').touched ? 'err':''"
              (focus)="errorDetails = ''">
          </div>
          <div class="form-group col-sm-5">
            <label>Last Name*</label>
            <input type="text" placeholder="" formControlName="lastName" class='form-control' [ngClass]="hotelChain.get('lastName').hasError('required') && hotelChain.get('lastName').touched ? 'err':''"
              (focus)="errorDetails = ''">
          </div>
        </div>
        <div class='formDiv'>
          <div class="form-group col-sm-5">
            <label>Phone Number</label>
            <input type="tel" placeholder="" formControlName="contactMobile" class='form-control' [ngClass]="hotelChain.get('contactMobile').hasError('pattern') || hotelChain.get('contactMobile').hasError('minlength') || hotelChain.get('contactMobile').hasError('maxlength')?'err':''"
              (focus)="errorDetails = ''">
            <!-- <div class="error" style="width:288px;margin-top:0" *ngIf="hotelChain.get('phoneNumber').hasError('pattern')">
                  Incorrect Phone number
              </div> -->
          </div>
          <div class="form-group col-sm-5">
            <label>Address</label>
            <textarea formControlName="address" class='form-control' rows="3" (focus)="errorDetails = ''"></textarea>
          </div>
        </div>
        <div class='formDiv'>
          <div class="form-group col-sm-5 logo-container row">
            <div class='col-xs-7'>
              <h5>Hotel Chain Logo</h5>
              <!-- <h5 class='size'>(72x72 or 144x144)</h5> -->
              <label class="image-name">{{imageFile.imageUrl ? imageFile.name : '(no file chosen)'}}</label>
              <input type="file" id="file_upload" class="form-control" #uploadFile (change)="uploadImage($event)" (focus)="errorDetails = ''">
              <button (click)="uploadFile.click()" class="btn-file">{{imageFile.imageUrl ? 'Change File' : 'Choose File'}}</button>
              <p *ngIf="errorUpload" class="error upload col-xs-12" (click)="imageFile.errorUpload = false; errorUpload = false;">Please upload images only.</p>
              <p *ngIf="fileSizeError" class="error upload col-xs-12" (click)="imageFile.fileSizeError = false; fileSizeError = false;">Image size should be less than 1MB.</p>
            </div>
            <div class='col-xs-5 text-right' *ngIf="imageFile && imageFile.name && imageFile.imageUrl && !imageFile.errorUpload">
              <div>
                <img class='image' [src]="imageFile.imageUrl" /></div>
                <button class='btn btn-default delete-image'>
                  <img src='assets/images/delete.svg' (click)="deleteImage()"/>
                </button>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class='buttonsDiv text-right col-sm-10'>
      <button [routerLink]="['/hca-management']" class='btn btn-default col-sm-3 col-xs-12'>Cancel</button>
      <button (click)="save()" class='btn btn-default col-sm-3 col-xs-12' [disabled]="!hotelChain.valid">Save</button>
    </div>
    <div class="error col-xs-12" *ngIf="errorDetails">{{errorDetails}}</div>
  </div>

  <div class="loader-container" [class.loader-hidden]="!showLoader">
    <div class="loader-overlay">
      <div>
          <img class="loader" src="/assets/images/ajax-reload.gif">
          <p class="loading-text">&nbsp;Hotel chain creation is in progress</p>
      </div>
    </div>
  </div>
</div>