<div class='col-sm-10 col-sm-offset-2 members-container'>
  <div>
    <h3 class="heading">Members</h3>
  </div>

  <div class="row menuDiv" *ngIf="!loadingMembers">
    <!-- filter dropdown start -->
    <div class='col-sm-4 dropdown filter-btn filter'>
      <form [formGroup]="membersForm">
        <button class='btn btn-default dropdown-toggle filter filterBtnWidth' data-toggle="dropdown" (click)='dropDown($event)' [ngClass]="membersForm.get('brands').value.length || membersForm.get('origins').value.length || (membersForm.get('statusId').value && membersForm.get('statusId').value != '-1') || (membersForm.get('isActionRequired').value && membersForm.get('isActionRequired').value != 'null') || membersForm.get('range').value ? 'on' : 'off'"
          [disabled]="">
          <img class="filter" *ngIf="!membersForm.get('brands').value.length && !membersForm.get('origins').value.length && (membersForm.get('statusId').value && membersForm.get('statusId').value == '-1') && (!membersForm.get('isActionRequired').value || membersForm.get('isActionRequired').value == 'null' ) && !membersForm.get('range').value"
            src="assets/images/filter-inactive.svg" />
          <img class="filter" *ngIf="membersForm.get('brands').value.length || membersForm.get('origins').value.length || (membersForm.get('statusId').value && membersForm.get('statusId').value != '-1') || (membersForm.get('isActionRequired').value && membersForm.get('isActionRequired').value != 'null' ) || membersForm.get('range').value"
            src="assets/images/filter-active.svg" />
          <span class="filter">
            <span>Filter {{!membersForm.get('brands').value.length && !membersForm.get('origins').value.length && (membersForm.get('statusId').value
              && membersForm.get('statusId').value == '-1') && (!membersForm.get('isActionRequired').value || membersForm.get('isActionRequired').value
              == 'null') && !membersForm.get('range').value ? '(Off)' : '(On)'}} {{!membersForm.get('brands').value.length
              && !membersForm.get('origins').value.length && (membersForm.get('statusId').value && membersForm.get('statusId').value
              == '-1') && (!membersForm.get('isActionRequired').value || membersForm.get('isActionRequired').value == 'null')
              && !membersForm.get('range').value ? '' : totalCount+' result(s)'}}</span>
          </span>
        </button>
        <ul class="dropdown-menu filter-menu" id='members-filter'>
          <li class='form-group'>
            <a>
              <label>Account Status</label>
              <select class='form-control' formControlName="statusId" *ngIf="memberRulesList">
                <option value="-1">All</option>
                <option *ngFor="let status of memberRulesList.status" [value]="status.statusId">{{status.statusDesc}}</option>
              </select>
            </a>
          </li>
          <li class='divider'></li>
          <!-- <li class='form-group'>
            <a>
              <label>Origins</label>
              <select class='form-control' formControlName="origin" *ngIf="memberRulesList">
                <option *ngFor="let origin of memberRulesList.origins" [value]="origin.sourceId" >{{origin.name}}</option>
              </select>
            </a>
          </li> -->
          <li class='form-group dropdown'>
            <a>
              <label>Origin</label>
              <div class='form-control dropdown-toggle brand-select' data-toggle='dropdown'>
                {{membersForm.get('origins').value.length}} Origin{{membersForm.get('origins').value.length != 1 ? 's' : ''}} selected
              </div>
              <ul class='dropdown-menu checkbox-list' *ngIf="memberRulesList">
                <li class='check-list' (click)='dropDown($event)' *ngFor="let origin of memberRulesList.origins;let i = index;">
                  <input type='checkbox' [id]='"origin"+origin.sourceId' name='origin' value="'origin'+origin.sourceId" (change)="checkSelection($event.target.checked, 'membersForm', 'origin', origin.sourceId);"
                  />
                  <label [for]='"origin"+origin.sourceId'>
                    <span class='ip-text'>{{origin.name}}</span>
                    <span class='check-box'></span>
                  </label>
                </li>
              </ul>
            </a>
          </li>
          <li class='divider'></li>
          <li class='form-group'>
            <a>
              <label>Action Required</label>
              <select class='form-control' formControlName="isActionRequired">
                <option value="null">All</option>
                <option value="true">true</option>
                <option value="false">false</option>
              </select>
            </a>
          </li>
          <li class='divider'></li>
          <li class='form-group dropdown'>
            <a>
              <label>Brand</label>
              <div class='form-control dropdown-toggle brand-select' data-toggle='dropdown'>
                {{membersForm.get('brands').value.length}} Brand{{membersForm.get('brands').value.length != 1 ? 's' : ''}} selected
              </div>
              <ul class='dropdown-menu checkbox-list' *ngIf="brandsList">
                <li class='check-list' (click)='dropDown($event)' *ngFor="let brand of brandsList;let i = index;">
                  <input type='checkbox' [id]='"brand"+brand.brandId' name='brand' value="'brand'+brand.brandId" (change)="checkSelection($event.target.checked, 'membersForm', 'brand', brand.brandId);"
                  />
                  <label [for]='"brand"+brand.brandId'>
                    <span class='ip-text'>{{brand.brandName}}</span>
                    <span class='check-box'></span>
                  </label>
                </li>
              </ul>
            </a>
          </li>

          <li class='divider'></li>
          <li class='form-group dropdown' [ngClass]="brandHotels.length <= 0 ? 'disbaleList' : '' ">
            <a>
              <label>Hotel</label>
              <div class="form-control custom-dropdown">
                <div class="btn-group col-xs-12 padding-0 select-icon" dropdown #dropdown="bs-dropdown" [autoClose]="true">
                  <button dropdownToggle type="button" class="btn dropdown-toggle form-control"
                  [ngClass]="{disabled : brandHotels.length <= 0}">
                    {{getSelectedStr(membersForm.get('hotelIds').value.length, 'Hotel')}}
                  </button>
                  <ul id="dropdown-triggers-manual" *dropdownMenu class="dropdown-menu checkbox-list" role="menu" aria-labelledby="button-triggers-manual">
                    <li *ngFor="let hotel of brandHotels" role="menuitem" class="hand dropdown-item check-list">
                      <input type='checkbox' [id]='"hotel"+hotel.hotelId' name='hotel' [value]="hotel.hotelId" [checked]="membersForm.get('hotelIds').value.indexOf(hotel.hotelId) >= 0"
                        (change)="checkSelected($event.target.checked, 'hotelIds', 'hotelId', hotel.hotelId)" />
                      <label [for]='"hotel"+hotel.hotelId'>
                        <span class='ip-text'>{{hotel.hotelName}}</span>
                        <span class='check-box'></span>
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
            </a>
          </li>

          <li class='form-group'>
            <a class="close-position">
              <label>Created Between</label>
              <input type="text" placeholder="Created between" placement="top" class="form-control search-box" onkeydown='return event.which == 8 || event.which == 46 || event.which == 37 || event.which == 39'
                bsDaterangepicker formControlName="range">
              <button class="close-icon" (click)="clearDate()"></button>
            </a>
          </li>
          <li class='divider'></li>
        </ul>
      </form>
    </div>
    <!-- filter dropdown end -->

    <div class="col-sm-4 search-text">
      <span>{{totalCount}} results</span>
    </div>
    <div class='col-sm-4 form-group'>
      <form [formGroup]="searchForm">
        <div class="flex">
          <input
            type="search"
            class="form-control"
            placeholder="Search Members"
            formControlName="searchTerm"
          />
          <button (click)="searchTerm()" class='btn btn-default margin-left5 activeBtn'>
            {{'BUTTONS.SEARCH' | translate}}
          </button>
        </div>
      </form>
  </div>
</div>

<!-- Empty state -->
<div class="message text-center" *ngIf="loadingMembers">
  <h3>Loading members. Wait a moment, please...</h3>
</div>

<div class="message text-center" *ngIf="!membersList.length && !loadingMembers">
  <h3>No records found</h3>
</div>

<div class="table-container" *ngIf="membersList.length && !loadingMembers">
  <div class="table-responsive">
    <table class="table table-catalog ">
      <thead>
        <tr>
          <!-- <th class='table-checkbox'> -->
          <!-- <input type='checkbox' id='promo0' name='promo' value="promo0" />
              <label for='promo0' class='margin-0'>
                <span class='check-box'></span>
              </label> -->
          <!-- </th> -->
          <th>Name</th>
          <th>Email/Username</th>
          <th>Last Login</th>
          <th>Creation Date</th>
          <th>Origin</th>
          <th>Card Code</th>
          <th>Coins available</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let member of membersList | paginate: { itemsPerPage: size, currentPage: p, totalItems: totalCount }">
          <!-- <td class='table-checkbox'> -->
          <!-- <input type='checkbox' id='promo' name='promo' />
              <label for='promo'>
                <span class='check-box'></span>
              </label> -->
          <!-- </td> -->
          <td>
            <span class="error-badge col-sm-1 pull-left" *ngIf="member?.profileUpdate">
              <img class="Error pull-left" src="assets/images/error.svg">
            </span>
            <span class="member-name col-sm-10 padding-0 pull-left">
              &nbsp;{{utilService.getFullName(member)}}
            </span>
          </td>
          <td>{{member.email}}</td>
          <td>{{member.lastAccessDate}}</td>
          <td>{{member.createdDateStr}}</td>
          <td>{{member.sourceName}}</td>
          <td>{{member.cardCode}}</td>
          <td>{{member?.memberPoints?.totalPoints}}</td>
          <!-- <td>{{member.statusId.statusDesc}}</td> -->
          <td class="">
            <div class='dropdown status' [ngClass]="(member.statusId.statusDesc == 'Repeated user' || member.statusId.statusDesc == 'Duplicate') ? 'duplicate' : ''">
              <span [ngClass]="member.statusId.statusDesc =='Active' ? 'activate' : 'inactivate'">{{member.statusId.statusDesc}}</span>
              <button type='button' class='btn btn-link dropdown-toggle' data-toggle="dropdown">
                <img src='assets/images/overflow.svg' />
              </button>
              <ul class="dropdown-menu statusList">
                <li class="pointer">
                  <a [routerLink]="['modify', member.loyaltyMemberId, 'booking']">
                    <img class='pull-left' src='assets/images/edit.svg' />
                    <span class='pull-left'>Modify</span>
                  </a>
                </li>
                <li class="pointer">
                  <a class='activate' *ngIf="member.statusId.statusId == 0 || member.statusId.statusId == 7" (click)="openModalActivateDeactivate(member.loyaltyMemberId,'Inactive', member)">
                    <img class='pull-left' src='assets/images/success.svg' />
                    <span class='pull-left'>Activate</span>
                  </a>
                </li>
                <li class="pointer">
                  <a class='deactivate' *ngIf="member.statusId.statusId == 1 || member.statusId.statusId == 7" (click)="openModalActivateDeactivate(member.loyaltyMemberId,'Active', member)">
                    <img class='pull-left' src='assets/images/delete.svg' />
                    <span class='pull-left'>Deactivate</span>
                  </a>
                </li>
                <li class="pointer" *ngIf="member.statusId.statusId == 1">
                  <a (click)="openModalEmail(member.loyaltyMemberId,'welcome', email)">
                    <img class='pull-left' src='assets/images/action-inactive.svg' />
                    <span class='pull-left'>Send Welcome Email</span>
                  </a>
                </li>
                <li class="pointer" *ngIf="member.statusId.statusId == 0">
                  <a (click)="openModalEmail(member.loyaltyMemberId,'verify', email)">
                    <img class='pull-left' src='assets/images/action-inactive.svg' />
                    <span class='pull-left'>Send Verification Email</span>
                  </a>
                </li>
                <li class="pointer" *ngIf="member.statusId.statusId != 7">
                  <a (click)="openModalActivateDeactivate(member.loyaltyMemberId,'duplicated', member)">
                    <!-- <img class='pull-left' src='assets/images/action-inactive.svg' /> -->
                    <i class="glyphicon glyphicon-repeat repeatIcon pull-left" aria-hidden="true"></i>
                    <span class='pull-left'>Duplicated</span>
                  </a>
                </li>
              </ul>
            </div>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td class="tfooter" colspan="8">
            <pagination-controls class="pull-right" (pageChange)="pageChanged($event)"></pagination-controls>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</div>
</div>
