<div class="transaction-container">
  <div class="row">
    <div class="col-sm-6 dropdown">
    </div>
    <div class="col-sm-6 text-right">
      <button class="btn btn-add-transaction" (click)="openModalAddTransaction()">
        <img src='assets/images/plus.svg' class="pull-left" />
        <span>Add Transaction</span>
      </button>
    </div>
  </div>

  <div class="table-responsive">
    <h3 *ngIf="!transactionList?.length" class="text-center">No Transaction History found</h3>
    <table class="table transaction-table" *ngIf="transactionList?.length">
      <thead>
        <tr>
          <th>Created at/Status</th>
          <th>Locator</th>
          <th>Transtype/Hotel</th>
          <th>Coins</th>
          <th>Cash Paid</th>
          <th>Quantity</th>
          <th>Nights</th>
          <th>Updated at</th>
          <th>Member Rate</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let transaction of transactionList | paginate: { itemsPerPage: size, currentPage: p, totalItems: totalCount }">
          <td>
            <span>{{transaction.transactionDateStr}}</span>
            <br/>
            <span [ngClass]="(transaction.status?.statusId == 4 || transaction.status?.statusId == 0 || transaction.status?.statusId == 6) ? 'inactive' : (transaction.status?.statusId == 3 ? 'pending' : 'active')">{{transaction.status?.statusDesc}}</span>
          </td>
          <td>{{transaction.locator ? transaction.locator : 'N/A'}}</td>
          <td>
            <span>{{transaction.description}}</span>
            <br/>
            <span>{{transaction.hotelName}}</span>
            <br/>
            <span *ngIf="transaction.productName && transaction.productName.indexOf('Generic discount 0%') === -1">{{transaction.productName}} </span>
            <br>
            <span *ngIf="(transaction.status?.statusId == 2 || transaction.status?.statusId == 5) && transaction.pointsTransTypeId == 2" (click)="voucherDownload(transaction?.pointTransactionId)" class="like_anchor">Download the Voucher</span>
          </td>
          <td>{{transaction.points ? transaction.points : 0}}</td>
          <td>${{transaction.cashPaid ? transaction.cashPaid : 0}}</td>
          <td>{{transaction.quantity ? transaction.quantity : 0}}</td>
          <td>{{transaction.nights ? transaction.nights : 'N/A'}}</td>
          <td>{{transaction.updatedDateStr}}</td>
          <td>{{transaction.memberRate ? transaction.memberRate : 'N/A'}}</td>
          <td class="dropdown">
            <button class="btn btn-link dropdown-toggle" data-toggle='dropdown'>
              <img src='assets/images/overflow.svg' />
            </button>
            <ul class="dropdown-menu custom-dropdown-menu pointer">
              <li *ngIf="transaction.status?.statusId == 2 || transaction.status?.statusId == 4 || transaction.status?.statusId == 5" (click)="openStatusModal(transaction?.pointTransactionId, 3, 'Cancel')">
                <a class="inactive">Cancel</a>
              </li>
              <ng-container *ngIf="transaction.pointsTransTypeId !== 3 || transaction.pointsTransTypeId !== 4 ">
                <li *ngIf="transaction.status?.statusId == 3" (click)="openStatusModal(transaction?.pointTransactionId, 5, 'Approve')">
                  <a class="active">Approve</a>
                </li>
              </ng-container>
              <li *ngIf="transaction.status?.statusId == 3" (click)="openStatusModal(transaction?.pointTransactionId, 4, 'Reject')">
                <a class="inactive">Reject</a>
              </li>
              <li *ngIf="transaction.status?.statusId == 6" (click)="openDetailsModal(transaction?.notes)">
                <a class="active">View Details</a>
              </li>
              <li *ngIf="transaction?.isDiscountCheck == true" (click)="checkDiscountStatus(transaction?.pointTransactionId)">
                <a class="active">Check Status</a>
              </li>
              <li *ngIf="transaction.status?.statusId != 0" (click)="openStatusModal(transaction?.pointTransactionId, 0, 'Delete')">
                <a class="inactive">Delete</a>
              </li>
              <!-- <li *ngIf="transaction.status?.statusId == 0" (click)="openStatusModal(transaction?.pointTransactionId, 1)">
                <a class="active">Activate Transaction</a>
              </li> -->
              <!-- <li>
                <a>Mark as valid reservation</a>
              </li>
              <li>
                <a>Send voucher to client</a>
              </li>
              <li>
                <a>Confirm transactions, exchange points, pending and send voucher</a>
              </li> -->
            </ul>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td class="tfooter" colspan="9"> <pagination-controls class="pull-right"  (pageChange)="getTransactionHistory($event)"></pagination-controls></td>
        </tr>
      </tfoot>
    </table>
  </div>
</div>
