<div class="modal-header" *ngIf="srcObject.mode == 'custom'">
  <h3 class="modal-title" id="exampleModalLabel">{{srcObject.title}}</h3>
  <p xngIf="(srcObject.status == 'Active' || srcObject.status == null)">{{srcObject.message}}</p>
  <input class="form-control" autocomplete="off" id="dText" [(ngModel)]="duplicatedValue" *ngIf="srcObject.status == 'Duplicated'" (keyup)="trimText($event)"/>
</div>
<div class="modal-footer text-center" *ngIf="srcObject.mode == 'custom'">  
  <button type="button" class="btn btn-default" id="deleteModal" data-dismiss="modal" (click)="confirmClose()">{{srcObject.action2}}</button>
  <button *ngIf="srcObject.status=='Duplicated'" [disabled]="!duplicatedValue || duplicatedValue == ''" type="button" class="btn btn-default" [ngClass]="srcObject.style == 'active'?'active':'inactive'"  data-dismiss="modal" (click)="action1()">{{srcObject.action1}}</button>
  <button *ngIf="srcObject.status!='Duplicated'" type="button" class="btn btn-default" [ngClass]="srcObject.style == 'active'?'active':'inactive'"  data-dismiss="modal" (click)="action1()">{{srcObject.action1}}</button>
</div>
