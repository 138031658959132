<div class='col-sm-9 col-sm-offset-3 employees-container'>
  <div class='row header'>
    <div class="col-sm-5 heading">
      <h3 class="h">Employees</h3>
    </div>
    <div class='col-sm-7 buttonsDiv text-right'>
      <button class='btn btn-default btn-add-employee import' (click)="importEmployeesModal()">
        <i class="glyphicon glyphicon-open" aria-hidden="true"></i> Import
      </button>
      <button class='btn btn-default btn-add-employee' [routerLink]="['add']">
        <img src='assets/images/plus-white.svg' class='pull-left' /> New Employee
      </button>
    </div>
  </div>

  <div class='row menuDiv'>
    <!-- filter dropdown start -->
    <div class='col-sm-3 dropdown filter-btn filter'>
      <form [formGroup]="filterForm">
        <button class='btn btn-default dropdown-toggle filter' [disabled]="!isFiltered && !employeesList.length" [ngClass]="isFilterOn ? 'on' : 'off'"
          data-toggle="dropdown" (click)='dropDown($event)'>
          <span class="filter">
            <span>Filter {{isFilterOn ? '(On)' : '(Off)'}} &nbsp;{{isFilterOn ? (totalCount ? totalCount+' result(s)' : '0 result(s)')
              : ''}}
            </span>
          </span>
        </button>
        <ul class="dropdown-menu filter-menu" id='ul'>
          <li class='form-group'>
            <a>
              <label>Active</label>
              <select class='form-control' formControlName="status">
                <option value="" selected>All</option>
                <option [value]="status.statusId" *ngFor="let status of filterLookUpData?.status">{{status.statusDesc}}</option>
              </select>
            </a>
          </li>
          <li class='divider'></li>
          <li class='form-group'>
            <a>
              <label>Department</label>
              <div class="form-control custom-dropdown">
                <div class="btn-group col-xs-12 padding-0 select-icon" dropdown #dropdown="bs-dropdown" [autoClose]="true">
                  <button dropdownToggle type="button" [title]="filterForm.get('department').value" class="btn dropdown-toggle form-control">
                    {{filterForm.get('department').value ? filterForm.get('department').value : 'All'}}
                  </button>
                  <ul id="dropdown-triggers-manual" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-triggers-manual">
                    <li role="menuitem" class="hand dropdown-item" (click)="selectedValue('department', '')">
                      <a class="dropdown-item" [class.active]="filterForm.get('department').value == ''">All</a>
                      <!-- <p class='divider'></p> -->
                    </li>
                    <li *ngFor="let department of filterLookUpData?.department; let i = index;" role="menuitem" class="hand dropdown-item" (click)="selectedValue('department', department)">
                      <a class="dropdown-item" [class.active]="filterForm.get('department').value == department">{{department}}</a>
                      <!-- <p class='divider' *ngIf=""></p> -->
                    </li>
                  </ul>
                </div>
              </div>
            </a>
            <!-- <a>
              <label>Department</label>
              <select class='form-control' formControlName="department">
                <option value="" selected>All</option>
                <option [value]="department" *ngFor="let department of filterLookUpData?.department">{{department}}</option>
              </select>
            </a> -->
          </li>
          <li class='divider'></li>
          <li class='form-group'>
            <a>
              <label>Position</label>
              <div class="form-control custom-dropdown">
                <div class="btn-group col-xs-12 padding-0 select-icon" dropdown #dropdown="bs-dropdown" [autoClose]="true">
                  <button dropdownToggle type="button" [title]="filterForm.get('position').value" class="btn dropdown-toggle form-control">
                    {{filterForm.get('position').value ? filterForm.get('position').value : 'All'}}
                    <!-- Position -->
                  </button>
                  <ul id="dropdown-triggers-manual" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-triggers-manual">
                    <li role="menuitem" class="hand dropdown-item" (click)="selectedValue('position', '')">
                      <a class="dropdown-item" [class.active]="filterForm.get('position').value == ''">All</a>
                    </li>
                    <li *ngFor="let position of filterLookUpData?.position" role="menuitem" class="hand dropdown-item" (click)="selectedValue('position', position)">
                      <a class="dropdown-item" [class.active]="filterForm.get('position').value == position">{{position}}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </a>
            <!-- <a>
              <label>Position</label>
              <select class='form-control' formControlName="position">
                <option value="" selected>All</option>
                <option [value]="position" *ngFor="let position of filterLookUpData?.position">{{position}}</option>
              </select>
            </a> -->
          </li>
          <li class='divider'></li>
          <li class='form-group'>
            <a>
              <label>Area</label>
              <div class="form-control custom-dropdown">
                <div class="btn-group col-xs-12 padding-0 select-icon" dropdown #dropdown="bs-dropdown" [autoClose]="true">
                  <button dropdownToggle type="button" [title]="filterForm.get('area').value" class="btn dropdown-toggle form-control">
                    {{filterForm.get('area').value ? filterForm.get('area').value : 'All'}}
                  </button>
                  <ul id="dropdown-triggers-manual" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-triggers-manual">
                    <li role="menuitem" class="hand dropdown-item" (click)="selectedValue('area', '')">
                      <a class="dropdown-item" [class.active]="filterForm.get('area').value == ''">All</a>
                    </li>
                    <li *ngFor="let area of filterLookUpData?.area" role="menuitem" class="hand dropdown-item" (click)="selectedValue('area', area)">
                      <a class="dropdown-item" [class.active]="filterForm.get('area').value == area">{{area}}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </a>
            <!-- <a>
              <label>Area</label>
              <select class='form-control' formControlName="area">
                <option value="" selected>All</option>
                <option [value]="area" *ngFor="let area of filterLookUpData?.area">{{area}}</option>
              </select>
            </a> -->
          </li>
          <li class='divider'></li>
          <li class='form-group dropdown'>
            <a>
                <label>Hotel</label>
                <div class="form-control custom-dropdown">
                    <div class="btn-group col-xs-12 padding-0 select-icon" dropdown #dropdown="bs-dropdown" [autoClose]="true">
                      <button dropdownToggle type="button" class="btn dropdown-toggle form-control">
                          {{(filterForm.get('hotelIds').value.indexOf(0) > -1) ? 'All Hotels Selected' : getSelectedStr(filterForm.get('hotelIds').value.length,'Hotel')}}
                      </button>
                      <ul id="dropdown-triggers-manual" *dropdownMenu class="dropdown-menu checkbox-list" role="menu" aria-labelledby="button-triggers-manual">
                        <li role="menuitem" class="hand dropdown-item check-list">
                            <input type='checkbox' id='hotel0' name='hotel' value="hotel0" [checked]="" [checked]="filterForm.get('hotelIds').value.indexOf(0) > -1"
                            (change)="checkSelected($event.target.checked, 'hotelIds', 'hotels', 'hotelId', 0)" />
                          <label for='hotel0'>
                            <span class='ip-text'>ALL</span>
                            <span class='check-box'></span>
                          </label>
                        </li>
                        <li *ngFor="let hotel of filterLookUpData?.hotels" role="menuitem" class="hand dropdown-item check-list" (click)="selectedValue('area', area)">
                            <input type='checkbox' [id]='"hotel"+hotel.hotelId' name='hotel' [value]="hotel.hotelId" [checked]="filterForm.get('hotelIds').value.indexOf(hotel.hotelId) >= 0"
                            (change)="checkSelected($event.target.checked, 'hotelIds', 'hotels', 'hotelId', hotel.hotelId)" />
                          <label [for]='"hotel"+hotel.hotelId'>
                            <span class='ip-text'>{{hotel.hotelName}}</span>
                            <span class='check-box'></span>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>
              <!-- <label>Hotel</label>
              <div class='form-control dropdown-toggle brand-select' data-toggle='dropdown' [ngClass]="">
                {{(filterForm.get('hotelIds').value.indexOf(0) > -1) ? 'All Hotels Selected' : getSelectedStr(filterForm.get('hotelIds').value.length,'Hotel')}}
              </div>
              <ul class='dropdown-menu checkbox-list'>
                <li class='check-list'>
                  <input type='checkbox' id='hotel0' name='hotel' value="hotel0" [checked]="" [checked]="filterForm.get('hotelIds').value.indexOf(0) > -1"
                    (change)="checkSelected($event.target.checked, 'hotelIds', 'hotels', 'hotelId', 0)" />
                  <label for='hotel0'>
                    <span class='ip-text'>ALL</span>
                    <span class='check-box'></span>
                  </label>
                </li>
                <li class='check-list' *ngFor="let hotel of filterLookUpData?.hotels">
                  <input type='checkbox' [id]='"hotel"+hotel.hotelId' name='hotel' [value]="hotel.hotelId" [checked]="filterForm.get('hotelIds').value.indexOf(hotel.hotelId) >= 0"
                    (change)="checkSelected($event.target.checked, 'hotelIds', 'hotels', 'hotelId', hotel.hotelId)" />
                  <label [for]='"hotel"+hotel.hotelId'>
                    <span class='ip-text'>{{hotel.hotelName}}</span>
                    <span class='check-box'></span>
                  </label>
                </li>
              </ul> -->
            </a>
          </li>
          <li class='divider'></li>
          <li class='form-group'>
            <a class="close-position">
              <label>Created Between</label>
              <input type="text" placeholder="Created between" placement="top" formControlName="range" class="form-control search-box"
                onkeydown='return event.which == 8 || event.which == 46 || event.which == 37 || event.which == 39' bsDaterangepicker>
              <button class="close-icon" (click)="clearDate()"></button>
            </a>
          </li>
        </ul>
      </form>
    </div>
    <!-- filter dropdown end -->

    <!-- Action dropdown start -->
    <div class='col-sm-4 dropdown filter-btn'>
      <button [disabled]="!selectedEmployees.length" [ngClass]="selectedEmployees.length ? 'on' : 'off'" class='btn btn-default dropdown-toggle btn-action'
        data-toggle="dropdown">
        <span>
          <span>Actions</span>
          <span class='glyphicon glyphicon-chevron-down'></span>
        </span>
      </button>
      <ul class="dropdown-menu action-menu">
        <li class='plus pointer' (click)="openStatusModal(this.selectedEmployees, 1)">
          <a class="activate">
            <img src='assets/images/success.svg' class='pull-left' />
            <span class='pull-left activate'>Activate Selected Employees</span>
          </a>
        </li>
        <li class='plus pointer' (click)="openStatusModal(this.selectedEmployees, 0)">
          <a class="delete">
            <img src='assets/images/delete.svg' class='pull-left' />
            <span class='pull-left deactivate'>Deactivate Selected Employees</span>
          </a>
        </li>
      </ul>
      <span [class.hide-ele]="" class='action-text' [hidden]="!isFiltered && !employeesList.length">{{selectedEmployees.length}} of {{employeesList.length}} selected</span>
    </div>
    <!-- Action dropdown end -->

    <div class='col-sm-2 search-text text-right' [ngClass]="" [hidden]="!isFiltered && !employeesList.length">
      <span>{{totalCount ? totalCount : 0}} results</span>
    </div>
    <form [formGroup]="searchForm">
      <div class="col-sm-3 form-group pull-right">
        <input type='search' class='form-control'
               placeholder="Search Employees"
               formControlName="searchTerm" />
      </div>
    </form>
  </div>

  <!-- rewards empty state with filters -->
  <div class='message text-center' *ngIf="isFiltered && !employeesList.length">
    <h3>No results found</h3>
  </div>

  <!-- Empty state -->
  <div class='message text-center' *ngIf="!isFiltered && !employeesList.length">
    <h3>You haven’t created any employees yet. Click on “+ New Employee” above to get started.</h3>
  </div>

  <div class="table-responsive" *ngIf="employeesList.length">
    <table class="table row  table-catalog ">
      <thead>
        <tr>

          <th class='table-checkbox'>
            <input type='checkbox' id='employee0' name='employee' value="employee0" [(ngModel)]="allEmployeesSelected" (change)="selectEmployees('all')"
            />
            <label for='employee0' class='margin-0'>
              <span class='check-box'></span>
            </label>
          </th>
          <th *ngFor="let header of tableHeader; let idx = index" class="pointer" (click)="sortTable(header.property)">{{header.key}}
            <span class="sort-icons up pointer" *ngIf="header.direction != null && header.direction == 'asc'"> &#9660;</span>
            <span class="sort-icons down pointer" *ngIf="header.direction != null && header.direction == 'desc'"> &#9650;</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let employee of employeesList | paginate: { itemsPerPage: size, currentPage: p, totalItems: totalCount }">
          <td class='table-checkbox'>
            <input type='checkbox' [id]='"employee"+employee.loyaltyMemberId' name='promo' value="'employee'+employee.loyaltyMemberId" [(ngModel)]="employee.checked"
              (change)="selectEmployees('single')" />
            <label [for]='"employee"+employee.loyaltyMemberId'>
              <span class='check-box'></span>
            </label>
          </td>
          <td>{{employee.empCode}}</td>
          <td>{{employee.email}}</td>
          <td>{{employee?.firstName}}</td>
          <td>{{employee?.lastName}}</td>
          <td>{{employee?.hotelName ? employee?.hotelName : 'N/A'}}</td>
          <td class="">
            <div class='dropdown status'>
              <span [ngClass]="employee.statusId?.statusId == 1 ? 'activate' : 'inactivate'">
                {{employee.statusId?.statusId == 1 ? 'Active' : 'Inactive'}}
              </span>
              <button type='button' class='btn btn-link dropdown-toggle' data-toggle="dropdown">
                <img src='assets/images/overflow.svg' />
              </button>
              <ul class="dropdown-menu statusList">
                <li class="pointer">
                  <!-- <a [routerLink]="['edit', employee.loyaltyMemberId]"> -->
                  <a [routerLink]="['edit', employee.loyaltyMemberId, 'guest-booking']">
                    <img class='pull-left' src='assets/images/edit.svg' />
                    <span class='pull-left'>Modify</span>
                  </a>
                </li>
                <li class="pointer">
                  <a class='deactivate' *ngIf="employee.statusId?.statusId == 1" (click)="openStatusModal([employee.loyaltyMemberId], !employee.statusId?.statusId)">
                    <img class='pull-left' src='assets/images/delete.svg' />
                    <span class='pull-left'>Deactivate</span>
                  </a>
                </li>
                <li class="pointer">
                  <a class='activate' *ngIf="employee.statusId?.statusId == 0" (click)="openStatusModal([employee.loyaltyMemberId], !employee.statusId?.statusId)">
                    <img class='pull-left' src='assets/images/success.svg' />
                    <span class='pull-left'>Activate</span>
                  </a>
                </li>
              </ul>
            </div>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td class="tfooter" colspan="7">
            <pagination-controls class="pull-right" (pageChange)="pageChanged($event)"></pagination-controls>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</div>
