<div class='col-sm-9 col-sm-offset-3 promotions-container'>
  <div class='row header'>
    <div class="col-sm-5 heading">
      <h3 class="h">Promotions</h3>
    </div>
    <div class='col-sm-7 buttonsDiv text-right'>
      <button class='btn btn-default btn-new-promo' [routerLink]="['add']">
        <img src='assets/images/plus-white.svg' class='pull-left' alt=""/> New Promo
      </button>
    </div>
  </div>

  <div class='row menuDiv'>
    <!-- filter dropdown start -->
    <div class='col-sm-3 dropdown filter-btn filter'>
      <form [formGroup]="promotionsForm">
        <button class='btn btn-default dropdown-toggle filter' data-toggle="dropdown" (click)='dropDown()'
                [ngClass]="(promotionsForm.get('brandIds').value.indexOf(0) > -1 && !promotionsForm.get('isActive').value && !promotionsForm.get('promoCodeTypeId').value && promotionsForm.get('hotelIds').value.indexOf(0) > -1) || (isInit && !promoList.length) ? 'off' : 'on'"
                [disabled]="((promotionsForm.get('brandIds').value.indexOf(0) > -1 && !promotionsForm.get('isActive').value && !promotionsForm.get('promoCodeTypeId').value && promotionsForm.get('hotelIds').value.indexOf(0) > -1) && !promoList.length)">
          <img class="filter"
               [src]="(promotionsForm.get('brandIds').value.indexOf(0) > -1 && !promotionsForm.get('isActive').value && !promotionsForm.get('promoCodeTypeId').value && promotionsForm.get('hotelIds').value.indexOf(0) > -1) ? 'assets/images/filter-inactive.svg' : 'assets/images/filter-active.svg'" alt=""/>
          <span class="filter">
                    <span>Filter {{(promotionsForm.get('brandIds').value.indexOf(0) > -1 && !promotionsForm.get('isActive').value && !promotionsForm.get('promoCodeTypeId').value && promotionsForm.get('hotelIds').value.indexOf(0) > -1) ? '(Off)' : '(On)'}}
                      {{(promotionsForm.get('brandIds').value.indexOf(0) > -1 && !promotionsForm.get('isActive').value && !promotionsForm.get('promoCodeTypeId').value && promotionsForm.get('hotelIds').value.indexOf(0) > -1) ? '' : promoList.length + ' result(s)'}}
                    </span>
                </span>
        </button>
        <ul class="dropdown-menu filter-menu" id='ul'>
          <li class='form-group'>
            <a>
              <label>Active</label>
              <select class='form-control' formControlName="isActive" *ngIf="promoRulesList">
                <option value="" selected>All</option>
                <option *ngFor="let status of promoRulesList.status"
                        [value]="status.statusId">{{status.statusDesc}}</option>
              </select>
            </a>
          </li>
          <li class='divider'></li>
          <li class='form-group'>
            <a>
              <label>Promo Type</label>
              <select class='form-control' formControlName="promoCodeTypeId" *ngIf="promoRulesList">
                <option value="">All</option>
                <option *ngFor="let promo of promoRulesList.promoType"
                        [value]="promo.promoCodeTypeId">{{promo.name}}</option>
              </select>
            </a>
          </li>
          <li class='divider'></li>
          <li class='form-group'>
            <a>
              <label>Transaction Type</label>
              <select class='form-control' formControlName="transactionTypeId" *ngIf="promoRulesList">
                <option value="">All</option>
                <option *ngFor="let transaction of promoRulesList.transType"
                        [value]="transaction.id">{{transaction.transactionTypeEn}}</option>
              </select>
            </a>
          </li>
          <li class='divider'></li>
          <li class='form-group dropdown'>
            <a>
              <label>Brand</label>
              <div class='form-control dropdown-toggle brand-select' data-toggle='dropdown'>
                {{(promotionsForm.get('brandIds').value.indexOf(0) > -1) ? 'All Brands' : getSelectedStr(promotionsForm.get('brandIds').value.length, 'Brand')}}
              </div>
              <ul class='dropdown-menu checkbox-list' *ngIf="promoRulesList">
                <li class='check-list'>
                  <input type='checkbox' id='brand0' name='brand' value="brand0"
                         [checked]="promotionsForm.get('brandIds').value.indexOf(0) > -1"
                         (change)="checkSelection($event.target.checked, 'promotionsForm', 'brandIds', 0);"/>
                  <label for='brand0'>
                    <span class='ip-text'>ALL</span>
                    <span class='check-box'></span>
                  </label>
                </li>
                <li class='check-list' (click)='dropDown()' *ngFor="let brand of promoRulesList.brands;let i = index;">
                  <input type='checkbox' [id]='"brand"+brand.brandId' name='brand' value="'brand'+brand.brandId"
                         [checked]="promotionsForm.get('brandIds').value.indexOf(brand.brandId) >= 0"
                         (change)="checkSelection($event.target.checked, 'promotionsForm', 'brandIds', brand.brandId);"/>
                  <label [for]='"brand"+brand.brandId'>
                    <span class='ip-text'>{{brand.brandName}}</span>
                    <span class='check-box'></span>
                  </label>
                </li>
              </ul>
            </a>
          </li>
          <li class='divider'></li>
          <li class='form-group dropdown'>
            <a>
              <label>Hotel</label>
              <div class='form-control dropdown-toggle brand-select' data-toggle='dropdown'
                   [ngClass]="promotionsForm.get('brandIds').value.length == 0 ? 'disabled' : ''">
                {{(promotionsForm.get('brandIds').value.indexOf(0) > -1 && (promotionsForm.get('hotelIds').value.indexOf(0) > -1)) ? 'All Hotels' : getSelectedStr((promotionsForm.get('hotelIds').value.length > -1 ? promotionsForm.get('hotelIds').value.length : 0), 'Hotel')}}
              </div>
              <ul class='dropdown-menu checkbox-list'>
                <li class='check-list'>
                  <input type='checkbox' id='hotel0' name='hotel' value="hotel0"
                         [checked]="promotionsForm.get('hotelIds').value.indexOf(0) > -1"
                         (change)="checkSelection($event.target.checked, 'promotionsForm', 'hotelIds', 0);"/>
                  <label for='hotel0'>
                    <span class='ip-text'>ALL</span>
                    <span class='check-box'></span>
                  </label>
                </li>
                <li class='check-list' *ngFor="let hotel of hotels;let i = index;">
                  <input type='checkbox' [id]='"hotel"+hotel.hotelId' name='hotel' value="'hotel'+hotel.hotelId"
                         [checked]="promotionsForm.get('hotelIds').value.indexOf(hotel.hotelId) >= 0"
                         (change)="checkSelection($event.target.checked, 'promotionsForm', 'hotelIds', hotel.hotelId);"/>
                  <label [for]='"hotel"+hotel.hotelId'>
                    <span class='ip-text'>{{hotel.hotelName}}</span>
                    <span class='check-box'></span>
                  </label>
                </li>
              </ul>
            </a>
          </li>
        </ul>
      </form>
    </div>
    <!-- filter dropdown end -->
    <div class='col-sm-4 dropdown filter-btn '>
      <button [ngClass]="selectedPromos.length ? 'on' : 'off'" class='btn btn-default dropdown-toggle action-btn'
              data-toggle="dropdown" [disabled]="!selectedPromos.length">
        <img [src]="actionSvg" alt=""/>
        <span>
                    <span>Actions</span>
                    <span class='glyphicon glyphicon-chevron-down'></span>
                </span>
      </button>
      <ul class="dropdown-menu action-menu">
        <li class='plus pointer' (click)="SelectAllPromos('Inactive')">
          <a class="activate">
            <img src='assets/images/success.svg' class='pull-left' alt=""/>
            <span class='pull-left activate'>Activate Selected Promos</span>
          </a>
        </li>
        <li class='plus pointer' (click)="SelectAllPromos('Active')">
          <a class="delete">
            <img src='assets/images/delete.svg' class='pull-left' alt=""/>
            <span class='pull-left deactivate'>Deactivate Selected Promos</span>
          </a>
        </li>
      </ul>
      <span [class.hide-ele]="!promoList.length" class='action-text'>{{selectedPromos.length}} of {{promoList.length}}
        selected</span>
    </div>
    <div class='col-sm-2 search-text text-right'
         [ngClass]="(!(promoList | filter : searchText : 'promoName').length || !promoList.length) ? 'hide-ele' : ''">
      <span>{{(promoList | filter : searchText : 'promoName').length}} results ({{promoList.length}} total)</span>
    </div>
    <form [formGroup]="searchForm">
      <div class='col-sm-3 form-group'>
        <input type='search' class='form-control' formControlName="searchTerm" placeholder="Search Promotions"
               [disabled]="!promoList.length"/>
      </div>
    </form>
  </div>

  <!-- rewards empty state with filters -->
  <div class='message text-center' *ngIf='!filterDefault && !promoList.length'>
    <h3>No results found</h3>
  </div>
  <!-- rewards empty state with search -->

  <div class='message text-center'
       *ngIf="promoList.length && (promoList | filter : searchText : 'promoName').length === 0">
    <h3>No results found</h3>
  </div>

  <!-- Empty state -->
  <div class='message text-center' *ngIf='filterDefault && !promoList.length'>
    <h3>You haven’t created any promotions yet. Click on “+ New Promo” above to get started.</h3>
  </div>

  <div *ngIf='promoList.length' class="table-responsive">
    <table class="table row  table-catalog " *ngIf="(promoList | filter : searchText : 'promoName').length">
      <thead>
      <tr>
        <th class='table-checkbox'>
          <input type='checkbox' id='promo0' name='promo' value="promo0" [(ngModel)]="allPromosSelected"
                 (change)="selectAll('promo');"/>
          <label for='promo0' class='margin-0'>
            <span class='check-box'></span>
          </label>
        </th>
        <th>Promo Name</th>
        <th>Point Value</th>
        <th>Start Date</th>
        <th>End Date</th>
        <th>Transaction Type</th>
        <th>Type</th>
        <th>Status</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let promo of promoList | filter : searchText : 'promoName'">
        <td class='table-checkbox'>
          <input type='checkbox' [id]='"promo"+promo.promocodeId' name='promo' value="'promo'+promo.promocodeId"
                 [(ngModel)]="promo.checked" (change)="checkPromoSelection('promo');"/>
          <label [for]='"promo"+promo.promocodeId'>
            <span class='check-box'></span>
          </label>
        </td>
        <td>{{promo.promoName}} </td>
        <td>{{promo.pointValue}}</td>
        <td>{{promo.dateFromStr}}</td>
        <td>{{promo.dateToStr}}</td>
        <td>{{promo.transactionTypeNameEN}}</td>
        <td>{{promo.promoCodeTypeName}}</td>
        <td class="">
          <div class='dropdown status'>
            <span [ngClass]="promo.status=='Active' ? 'activate' : 'inactivate' ">{{promo.status}}</span>
            <button type='button' class='btn btn-link dropdown-toggle' data-toggle="dropdown">
              <img src='assets/images/overflow.svg' alt=""/>
            </button>
            <ul class="dropdown-menu statusList">
              <li class="pointer">
                <a [routerLink]="['edit', promo.promocodeId]">
                  <img class='pull-left' src='assets/images/edit.svg' alt=""/>
                  <span class='pull-left'>Modify</span>
                </a>
              </li>
              <li class="pointer">
                <a class='deactivate' *ngIf="promo.status == 'Active'"
                   (click)="openModalActivateDeactivate([promo.promocodeId], 'Active', promo)">
                  <img class='pull-left' src='assets/images/delete.svg' alt=""/>
                  <span class='pull-left'>Deactivate</span>
                </a>
              </li>
              <li class="pointer">
                <a class='activate' *ngIf="promo.status == 'Inactive'"
                   (click)="openModalActivateDeactivate([promo.promocodeId], 'Inactive', promo)">
                  <img class='pull-left' src='assets/images/success.svg' alt=""/>
                  <span class='pull-left'>Activate</span>
                </a>
              </li>
            </ul>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
