<div class='col-sm-10 col-sm-offset-2'>
  <wizard navBarLocation="top" class="wizardStyle" navigationMode="semi-strict" navBarLayout="large-empty-symbols">

    <!-- Brand and Hotel Step start -->
    <wizard-step stepTitle="Loyalty Program Setup 2" navigationSymbol="1">
      <ng-template wizardStepTitle>
        <div class="d-none d-md-block" class="wizardHeadingTextStyle">Brand & Hotel Details</div>
      </ng-template>
      <div id="" class="customContainer" [formGroup]="loyaltyBrand">
        <ul class="nav nav-tabs hotel-brand-tabs">
          <li *ngFor="let brand of loyaltyBrand.controls['brandsList'].value; let i=index;" [ngClass]="i == selectedBrandIndex ? 'active':''">
            <a class="pointer text-center" data-toggle="tab" id="1" [href]="'#brand'+i" (click)="getBrandDetailsByBrandId(brand, i)">
              <span>{{'Brand '+(i+1)}} </span>
              <span class="pull-right minus" *ngIf="loyaltyBrand.controls['brandsList'].value.length > 1" (click)="$event.preventDefault();remove('loyaltyBrand', 'brandsList', i, 'brand')">
                <img class="pull-left" src="assets/images/minus.svg">
              </span>
            </a>
          </li>
        </ul>
        <div class="tab-content">
          <div class="tab-pane fade in active" [id]="'brand'+selectedBrandIndex">
            <div>
              <div class="centered-content">
                <div>
                  <div class="col-sm-12 tabPaneBorder">
                    <div class='editContainer col-sm-12'>
                      <div>
                        <h3>Hotel Brand Details</h3>
                      </div>
                      <form novalidate [formGroup]="brandListArray">
                        <div class='formDiv row margin-top'>
                          <div class='form-group col-sm-5'>
                            <label class="labelStyle">Hotel Brand Name*</label>
                            <input type="text" placeholder="" class='form-control inputTextStyle' formControlName="brandName" [ngClass]="brandListArray.get('brandName').hasError('brandTaken') || brandListArray.get('brandName').hasError('brandAlreadyTaken') || brandListArray.get('brandName').hasError('required') && brandListArray.get('brandName').touched ? 'err':''">
                            <p class="error" *ngIf="brandListArray.get('brandName').hasError('brandAlreadyTaken') || brandListArray.get('brandName').hasError('brandTaken')">Brand name already exists.</p>
                          </div>
                          <div class='form-group col-sm-5 down-arrow  select-icon'>
                            <label class="labelStyle">Available Language* (Choose at least 1)</label>
                            <select class='form-control inputTextStyle' formControlName="languageId" [ngClass]="brandListArray.get('languageId').hasError('required') && brandListArray.get('languageId').touched ? 'err':''">
                              <option value="">Choose Language</option>
                              <option *ngFor="let language of languages" [value]="language.id">{{language.language}}</option>
                            </select>
                          </div>
                        </div>
                        <div class='formDiv row'>
                          <div class="form-group col-sm-5 margin-bottom45">
                            <label class="labelStyle">
                              Brand Website URLfgh
                            </label>
                            <textarea class='form-control inputTextStyle' [ngClass]="brandListArray.get('website').hasError('pattern') ? 'err':''" formControlName="website"></textarea>
                          </div>
                        </div>
                        <div class='formDiv row'>
                          <div class="form-group col-sm-5 row margin-right-15">
                            <div class='col-sm-7'>
                              <label class="labelStyle">Logo (Optional)</label>
                              <p *ngIf='!brandListArray.get("brandLogo").value' class='image-name'>no file chosen</p>
                              <p *ngIf='brandListArray.get("brandLogo").value' class='image-name'>{{brandListArray.get("brandLogoName").value}}</p>
                              <input class='form-control' type='file' #uploadLogoFile (change)="fileChanged($event,'logo', 'brand', selectedBrandIndex)"
                                (focus)="errorDetails = ''" />
                              <button (click)="uploadLogoFile.click()" class='btn-file'>{{brandListArray.get("brandLogo").value ? 'Change File' : 'Choose File'}}</button>
                              <p *ngIf='brandListArray.get("logoError").value' class="error upload" (click)="logoError = false">Please upload Image only.</p>
                            </div>
                            <div class='col-sm-5 '>
                              <div *ngIf='brandListArray.get("logoUrl").value'>
                                <img class="image" [src]='brandListArray.get("logoUrl").value' />
                                <button class='btn btn-default delete-image'>
                                  <img src='assets/images/delete.svg' (click)="deleteImage(brandListArray, 'brandsList', selectedBrandIndex, 'brand', 'logo')"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                          <div class="form-group col-sm-5 row margin-right-15">
                            <div class='col-sm-7'>
                              <label class="labelStyle">Favicon (Optional)</label>
                              <p *ngIf='!brandListArray.get("favicon").value' class='image-name'>no file chosen</p>
                              <p *ngIf='brandListArray.get("favicon").value' class='image-name'>{{brandListArray.get("faviconName").value}}</p>
                              <input class='form-control' type='file' #uploadFavFile (change)="fileChanged($event,'favicon', 'brand', selectedBrandIndex)"
                                (focus)="errorDetails = ''" />
                              <button (click)="uploadFavFile.click()" class='btn-file'>{{brandListArray.get("favicon").value ? 'Change File' :'Choose File'}}</button>
                              <p *ngIf='brandListArray.get("favError").value' class="error upload" (click)="favError = false">Please upload Image only.</p>
                            </div>
                            <div class='col-sm-5'>
                              <div *ngIf='brandListArray.get("favUrl").value'>
                                <img class="image" [src]='brandListArray.get("favUrl").value' />
                                <button class='btn btn-default delete-image'>
                                  <img src='assets/images/delete.svg' (click)="deleteImage(brandListArray, 'brandsList', selectedBrandIndex, 'brand', 'favicon')"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div formArrayName="hotelsList">
                          <div *ngFor="let hotel of hotelListControl; let i=index">
                            <div formGroupName="{{i}}">
                              <div class='formDiv row'>
                                <div *ngIf='i > 0' class='hotel-divider clear'></div>
                                <div class='col-sm-6'>
                                  <h3>Hotel {{i + 1}} Details</h3>
                                </div>
                                <div class='col-sm-6 text-right padding-top-40'>
                                  <button class='btn btn-default remove-btn' (click)="remove(brandListArray, 'hotelsList', i, 'hotel')">
                                    <img src='assets/images/minus.svg' class='pull-left' />
                                    <span class='pull-right'>Remove</span>
                                  </button>
                                </div>
                              </div>
                              <div class='formDiv row margin-top25'>
                                <div class='form-group col-sm-5'>
                                  <label class="labelStyle">Hotel Name*</label>
                                  <input type="text" placeholder="" class='form-control inputTextStyle' formControlName="hotelName" [ngClass]="hotel.get('hotelName').hasError('minLength') || hotel.get('hotelName').hasError('maxLength') || hotel.get('hotelName').hasError('required') && hotel.get('hotelName').touched ? 'err':''">
                                </div>
                              </div>
                              <div class='formDiv row'>
                                <div class='form-group col-sm-5'>
                                  <label class="labelStyle">Hotel Website URL</label>
                                  <textarea class='form-control inputTextStyle' formControlName="website" (focus)="errorDetails = ''" [ngClass]="hotel.get('website').hasError('pattern') && hotel.get('hotelName').touched ? 'err':''"></textarea>
                                </div>
                                <div class="form-group col-sm-5">
                                  <label class="labelStyle">Description</label>
                                  <input type='text' class='form-control inputTextStyle' formControlName='description' (focus)="errorDetails = ''" />
                                </div>
                              </div>
                              <div class='formDiv row' formArrayName="seasonsList">
                                <p class="error" *ngIf="brandListArray.get('hotelsList').at(i).get('isDatesRepeated').value">selected Date is already previous date range</p>
                                <p class="error" *ngIf="brandListArray.get('hotelsList').at(i).get('dateRepeated').value">DateTo must be greater than dateFrom</p>
                                <div class='form-group col-sm-10 row' *ngFor="let season of brandListArray.get('hotelsList').at(i).get('seasonsList').controls; let j=index">
                                  <div [formGroupName]="j">
                                    <div class='col-sm-3'>
                                      <label class="labelStyle">Season*</label>
                                      <input type='text' class='form-control inputTextStyle' formControlName="name" [ngClass]="season.get('name').hasError('required') && season.get('name').touched ? 'err':''"
                                      />
                                    </div>
                                    <div class='col-sm-3'>
                                      <label class="labelStyle">Date From*</label>
                                      <input type="date" formControlName="dateFrom" placeholder="MM/DD/YYYY" class="form-control inputTextStyle" onkeydown='return event.which == 8 || event.which == 46 || event.which == 37 || event.which == 39'
                                        (click)="selectDate($event.target.value, 'dateFrom', i, j)" [ngClass]="season.get('dateFrom').hasError('required') && season.get('dateFrom').touched ? 'err':''">
                                    </div>
                                    <div class='col-sm-3'>
                                      <label class="labelStyle">Date To*</label>
                                      <input type="date" formControlName="dateTo" class="form-control inputTextStyle" onkeydown='return event.which == 8 || event.which == 46 || event.which == 37 || event.which == 39'
                                        placeholder="End Date" (click)="selectDate($event.target.value, 'dateTo', i, j)" [ngClass]="season.get('dateTo').hasError('required') && season.get('dateTo').touched ? 'err':''">
                                    </div>
                                    <div class='col-sm-3'>
                                      <label class="labelStyle">
                                        <!-- Points -->
                                        Points per night*
                                      </label>
                                      <input type='number' onkeypress='return event.charCode >= 48 && event.charCode <= 57' class='form-control inputTextStyle'
                                        formControlName="points" [ngClass]="season.get('points').hasError('required') && season.get('points').touched ? 'err':''"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class='row formDiv margin-bottom25'>
                                <div class='form-group col-sm-4'>
                                  <button class='btn btn-default btn-add' (click)="addSeason(selectedBrandIndex, i)">Add Season</button>
                                </div>
                              </div>
                              <div class='formDiv row'>
                                <div class="form-group col-sm-5 profilePic row margin-bottom-40">
                                  <div class='col-sm-7'>
                                    <label class="labelStyle">Hotel Hero Banner Image</label>
                                    <p *ngIf='!hotel.get("banner").value' class='image-name'>no file chosen</p>
                                    <p *ngIf='hotel.get("banner").value' class='image-name'>{{hotel.get("bannerName").value}}</p>
                                    <input class='form-control' type='file' #uploadBannerFile (change)="fileChanged($event,'banner', 'hotel', selectedBrandIndex, i)"
                                      (focus)="errorDetails = ''" />
                                    <button (click)="uploadBannerFile.click()" class='btn-file'>{{hotel.get("banner").value ? 'Change File' :'Choose File'}}</button>
                                    <p *ngIf='hotel.get("bannerError").value' class="error upload" (click)="bannerError = false">Please upload Image only.</p>
                                  </div>
                                  <div class='col-sm-5'>
                                    <div *ngIf='hotel.get("bannerUrl").value'>
                                      <img class="image" [src]='hotel.get("bannerUrl").value' />
                                      <button class='btn btn-default delete-image'>
                                        <img src='assets/images/delete.svg' (click)="deleteImage(hotel, 'hotelsList', i, 'hotel', 'banner')" />
                                      </button>
                                    </div>
                                  </div>

                                </div>
                                <div class="form-group col-sm-5">
                                  <label>Tags</label>
                                  <input [(ngModel)]="tagInputText" [ngModelOptions]="{standalone: true}" style="display: none" />
                                  <tag-input formControlName='tags' [(inputText)]="tagInputText" [onTextChangeDebounce]="300" [theme]="'minimal'" [onlyFromAutocomplete]="true"
                                    [identifyBy]="'hotelTagId'" [displayBy]="'name'" [placeholder]="''" (keydown)="searchTerm$.next($event.target.value)">
                                    <tag-input-dropdown [showDropdownIfEmpty]="false" [displayBy]="'name'" [identifyBy]="'hotelTagId'" [autocompleteObservable]="hotelTags">
                                      <ng-template let-item="item" let-index="index">
                                        {{ item.name }}
                                      </ng-template>
                                    </tag-input-dropdown>
                                  </tag-input>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div class='row  step3-footer-buttons'>
                    <div class='col-sm-6'>
                      <button type="button" class="btn btn-default" class="addButtonStyle" (click)="addHotel(selectedBrandIndex)">
                        <img class='pull-left' src='assets/images/plus-white.svg' />Add a Hotel
                      </button>
                      <button type="button" class="btn btn-default" class="addButtonStyle" (click)="addLoyaltyBrand()">
                        <img class='pull-left' src='assets/images/plus-white.svg' />Add a Brand
                      </button>
                    </div>
                    <div class='col-sm-6 text-right'>
                      <button type="button" class="btn btn-default cancelButtonStyle" (click)="cancel()">
                        <strong>Cancel</strong>
                      </button>
                      <button type="button" class="btn buttonStyle" disabled>Previous</button>
                      <button type="button" class="btn btn-default nextButtonStyle" nextStep (click)="saveWizard()" [ngClass]="!loyaltyBrand.valid ? 'disabled': ''">Next</button>
                    </div>
                  </div>
                </div>
                <div class="btn-group" class="buttonDivStyle">
                  <button type="button" class="btn btn-default cancelButtonStyle" (click)="cancel()">Cancel</button>
                  <button type="button" class="btn buttonStyle" disabled>Previous</button>
                  <button type="button" class="btn btn-default nextButtonStyle" nextStep (click)="saveWizard()" [ngClass]="!loyaltyBrand.valid ? 'disabled': ''">Next</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </wizard-step>
    <!-- Brand and Hotel Step end -->

    <!-- Add Permissions step start -->
    <wizard-step stepTitle="Admin Permissions" navigationSymbol="2">
      <ng-template wizardStepTitle>
        <div class="d-none d-md-block" class="wizardHeadingTextStyle">Admin Permissions</div>
      </ng-template>
      <div class="centered-content">
        <div class="col-sm-12 step4">
          <form [formGroup]="loyaltyAdmin" class='form-group'>
            <div formArrayName="adminsList">
              <div *ngFor="let admin of loyaltyAdmin.controls['adminsList']['controls']; let i=index">
                <div formGroupName="{{i}}">
                  <div class='row col-sm-7 margin-bottom25'>
                    <h3>Admin Permissions</h3>
                    <p class='adminPermissionDivText'>Setup user access for brand and hotel levels. This can also be configured later in the user management
                      screen under settings.</p>
                  </div>
                  <div class="col-sm-5 text-right margin-top25" *ngIf="loyaltyAdmin.controls['adminsList'].value.length > 1">
                    <button class="btn btn-default remove-btn" (click)="remove('loyaltyAdmin', 'adminsList', i, 'admin')">
                      <img class="pull-left" src="assets/images/minus.svg">
                      <span class="pull-right">Remove</span>
                    </button>
                  </div>
                  <div class='formDiv row'>
                    <div class='form-group col-sm-4 down-arrow select-icon'>
                      <label class="labelStyle">Admin Role*</label>
                      <select class='form-control inputTextStyle' formControlName="roleId" (focus)="errorDetails = ''" (change)="selectedRole(i)"
                        [ngClass]="admin.get('roleId').hasError('required') && admin.get('roleId').touched ? 'err':''">
                        <option value="">Choose Role</option>
                        <option *ngFor="let role of hcaRoles" [value]="role.roleId">
                          {{role.roleName}}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class='formDiv row'>
                    <div class='form-group col-sm-4 down-arrow select-icon'>
                      <label class="labelStyle">Hotel Brand* </label>
                      <select class='form-control inputTextStyle' formControlName="brandName" (focus)="errorDetails = ''" (change)="selectedBrands(i, brand)"
                        [ngClass]="admin.get('brandName').hasError('required') && admin.get('brandName').touched ? 'err':''">
                        <option value="" *ngIf="admin.get('roleId').value != 12">Choose Brand</option>
                        <option value="" *ngIf="admin.get('roleId').value == 12">All</option>
                        <option *ngFor="let brand of loyaltyBrand.controls['brandsList'].value; let j = index;" [value]="brand.brandName">{{brand.brandName}}</option>
                      </select>
                    </div>
                    <div class='form-group col-sm-4 down-arrow select-icon'>
                      <label class="labelStyle">Hotels* </label>
                      <select class='form-control inputTextStyle dropdown-toggle' (focus)="errorDetails = ''" data-toggle="dropdown" formControlName="hotelName"
                        [ngClass]="admin.get('hotelName').hasError('required') && admin.get('hotelName').touched ? 'err':''">
                        <option value="" *ngIf="admin.get('roleId').value == 14 || admin.get('roleId').value == ''">Choose Hotel</option>
                        <option value="" *ngIf="admin.get('roleId').value == 12 || admin.get('roleId').value == 13">All</option>
                        <option *ngFor="let hotel of hotels" [value]="hotel.hotelName">{{hotel.hotelName}}</option>
                      </select>
                    </div>
                  </div>
                  <div class='formDiv row'>
                    <div class='form-group col-sm-4'>
                      <label class="labelStyle">First Name*</label>
                      <input type='text' class='form-control inputTextStyle' [ngClass]="admin.get('firstName').hasError('required') && admin.get('firstName').touched ? 'err':''"
                        formControlName="firstName" />
                    </div>
                    <div class='form-group col-sm-4'>
                      <label class="labelStyle">Last Name*</label>
                      <input type='text' class='form-control inputTextStyle' formControlName="lastName" [ngClass]="admin.get('lastName').hasError('required') && admin.get('lastName').touched ? 'err':''"
                      />
                    </div>
                  </div>
                  <div class='formDiv row'>
                    <div class='form-group col-sm-4'>
                      <label class="labelStyle">Email ID*</label>
                      <input type="text" placeholder="" class='form-control inputTextStyle' formControlName="email" [ngClass]="admin.get('email').hasError('adminTaken') || admin.get('email').hasError('pattern') || admin.get('email').hasError('required') && admin.get('email').touched ? 'err':''">
                      <p class="error" *ngIf="admin.get('email').hasError('adminTaken')">Email id already exists. Please contact the admin.</p>
                    </div>
                    <div class='form-group col-sm-4'>
                      <label class="labelStyle">Contact Number</label>
                      <input type="tel" placeholder="" class='form-control inputTextStyle' formControlName="mobileNumber" [ngClass]="admin.get('mobileNumber').hasError('pattern') || admin.get('mobileNumber').hasError('minlength') || admin.get('mobileNumber').hasError('maxlength') ? 'err':''">
                    </div>
                  </div>
                  <div class='formDiv row'>
                    <div class='form-group col-sm-4'>
                      <label class="labelStyle">Skype ID</label>
                      <input type="text" placeholder="" class='form-control inputTextStyle' formControlName="skypeId">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>

          <div class='row  step3-footer-button btn-admin-permissions'>
            <div class='col-sm-5'>
              <button type="button" class="btn btn-default addButtonStyle step4-btn" (click)="addLoyaltyAdmin()">
                <img class='pull-left' src='assets/images/plus-white.svg' />Add Admin
              </button>
            </div>
            <div class='col-sm-7 text-right'>
              <button type="button" class="btn btn-default cancelButtonStyle" (click)="cancel()">
                <strong>Cancel</strong>
              </button>
              <button type="button" class="btn btn-default nextButtonStyle  prevButton" previousStep>Previous</button>
              <button type="button" class="btn btn-default nextButtonStyle" nextStep [ngClass]="!loyaltyAdmin.valid ? 'disabled': ''" (click)="saveWizard('loyaltyAdmin')">Next</button>
            </div>
          </div>
        </div>
        <div class="btn-group" class="buttonDivStyle">
          <button type="button" class="btn btn-default cancelButtonStyle" (click)="cancel()">Cancel</button>
          <button type="button" class="btn btn-default nextButtonStyle  prevButton" previousStep>Previous</button>
          <button type="button" class="btn btn-default nextButtonStyle" nextStep [ngClass]="!loyaltyAdmin.valid ? 'disabled': ''" (click)="saveWizard('loyaltyAdmin')">Next</button>
        </div>
      </div>
    </wizard-step>
    <!-- Add Permissions step end -->

    <!-- confirmation step start -->
    <wizard-step stepTitle="Review and confirm" navigationSymbol="3">
      <ng-template wizardStepTitle>
        <div class="d-none d-md-block" class="wizardHeadingTextStyle">Review and Confirm</div>
      </ng-template>
      <div class="centered-content">
        <div>
          <div class="col-md-12 ">
            <div class='editContainer confirmation'>
              <div class='margin-bottom25'>
                <h3 class='margin-top-55'>Confirm Details</h3>
              </div>
              <form [formGroup]="" class='form-group'>

                <!-- Hotel Brand Details -->
                <div>
                  <div *ngFor="let brand of loyaltyBrand.get('brandsList').value; let i = index;">
                    <div class='margin-bottom25 clear'>
                      <p class="adminPermHeading">Hotel Brand {{i+1}} Details:</p>
                    </div>
                    <div class='row formDiv'>
                      <div class='form-group col-sm-3'>
                        <label class="labelStyle">Hotel Brand Name</label>
                        <div class="confirmationDivTextStyle">{{brand.brandName}}</div>
                      </div>
                      <div class='form-group col-sm-3'>
                        <label class="labelStyle">Language(s)</label>
                        <div class="confirmationDivTextStyle">English</div>
                      </div>
                    </div>
                    <div class='row formDiv'>
                      <div class='form-group col-sm-3'>
                        <label class="labelStyle">Brand Website URL</label>
                        <div class="confirmationDivTextStyle">{{brand.website}}</div>
                      </div>
                    </div>

                    <!-- Hotel Details -->
                    <div *ngFor="let hotel of brand.hotelsList; let i = index;">
                      <div class='margin-bottom25 clear'>
                        <p class="adminPermHeading">Hotel {{i + 1}} Details:</p>
                      </div>
                      <div>
                        <div class='row formDiv'>
                          <div class='form-group col-sm-3'>
                            <label class="labelStyle">Hotel Name</label>
                            <div class="confirmationDivTextStyle"> {{hotel.hotelName}}</div>
                          </div>
                        </div>
                        <div class='row formDiv'>
                          <div class='form-group col-sm-3'>
                            <label class="labelStyle">Hotel Website URL</label>
                            <div class="confirmationDivTextStyle">{{hotel.website}}</div>
                          </div>
                          <div class='form-group col-sm-3'>
                            <label class="labelStyle">Description</label>
                            <div class="confirmationDivTextStyle">{{hotel.description}}</div>
                          </div>
                        </div>
                        <div class='row formDiv'>
                          <div class='form-group col-sm-3'>
                            <label class="labelStyle">Tags</label>
                            <div>
                              <span *ngFor="let tag of hotel.tags; let isLast=last" class="confirmationDivTextStyle"> {{tag.name}}{{isLast ? '' : ','}}</span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- season Details -->
                      <div *ngFor="let season of hotel.seasonsList; let i = index;">
                        <div class='margin-bottom25 clear'>
                          <p>Season {{i+1}} Details:</p>
                        </div>
                        <div>
                          <div class='row formDiv'>
                            <div class='form-group col-sm-3'>
                              <label class="labelStyle">Season*</label>
                              <div class="confirmationDivTextStyle">{{season.name}}</div>
                            </div>
                          </div>
                          <div class='row formDiv'>
                            <div class='form-group col-sm-3'>
                              <label class="labelStyle">Date From*</label>
                              <div class="confirmationDivTextStyle">{{season.dateFrom}}</div>
                            </div>
                            <div class='form-group col-sm-3'>
                              <label class="labelStyle">Date To*</label>
                              <div class="confirmationDivTextStyle">{{season.dateTo}}</div>
                            </div>
                          </div>
                          <div class='row formDiv'>
                            <div class='form-group col-sm-3'>
                              <label class="labelStyle">
                                <!-- Points -->
                                Points per night*
                              </label>
                              <div class="confirmationDivTextStyle">{{season.points}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Admin Information -->
                <div *ngFor="let admin of loyaltyAdmin.get('adminsList').value; let i = index;">
                  <div class='margin-bottom25 clear'>
                    <p class="adminPermHeading">Admin {{i + 1}} Information:</p>
                  </div>
                  <div>
                    <div class='row formDiv'>
                      <div class='form-group col-sm-3'>
                        <label class="labelStyle">Admin Role</label>
                        <div class="confirmationDivTextStyle">{{admin.roleId == 12 ? "Hotel Chain Admin" : (admin.roleId == 13 ? "Brand Admin" : (admin.roleId ==
                          14 ? "Hotel Admin" : ''))}}</div>
                      </div>
                    </div>
                    <div class='row formDiv'>
                      <div class='form-group col-sm-3'>
                        <label class="labelStyle">Hotel Brand</label>
                        <div class="confirmationDivTextStyle">
                          {{admin.roleId == 12 ? "All" : admin.brandName}}
                        </div>
                      </div>
                      <div class='form-group col-sm-3'>
                        <label class="labelStyle">Hotels</label>
                        <div class="confirmationDivTextStyle">
                          {{(admin.roleId == 12 || admin.roleId == 13) ? "All" : admin.hotelName}}
                        </div>
                      </div>
                    </div>
                    <div class='row formDiv'>
                      <div class='form-group col-sm-3'>
                        <label class="labelStyle">First Name</label>
                        <div class="confirmationDivTextStyle">{{admin.firstName}}</div>
                      </div>
                      <div class='form-group col-sm-3'>
                        <label class="labelStyle">Last Name</label>
                        <div class="confirmationDivTextStyle">{{admin.lastName}}</div>
                      </div>
                    </div>
                    <div class='row formDiv'>
                      <div class='form-group col-sm-3'>
                        <label class="labelStyle">Skype ID</label>
                        <div class="confirmationDivTextStyle">{{admin.skypeId}}</div>
                      </div>
                      <div class='form-group col-sm-3'>
                        <label class="labelStyle">Email ID</label>
                        <div class="confirmationDivTextStyle">{{admin.email}}</div>
                      </div>
                    </div>
                    <div class='row formDiv'>
                      <div class='form-group col-sm-3'>
                        <label class="labelStyle">Contact Number</label>
                        <div class="confirmationDivTextStyle">{{admin.mobileNumber}}</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="btn-group" class="footerButtonDivStyleNav">
                  <button type="button" class="btn btn-default cancelButtonStyle" (click)="cancel()">
                    <strong>Cancel</strong>
                  </button>
                  <button type="button" class="btn btn-default nextButtonStyle" previousStep>Previous</button>
                  <button type="button" class="btn btn-default saveButtonStyle" (click)="saveWizard('saveLoyalty')">
                    <img src='assets/images/save.svg' class='pull-left' />
                    <span>Save</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="btn-group" class="buttonDivStyle">
          <button type="button" class="btn btn-default nextButtonStyle" previousStep>Previous</button>
          <button type="button" class="btn btn-default saveButtonStyle" (click)="saveWizard('saveLoyalty')">
            <img src='assets/images/save.svg' class='pull-left' />
            <span>Save</span>
          </button>
        </div>
      </div>
    </wizard-step>
    <!-- confirmation step end -->
  </wizard>
</div>