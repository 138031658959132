<div>
  <div class='row'>
    <div class='col-sm-9 padding-right-10'>
      <ul class='nav nav-tabs rules-tabs'>
        <li class="active">
          <a id="En" *ngIf="!isDirtyEs" data-toggle="tab" href="#Eng">
            <span>English</span>
          </a>
          <a *ngIf="isDirtyEs" class="pointer" (click)="changeLanguage()">
            <span>English</span>
          </a>
        </li>
        <li class="">
          <a id="Es" *ngIf="!isDirtyEn" data-toggle="tab" href="#Span">
            <span>Español</span>
          </a>
          <a *ngIf="isDirtyEn" class="pointer" (click)="changeLanguage()">
            <span>Español</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
  <div class="tab-content">
    <!-- English -->
    <div id="Eng" class="tab-pane fade in active">
      <div class="centered-content">
        <div>
          <div class="col-sm-9 tabPaneBorder">
            <div class='editContainer col-sm-9'>
              <div class='row'>
                <div class='col-xs-12'>
                  <h3 class='step-heading'>
                    <!-- {{'LANDING-PAGE.STEP1-HEADING' | translate}} -->
                    Step 1.) Hero Banner Setup
                  </h3>
                  <p class='heading-text'>
                    <!-- {{'LANDING-PAGE.STEP1-TEXT' | translate}} -->
                    This is the first banner that members will see on your page. It is positioned just below the website header and changes based
                    on a members loyalty level, booking history, and purchase history.
                  </p>
                </div>
              </div>

              <!-- Default Banner Config -->
              <div class='row'>
                <div class='col-xs-12'>
                  <p class='title'>
                    <!-- {{'LANDING-PAGE.CONFIGURATION-1' | translate}} -->
                    Default Banner Configuration
                  </p>
                  <form [formGroup]="defaultBannerEn">
                    <div class='form-group row upload-image-container'>
                      <div class='col-sm-7'>
                        <label class='labelStyle'>
                          <!-- {{'LANDING-PAGE.BACKGROUND-IMAGE' | translate}} -->
                          Background Image
                        </label>
                        <p>{{defaultBannerEn.get('backgroundImage').value ? defaultBannerEn.get('backgroundImage').value : 'no file choosen'}}</p>
                        <input class='form-control' type='file' #uploadDefaultBannerEn (change)="fileChanged($event,'defaultBannerEn', 'uploadDefaultBannerEn')"
                          (focus)="errorDetails = ''" />
                        <button (click)="uploadDefaultBannerEn.click()" class='btn-file'>
                          <!-- {{'LANDING-PAGE.UPLOAD-PHOTO' | translate}} -->
                          <!-- Upload Photo -->
                          {{defaultBannerEn.get('backgroundImage').value ? 'Change Photo' : 'Choose Photo'}}
                        </button>
                        <p *ngIf="defaultBannerEn.get('backgroundImageError').value" class="error upload">Please upload Image only.</p>
                        <p *ngIf="defaultBannerEn.get('backgroundImageSizeError').value" class="error upload">Image size should be less than 1MB.</p>
                      </div>
                      <div class='col-sm-5' *ngIf="defaultBannerEn.get('backgroundImage').value && !defaultBannerEn.get('backgroundImageError').value && !defaultBannerEn.get('backgroundImageSizeError').value">
                        <img class="image" [src]="defaultBannerEn.get('backgroundImageUrl').value ? defaultBannerEn.get('backgroundImageUrl').value : getImgStyle(defaultBannerEn.get('id').value, defaultBannerEn.get('backgroundImage').value)"
                        />
                        <button class='btn btn-default delete-image'>
                          <img src='assets/images/delete.svg' (click)="deleteImage('defaultBannerEn','english')" />
                        </button>
                      </div>
                    </div>
                    <div class='form-group'>
                      <label class='labelStyle'>
                        <!-- {{'LANDING-PAGE.HEADING' | translate}} -->
                        Heading
                      </label>

                      <textarea class='form-control inputTextStyle l-page' [mention]="items" [mentionConfig]="{triggerChar:'@',maxItems:10,labelKey:'name'}"
                        formControlName="heading" placeholder="Heading" (focus)="errorDetails = ''"></textarea>
                    </div>
                    <div class='form-group'>
                      <label class='labelStyle'>
                        <!-- {{'LANDING-PAGE.SUB-HEADING' | translate}} -->
                        Sub-Heading
                      </label>
                      <textarea class='form-control inputTextStyle l-page' [mention]="items" [mentionConfig]="{triggerChar:'@',maxItems:10,labelKey:'name'}"
                        formControlName="subHeading" placeholder="Sub-Heading" (focus)="errorDetails = ''"></textarea>
                    </div>
                    <!-- <div class='form-group'>
                      <label class='labelStyle'>
                        {{'LANDING-PAGE.CONTENT' | translate}}
                        Content
                      </label>
                      <textarea class='form-control inputTextStyle l-page' [mention]="items" [mentionConfig]="{triggerChar:'#',maxItems:10,labelKey:'name'}"
                        formControlName="content" placeholder="Content" (focus)="errorDetails = ''"></textarea>
                    </div> -->
                    <div class='form-group'>
                      <label class='labelStyle'>
                        <!-- {{'LANDING-PAGE.BUTTON-TEXT' | translate}} -->
                        Button Text
                      </label>
                      <input type='text' class='form-control inputTextStyle buttonText' placeholder="Book Now" formControlName="buttonText" (focus)="errorDetails = ''"
                      />
                    </div>
                    <div class='form-group'>
                      <label class='labelStyle'>
                        <!-- {{'LANDING-PAGE.BUTTON-ACTION' | translate}} -->
                        Button Action
                      </label>
                      <label class="radio">
                        <input id="deafultBnr1" type="radio" value="Book Now" formControlName="buttonActionType" (focus)="errorDetails = ''">
                        <span class="outer"></span>
                        <span class='radio-text'>
                          <!-- {{'LANDING-PAGE.BOOK-NOW' | translate}} -->
                          Book Now
                        </span>
                      </label>
                      <label class="radio">
                        <input id="deafultBnr2" type="radio" value="External URL" formControlName="buttonActionType" (focus)="errorDetails = ''">
                        <span class="outer"></span>
                        <span class='radio-text'>
                          <!-- {{'LANDING-PAGE.EXTERNAL-URL' | translate}}  -->
                          External URL
                        </span>
                      </label>
                    </div>
                    <div class='form-group' *ngIf="defaultBannerEn.get('buttonActionType').value == 'External URL'">
                      <label class='labelStyle'>
                        External URL
                      </label>
                      <input type="text" class='form-control inputTextStyle l-page' formControlName="externalUrl" placeholder="External URL" (focus)="errorDetails = ''"
                        [ngClass]="defaultBannerEn.get('externalUrl').hasError('pattern') || defaultBannerEn.get('externalUrl').hasError('required') ? 'err' : ''">
                    </div>
                  </form>
                </div>
              </div>
              <!-- Default Banner Config -->

              <!-- Default Banner Non Member Config -->
              <div class='row'>
                <div class='col-xs-12'>
                  <p class='title'>
                    <!-- {{'LANDING-PAGE.CONFIGURATION-2' | translate}} -->
                    Default Banner Configuration - Non Member
                  </p>
                  <form [formGroup]="defaultBannerNonMemberEn">
                    <div class='form-group row upload-image-container'>
                      <div class='col-sm-7'>
                        <label class='labelStyle'>
                          <!-- {{'LANDING-PAGE.BACKGROUND-IMAGE' | translate}} -->
                          Background Image
                        </label>
                        <p>{{defaultBannerNonMemberEn.get('backgroundImage').value ? defaultBannerNonMemberEn.get('backgroundImage').value : 'no file choosen'}}</p>
                        <input class='form-control' type='file' #uploadDefaultBannerNonMemberEn (change)="fileChanged($event,'defaultBannerNonMemberEn', 'uploadDefaultBannerNonMemberEn')"
                          (focus)="errorDetails = ''" />
                        <button (click)="uploadDefaultBannerNonMemberEn.click()" class='btn-file'>
                          <!-- {{'LANDING-PAGE.UPLOAD-PHOTO' | translate}} -->
                          <!-- Upload Photo -->
                          {{defaultBannerNonMemberEn.get('backgroundImage').value ? 'Change Photo' : 'Choose Photo'}}
                        </button>
                        <p *ngIf="defaultBannerNonMemberEn.get('backgroundImageError').value" class="error upload">Please upload Image only.</p>
                        <p *ngIf="defaultBannerNonMemberEn.get('backgroundImageSizeError').value" class="error upload">Image size should be less than 1MB.</p>
                      </div>
                      <div class='col-sm-5' *ngIf="defaultBannerNonMemberEn.get('backgroundImage').value && !defaultBannerNonMemberEn.get('backgroundImageError').value && !defaultBannerNonMemberEn.get('backgroundImageSizeError').value">
                        <img class="image" [src]="defaultBannerNonMemberEn.get('backgroundImageUrl').value ? defaultBannerNonMemberEn.get('backgroundImageUrl').value : getImgStyle(defaultBannerNonMemberEn.get('id').value, defaultBannerNonMemberEn.get('backgroundImage').value)"
                        />
                        <button class='btn btn-default delete-image'>
                          <img src='assets/images/delete.svg' (click)="deleteImage('defaultBannerNonMemberEn','english')" />
                        </button>
                      </div>
                    </div>
                    <div class='form-group'>
                      <label class='labelStyle'>
                        <!-- {{'LANDING-PAGE.HEADING' | translate}} -->
                        Heading
                      </label>
                      <textarea class='form-control inputTextStyle l-page' [mention]="items" [mentionConfig]="{triggerChar:'#',maxItems:10,labelKey:'name'}"
                        formControlName="heading" placeholder="Heading" (focus)="errorDetails = ''"></textarea>
                    </div>
                    <div class='form-group'>
                      <label class='labelStyle'>
                        <!-- {{'LANDING-PAGE.SUB-HEADING' | translate}} -->
                        Sub-Heading
                      </label>
                      <textarea class='form-control inputTextStyle l-page' [mention]="items" [mentionConfig]="{triggerChar:'#',maxItems:10,labelKey:'name'}"
                        formControlName="subHeading" placeholder="Sub-Heading" (focus)="errorDetails = ''"></textarea>
                    </div>
                    <!-- <div class='form-group'>
                      <label class='labelStyle'>
                        {{'LANDING-PAGE.CONTENT' | translate}}
                        Content
                      </label>
                      <textarea class='form-control inputTextStyle l-page' [mention]="items" [mentionConfig]="{triggerChar:'#',maxItems:10,labelKey:'name'}"
                        formControlName="content" placeholder="Content" (focus)="errorDetails = ''"></textarea>
                    </div> -->
                    <div class='form-group'>
                      <label class='labelStyle'>
                        <!-- {{'LANDING-PAGE.BUTTON-TEXT' | translate}} -->
                        Button Text
                      </label>
                      <input type='text' class='form-control inputTextStyle buttonText' placeholder="Sign Up" formControlName="buttonText" (focus)="errorDetails = ''"
                      />
                    </div>
                    <div class='form-group'>
                      <label class='labelStyle'>
                        <!-- {{'LANDING-PAGE.BUTTON-ACTION' | translate}} -->
                        Button Action
                      </label>
                      <label class="radio">
                        <input id="deafultBnrNonMember1" type="radio" value="Register" formControlName="buttonActionType" (focus)="errorDetails = ''">
                        <span class="outer"></span>
                        <span class='radio-text'>
                          <!-- {{'LANDING-PAGE.SIGNUP' | translate}} -->
                          Sign Up
                        </span>
                      </label>
                      <label class="radio">
                        <input id="deafultBnr2" type="radio" value="External URL" formControlName="buttonActionType" (focus)="errorDetails = ''">
                        <span class="outer"></span>
                        <span class='radio-text'>
                          <!-- {{'LANDING-PAGE.EXTERNAL-URL' | translate}} -->
                          External URL
                        </span>
                      </label>
                    </div>
                    <div class='form-group' *ngIf="defaultBannerNonMemberEn.get('buttonActionType').value == 'External URL'">
                      <label class='labelStyle'>
                        <!-- {{'LANDING-PAGE.EXTERNAL-URL' | translate}} -->
                        External URL
                      </label>
                      <input type="text" class='form-control inputTextStyle l-page' placeholder="External URL" formControlName="externalUrl" (focus)="errorDetails = ''"
                        [ngClass]="defaultBannerNonMemberEn.get('externalUrl').hasError('pattern') || defaultBannerNonMemberEn.get('externalUrl').hasError('required') ? 'err' : ''">
                    </div>
                  </form>
                </div>
              </div>
              <!-- Default Banner Non Member Config -->

              <!-- Upcoming Banner Config -->
              <div class='row'>
                <div class='col-xs-12'>
                  <p class='title margin-bottom-14'>
                    <!-- {{'LANDING-PAGE.CONFIGURATION-3' | translate}} -->
                    Upcoming Visit Banner Configuration
                  </p>
                  <label class='labelStyle margin-bottom25'>
                    To display hotel name of the retrieved banner in member landing page, please mention @HotelName wherever required in Heading/Sub
                    heading sections.
                  </label>
                  <form [formGroup]="upcomingBannerEn">
                    <!-- <div class='form-group row'>
                      <div class='col-sm-7'>
                        <label class='labelStyle'>{{'LANDING-PAGE.BACKGROUND-IMAGE' | translate}}</label>
                        <input class='form-control' type='file' #uploadFile (change)="uploadImage($event,'logo')" (focus)="errorDetails = ''" />
                        <button (click)="uploadFile.click()" class='btn-file'>{{'LANDING-PAGE.UPLOAD-PHOTO' | translate}}</button>
                      </div>
                      <div class='col-sm-5'>
                        <img class="image" [src]="imageFile_logo.imageUrl" />
                        <button class='btn btn-default delete-image'>
                          <img src='assets/images/delete.svg' (click)="deleteImage('upcomingBannerEn','english')" />
                        </button>
                      </div>
                    </div> -->
                    <div class='form-group'>
                      <label class='labelStyle'>
                        <!-- {{'LANDING-PAGE.HEADING' | translate}} -->
                        Heading
                      </label>
                      <textarea class='form-control inputTextStyle l-page' placeholder="Heading" [mention]="items" [mentionConfig]="{triggerChar:'#',maxItems:10,labelKey:'name'}"
                        formControlName="heading" (focus)="errorDetails = ''"></textarea>
                    </div>
                    <div class='form-group'>
                      <label class='labelStyle'>
                        <!-- {{'LANDING-PAGE.SUB-HEADING' | translate}} -->
                        Sub-Heading
                      </label>
                      <textarea class='form-control inputTextStyle l-page' placeholder="Sub-Heading" [mention]="items" [mentionConfig]="{triggerChar:'#',maxItems:10,labelKey:'name'}"
                        formControlName="subHeading" (focus)="errorDetails = ''"></textarea>
                    </div>
                    <!-- <div class='form-group'>
                      <label class='labelStyle'>
                        {{'LANDING-PAGE.CONTENT' | translate}}
                        Content
                      </label>
                      <textarea class='form-control inputTextStyle l-page' placeholder="Content" [mention]="items" [mentionConfig]="{triggerChar:'#',maxItems:10,labelKey:'name'}"
                        formControlName="content" (focus)="errorDetails = ''"></textarea>
                    </div> -->
                    <div class='form-group'>
                      <label class='labelStyle'>
                        <!-- {{'LANDING-PAGE.BUTTON-TEXT' | translate}} -->
                        Button Text
                      </label>
                      <input type='text' class='form-control inputTextStyle buttonText' placeholder="View Booking" formControlName="buttonText"
                        (focus)="errorDetails = ''" />
                    </div>
                    <div class='form-group'>
                      <label class='labelStyle'>
                        <!-- {{'LANDING-PAGE.BUTTON-ACTION' | translate}} -->
                        Button Action
                      </label>
                      <label class="radio">
                        <input id="upcomingBanner1" type="radio" value="View Booking" formControlName="buttonActionType" (focus)="errorDetails = ''">
                        <span class="outer"></span>
                        <span class='radio-text'>
                          <!-- {{'LANDING-PAGE.VIEW-BOOKING' | translate}} -->
                          View Booking
                        </span>
                      </label>
                      <label class="radio">
                        <input id="upcomingBanner2" type="radio" value="External URL" formControlName="buttonActionType" (focus)="errorDetails = ''">
                        <span class="outer"></span>
                        <span class='radio-text'>
                          <!-- {{'LANDING-PAGE.EXTERNAL-URL' | translate}} -->
                          External URL
                        </span>
                      </label>
                    </div>
                    <div class='form-group' *ngIf="upcomingBannerEn.get('buttonActionType').value == 'External URL'">
                      <label class='labelStyle'>
                        <!-- {{'LANDING-PAGE.EXTERNAL-URL' | translate}} -->
                        External URL
                      </label>
                      <input type="text" class='form-control inputTextStyle l-page' placeholder="External URL" formControlName="externalUrl" (focus)="errorDetails = ''"
                        [ngClass]="upcomingBannerEn.get('externalUrl').hasError('pattern') || upcomingBannerEn.get('externalUrl').hasError('required') ? 'err' : ''">
                    </div>
                  </form>
                </div>
              </div>
              <!-- Upcoming Banner Config -->

              <!-- Past Banner Config -->
              <div class='row'>
                <div class='col-xs-12'>
                  <p class='title margin-bottom-14'>
                    <!-- {{'LANDING-PAGE.CONFIGURATION-4' | translate}} -->
                    Past Visit Banner Configuration
                  </p>
                  <label class='labelStyle margin-bottom25'>
                    To display hotel name of the retrieved banner in member landing page, please mention @HotelName wherever required in Heading/Sub
                    heading sections.
                  </label>
                  <form [formGroup]="pastBannerEn">
                    <!-- <div class='form-group row'>
                      <div class='col-sm-7'>
                        <label class='labelStyle'>{{'LANDING-PAGE.BACKGROUND-IMAGE' | translate}}</label>
                        <input class='form-control' type='file' #uploadFile (change)="uploadImage($event,'logo')" (focus)="errorDetails = ''" />
                        <button (click)="uploadFile.click()" class='btn-file'>{{'LANDING-PAGE.UPLOAD-PHOTO' | translate}}</button>
                      </div>
                      <div class='col-sm-5'>
                        <img class="image" [src]="imageFile_logo.imageUrl" />
                        <button class='btn btn-default delete-image'>
                          <img src='assets/images/delete.svg' (click)="deleteImage('pastBannerEn','english')" />
                        </button>
                      </div>
                    </div> -->
                    <div class='form-group'>
                      <label class='labelStyle'>
                        <!-- {{'LANDING-PAGE.HEADING' | translate}} -->
                        Heading
                      </label>
                      <textarea class='form-control inputTextStyle l-page' placeholder="Heading" [mention]="items" [mentionConfig]="{triggerChar:'#',maxItems:10,labelKey:'name'}"
                        formControlName="heading" (focus)="errorDetails = ''"></textarea>
                    </div>
                    <div class='form-group'>
                      <label class='labelStyle'>
                        <!-- {{'LANDING-PAGE.SUB-HEADING' | translate}} -->
                        Sub-Heading
                      </label>
                      <textarea class='form-control inputTextStyle l-page' placeholder="Sub-Heading" [mention]="items" [mentionConfig]="{triggerChar:'#',maxItems:10,labelKey:'name'}"
                        formControlName="subHeading" (focus)="errorDetails = ''"></textarea>
                    </div>
                    <!-- <div class='form-group'>
                      <label class='labelStyle'>
                        {{'LANDING-PAGE.CONTENT' | translate}}
                        Content
                      </label>
                      <textarea class='form-control inputTextStyle l-page' placeholder="Content" [mention]="items" [mentionConfig]="{triggerChar:'#',maxItems:10,labelKey:'name'}"
                        formControlName="content" (focus)="errorDetails = ''"></textarea>
                    </div> -->
                    <div class='form-group'>
                      <label class='labelStyle'>
                        Display Banner after Past Visit (days)
                      </label>
                      <input type='number' class="form-control inputTextStyle" onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                        formControlName="showForDays" (focus)="errorDetails = ''" />
                    </div>
                    <div class='form-group'>
                      <label class='labelStyle'>
                        <!-- {{'LANDING-PAGE.BUTTON-TEXT' | translate}} -->
                        Button Text
                      </label>
                      <input type='text' class='form-control inputTextStyle buttonText' placeholder="Take Survey" formControlName="buttonText"
                        (focus)="errorDetails = ''" />
                    </div>
                    <div class='form-group'>
                      <label class='labelStyle'>
                        <!-- {{'LANDING-PAGE.BUTTON-ACTION' | translate}} -->
                        Button Action
                      </label>
                      <!-- <label class="radio">
                        <input id="pastBanner1" type="radio" value="Take Survey" formControlName="buttonActionType" (focus)="errorDetails = ''">
                        <span class="outer"></span>
                        <span class='radio-text'>                         
                          Take Survey
                        </span>
                      </label> -->
                      <label class="radio">
                        <input id="pastBanner2" type="radio" value="External URL" formControlName="buttonActionType" (focus)="errorDetails = ''">
                        <span class="outer"></span>
                        <span class='radio-text'>
                          <!-- {{'LANDING-PAGE.EXTERNAL-URL' | translate}} -->
                          External URL
                        </span>
                      </label>
                    </div>
                    <div class='form-group' *ngIf="pastBannerEn.get('buttonActionType').value == 'External URL'">
                      <label class='labelStyle'>
                        <!-- {{'LANDING-PAGE.EXTERNAL-URL' | translate}} -->
                        External URL
                      </label>
                      <input type="text" class='form-control inputTextStyle l-page' placeholder="External URL" formControlName="externalUrl" (focus)="errorDetails = ''"
                        [ngClass]="pastBannerEn.get('externalUrl').hasError('pattern') || pastBannerEn.get('externalUrl').hasError('required') ? 'err' : ''">
                    </div>
                  </form>
                </div>
              </div>
              <!-- Past Banner Config -->

              <!-- Birthday Banner Config -->
              <div class='row'>
                <div class='col-xs-12'>
                  <p class='title'>
                    <!-- {{'LANDING-PAGE.CONFIGURATION-5' | translate}} -->
                    Birthday Configuration
                  </p>
                  <form [formGroup]="birthdayBannerEn">
                    <div class='form-group row upload-image-container'>
                      <div class='col-sm-7'>
                        <label class='labelStyle'>
                          <!-- {{'LANDING-PAGE.BACKGROUND-IMAGE' | translate}} -->
                          Background Image
                        </label>
                        <p>{{birthdayBannerEn.get('backgroundImage').value ? birthdayBannerEn.get('backgroundImage').value : 'no file choosen'}}</p>
                        <input class='form-control' type='file' #uploadbirthdayBannerEn (change)="fileChanged($event,'birthdayBannerEn', 'uploadbirthdayBannerEn')"
                          (focus)="errorDetails = ''" />
                        <button (click)="uploadbirthdayBannerEn.click()" class='btn-file'>
                          <!-- {{'LANDING-PAGE.UPLOAD-PHOTO' | translate}} -->
                          <!-- Upload Photo -->
                          {{birthdayBannerEn.get('backgroundImage').value ? 'Change Photo' : 'Choose Photo'}}
                        </button>
                        <p *ngIf="birthdayBannerEn.get('backgroundImageError').value" class="error upload">Please upload Image only.</p>
                        <p *ngIf="birthdayBannerEn.get('backgroundImageSizeError').value" class="error upload">Image size should be less than 1MB.</p>
                      </div>
                      <div class='col-sm-5' *ngIf="birthdayBannerEn.get('backgroundImage').value && !birthdayBannerEn.get('backgroundImageError').value && !birthdayBannerEn.get('backgroundImageSizeError').value">
                        <img class="image" [src]="birthdayBannerEn.get('backgroundImageUrl').value ? birthdayBannerEn.get('backgroundImageUrl').value : getImgStyle(birthdayBannerEn.get('id').value, birthdayBannerEn.get('backgroundImage').value)"
                        />
                        <button class='btn btn-default delete-image'>
                          <img src='assets/images/delete.svg' (click)="deleteImage('birthdayBannerEn','english')" />
                        </button>
                      </div>
                    </div>
                    <div class='form-group'>
                      <label class='labelStyle'>
                        <!-- {{'LANDING-PAGE.HEADING' | translate}} -->
                        Heading
                      </label>
                      <textarea class='form-control inputTextStyle l-page' placeholder="Heading" [mention]="items" [mentionConfig]="{triggerChar:'#',maxItems:10,labelKey:'name'}"
                        formControlName="heading" (focus)="errorDetails = ''"></textarea>
                    </div>
                    <div class='form-group'>
                      <label class='labelStyle'>
                        <!-- {{'LANDING-PAGE.SUB-HEADING' | translate}} -->
                        Sub-Heading
                      </label>
                      <textarea class='form-control inputTextStyle l-page' placeholder="Sub-Heading" [mention]="items" [mentionConfig]="{triggerChar:'#',maxItems:10,labelKey:'name'}"
                        formControlName="subHeading" (focus)="errorDetails = ''"></textarea>
                    </div>
                    <!-- <div class='form-group'>
                      <label class='labelStyle'>
                        {{'LANDING-PAGE.CONTENT' | translate}}
                        Content
                      </label>
                      <textarea class='form-control inputTextStyle l-page' placeholder="Content" [mention]="items" [mentionConfig]="{triggerChar:'#',maxItems:10,labelKey:'name'}"
                        formControlName="content" (focus)="errorDetails = ''"></textarea>
                    </div> -->
                    <div class='form-group'>
                      <label class='labelStyle'>
                        Display Banner before Birthday (days)
                      </label>
                      <input type='number' class="form-control inputTextStyle" formControlName="showBefore" onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                        (focus)="errorDetails = ''" />
                    </div>
                    <div class='form-group'>
                      <label class='labelStyle'>
                        Display Banner after Birthday (days) 
                      </label>
                      <input type='number' class="form-control inputTextStyle" formControlName="showAfter" onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                        (focus)="errorDetails = ''" />
                    </div>
                    <div class='form-group'>
                      <label class='labelStyle'>
                        <!-- {{'LANDING-PAGE.BUTTON-TEXT' | translate}} -->
                        Button Text
                      </label>
                      <input type='text' class='form-control inputTextStyle buttonText' placeholder="View Member Points" formControlName="buttonText"
                        (focus)="errorDetails = ''" />
                    </div>
                    <div class='form-group'>
                      <label class='labelStyle'>
                        <!-- {{'LANDING-PAGE.BUTTON-ACTION' | translate}} -->
                        Button Action
                      </label>
                      <label class="radio">
                        <input id="birthdayBanner1" type="radio" value="Member Points" formControlName="buttonActionType" (focus)="errorDetails = ''">
                        <span class="outer"></span>
                        <span class='radio-text'>
                          <!-- {{'LANDING-PAGE.MEMBER-POINTS' | translate}} -->
                          View Member Points
                        </span>
                      </label>
                      <label class="radio">
                        <input id="birthdayBanner2" type="radio" value="External URL" formControlName="buttonActionType" (focus)="errorDetails = ''">
                        <span class="outer"></span>
                        <span class='radio-text'>
                          <!-- {{'LANDING-PAGE.EXTERNAL-URL' | translate}} -->
                          External URL
                        </span>
                      </label>
                    </div>
                    <div class='form-group' *ngIf="birthdayBannerEn.get('buttonActionType').value == 'External URL'">
                      <label class='labelStyle'>
                        <!-- {{'LANDING-PAGE.EXTERNAL-URL' | translate}} -->
                        External URL
                      </label>
                      <input type="text" class='form-control inputTextStyle l-page' placeholder="External URL" formControlName="externalUrl" (focus)="errorDetails = ''"
                        [ngClass]="birthdayBannerEn.get('externalUrl').hasError('pattern') || birthdayBannerEn.get('externalUrl').hasError('required') ? 'err' : ''">
                    </div>
                  </form>
                </div>
              </div>
              <!-- Birthday Banner Config -->
            </div>
          </div>
          <div class='col-sm-9 padding-right-0'>
            <div class='buttons-div clear text-right row'>
              <!-- <button class='btn btn-default btn-cancel' (click)="cancel('english')">Cancel</button> -->
              <div class="col-sm-6">
                <div class="error col-xs-12 error-align" *ngIf="errorDetails">{{errorDetails}}</div>
                <div class="success col-xs-12 success-align" *ngIf="successMsg">{{successMsg}}</div>
              </div>
              <div class="col-sm-6">
                <button class='btn btn-default btn-save' [ngClass]="(!defaultBannerEn.valid || !defaultBannerNonMemberEn.valid || !upcomingBannerEn.valid || !pastBannerEn.valid || !birthdayBannerEn.valid) ? 'disabled' : ''"
                  (click)="saveForm()">
                  <img src='assets/images/save.svg' class='pull-left' />
                  <span class='pull-left'>Save</span>
                </button>
              </div>
            </div>
            <!-- <div class="error col-xs-12" *ngIf="errorDetails">{{errorDetails}}</div>
            <div class="success col-xs-12" *ngIf="successMsg">{{successMsg}}</div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- English -->

    <!-- Spanish -->
    <div id="Span" class="tab-pane fade">
      <div class="centered-content">
        <div>
          <div class="col-sm-9 tabPaneBorder">
            <div class='editContainer col-sm-9'>
              <div class='row'>
                <div class='col-xs-12'>
                  <h3 class='step-heading'>
                    <!-- {{'LANDING-PAGE.STEP1-HEADING' | translate}} -->
                    Paso 1.) Configuración del Banner Hero
                  </h3>
                  <p class='heading-text'>
                    <!-- {{'LANDING-PAGE.STEP1-TEXT' | translate}} -->
                    Este es el primer banner que los miembros verán en su página. Se ubica justo debajo del encabezado del sitio web y los cambios
                    se basan en el nivel de lealtad de los miembros, el historial de reservas y el historial de compras.
                  </p>
                </div>
              </div>

              <!-- Default Banner Config -->
              <div class='row'>
                <div class='col-xs-12'>
                  <p class='title'>
                    <!-- {{'LANDING-PAGE.CONFIGURATION-1' | translate}} -->
                    Configuración predeterminada de Banner
                  </p>
                  <form [formGroup]="defaultBannerEs">
                    <div class='form-group row upload-image-container'>
                      <div class='col-sm-7'>
                        <label class='labelStyle'>
                          <!-- {{'LANDING-PAGE.BACKGROUND-IMAGE' | translate}} -->
                          Imagen de fondo
                        </label>
                        <p>{{defaultBannerEs.get('backgroundImage').value ? defaultBannerEs.get('backgroundImage').value : 'no file choosen'}}</p>
                        <input class='form-control' type='file' #uploadDefaultBannerEs (change)="fileChanged($event,'defaultBannerEs', 'uploadDefaultBannerEs')"
                          (focus)="errorDetails = ''" />
                        <button (click)="uploadDefaultBannerEs.click()" class='btn-file'>
                          <!-- {{'LANDING-PAGE.UPLOAD-PHOTO' | translate}} -->
                          <!-- Subir foto -->
                          {{defaultBannerEs.get('backgroundImage').value ? 'Cambiar Fotografía' : 'Escoge una foto'}}
                        </button>
                        <p *ngIf="defaultBannerEs.get('backgroundImageError').value" class="error upload">Subir solo imagen.</p>
                        <p *ngIf="defaultBannerEs.get('backgroundImageSizeError').value" class="error upload">El tamaño de la imagen debe ser inferior a 1 MB.</p>
                      </div>
                      <div class='col-sm-5' *ngIf="defaultBannerEs.get('backgroundImage').value && !defaultBannerEs.get('backgroundImageError').value && !defaultBannerEs.get('backgroundImageSizeError').value">
                        <img class="image" [src]="defaultBannerEs.get('backgroundImageUrl').value ? defaultBannerEs.get('backgroundImageUrl').value : getImgStyle(defaultBannerEs.get('id').value, defaultBannerEs.get('backgroundImage').value)"
                        />
                        <button class='btn btn-default delete-image'>
                          <img src='assets/images/delete.svg' (click)="deleteImage('defaultBannerEs','spanish')" />
                        </button>
                      </div>
                    </div>
                    <div class='form-group'>
                      <label class='labelStyle'>
                        <!-- {{'LANDING-PAGE.HEADING' | translate}} -->
                        Título
                      </label>
                      <textarea class='form-control inputTextStyle l-page' [mention]="items" [mentionConfig]="{triggerChar:'#',maxItems:10,labelKey:'name'}"
                        formControlName="heading" placeholder="Título" (focus)="errorDetails = ''"></textarea>
                    </div>
                    <div class='form-group'>
                      <label class='labelStyle'>
                        <!-- {{'LANDING-PAGE.SUB-HEADING' | translate}} -->
                        Subtítulo
                      </label>
                      <textarea class='form-control inputTextStyle l-page' [mention]="items" [mentionConfig]="{triggerChar:'#',maxItems:10,labelKey:'name'}"
                        formControlName="subHeading" placeholder="Subtítulo" (focus)="errorDetails = ''"></textarea>
                    </div>
                    <!-- <div class='form-group'>
                      <label class='labelStyle'>
                        {{'LANDING-PAGE.CONTENT' | translate}}
                        Contenido
                      </label>
                      <textarea class='form-control inputTextStyle l-page' [mention]="items" [mentionConfig]="{triggerChar:'#',maxItems:10,labelKey:'name'}"
                        formControlName="content" placeholder="Contenido" (focus)="errorDetails = ''"></textarea>
                    </div> -->
                    <div class='form-group'>
                      <label class='labelStyle'>
                        <!-- {{'LANDING-PAGE.BUTTON-TEXT' | translate}} -->
                        Botón de texto
                      </label>
                      <input type='text' class='form-control inputTextStyle buttonText' formControlName="buttonText" placeholder="Botón de texto"
                        (focus)="errorDetails = ''" />
                    </div>
                    <div class='form-group'>
                      <label class='labelStyle'>
                        <!-- {{'LANDING-PAGE.BUTTON-ACTION' | translate}} -->
                        Acción del botón
                      </label>
                      <label class="radio">
                        <input id="deafultBnrEs1" type="radio" value="Book Now" formControlName="buttonActionType" (focus)="errorDetails = ''">
                        <span class="outer"></span>
                        <span class='radio-text'>
                          <!-- {{'LANDING-PAGE.BOOK-NOW' | translate}} -->
                          Reservar ahora
                        </span>
                      </label>
                      <label class="radio">
                        <input id="deafultBnrEs2" type="radio" value="External URL" formControlName="buttonActionType" (focus)="errorDetails = ''">
                        <span class="outer"></span>
                        <span class='radio-text'>
                          <!-- {{'LANDING-PAGE.EXTERNAL-URL' | translate}}  -->
                          URL externa
                        </span>
                      </label>
                    </div>
                    <div class='form-group' *ngIf="defaultBannerEs.get('buttonActionType').value == 'External URL'">
                      <label class='labelStyle'>
                        <!-- {{'LANDING-PAGE.EXTERNAL-URL' | translate}} -->
                        URL externa
                      </label>
                      <input type="text" class='form-control inputTextStyle l-page' formControlName="externalUrl" placeholder="External URL" (focus)="errorDetails = ''"
                        [ngClass]="defaultBannerEs.get('externalUrl').hasError('pattern') || defaultBannerEs.get('externalUrl').hasError('required') ? 'err' : ''">
                    </div>
                  </form>
                </div>
              </div>
              <!-- Default Banner Config -->

              <!-- Default Banner Non Member Config -->
              <div class='row'>
                <div class='col-xs-12'>
                  <p class='title'>
                    <!-- {{'LANDING-PAGE.CONFIGURATION-2' | translate}} -->
                    Configuración de banner predeterminada: no miembro
                  </p>
                  <form [formGroup]="defaultBannerNonMemberEs">
                    <div class='form-group row upload-image-container'>
                      <div class='col-sm-7'>
                        <label class='labelStyle'>
                          <!-- {{'LANDING-PAGE.BACKGROUND-IMAGE' | translate}} -->
                          Imagen de fondo
                        </label>
                        <p>{{defaultBannerNonMemberEs.get('backgroundImage').value ? defaultBannerNonMemberEs.get('backgroundImage').value : 'no file choosen'}}</p>
                        <input class='form-control' type='file' #uploadDefaultBannerNonMemberEs (change)="fileChanged($event,'defaultBannerNonMemberEs', 'uploadDefaultBannerNonMemberEs')"
                          (focus)="errorDetails = ''" />
                        <button (click)="uploadDefaultBannerNonMemberEs.click()" class='btn-file'>
                          <!-- {{'LANDING-PAGE.UPLOAD-PHOTO' | translate}} -->
                          <!-- Subir foto -->
                          {{defaultBannerNonMemberEs.get('backgroundImage').value ? 'Cambiar Fotografía' : 'Escoge una foto'}}
                        </button>
                        <p *ngIf="defaultBannerNonMemberEs.get('backgroundImageError').value" class="error upload">Subir solo imagen.</p>
                        <p *ngIf="defaultBannerNonMemberEs.get('backgroundImageSizeError').value" class="error upload">El tamaño de la imagen debe ser inferior a 1 MB.</p>
                      </div>
                      <div class='col-sm-5' *ngIf="defaultBannerNonMemberEs.get('backgroundImage').value && !defaultBannerNonMemberEs.get('backgroundImageError').value && !defaultBannerNonMemberEs.get('backgroundImageSizeError').value">
                        <img class="image" [src]="defaultBannerNonMemberEs.get('backgroundImageUrl').value ? defaultBannerNonMemberEs.get('backgroundImageUrl').value : getImgStyle(defaultBannerNonMemberEs.get('id').value, defaultBannerNonMemberEs.get('backgroundImage').value)"
                        />
                        <button class='btn btn-default delete-image'>
                          <img src='assets/images/delete.svg' (click)="deleteImage('defaultBannerNonMemberEs','spanish')" />
                        </button>
                      </div>
                    </div>
                    <div class='form-group'>
                      <label class='labelStyle'>
                        <!-- {{'LANDING-PAGE.HEADING' | translate}} -->
                        Título
                      </label>
                      <textarea class='form-control inputTextStyle l-page' [mention]="items" [mentionConfig]="{triggerChar:'#',maxItems:10,labelKey:'name'}"
                        formControlName="heading" placeholder="Título" (focus)="errorDetails = ''"></textarea>
                    </div>
                    <div class='form-group'>
                      <label class='labelStyle'>
                        <!-- {{'LANDING-PAGE.SUB-HEADING' | translate}} -->
                        Subtítulo
                      </label>
                      <textarea class='form-control inputTextStyle l-page' [mention]="items" [mentionConfig]="{triggerChar:'#',maxItems:10,labelKey:'name'}"
                        formControlName="subHeading" placeholder="Subtítulo" (focus)="errorDetails = ''"></textarea>
                    </div>
                    <!-- <div class='form-group'>
                      <label class='labelStyle'>
                        {{'LANDING-PAGE.CONTENT' | translate}}
                        Contenido
                      </label>
                      <textarea class='form-control inputTextStyle l-page' [mention]="items" [mentionConfig]="{triggerChar:'#',maxItems:10,labelKey:'name'}"
                        formControlName="content" placeholder="Contenido" (focus)="errorDetails = ''"></textarea>
                    </div> -->
                    <div class='form-group'>
                      <label class='labelStyle'>
                        <!-- {{'LANDING-PAGE.BUTTON-TEXT' | translate}} -->
                        Botón de texto
                      </label>
                      <input type='text' class='form-control inputTextStyle buttonText' formControlName="buttonText" placeholder="Botón de texto"
                        (focus)="errorDetails = ''" />
                    </div>
                    <div class='form-group'>
                      <label class='labelStyle'>
                        <!-- {{'LANDING-PAGE.BUTTON-ACTION' | translate}} -->
                        Acción del botón
                      </label>
                      <label class="radio">
                        <input id="deafultBnrNonMemberEs1" type="radio" value="Register" formControlName="buttonActionType" (focus)="errorDetails = ''">
                        <span class="outer"></span>
                        <span class='radio-text'>
                          <!-- {{'LANDING-PAGE.SIGNUP' | translate}} -->
                          Regístrate
                        </span>
                      </label>
                      <label class="radio">
                        <input id="deafultBnrNonMemberEs2" type="radio" value="External URL" formControlName="buttonActionType" (focus)="errorDetails = ''">
                        <span class="outer"></span>
                        <span class='radio-text'>
                          <!-- {{'LANDING-PAGE.EXTERNAL-URL' | translate}} -->
                          URL externa
                        </span>
                      </label>
                    </div>
                    <div class='form-group' *ngIf="defaultBannerNonMemberEs.get('buttonActionType').value == 'External URL'">
                      <label class='labelStyle'>
                        <!-- {{'LANDING-PAGE.EXTERNAL-URL' | translate}} -->
                        URL externa
                      </label>
                      <input type="text" class='form-control inputTextStyle l-page' placeholder="URL externa" formControlName="externalUrl" (focus)="errorDetails = ''"
                        [ngClass]="defaultBannerNonMemberEs.get('externalUrl').hasError('pattern') || defaultBannerNonMemberEs.get('externalUrl').hasError('required') ? 'err' : ''">
                    </div>
                  </form>
                </div>
              </div>
              <!-- Default Banner Non Member Config -->

              <!-- Upcoming Banner Config -->
              <div class='row'>
                <div class='col-xs-12'>
                  <p class='title'>
                    <!-- {{'LANDING-PAGE.CONFIGURATION-3' | translate}} -->
                    Próxima configuración de Banner de visita
                  </p>
                  <label class='labelStyle margin-bottom25'>
                    Para mostrar el nombre del hotel del banner recuperado en la página de destino del miembro, mencione @HotelName cuando sea
                    necesario en las secciones de encabezado / subtítulo.
                  </label>
                  <form [formGroup]="upcomingBannerEs">
                    <!-- <div class='form-group row'>
                      <div class='col-sm-7'>
                        <label class='labelStyle'>{{'LANDING-PAGE.BACKGROUND-IMAGE' | translate}}</label>
                        <input class='form-control' type='file' #uploadFile (change)="uploadImage($event,'logo')" (focus)="errorDetails = ''" />
                        <button (click)="uploadFile.click()" class='btn-file'>{{'LANDING-PAGE.UPLOAD-PHOTO' | translate}}</button>
                      </div>
                      <div class='col-sm-5'>
                        <img class="image" [src]="imageFile_logo.imageUrl" />
                        <button class='btn btn-default delete-image'>
                          <img src='assets/images/delete.svg' (click)="deleteImage('upcomingBannerEs','spanish')" />
                        </button>
                      </div>
                    </div> -->
                    <div class='form-group'>
                      <label class='labelStyle'>
                        <!-- {{'LANDING-PAGE.HEADING' | translate}} -->
                        Título
                      </label>
                      <textarea class='form-control inputTextStyle l-page' placeholder="Título" [mention]="items" [mentionConfig]="{triggerChar:'#',maxItems:10,labelKey:'name'}"
                        formControlName="heading" (focus)="errorDetails = ''"></textarea>
                    </div>
                    <div class='form-group'>
                      <label class='labelStyle'>
                        <!-- {{'LANDING-PAGE.SUB-HEADING' | translate}} -->
                        Subtítulo
                      </label>
                      <textarea class='form-control inputTextStyle l-page' placeholder="Subtítulo" [mention]="items" [mentionConfig]="{triggerChar:'#',maxItems:10,labelKey:'name'}"
                        formControlName="subHeading" (focus)="errorDetails = ''"></textarea>
                    </div>
                    <!-- <div class='form-group'>
                      <label class='labelStyle'>
                        {{'LANDING-PAGE.CONTENT' | translate}}
                        Contenido
                      </label>
                      <textarea class='form-control inputTextStyle l-page' placeholder="Contenido" [mention]="items" [mentionConfig]="{triggerChar:'#',maxItems:10,labelKey:'name'}"
                        formControlName="content" (focus)="errorDetails = ''"></textarea>
                    </div> -->
                    <div class='form-group'>
                      <label class='labelStyle'>
                        <!-- {{'LANDING-PAGE.BUTTON-TEXT' | translate}} -->
                        Botón de texto
                      </label>
                      <input type='text' class='form-control inputTextStyle buttonText' placeholder="Ver reserva" formControlName="buttonText"
                        (focus)="errorDetails = ''" />
                    </div>
                    <div class='form-group'>
                      <label class='labelStyle'>
                        <!-- {{'LANDING-PAGE.BUTTON-ACTION' | translate}} -->
                        Acción del botón
                      </label>
                      <label class="radio">
                        <input id="upcomingBannerEs1" type="radio" value="View Booking" formControlName="buttonActionType" (focus)="errorDetails = ''">
                        <span class="outer"></span>
                        <span class='radio-text'>
                          <!-- {{'LANDING-PAGE.VIEW-BOOKING' | translate}} -->
                          Ver reserva
                        </span>
                      </label>
                      <label class="radio">
                        <input id="upcomingBannerEs2" type="radio" value="External URL" formControlName="buttonActionType" (focus)="errorDetails = ''">
                        <span class="outer"></span>
                        <span class='radio-text'>
                          <!-- {{'LANDING-PAGE.EXTERNAL-URL' | translate}} -->
                          URL externa
                        </span>
                      </label>
                    </div>
                    <div class='form-group' *ngIf="upcomingBannerEs.get('buttonActionType').value == 'External URL'">
                      <label class='labelStyle'>
                        <!-- {{'LANDING-PAGE.EXTERNAL-URL' | translate}} -->
                        URL externa
                      </label>
                      <input type="text" class='form-control inputTextStyle l-page' placeholder="URL externa" formControlName="externalUrl" (focus)="errorDetails = ''"
                        [ngClass]="upcomingBannerEs.get('externalUrl').hasError('pattern') || upcomingBannerEs.get('externalUrl').hasError('required') ? 'err' : ''">
                    </div>
                  </form>
                </div>
              </div>
              <!-- Upcoming Banner Config -->

              <!-- Past Banner Config -->
              <div class='row'>
                <div class='col-xs-12'>
                  <p class='title'>
                    <!-- {{'LANDING-PAGE.CONFIGURATION-4' | translate}} -->
                    Configuración de Banner de visitas pasadas
                  </p>
                  <label class='labelStyle margin-bottom25'>
                    Para mostrar el nombre del hotel del banner recuperado en la página de destino del miembro, mencione @HotelName cuando sea
                    necesario en las secciones de encabezado / subtítulo.
                  </label>
                  <form [formGroup]="pastBannerEs">
                    <!-- <div class='form-group row'>
                      <div class='col-sm-7'>
                        <label class='labelStyle'>{{'LANDING-PAGE.BACKGROUND-IMAGE' | translate}}</label>
                        <input class='form-control' type='file' #uploadFile (change)="uploadImage($event,'logo')" (focus)="errorDetails = ''" />
                        <button (click)="uploadFile.click()" class='btn-file'>{{'LANDING-PAGE.UPLOAD-PHOTO' | translate}}</button>
                      </div>
                      <div class='col-sm-5'>
                        <img class="image" [src]="imageFile_logo.imageUrl" />
                        <button class='btn btn-default delete-image'>
                          <img src='assets/images/delete.svg' (click)="deleteImage('pastBannerEn','spanish')" />
                        </button>
                      </div>
                    </div> -->
                    <div class='form-group'>
                      <label class='labelStyle'>
                        <!-- {{'LANDING-PAGE.HEADING' | translate}} -->
                        Título
                      </label>
                      <textarea class='form-control inputTextStyle l-page' placeholder="Título" [mention]="items" [mentionConfig]="{triggerChar:'#',maxItems:10,labelKey:'name'}"
                        formControlName="heading" (focus)="errorDetails = ''"></textarea>
                    </div>
                    <div class='form-group'>
                      <label class='labelStyle'>
                        <!-- {{'LANDING-PAGE.SUB-HEADING' | translate}} -->
                        Subtítulo
                      </label>
                      <textarea class='form-control inputTextStyle l-page' placeholder="Subtítulo" [mention]="items" [mentionConfig]="{triggerChar:'#',maxItems:10,labelKey:'name'}"
                        formControlName="subHeading" (focus)="errorDetails = ''"></textarea>
                    </div>
                    <!-- <div class='form-group'>
                      <label class='labelStyle'>
                        {{'LANDING-PAGE.CONTENT' | translate}}
                        Contenido
                      </label>
                      <textarea class='form-control inputTextStyle l-page' placeholder="Contenido" [mention]="items" [mentionConfig]="{triggerChar:'#',maxItems:10,labelKey:'name'}"
                        formControlName="content" (focus)="errorDetails = ''"></textarea>
                    </div> -->
                    <div class='form-group'>
                      <label class='labelStyle'>
                        Mostrar Banner después de la visita pasada (días)
                      </label>
                      <input type='number' class="form-control inputTextStyle" formControlName="showForDays" onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                        (focus)="errorDetails = ''" />
                    </div>
                    <div class='form-group'>
                      <label class='labelStyle'>
                        <!-- {{'LANDING-PAGE.BUTTON-TEXT' | translate}} -->
                        Botón de texto
                      </label>
                      <input type='text' class='form-control inputTextStyle buttonText' placeholder="Tomar encuesta" formControlName="buttonText"
                        (focus)="errorDetails = ''" />
                    </div>
                    <div class='form-group'>
                      <label class='labelStyle'>
                        <!-- {{'LANDING-PAGE.BUTTON-ACTION' | translate}} -->
                        Acción del botón
                      </label>
                      <!-- <label class="radio">
                        <input id="pastBannerEs1" type="radio" value="Take Survey" formControlName="buttonActionType" (focus)="errorDetails = ''">
                        <span class="outer"></span>
                        <span class='radio-text'>                         
                          Tomar encuesta
                        </span>
                      </label> -->
                      <label class="radio">
                        <input id="pastBannerEs2" type="radio" value="External URL" formControlName="buttonActionType" (focus)="errorDetails = ''">
                        <span class="outer"></span>
                        <span class='radio-text'>
                          <!-- {{'LANDING-PAGE.EXTERNAL-URL' | translate}} -->
                          URL externa
                        </span>
                      </label>
                    </div>
                    <div class='form-group' *ngIf="pastBannerEs.get('buttonActionType').value == 'External URL'">
                      <label class='labelStyle'>
                        <!-- {{'LANDING-PAGE.EXTERNAL-URL' | translate}} -->
                        URL externa
                      </label>
                      <input type="text" class='form-control inputTextStyle l-page' placeholder="URL externa" formControlName="externalUrl" (focus)="errorDetails = ''"
                        [ngClass]="pastBannerEs.get('externalUrl').hasError('pattern') || pastBannerEs.get('externalUrl').hasError('required') ? 'err' : ''">
                    </div>
                  </form>
                </div>
              </div>
              <!-- Past Banner Config -->

              <!-- Birthday Banner Config -->
              <div class='row'>
                <div class='col-xs-12'>
                  <p class='title'>
                    <!-- {{'LANDING-PAGE.CONFIGURATION-5' | translate}} -->
                    Configuración de cumpleaños
                  </p>
                  <form [formGroup]="birthdayBannerEs">
                    <div class='form-group row upload-image-container'>
                      <div class='col-sm-7'>
                        <label class='labelStyle'>
                          <!-- {{'LANDING-PAGE.BACKGROUND-IMAGE' | translate}} -->
                          Imagen de fondo
                        </label>
                        <p>{{birthdayBannerEs.get('backgroundImage').value ? birthdayBannerEs.get('backgroundImage').value : 'no file choosen'}}</p>
                        <input class='form-control' type='file' #uploadbirthdayBannerEs (change)="fileChanged($event,'birthdayBannerEs', 'uploadbirthdayBannerEs')"
                          (focus)="errorDetails = ''" />
                        <button (click)="uploadbirthdayBannerEs.click()" class='btn-file'>
                          <!-- {{'LANDING-PAGE.UPLOAD-PHOTO' | translate}} -->
                          <!-- Subir foto -->
                          {{birthdayBannerEs.get('backgroundImage').value ? 'Cambiar Fotografía' : 'Escoge una foto'}}
                        </button>
                        <p *ngIf="birthdayBannerEs.get('backgroundImageError').value" class="error upload">Subir solo imagen.</p>
                        <p *ngIf="birthdayBannerEs.get('backgroundImageSizeError').value" class="error upload">El tamaño de la imagen debe ser inferior a 1 MB.</p>
                      </div>
                      <div class='col-sm-5' *ngIf="birthdayBannerEs.get('backgroundImage').value && !birthdayBannerEs.get('backgroundImageError').value && !birthdayBannerEs.get('backgroundImageSizeError').value">
                        <img class="image" [src]="birthdayBannerEs.get('backgroundImageUrl').value ? birthdayBannerEs.get('backgroundImageUrl').value : getImgStyle(birthdayBannerEs.get('id').value, birthdayBannerEs.get('backgroundImage').value)"
                        />
                        <button class='btn btn-default delete-image'>
                          <img src='assets/images/delete.svg' (click)="deleteImage('birthdayBannerEs','spanish')" />
                        </button>
                      </div>
                    </div>
                    <div class='form-group'>
                      <label class='labelStyle'>
                        <!-- {{'LANDING-PAGE.HEADING' | translate}} -->
                        Título
                      </label>
                      <textarea class='form-control inputTextStyle l-page' placeholder="Título" [mention]="items" [mentionConfig]="{triggerChar:'#',maxItems:10,labelKey:'name'}"
                        formControlName="heading" (focus)="errorDetails = ''"></textarea>
                    </div>
                    <div class='form-group'>
                      <label class='labelStyle'>
                        <!-- {{'LANDING-PAGE.SUB-HEADING' | translate}} -->
                        Subtítulo
                      </label>
                      <textarea class='form-control inputTextStyle l-page' placeholder="Subtítulo" [mention]="items" [mentionConfig]="{triggerChar:'#',maxItems:10,labelKey:'name'}"
                        formControlName="subHeading" (focus)="errorDetails = ''"></textarea>
                    </div>
                    <!-- <div class='form-group'>
                      <label class='labelStyle'>
                        {{'LANDING-PAGE.CONTENT' | translate}}
                        Contenido
                      </label>
                      <textarea class='form-control inputTextStyle l-page' placeholder="Contenido" [mention]="items" [mentionConfig]="{triggerChar:'#',maxItems:10,labelKey:'name'}"
                        formControlName="content" (focus)="errorDetails = ''"></textarea>
                    </div> -->
                    <div class='form-group'>
                      <label class='labelStyle'>
                        Mostrar Banner antes del cumpleaños (días)
                      </label>
                      <input type='number' class="form-control inputTextStyle" onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                        formControlName="showBefore" (focus)="errorDetails = ''" />
                    </div>
                    <div class='form-group'>
                      <label class='labelStyle'>
                        Mostrar Banner después del cumpleaños (días)
                      </label>
                      <input type='number' class="form-control inputTextStyle" onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                        formControlName="showAfter" (focus)="errorDetails = ''" />
                    </div>
                    <div class='form-group'>
                      <label class='labelStyle'>
                        <!-- {{'LANDING-PAGE.BUTTON-TEXT' | translate}} -->
                        Botón de texto
                      </label>
                      <input type='text' class='form-control inputTextStyle buttonText' placeholder="Ver puntos de miembro" formControlName="buttonText"
                        (focus)="errorDetails = ''" />
                    </div>
                    <div class='form-group'>
                      <label class='labelStyle'>
                        <!-- {{'LANDING-PAGE.BUTTON-ACTION' | translate}} -->
                        Acción del botón
                      </label>
                      <label class="radio">
                        <input id="birthdayBannerEs1" type="radio" value="Member Points" formControlName="buttonActionType" (focus)="errorDetails = ''">
                        <span class="outer"></span>
                        <span class='radio-text'>
                          <!-- {{'LANDING-PAGE.MEMBER-POINTS' | translate}} -->
                          Ver puntos de miembro
                        </span>
                      </label>
                      <label class="radio">
                        <input id="birthdayBannerEs2" type="radio" value="External URL" formControlName="buttonActionType" (focus)="errorDetails = ''">
                        <span class="outer"></span>
                        <span class='radio-text'>
                          <!-- {{'LANDING-PAGE.EXTERNAL-URL' | translate}} -->
                          URL externa
                        </span>
                      </label>
                    </div>
                    <div class='form-group' *ngIf="birthdayBannerEs.get('buttonActionType').value == 'External URL'">
                      <label class='labelStyle'>
                        <!-- {{'LANDING-PAGE.EXTERNAL-URL' | translate}} -->
                        URL externa
                      </label>
                      <input type="text" class='form-control inputTextStyle l-page' placeholder="URL externa" formControlName="externalUrl" (focus)="errorDetails = ''"
                        [ngClass]="birthdayBannerEs.get('externalUrl').hasError('pattern') || birthdayBannerEs.get('externalUrl').hasError('required') ? 'err' : ''">
                    </div>
                  </form>
                </div>
              </div>
              <!-- Birthday Banner Config -->
            </div>
          </div>
          <div class='col-sm-9 padding-right-0'>
            <div class='row buttons-div clear text-right'>
              <!-- <button class='btn btn-default btn-cancel' (click)="cancel('spanish')">Cancelar</button> -->
              <div class="col-sm-6">
                <div class="error col-xs-12 error-align" *ngIf="errorDetails">{{errorDetails}}</div>
                <div class="success col-xs-12 success-align" *ngIf="successMsg">{{successMsg}}</div>
              </div>
              <div class="col-sm-6">
                <button class='btn btn-default btn-save' [ngClass]="(!defaultBannerEs.valid || !defaultBannerNonMemberEs.valid || !upcomingBannerEs.valid || !pastBannerEs.valid || !birthdayBannerEs.valid) ? 'disabled' : ''"
                  (click)="saveForm()">
                  <img src='assets/images/save.svg' class='pull-left' />
                  <span class='pull-left'>Salvar</span>
                </button>
              </div>
            </div>
            <!-- <div class="error col-xs-12" *ngIf="errorDetails">{{errorDetails}}</div>
            <div class="success col-xs-12" *ngIf="successMsg">{{successMsg}}</div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- Spanish -->



  </div>
</div>