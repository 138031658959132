<div class='col-sm-9 col-sm-offset-3 booking-container'>
  <div class="row">
    <h3 class="col-sm-4 heading padding-0">{{!bookingId ? 'New' : 'Modify'}} Booking</h3>
  </div>
  <div class="row">
    <form [formGroup]="bookingForm" class="booking-form">
      <div class="row">
        <div class="col-sm-4 form-group">
          <label>Locator*</label>
          <input type="text" class="form-control" placeholder="Locator" formControlName="locator"
          [ngClass]="bookingForm.get('locator').hasError('maxlength') 
            || (bookingForm.get('locator').hasError('whitespace') || bookingForm.get('locator').hasError('required')) && bookingForm.get('locator').touched ? 'err' : ''"  
          onkeydown="javascript: return /^[\w\-\s]*$/.test(event.key) ? true : false" [readonly]="!isNewPmsMigration"/>
        </div>
      </div>
      <div class="row dates-row">
        <div class="col-sm-4 form-group">
          <label>Checkin</label>
          <input type="text" [formControl]="bookingForm.controls['checkInDate']" class="form-control date" *ngIf="!isNewPmsMigration"
            placeholder="Check In Date"  readonly>
          <input type="text" [formControl]="bookingForm.controls['checkInDate']" class="form-control date" *ngIf="isNewPmsMigration"
            onkeydown='return event.which == 8 || event.which == 46 || event.which == 37 || event.which == 39'
            placeholder="Check In Date" bsDatepicker #dp1="bsDatepicker" placement="bottom" [bsConfig]="bsConfig">
        </div>
        <div class="col-sm-4 form-group">
          <label>Checkout</label>
          <input type="text" [formControl]="bookingForm.controls['checkOutDate']" class="form-control date" *ngIf="!isNewPmsMigration"
            placeholder="Check Out Date" readonly>
          <input type="text" [formControl]="bookingForm.controls['checkOutDate']" class="form-control date" *ngIf="isNewPmsMigration"
            onkeydown='return event.which == 8 || event.which == 46 || event.which == 37 || event.which == 39'
            placeholder="Check Out Date" bsDatepicker #dp2="bsDatepicker" placement="bottom" [bsConfig]="bsConfig">
        </div>
        
        <p class="col-xs-12 error text-left" *ngIf="bookingForm?.errors">Check In Date must not be greater than Check
          Out Date</p>
      </div>
      <div class="row search">
      </div>
      <div class="row">
        <div class="col-sm-4 form-group custom-dropdown">
          <label>Hotel</label>
          <input type="text" class="form-control" [formControl]="bookingForm.controls['hotelName']" readonly
            value="{{bookingForm.get('hotelName').value}}">
          <a class="add-hotel-btn" (click)="goToHotel()">
            <img src='assets/images/plus.svg' class='pull-left' />
          </a>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 form-group">
          <label>Agency</label>
          <input type="text" class="form-control" placeholder="Agency" formControlName="agency" 
          [ngClass]="bookingForm.get('agency').hasError('maxlength') ? 'err' : ''" [readonly]="!isNewPmsMigration"/>
        </div>
        <div class="col-sm-4 form-group">
          <label>Adults</label>
          <input type="text" class="form-control" placeholder="Adults" formControlName="adults" 
          [ngClass]="bookingForm.get('adults').hasError('maxlength') || (bookingForm.get('adults').hasError('whitespace') 
            || bookingForm.get('adults').hasError('required')) && bookingForm.get('locator').touched ? 'err' : ''"  
          onkeypress='return event.charCode >= 48 && event.charCode <= 57' [readonly]="!isNewPmsMigration"/>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 form-group">
          <label>Direct</label>
          <select class="form-control" formControlName="direct">
            <option [value]="''" selected>Select Direct</option>
            <option *ngFor="let direct of filterLookUpData?.direct" [value]="direct">{{direct}}</option>
          </select>
        </div>
        <div class="col-sm-4 form-group">
          <label>Treatment</label>
          <select class="form-control" formControlName="treatmentId" [ngClass]="bookingForm.get('treatmentId').hasError('required') && bookingForm.get('treatmentId').touched ? 'err' : ''" disabled>
            <option [value]="''" selected disabled>Select Treatment</option>
            <option *ngFor="let treatment of filterLookUpData?.treatment" [value]="treatment.id">{{treatment.treatment}}</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-2 form-group">
          <label>Stay Cost</label>
          <input type="text" class="form-control" placeholder="Stay Cost" 
          formControlName="stayCost" 
          [ngClass]="bookingForm.get('stayCost').hasError('min') || bookingForm.get('stayCost').hasError('max') || bookingForm.get('stayCost').hasError('pattern') 
          || (bookingForm.get('stayCost').hasError('whitespace') || bookingForm.get('stayCost').hasError('required')) && bookingForm.get('stayCost').touched ? 'err' : ''" 
          onkeydown='return event.which != 189' [readonly]="!isNewPmsMigration"/>
        </div>
        <div class="col-sm-2 form-group">
          <label>Net Cost</label>
          <input type="text" class="form-control" placeholder="Net Cost" 
          formControlName="netCost" 
          [ngClass]="bookingForm.get('netCost').hasError('min') || bookingForm.get('netCost').hasError('max') || bookingForm.get('netCost').hasError('pattern') 
          || (bookingForm.get('netCost').hasError('whitespace') || bookingForm.get('netCost').hasError('required')) && bookingForm.get('netCost').touched ? 'err' : ''" 
          [readonly]="!isNewPmsMigration"/>
        </div>
        <div class="col-sm-2 form-group">
          <label>Extra Cost</label>
          <input type="text" class="form-control" placeholder="Extra Cost" 
          formControlName="extraCost" 
          [ngClass]="bookingForm.get('extraCost').hasError('min') || bookingForm.get('extraCost').hasError('max') || bookingForm.get('extraCost').hasError('pattern') 
          || (bookingForm.get('extraCost').hasError('whitespace') || bookingForm.get('extraCost').hasError('required')) && bookingForm.get('extraCost').touched ? 'err' : ''" 
          [readonly]="!isNewPmsMigration"/>
        </div>
        <div class="col-sm-2 form-group">
          <label>Currency</label>
          <select class="form-control" formControlName="currency" *ngIf="!isNewPmsMigration"
          [ngClass]="bookingForm.get('currency').hasError('required') && bookingForm.get('currency').touched ? 'err' : ''" 
          disabled
          >
          <option [value]="''" selected disabled>Select currency</option>
          <option *ngFor="let origin of currencies" [value]="origin.id">{{origin.name}}</option>
          </select>

          <select class="form-control" formControlName="currency" *ngIf="isNewPmsMigration"
          [ngClass]="bookingForm.get('currency').hasError('required') && bookingForm.get('currency').touched ? 'err' : ''" 
          >
            <option [value]="''" selected disabled>Select currency</option>
            <option *ngFor="let origin of currencies" [value]="origin.id">{{origin.name}}</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 form-group">
          <label>PMS Id</label>
          <input type="text" class="form-control" placeholder="PMS Id" formControlName="pmsId"  
          [ngClass]="bookingForm.get('pmsId').hasError('maxlength') || (bookingForm.get('pmsId').hasError('whitespace')) && bookingForm.get('pmsId').touched ? 'err' : ''" 
          [readonly]="!isNewPmsMigration"/>
        </div>
        <div class="col-sm-4 form-group">
          <label>PMS Title</label>
          <input type="text" class="form-control" placeholder="PMS Title" formControlName="pmsEntitler" 
          [ngClass]="bookingForm.get('pmsEntitler').hasError('maxlength') || (bookingForm.get('pmsEntitler').hasError('whitespace')) && bookingForm.get('pmsEntitler').touched ? 'err' : ''" 
          [readonly]="!isNewPmsMigration"/>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 form-group">
          <label>PMS Bonus</label>
          <input type="text" class="form-control" placeholder="PMS Bonus" formControlName="pmsBonus" 
          [ngClass]="bookingForm.get('pmsBonus').hasError('maxlength') || (bookingForm.get('pmsBonus').hasError('whitespace')) && bookingForm.get('pmsBonus').touched ? 'err' : ''" 
          [readonly]="!isNewPmsMigration"/>
        </div>
        <div class="col-sm-4 form-group">
          <label>PMS Bookingreference</label>
          <input type="text" class="form-control" formControlName="pmsBookingReference"
            [ngClass]="bookingForm.get('pmsBookingReference').hasError('maxlength') || (bookingForm.get('pmsBookingReference').hasError('whitespace')) && bookingForm.get('pmsBookingReference').touched ? 'err' : ''" 
            [readonly]="!isNewPmsMigration"/>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-3 form-group">
          <label>State</label>
          <select class="form-control" formControlName="state" *ngIf="!isNewPmsMigration"
          [ngClass]="bookingForm.get('state').hasError('required') && bookingForm.get('state').touched ? 'err' : ''" 
          disabled
          >
          <option [value]="''" selected disabled>Select State</option>
            <option *ngFor="let state of filterLookUpData?.state" [value]="state.id">{{state.state}}</option>
          </select>

          <select class="form-control" formControlName="state" *ngIf="isNewPmsMigration"
          [ngClass]="bookingForm.get('state').hasError('required') && bookingForm.get('state').touched ? 'err' : ''" 
          >
            <option [value]="''" selected disabled>Select State</option>
            <option *ngFor="let state of filterLookUpData?.state" [value]="state.id">{{state.state}}</option>
          </select>
        
        </div>
        <div class="col-sm-2 form-group">
            <label>Origin</label>
            <input type="text" class="form-control" formControlName="origin"
              [ngClass]="bookingForm.get('pmsBookingReference').hasError('maxlength') 
              || (bookingForm.get('pmsBookingReference').hasError('whitespace')) && bookingForm.get('pmsBookingReference').touched ? 'err' : ''" 
              readonly/>
        </div>
        <div class="col-sm-3 form-group">
          <label>Referral Code</label>
          <input type="text" class="form-control" formControlName="referralCode"
            [ngClass]="bookingForm.get('pmsBookingReference').hasError('maxlength') 
            || (bookingForm.get('pmsBookingReference').hasError('whitespace')) && bookingForm.get('pmsBookingReference').touched ? 'err' : ''" 
            [readonly]="!editableReferralCodeField"/>
        </div>
      </div>
      <div class="col-sm-3 form-group">
        <div class="form-group">
          <label>Comments</label>
          <textarea class="form-control" 
                    formControlName="comments"
                    [ngClass]="bookingForm.get('pmsBookingReference').hasError('maxlength') 
                    || (bookingForm.get('pmsBookingReference').hasError('whitespace')) && bookingForm.get('pmsBookingReference').touched ? 'err' : ''"
                    style="width: 100%; height: 100px;"
                    ></textarea>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-7 form-group">
          <span *ngFor="let transaction of transactionData">
            <a class="hand font15" (click)="goToBookingTransaction(transaction.pointTransactionId);">Click here to view transaction of {{transaction.pointTransactionId}}</a><br>          
          </span>
        </div>
      </div>

      <div class="row buttons-container">
        <div class="col-sm-8" *ngIf="!bookingId">
          <button class="btn btn-cancel col-sm-2 pull-left" (click)="confirmClose()">Cancel</button>
          <button class="btn btn-record col-sm-2 pull-right" [disabled]="!bookingForm.valid" (click)="addBooking(false)">Record</button>
          <button class="btn btn-add col-sm-5 pull-right" [disabled]="!bookingForm.valid" (click)="addBooking(true)">Record
            and Add Another</button>
        </div>
        <div class="col-sm-8" *ngIf="bookingId">
            <button class="btn btn-deactivate col-sm-3 pull-left" (click)="openStatusModal([bookingForm.get('bookingId').value], 0)">Delete Booking</button>
            <button class="btn btn-record col-sm-3 pull-right" (click)="syncWithPms(false)" *ngIf="!isNewPmsMigration">
              Sync with PMS
            </button>
            <button class="btn btn-record col-sm-3 pull-right" (click)="addBooking(false)" *ngIf="isNewPmsMigration">
                <img src='assets/images/save.svg' class='pull-left'/>Save Booking</button>
            <button class="btn btn-cancel col-sm-2 pull-right" (click)="confirmClose()">Cancel</button>
        </div>
      </div>
      <p class="col-sm-12 error text-center" *ngIf="errorMsg">{{errorMsg}}</p>
      <p class="col-sm-12 success text-center" *ngIf="successMsg">{{successMsg}}</p>
    </form>
  </div>
</div>

