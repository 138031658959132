<div class='col-sm-9 col-sm-offset-3 main-container'>
  <div class='head-line'>
      <p>Choose a language</p>
      <p>Changing the language will reflect across all screens.</p>
  </div>
  <form novalidate [formGroup]="settings">
    <div class='row'>
      <div class='formDiv'>
        <div class="form-group col-sm-5">
          <label>Language</label>
          <select class='form-control pointer' formControlName="selectedLang">
            <option>Choose Language</option>
            <option *ngFor="let language of languages" [value]="language['code']" triggerValue>
              {{language['name']}}
            </option>
          </select>
        </div>
      </div>
    </div>
  </form>
</div>

<div class='col-sm-9 col-sm-offset-3 main-container'>
    <div class='head-line'>
        <p>Allow Login Access to All Brands</p>
        <p>Changing the option will let members have access to login to all brands of this hotelchain or only login to particular brand.</p>
    </div>
    <form novalidate [formGroup]="permissionSettings">
      <div class='row'>
        <div class='formDiv'>
          <div class="form-group col-sm-5">
            <label>Allow Login to All Brands</label>
            <div>
              <label class="radio">
                <input id="radio1" type="radio" [value]="true" formControlName="isAllowAccess">
                <span class="outer"></span>
                <span class='radio-text'>Yes</span>
              </label>
              <label class="radio">
                <input id="radio1" type="radio" [value]="false" formControlName="isAllowAccess">
                <span class="outer"></span>
                <span class='radio-text'>No</span>
              </label>
            </div>
            <div class="success col-xs-12 text-left" *ngIf="loginSuccessMsg">{{loginSuccessMsg}}</div>
            <div class="error col-xs-12 text-left" *ngIf="loginErrorMsg">{{loginErrorMsg}}</div>
          </div>
        </div>
      </div>
    </form>
  </div>
  