<div class='col-sm-9 col-sm-offset-3 marketing-container'>
  <div class='row header'>
    <!-- Catalog List start -->
    <div class="col-sm-5 dropdown text-right padding-top-8" [class.hide-ele]="!catalogs.length">
      <a class="dropdown-toggle headline" data-toggle="dropdown" *ngIf="selectedCatalog">{{selectedCatalog.catalogName}}
        <span class="glyphicon glyphicon-chevron-down pointer"></span>
      </a>
      <ul class="dropdown-menu nav-list">
        <li (click)="getRewardsByCatalog({catalogId: 0, catalogName: 'Rewards Catalog (All)'})" [class.active-catalog]="selectedCatalog && selectedCatalog.catalogId == 0">
          <a>
            <img *ngIf="selectedCatalog && selectedCatalog.catalogId == 0" src='assets/images/success.svg' class='pull-right success'
            />
            <span>Show All Rewards</span>
          </a>
        </li>
        <li *ngFor="let catalog of catalogs" class="pointer" (click)="getRewardsByCatalog(catalog)" [class.active-catalog]="selectedCatalog && selectedCatalog.catalogId == catalog.catalogId">
          <a>
            <img *ngIf="selectedCatalog && selectedCatalog.catalogId == catalog.catalogId" class='pull-right success' src='assets/images/success.svg'
            />
            <span>{{catalog.catalogName}}</span>
          </a>
        </li>
        <li class='divider'></li>
        <li class='plus pointer' (click)="catalogModal(selectedCatalog.catalogId)" *ngIf="selectedCatalog && selectedCatalog.catalogId != 0"
          [ngClass]="selectedCatalog && selectedCatalog.catalogId == 0 ? 'disabled': ''">
          <a>
            <img src='assets/images/edit.svg' />
            <span>Modify Selected Catalog</span>
          </a>
        </li>
        <li class='plus pointer' *ngIf="selectedCatalog && selectedCatalog.status == 'Active'" [ngClass]="selectedCatalog && selectedCatalog.catalogId == 0 ? 'disabled': ''"
          (click)="changeCatalogStatus('Inactive')">
          <a class="delete">
            <img src='assets/images/delete.svg' />
            <span>Deactivate Selected Catalog</span>
          </a>
        </li>
        <li class='plus pointer' *ngIf="selectedCatalog && selectedCatalog.catalogId != 0 && selectedCatalog.status == 'Inactive'"
          [ngClass]="selectedCatalog && selectedCatalog.catalogId == 0 ? 'disabled': ''" (click)="changeCatalogStatus('Activate')">
          <a class="activate">
            <img src='assets/images/success.svg' />
            <span>Activate Selected Catalog</span>
          </a>
        </li>
      </ul>
    </div>
    <!-- Catalog List end -->

    <div class='col-sm-7 buttonsDiv text-right'>
      <button class='btn btn-default' (click)="catalogModal()">
        <img src='assets/images/plus.svg' class='pull-left' /> New Catalog
      </button>
      <button class='btn btn-default' [class.enabled]="catalogs.length" [disabled]="!catalogs.length" [routerLink]="['new-reward']">
        <img src='assets/images/plus-white.svg' class='pull-left' /> New Reward
      </button>
    </div>

  </div>

  <div class='row menuDiv'>
    <!-- filter dropdown start -->
    <div class='col-sm-3 dropdown filter-btn filter'>
      <button class='btn btn-default dropdown-toggle filter' data-toggle="dropdown" (click)='dropDown($event)' [ngClass]="filterDefault ? 'on' : 'off'"
        [disabled]="!filterDefault && !rewards.length">
        <img class="filter" [src]="filterSvg" />
        <span class="filter">
          <span>Filter</span>
          <span>({{filterDefault ? 'On' : 'Off'}}{{filterDefault && rewards.length ? ' , ' : '' }}{{filterDefault && rewards.length
            ? rewards.length+' result(s)' : '' }})</span>
        </span>
      </button>
      <ul class="dropdown-menu filter-menu" id='ul'>
        <li class='form-group'>
          <a>
            <label>Active</label>
            <select class='form-control' [(ngModel)]="selectedStatus" (change)="filterChanged()">
              <option value="">All</option>
              <option value="1">Yes</option>
              <option value="0">No</option>
            </select>
          </a>
        </li>
        <li class='divider'></li>
        <li class='form-group'>
          <a>
            <label>Product type</label>
            <select class='form-control' [(ngModel)]="selectedProduct" (change)="filterChanged()">
              <option value="">All</option>
              <option *ngFor="let product of products" [value]="product.productId">{{product.productType}}</option>

            </select>
          </a>
        </li>
        <li class='divider'></li>
        <li class='form-group dropdown'>
          <a>
            <label>Brand</label>
            <div class='form-control dropdown-toggle brand-select' data-toggle='dropdown'>
              {{allBrandsSelected ? 'All' : templateString('brand',selectedBrands.length)}}
            </div>
            <ul class='dropdown-menu checkbox-list'>
              <li class='check-list' (click)='$event.stopPropagation()'>
                <input type='checkbox' id='brand0' name='brand' value="brand0" [(ngModel)]="allBrandsSelected" (change)="selectAll('brand');"
                />
                <label for='brand0'>
                  <span class='ip-text'>ALL</span>
                  <span class='check-box'></span>
                </label>
              </li>
              <li class='check-list' (click)='$event.stopPropagation()' *ngFor="let brand of hotelBrands">
                <input type='checkbox' [id]='"brand"+brand.brandId' name='brand' value="'brand'+brand.brandId" [(ngModel)]="brand.checked"
                  (change)="checkSelection('brand');" />
                <label [for]='"brand"+brand.brandId'>
                  <span class='ip-text'>{{brand.brandName}}</span>
                  <span class='check-box'></span>
                </label>
              </li>
            </ul>
          </a>
        </li>
        <li class='divider'></li>
        <li class='form-group dropdown'>
          <a>
            <label>Hotel</label>
            <div class='form-control dropdown-toggle brand-select' data-toggle='dropdown'>
              {{allBrandsSelected && allHotelsSelected ? 'All' : templateString('hotel',selectedHotels.length)}}
            </div>
            <ul class='dropdown-menu checkbox-list mb-0'>
              <li class='check-list' (click)='$event.stopPropagation()'>
                <input type='checkbox' id='hotel0' name='hotel' value="hotel0" [(ngModel)]="allHotelsSelected" (change)="selectAll('hotel');"
                />
                <label for='hotel0'>
                  <span class='ip-text'>ALL</span>
                  <span class='check-box'></span>
                </label>
              </li>
              <li class='check-list' *ngFor="let hotel of brandHotels" (click)='$event.stopPropagation()'>
                <input type='checkbox' [id]='"hotel"+hotel.hotelId' name='hotel' value="'hotel'+hotel.hotelId" [(ngModel)]="hotel.checked"
                  (change)="checkSelection('hotel');" />
                <label [for]='"hotel"+hotel.hotelId'>
                  <span class='ip-text'>{{hotel.hotelName}}</span>
                  <span class='check-box'></span>
                </label>
              </li>
            </ul>
          </a>
        </li>
      </ul>
    </div>
    <!-- filter dropdown end -->
    <div class='col-sm-4 dropdown filter-btn '>
      <button [ngClass]="selectedRewards.length ? 'on' : 'off'" class='btn btn-default dropdown-toggle action-btn' data-toggle="dropdown"
        [disabled]="!selectedRewards.length">
        <img [src]="actionSvg" />
        <span>
          <span>Actions</span>
          <span class='glyphicon glyphicon-chevron-down'></span>
        </span>
      </button>
      <ul class="dropdown-menu action-menu">
        <li class='plus pointer' (click)="SelectAllRewards('Inactive')">
          <a class="activate">
            <img src='assets/images/success.svg' class='pull-left' />
            <span class='pull-left activate'>Activate Selected Rewards</span>
          </a>
        </li>
        <li class='plus pointer' (click)="SelectAllRewards('Active')">
          <a class="delete">
            <img src='assets/images/delete.svg' class='pull-left' />
            <span class='pull-left deactivate'>Deactivate Selected Rewards</span>
          </a>
        </li>
        <!-- <li>
            <a>Activate selected rewards</a>
          </li>
        <li>
          <a>Deactivate selected rewards</a>
        </li> -->
      </ul>
      <span [class.hide-ele]="!rewards.length" class='action-text'>{{selectedRewards.length}} of {{rewards.length}} selected</span>
    </div>
    <div class='col-sm-2 search-text text-right' [ngClass]="(!(rewards | filter : searchText : 'productNameEN').length || !rewards.length) ? 'hide-ele' : ''">
      <span>{{(rewards | filter : searchText : 'productNameEN').length}} results ({{rewards.length}} total)</span>
    </div>
    <div class='col-sm-3 form-group'>
      <input type='search' class='form-control' [(ngModel)]="searchText" placeholder="Search Products..." [disabled]="!rewards.length"
      />
    </div>
  </div>

  <!-- rewards loading -->
  <div class="message text-center" *ngIf="loading">
    <h3>Loading catalog...</h3>
  </div>
  <!-- rewards loading -->

  <!-- rewards empty state -->
  <div class='message text-center' *ngIf='!loading && !filterDefault &&  catalogs.length && !rewards.length'>
    <h3>You haven't added any rewards to this catalog yet. Click on "+ New Reward" above to get started.</h3>
  </div>
  <!-- rewards empty state -->

  <!-- rewards empty state with filters -->
  <div class='message text-center' *ngIf='filterDefault && catalogs.length && !rewards.length'>
    <h3>No results found</h3>
  </div>
  <!-- rewards empty state with filters -->

  <div class='message padding-left-280' *ngIf="rewards.length && (rewards | filter : searchText : 'productNameEN').length === 0">
    <h3>No results found</h3>
  </div>

  <!-- catalogs empty state -->
  <div class='message text-center' *ngIf='!catalogs.length'>
    <h3>You haven't created any catalogs yet. Click on "+ New Catalog" above to get started.</h3>
  </div>
  <!-- catalogs empty state -->

  <div *ngIf='rewards.length' class="table-responsive">
    <table class="table table-catalog row" *ngIf="(rewards | filter : searchText : 'productNameEN').length">
      <thead>
        <tr>
          <th class='table-checkbox'>
            <input type='checkbox' id='reward0' name='reward' value="reward0" [(ngModel)]="allRewardsSelected" (change)="selectAll('reward');"
            />
            <label for='reward0' class='margin-0'>
              <span class='check-box'></span>
            </label>
          </th>
          <th>Offer Name</th>
          <th [ngClass]="!(selectedCatalog && selectedCatalog.catalogId == 0) ? 'th_align' : '' ">Points</th>
          <th [ngClass]="!(selectedCatalog && selectedCatalog.catalogId == 0) ? 'th_align' : '' ">% Off</th>
          <th [ngClass]="!(selectedCatalog && selectedCatalog.catalogId == 0) ? 'th_align' : '' ">Level</th>
          <th [ngClass]="!(selectedCatalog && selectedCatalog.catalogId == 0) ? 'th_align pl-100' : '' ">Type</th>
          <th [ngClass]="!(selectedCatalog && selectedCatalog.catalogId == 0) ? 'th_align pl-130' : '' ">Tag</th>
          <th *ngIf="selectedCatalog && selectedCatalog.catalogId == 0">Catalog</th>
          <th [ngClass]="!(selectedCatalog && selectedCatalog.catalogId == 0) ? 'th_align pl-125' : '' ">Status</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let reward of rewards | filter : searchText : 'productNameEN'; let i = index;" class="parent-tr">
          <td class='table-checkbox'>
            <input type='checkbox' [id]='"reward"+reward.rewardId' name='reward' value="'reward'+reward.rewardId" [(ngModel)]="reward.checked"
              (change)="checkSelection('reward');" />
            <label [for]='"reward"+reward.rewardId'>
              <span class='check-box'></span>
            </label>
          </td>
          <td>{{reward.productNameEN}}</td>
          <td class="nested-reward" colspan="6" *ngIf="reward.productList && reward.productList.length">
            <table class="table table-catalog">
              <tbody>
                <tr *ngFor="let subreward of reward.productList">
                  <td>{{subreward.productType == "Discount" ? 0 : subreward.points}}</td>
                  <td>{{subreward.productDiscount}}</td>
                  <td>
                    <span *ngFor="let level of subreward.catalogLevelsMap; let isLast=last"> {{level.loayaltyId ? level.loayaltyId.name : ''}}{{isLast ? '' : ', '}}</span>
                  </td>
                  <td>{{subreward.productType}}</td>
                  <td>
                    <span *ngFor="let tag of subreward.tags; let isLast=last">{{tag.tagId.name}}{{isLast ? '' : ', '}}</span>
                  </td>
                  <td *ngIf="selectedCatalog && selectedCatalog.catalogId == 0">{{subreward.catalogName}}</td>
                </tr>
              </tbody>
            </table>
          </td>
          <td class="">
            <div class='dropdown status'>
              <span [ngClass]="reward.status=='Active' ? 'activate' : 'inactivate' ">{{reward.status}}</span>
              <button type='button' class='btn btn-link dropdown-toggle' data-toggle="dropdown">
                <img src='assets/images/overflow.svg' />
              </button>
              <ul class="dropdown-menu statusList">
                <li class="hand">
                  <a [routerLink]="['/marketing/rewards-catalog/edit-reward', reward.rewardId]">
                    <img class='pull-left' src='assets/images/edit.svg' />
                    <span class='pull-left'>Modify</span>
                  </a>
                </li>
                <li class="pointer">
                  <a class='deactivate' *ngIf="reward.status == 'Active'" (click)="openModalActivateDeactivate([reward.rewardId], 'Active',subreward)">
                    <img class='pull-left' src='assets/images/delete.svg' />
                    <span class='pull-left'>Deactivate</span>
                  </a>
                </li>
                <li class="pointer">
                  <a class='activate' *ngIf="reward.status == 'Inactive'" (click)="openModalActivateDeactivate([reward.rewardId], 'Inactive',subreward)">
                    <img class='pull-left' src='assets/images/success.svg' />
                    <span class='pull-left'>Activate</span>
                  </a>
                </li>
              </ul>
            </div>
          </td>
        </tr>
        <!-- <tr *ngFor="let reward of rewards | filter : searchText : 'productName'">
          <td class='table-checkbox'>
              <input type='checkbox' [id]='"reward"+reward.rewardCatalogId' name='reward' value="'reward'+reward.rewardCatalogId" [(ngModel)]="reward.checked" (change)="checkSelection('reward');"/>
              <label [for]='"reward"+reward.rewardCatalogId'>
                <span class='check-box'></span>
             </label>
          </td>
          <td>{{reward.productName}} </td>
          <td>{{reward.points}}</td>
          <td>{{reward.productDiscount}}%</td>
          <td><span *ngFor="let level of reward.catalogLevelsMap; let isLast=last">{{level.loayaltyId.name}}{{isLast ? '' : ', '}}</span></td>
          <td>{{reward.productType}}</td>
          <td class='td-tag'><span *ngFor="let tag of reward.tags; let isLast=last">{{tag.tagId.name}}{{isLast ? '' : ', '}}</span></td>
          <td *ngIf="selectedCatalog && selectedCatalog.catalogId == 0">{{reward.catalogName}}</td>
          <td class="">
            <div class='dropdown status'>
              <span [ngClass]="reward.status=='Active' ? 'activate' : 'inactivate' ">{{reward.status}}</span>
              <button type='button' class='btn btn-link dropdown-toggle' data-toggle="dropdown">
                <img src='assets/images/overflow.svg' />
              </button>
              <ul class="dropdown-menu statusList">
                <li class="hand">
                  <a [routerLink]="['/marketing/rewards-catalog/edit-reward', reward.rewardId, reward.rewardCatalogId]">
                    <img class='pull-left' src='assets/images/edit.svg' />
                    <span class='pull-left'>Modify</span>
                  </a>
                </li>
                <li class="pointer">
                  <a class='deactivate' *ngIf="reward.status == 'Active'" (click)="openModalActivateDeactivate([reward.rewardCatalogId], 'Active',reward)">
                    <img class='pull-left' src='assets/images/delete.svg' />
                    <span class='pull-left'>Deactivate</span>
                  </a>
                </li>
                <li class="pointer">
                  <a class='activate' *ngIf="reward.status == 'Inactive'" (click)="openModalActivateDeactivate([reward.rewardCatalogId], 'Inactive',reward)">
                    <img class='pull-left' src='assets/images/success.svg' />
                    <span class='pull-left'>Activate</span>
                  </a>
                </li>
              </ul>
            </div>
          </td>
        </tr> -->
      </tbody>
    </table>
  </div>
  <div class='main-button'>
    <button class='btn btn-default col-sm-2 pull-right' [class.enabled]="catalogs.length" [disabled]="!catalogs.length" [routerLink]="['new-reward']">
      <img src='assets/images/plus-white.svg' class='pull-left' /> New Reward
    </button>
  </div>
</div>
