<html>

<head>

</head>

<body>

  <!-- <div class="container-fluid">
      <div class="sample-header" *ngIf="HeaderAndFooter && HeaderAndFooter.headerHtml" [innerHTML]="HeaderAndFooter.headerHtml"></div>
      <div class="content">
        <button class="button" >sample1</button>
        <button>sample2</button>
        <button>sample3</button>
        <p>sample text to check website text css for font</p>

      </div>
      <div class="col-xs-12 sample-footer" *ngIf="HeaderAndFooter && HeaderAndFooter.footerHtml" [innerHTML]="HeaderAndFooter.footerHtml"></div>
    </div> -->





  <nav class="navbar navbar-inverse navbar-custom-1 navbar-fixed-top">
    <div class='container-fluid'>
      <div class="navbar-header">
        <!-- <div class="row">
          <div class="col-xs-6">
            <ul>
              <li class='col-xs-3'>
                <img src='assets/images/sandos-small-logo.png' />
              </li>
              <li class='col-xs-9'>
                Sandos Hotels & Resorts
                <span class='glyphicon glyphicon-chevron-down custom-glyph-chevron'></span>
              </li>
            </ul>
          </div>
          <div class="col-xs-6">
            <ul>
              <li class='dropdown col-xs-6'>
                <a class=" pointer dropdown-toggle" data-toggle="dropdown">Language
                  <span class="caret"></span>
                </a>
                <ul class="dropdown-menu dropdown-lang">
                  <li [ngClass]="langType.languageId == 1 ? 'active' : ''">
                    <a (click)="changeLang('en')">English</a>
                  </li>
                  <li [ngClass]="langType.languageId == 2 ? 'active' : ''">
                    <a (click)="changeLang('es')">Spanish</a>
                  </li>
                </ul>
              </li>
              <li class='dropdown col-xs-6'>
                <a class=" pointer dropdown-toggle" data-toggle="dropdown">Menu
                  <span class="caret"></span>
                </a>
                <ul class="dropdown-menu dropdown-lang">
                  <li>
                    <span class="glyphicon glyphicon-earphone"></span>
                  </li>
                  <li>
                    <a class="pointer">My reservation</a>
                  </li>
                  <li>
                    <a class="pointer">About us</a>
                  </li>
                  <li>
                    <a class="pointer">Back to top</a>
                  </li>
                  <li>
                    <a class="pointer">Sandos4u</a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div> -->

        <div class="col-xs-6 col-sm-12">
          <ul>
            <li>
              <!-- <img src='assets/images/icon1.png' /> -->
              <img src='assets/images/sandos-small-logo.png' />
            </li>
            <li>
              Sandos Hotels & Resorts
              <span class='glyphicon glyphicon-chevron-down custom-glyph-chevron'></span>
            </li>
          </ul>
        </div>
        <div class="col-xs-6 text-right header-top-right-menu visible-xs">
          <ul class='header-top-right-menu'>
            <li class='dropdown col-xs-6'>
              <a class=" pointer dropdown-toggle" data-toggle="dropdown">Language
                <span class="caret"></span>
              </a>
              <ul class="dropdown-menu dropdown-lang">
                <li [ngClass]="langType.languageId == 1 ? 'active' : ''">
                  <a (click)="changeLang('en')">English</a>
                </li>
                <li [ngClass]="langType.languageId == 2 ? 'active' : ''">
                  <a (click)="changeLang('es')">Spanish</a>
                </li>
              </ul>
            </li>
            <li class='dropdown col-xs-6'>
              <a class=" pointer dropdown-toggle" data-toggle="dropdown">Menu
                <span class="caret"></span>
              </a>
              <ul class="dropdown-menu custom-dropdown-menu">
                <li>
                  <a>
                    <span class="glyphicon glyphicon-earphone"></span>
                  </a>
                </li>
                <li>
                  <a class="pointer">My reservation</a>
                </li>
                <li>
                  <a class="pointer">About us</a>
                </li>
                <li>
                  <a class="pointer">Back to top</a>
                </li>
                <li>
                  <a class="pointer">Sandos4u</a>
                </li>
              </ul>
            </li>
          </ul>
        </div>



        <!-- <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar-header" aria-expanded="false">
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button> -->
      </div>
      <div class="collapse navbar-collapse  navbar-collapse-custom row" id="navbar-collapse">
        <!-- <div class='col-xs-3'>
          <ul>
            <li>
              <img src='assets/images/sandos-small-logo.png' />
            </li>
            <li>
              Sandos Hotels & Resorts
              <span class='glyphicon glyphicon-chevron-down custom-glyph-chevron'></span>
            </li>
          </ul>
        </div> -->
        <div class='col-xs-9 text-right pull-right top-right-menu'>
          <ul>
            <li>
              <!-- <img src='assets/images/icon2.png' /> -->
              <span class="glyphicon glyphicon-earphone"></span>
            </li>
            <li>
              <a class="pointer">My reservation</a>
            </li>
            <li>
              <a class="pointer">About us</a>
            </li>
            <li>
              <a class="pointer">Back to top</a>
            </li>
            <li>
              <a class="pointer">Sandos4u</a>
            </li>
            <li>
              <a class="pointer" (click)="logout()">Logout</a>
            </li>
            <li class='dropdown'>
              <a class=" pointer dropdown-toggle" data-toggle="dropdown">Language
                <span class="caret"></span>
              </a>
              <ul class="dropdown-menu dropdown-lang">
                <li [ngClass]="langType.languageId == 1 ? 'active' : ''">
                  <a (click)="changeLang('en')">English</a>
                </li>
                <li [ngClass]="langType.languageId == 2 ? 'active' : ''">
                  <a (click)="changeLang('es')">Spanish</a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>

  <!-- navbar-2 -->
  <div *ngIf="HeaderAndFooter && HeaderAndFooter.headerHtml" [innerHTML]="HeaderAndFooter.headerHtml" style='position:relative;top:37px;'></div>
  
  <!-- <nav class="navbar navbar-custom-2 container-fluid" style='position:relative;top:37px;'>
    <div class="navbar-header row visible-xs">
      <div class='col-xs-6 dropdown'>
        <button type="button" class="navbar-toggle collapsed custom-navbar-toggle dropdown-toggle" data-toggle='dropdown' xdata-target="#navbar-collapse-2"
          aria-expanded="false">
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
        <ul class="dropdown-menu">
          <li>
            <a>HOW IT WORKS</a>
          </li>
          <li>
            <a>SPEND POINTS</a>
          </li>
          <li>
            <a>EARN POINTS</a>
          </li>
          <li>
            <a>MEMBERSHIP</a>
          </li>
        </ul>
        <img src='assets/images/preview-logo.png' />
      </div>
      <div class='col-xs-6 text-right'>
      </div>

    </div>
    <div class="collapse navbar-collapse  navbar-collapse-custom row" id="navbar-collapse-2">
      <div class='col-xs-2'>
        <img src='assets/images/preview-logo.png' />
      </div>
      <div class='col-xs-10  top-right-menu'>
        <ul>
          <li>
            <a>HOW IT WORKS</a>
          </li>
          <li>
            <a>SPEND POINTS</a>
          </li>
          <li>
            <a>EARN POINTS</a>
          </li>
          <li>
            <a>MEMBERSHIP</a>
          </li>
        </ul>
      </div>
    </div>
  </nav> -->

  <!-- banner container -->
  <div class='banner banner-container' *ngIf="heroBanner && (heroBanner.heading || heroBanner.buttonText || heroBanner.subHeading || heroBanner.backgroundImage)"
    [ngStyle]="heroBanner.backgroundImage ? {'background': getImgStyle(heroBanner.id, heroBanner.backgroundImage, 'herobanner', 'center/100% 100% no-repeat', 'bgImg')} : {'background': 'none'}">
    <h1 class='banner-heading heading' *ngIf="heroBanner.heading">{{heroBanner.heading}}</h1>
    <p class='banner-sub-heading sub-heading' *ngIf="heroBanner.subHeading">
      {{heroBanner.subHeading}}
    </p>
    <button class='banner-button btn btn-default btn-banner' *ngIf="heroBanner.buttonText && heroBanner.buttonActionType" (click)="heroBanner.buttonActionType == 'External URL' && goTo(heroBanner.buttonActionType, heroBanner.externalUrl)">{{heroBanner.buttonText}}</button>
  </div>

  <!-- coins container -->
  <div class="container tiles coins-container text-center clear" *ngIf="memberConfig && (memberConfig.heading || memberConfig.subHeading)">
    <div class='row'>
      <div class="col-xs-12">
        <h1 class='tiles-heading coins-main-heading' *ngIf="memberConfig.heading">
          <!-- EASILY EARN SANDOS COINS -->
          {{memberConfig.heading}}
        </h1>
        <p class="tiles-subheading coins-sub-heading" *ngIf="memberConfig.subHeading">
          <!-- As a new loyalty member, there are a few ways to get started earning Sandos Coins. -->
          {{memberConfig.subHeading}}
        </p>
        <div class='earn-coins-container' *ngIf="memberConfig && memberConfig.tilesTo.length">
          <div *ngFor="let member of memberConfig.tilesTo; let i = index;" class="blocks-container">
            <div class='blocks xsandos-member' *ngIf="(member.image || member.tilesHeading || member.earnPoints)">
              <img [ngStyle]="{visibility: !member.image ? 'hidden' : null}" [src]="getImgStyle(member.id, member.image, 'newmember/tile', 'center 5px no-repeat' , 'img')" class="tile-image image-responsive"
              />
              <div class='heading-div'>
                <!-- <p class='progress-text margin-0'>
                  Profile Completion 75%
                </p>
                <div class="progress custom-progress margin-0">
                  <div class="progress-bar custom-progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"
                    style="width:70%">
                    <span class="sr-only">70% Complete</span>
                  </div>
                </div> -->
                <p class="margin-0 coins-heading" *ngIf="member.tilesHeading">
                  <!-- Complete Your Profile -->
                  {{member.tilesHeading}}
                </p>
              </div>
              <h5 class='coins-count margin-0' *ngIf="member.earnPoints">Earn {{member.earnPoints}} Coins</h5>
            </div>
          </div>

          <!-- <div class='blocks icon-friend'>
            <div class='heading-div'>
              <p class="margin-0 coins-heading">Invite Your Friends via Email</p>
            </div>
            <h5 class='coins-count margin-0'>Earn 200 Coins</h5>
          </div>
          <div class='blocks icon-book'>
            <div class='heading-div'>
              <p class="margin-0 coins-heading">Tell us about your Upcoming Visit</p>
            </div>
            <h5 class='coins-count margin-0'>Earn 500 Coins</h5>
          </div>
          <div class='blocks chat-bubble'>
            <div class='heading-div'>
              <p class="margin-0 coins-heading">Share a blog post</p>
            </div>
            <h5 class='coins-count margin-0'>Earn 500 Coins</h5>
          </div> -->

        </div>
      </div>
    </div>
  </div>

  <!-- Featured rewards container -->
  <div class="rewards container coins-container text-center clear margin-top" *ngIf="catalogRewardsList.length">
    <div class="row">
      <div class="col-xs-12">
        <h1 class='rewards-heading coins-main-heading'>Featured Rewards</h1>
        <p class="rewards-subheading coins-sub-heading">
          Here's just a preview of some of the rewards available to our members.
        </p>
        <div class='catalog-banners'>
          <div class='rewards-row'>
            <div class='reward w-h-210' *ngIf="catalogRewardsList[0]" [ngStyle]="{'background': getImgStyle(catalogRewardsList[0].rewardId, catalogRewardsList[0].productImage, 'products', 'center/100% 100% no-repeat', 'bgImg')}">
              <div class='reward-heading'>
                <img src='assets/images/heart.svg' class='pull-left' />
                <div class='pull-right points'>
                  {{catalogRewardsList[0].points}} Points
                </div>
              </div>
              <div class='reward-content'>
                <p> {{catalogRewardsList[0].productName}}</p>
              </div>
              <div class="reward-footer-container">
                <div class="left">
                  <div class='reward-footer'>
                    <div class='underline'></div>
                    <p class='footer-text'>
                      {{catalogRewardsList[0].brandName}}
                    </p>
                  </div>
                </div>
                <div class="right">
                  <button class='btn btn-default btn-redeem pull-right'>REDEEM NOW</button>
                </div>
              </div>
              <div class='reward-overlay'>
                <div class='reward-heading'>
                  <!-- <img src='assets/images/heart.svg' class='pull-left' /> -->
                </div>
                <div class='reward-content'>
                  <p>{{catalogRewardsList[0].productName}}</p>
                </div>
                <button class='btn btn-default btn-redeem'>REDEEM NOW</button>
              </div>
            </div>

            <div class='reward w-h-210' *ngIf="catalogRewardsList[1]" [ngStyle]="{'background': getImgStyle(catalogRewardsList[1].rewardId, catalogRewardsList[1].productImage, 'products', 'center/100% 100% no-repeat', 'bgImg')}">
              <div class='reward-heading'>
                <img src='assets/images/heart.svg' class='pull-left' />
                <div class='pull-right points'>
                  {{catalogRewardsList[1].points}} Points
                </div>
              </div>
              <div class='reward-content r2'>
                <p>{{catalogRewardsList[1].productName}}</p>
              </div>
              <div class="reward-footer-container">
                <div class='left'>
                  <div class='reward-footer'>
                    <div class='underline'></div>
                    <p class='footer-text'>
                      {{catalogRewardsList[1].brandName}}
                    </p>
                  </div>
                </div>
                <div class='right'>
                  <button class='btn btn-default btn-redeem pull-right'>REDEEM NOW</button>
                </div>
              </div>

              <div class='reward-overlay r2-overlay'>
                <div class='reward-heading'>
                  <!-- <img src='assets/images/heart.svg' class='pull-left' /> -->
                </div>
                <div class='reward-content r2'>
                  <p>{{catalogRewardsList[1].productName}}</p>
                </div>
                <button class='btn btn-default btn-redeem'>REDEEM NOW</button>
              </div>
            </div>

            <div class='reward w-450-h-210' *ngIf="catalogRewardsList[2]" [ngStyle]="{'background': getImgStyle(catalogRewardsList[2].rewardId, catalogRewardsList[2].productImage, 'products', 'center/100% 100% no-repeat', 'bgImg')}">
              <div class='reward-heading'>
                <img src='assets/images/heart.svg' class='pull-left' />
                <div class='pull-right points'>
                  {{catalogRewardsList[2].points}} Points
                </div>
              </div>
              <div class='reward-content'>
                <p>{{catalogRewardsList[2].productName}}</p>
              </div>
              <div class="reward-footer-container">
                <div class='left'>
                  <div class='reward-footer'>
                    <div class='underline'></div>
                    <p class='footer-text'>
                      {{catalogRewardsList[2].brandName}}
                    </p>
                  </div>
                </div>
                <div class='right'>
                  <button class='btn btn-default btn-redeem pull-right'>REDEEM NOW</button>
                </div>
              </div>

              <div class='reward-overlay r450 r450-overlay'>
                <div class='reward-heading'>
                  <!-- <img src='assets/images/heart.svg' class='pull-left' /> -->
                </div>
                <div class='reward-content'>
                  <p>{{catalogRewardsList[2].productName}}</p>
                </div>
                <button class='btn btn-default btn-redeem'>REDEEM NOW</button>
              </div>
            </div>
          </div>
          <div class='rewards-row'>
            <div class='row-2'>
              <div class='reward w-450-h-210' *ngIf="catalogRewardsList[3]" [ngStyle]="{'background': getImgStyle(catalogRewardsList[3].rewardId, catalogRewardsList[3].productImage, 'products', 'center/100% 100% no-repeat', 'bgImg')}">
                <div class='reward-heading'>
                  <img src='assets/images/heart.svg' class='pull-left' />
                  <div class='pull-right points'>
                    {{catalogRewardsList[3].points}} Points
                  </div>
                </div>
                <div class='reward-content'>
                  <p>{{catalogRewardsList[3].productName}}</p>
                </div>
                <div class="reward-footer-container">
                  <div class='left'>
                    <div class='reward-footer'>
                      <div class='underline'></div>
                      <p class='footer-text'>
                        {{catalogRewardsList[3].brandName}}
                      </p>
                    </div>
                  </div>
                  <div class='right'>
                    <button class='btn btn-default btn-redeem pull-right'>REDEEM NOW</button>
                  </div>
                </div>

                <div class='reward-overlay r450 r450-overlay'>
                  <div class='reward-heading'>
                    <!-- <img src='assets/images/heart.svg' class='pull-left' /> -->
                  </div>
                  <div class='reward-content'>
                    <p>{{catalogRewardsList[3].productName}}</p>
                  </div>
                  <button class='btn btn-default btn-redeem'>REDEEM NOW</button>
                </div>
              </div>

              <div class='reward w-h-210 margin-top-30' *ngIf="catalogRewardsList[6]" [ngStyle]="{'background': getImgStyle(catalogRewardsList[6].rewardId, catalogRewardsList[6].productImage, 'products', 'center/100% 100% no-repeat', 'bgImg')}">
                <div class='reward-heading'>
                  <img src='assets/images/heart.svg' class='pull-left' />
                  <div class='pull-right points'>
                    {{catalogRewardsList[6].points}} Points
                  </div>
                </div>
                <div class='reward-content'>
                  <p>{{catalogRewardsList[6].productName}}</p>
                </div>
                <div class="reward-footer-container">
                  <div class='left'>
                    <div class='reward-footer'>
                      <div class='underline'></div>
                      <p class='footer-text'>
                        {{catalogRewardsList[6].brandName}}
                      </p>
                    </div>
                  </div>
                  <div class='right'>
                    <button class='btn btn-default btn-redeem pull-right'>REDEEM NOW</button>
                  </div>
                </div>

                <div class='reward-overlay r450-overlay'>
                  <div class='reward-heading'>
                    <!-- <img src='assets/images/heart.svg' class='pull-left' /> -->
                  </div>
                  <div class='reward-content'>
                    <p>{{catalogRewardsList[6].productName}}</p>
                  </div>
                  <button class='btn btn-default btn-redeem'>REDEEM NOW</button>
                </div>
              </div>

              <div class='reward w-h-210 margin-top-30' style='margin-right:0' *ngIf="catalogRewardsList[7]" [ngStyle]="{'background': getImgStyle(catalogRewardsList[7].rewardId, catalogRewardsList[7].productImage, 'products', 'center/100% 100% no-repeat', 'bgImg')}">
                <div class='reward-heading'>
                  <img src='assets/images/heart.svg' class='pull-left' />
                  <div class='pull-right points'>
                    {{catalogRewardsList[7].points}} Points
                  </div>
                </div>
                <div class='reward-content padding-bottom-0'>
                  <p>{{catalogRewardsList[7].productName}}</p>
                </div>
                <div class="reward-footer-container">
                  <div class='left'>
                    <div class='reward-footer'>
                      <div class='underline'></div>
                      <p class='footer-text'>
                        {{catalogRewardsList[7].brandName}}
                      </p>
                    </div>
                  </div>
                  <div class='right'>
                    <button class='btn btn-default btn-redeem pull-right'>REDEEM NOW</button>
                  </div>
                </div>

                <div class='reward-overlay  overlay-8'>
                  <div class='reward-heading'>
                    <!-- <img src='assets/images/heart.svg' class='pull-left' /> -->
                  </div>
                  <div class='reward-content'>
                    <p>{{catalogRewardsList[7].productName}}</p>
                  </div>
                  <button class='btn btn-default btn-redeem'>REDEEM NOW</button>
                </div>
              </div>

            </div>

            <div class='reward w-210-h-450' *ngIf="catalogRewardsList[4]" [ngStyle]="{'background': getImgStyle(catalogRewardsList[4].rewardId, catalogRewardsList[4].productImage, 'products', 'center/100% 100% no-repeat', 'bgImg')}">
              <div class='reward-heading'>
                <img src='assets/images/heart.svg' class='pull-left' />
                <div class='pull-right points'>
                  {{catalogRewardsList[4].points}} Points
                </div>
              </div>
              <div class='reward-content h450'>
                <p>{{catalogRewardsList[4].productName}}</p>
              </div>
              <div class="reward-footer-container">
                <div class='left'>
                  <div class='reward-footer'>
                    <div class='underline'></div>
                    <p class='footer-text'>
                      {{catalogRewardsList[4].brandName}}
                    </p>
                  </div>
                </div>
                <div class='right'>
                  <button class='btn btn-default btn-redeem pull-right'>REDEEM NOW</button>
                </div>
              </div>

              <div class='reward-overlay h450 h450-overlay'>
                <div class='reward-heading'>
                  <!-- <img src='assets/images/heart.svg' class='pull-left' /> -->
                </div>
                <div class='reward-content h450'>
                  <p>{{catalogRewardsList[4].productName}}</p>
                </div>
                <button class='btn btn-default btn-redeem'>REDEEM NOW</button>
              </div>
            </div>

            <div class='row-2-c-3'>
              <div class='reward w-h-210' *ngIf="catalogRewardsList[5]" [ngStyle]="{'background': getImgStyle(catalogRewardsList[5].rewardId, catalogRewardsList[5].productImage, 'products', 'center/cover no-repeat', 'bgImg')}">
                <div class='reward-heading'>
                  <img src='assets/images/heart.svg' class='pull-left' />
                  <div class='pull-right points'>
                    {{catalogRewardsList[5].points}} Points
                  </div>
                </div>
                <div class='reward-content'>
                  <p>{{catalogRewardsList[5].productName}}</p>
                </div>
                <div class="reward-footer-container">
                  <div class='left'>
                    <div class='reward-footer'>
                      <div class='underline'></div>
                      <p class='footer-text'>
                        {{catalogRewardsList[5].brandName}}
                      </p>
                    </div>
                  </div>
                  <div class='right'>
                    <button class='btn btn-default btn-redeem pull-right'>REDEEM NOW</button>
                  </div>
                </div>

                <div class='reward-overlay r450-overlay'>
                  <div class='reward-heading'>
                    <!-- <img src='assets/images/heart.svg' class='pull-left' /> -->
                  </div>
                  <div class='reward-content'>
                    <p>{{catalogRewardsList[5].productName}}</p>
                  </div>
                  <button class='btn btn-default btn-redeem'>REDEEM NOW</button>
                </div>
              </div>

              <div class='reward w-h-210 margin-top-30' *ngIf="catalogRewardsList[8]" [ngStyle]="{'background': getImgStyle(catalogRewardsList[8].rewardId, catalogRewardsList[8].productImage, 'products', 'center/cover no-repeat', 'bgImg')}">
                <div class='reward-heading'>
                  <img src='assets/images/heart.svg' class='pull-left' />
                  <div class='pull-right points'>
                    {{catalogRewardsList[8].points}} Points
                  </div>
                </div>
                <div class='reward-content padding-bottom-0'>
                  <p>{{catalogRewardsList[8].productName}}</p>
                </div>
                <div class="reward-footer-container">
                  <div class='left'>
                    <div class='reward-footer'>
                      <div class='underline'></div>
                      <p class='footer-text'>
                        {{catalogRewardsList[8].brandName}}
                      </p>
                    </div>
                  </div>
                  <div class='right'>
                    <button class='btn btn-default btn-redeem pull-right'>REDEEM NOW</button>
                  </div>
                </div>

                <div class='reward-overlay r450-overlay'>
                  <div class='reward-heading'>
                    <!-- <img src='assets/images/heart.svg' class='pull-left' /> -->
                  </div>
                  <div class='reward-content'>
                    <p>{{catalogRewardsList[8].productName}}</p>
                  </div>
                  <button class='btn btn-default btn-redeem'>REDEEM NOW</button>
                </div>
              </div>
            </div>

          </div>

          <!-- <div class='rewards-row'>
            <div class='reward w-h-210'>
              <div class='reward-heading'>
                <img src='assets/images/heart.svg' class='pull-left' />
                <div class='pull-right points'>
                  50 Points
                </div>
              </div>
              <div class='reward-content'>
                <p>Box of chocolates</p>
              </div>
              <div class='reward-footer'>
                <div class='underline'></div>
                <p class='footer-text'>
                  Sandos Papagayo Beach Resort
                </p>
              </div>
              <div class='reward-overlay'>
                <button class='btn btn-default btn-redeem'>REDEEM NOW</button>
              </div>
            </div>

            <div class='reward w-h-210'>
              <div class='reward-heading'>
                <img src='assets/images/heart.svg' class='pull-left' />
                <div class='pull-right points'>
                  100 Points
                </div>
              </div>
              <div class='reward-content'>
                <p>Slippers with sandos logo</p>
              </div>
              <div class='reward-footer'>
                <div class='underline'></div>
                <p class='footer-text'>
                  Sandos Papagayo Beach Resort
                </p>
              </div>
              <div class='reward-overlay'>
                <button class='btn btn-default btn-redeem'>REDEEM NOW</button>
              </div>
            </div>

          </div> -->
        </div>
      </div>
    </div>

    <div class='row rewards-button text-center margin-top-35' *ngIf="catalogRewardsList.length > 8">
      <div class='col-xs-12'>
        <button class='btn btn-default btn-full-rewards'>SEE FULL REWARDS CATALOG</button>
      </div>
    </div>
  </div>

  <!-- signup container -->
  <div class='banner-bottom signup-container'>
    <h1 class='banner-bottom-heading heading'>SIGN UP TODAY AND SAVE ON YOUR FIRST BOOKING</h1>
    <p class='banner-bottom-sub-heading sub-heading'>
      Book a trip to Sandos Cancun Lifestyle Resort and save 20%
    </p>
    <button class='btn btn-default banner-bottom-button btn-banner'>SIGN UP</button>
  </div>

  <!-- Logo container -->
  <div class="container-fluid logo-container clear">
    <div class="row">
      <div class='col-xs-12 text-center'>
        <img src='assets/images/sandos-logo-72@2x.png' class='logo-image' />
      </div>
    </div>
  </div>

  <div *ngIf="HeaderAndFooter && HeaderAndFooter.footerHtml" [innerHTML]="HeaderAndFooter.footerHtml"></div>
  <!-- Footer container -->
  <!-- <div class="container-fluid footer footer-container">
    <div class="row">
      <div class="col-sm-4">
        <ul>
          <li>
            <a class='list-heading'>ABOUT US</a>
          </li>
          <li>
            <a>About Sandos</a>
          </li>
          <li>
            <a>Awards And Recognitions</a>
          </li>
        </ul>
        <ul>
          <li>
            <a class='list-heading'>NEWS</a>
          </li>
          <li>
            <a>Blog Sandos</a>
          </li>
        </ul>
        <ul>
          <li>
            <a class='list-heading'>SANDOS HOTELS & RESORTS</a>
          </li>
          <li>
            <a>America - Mexico 01 800 907 52 92</a>
          </li>
          <li>
            <a>America - USA 1 866 336 40 83</a>
          </li>
          <li>
            <a>Europa - Español +34 971 78 33 02</a>
          </li>
          <li>
            <a>Europe - UK +44 20 305 137 68</a>
          </li>
        </ul>
      </div>
      <div class="col-sm-4">
        <ul>
          <li>
            <a class='list-heading'>SANDOS SMART AGENTS</a>
          </li>
          <li>
            <a>Sandos SMART Agents</a>
          </li>
        </ul>
        <ul>
          <li>
            <a class='list-heading'>SANDOS4U</a>
          </li>
          <li>
            <a>Loyalty Program</a>
          </li>
        </ul>
        <ul>
          <li>
            <a class='list-heading'>WEDDINGS</a>
          </li>
          <li>
            <a>Plan Your Wedding</a>
          </li>
        </ul>
      </div>
      <div class="col-sm-4">
        <ul>
          <li>
            <a class='list-heading'>CONTACT US</a>
          </li>
          <li>
            <a>Send Us A Message</a>
          </li>
        </ul>
        <ul>
          <li>
            <a class='list-heading'>NEWSLETTER SIGN-UP</a>
          </li>
          <li>
            <input type='text' placeholder="Enter your E-mail address" class="email-ip" />
            <span class='glyphicon glyphicon-chevron-right right-arrow'></span>
          </li>
          <li class="checkbox-conditions">
            <input type='checkbox' />
            <label>I AGREE TO THE CONDITIONS</label>
          </li>
        </ul>
      </div>
    </div>

    <div class='row'>
      <div class='col-xs-12 chats-div'>
       
      </div>
    </div>

    <div class='row'>
      <div class='col-xs-12 final-div text-center'>
        &#169;2017 SANDOS HOTELS & RESORTS.ALL RIGHTS RESERVED -
        <span>TERMS AND CONDITIONS</span>
      </div>
    </div>
  </div> -->
</body>

</html>