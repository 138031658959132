<div class='col-sm-9 col-sm-offset-3 exchange-container'>
  <div class='row header'>
    <div class="col-sm-5 heading">
      <h3 class="h">Exchange of Benefits</h3>
    </div>
    <div class='col-sm-7 buttonsDiv text-right'>
      <button class='btn btn-default btn-add-exchange' [routerLink]="['add']">
        <img src='assets/images/plus-white.svg' class='pull-left' /> New Exchange
      </button>
    </div>
  </div>

  <div class='row menuDiv'>
    <!-- filter dropdown start -->
    <div class='col-sm-3 dropdown filter-btn filter'>
      <form [formGroup]="filterForm">
        <button class='btn btn-default dropdown-toggle filter' [disabled]="!isFiltered && !BenefitsList.length" [ngClass]="isFilterOn ? 'on' : 'off'"
          data-toggle="dropdown" (click)='dropDown($event)'>
          <span class="filter">
            <span>Filter {{isFilterOn ? '(On)' : '(Off)'}} &nbsp;{{isFilterOn ? (totalCount ? totalCount+' result(s)' : '0 result(s)')
              : ''}}
            </span>
          </span>
        </button>
        <ul class="dropdown-menu filter-menu" id='ul'>
          <li class='form-group'>
            <a>
              <label>Redeem</label>
              <select class='form-control' formControlName="status">
                <option value="" selected>All</option>
                <option [value]="status.statusId" *ngFor="let status of filterLookUpDate?.status">{{status.statusDesc}}</option>
              </select>
            </a>
          </li>
          <li class='divider'></li>
          <!-- <li class='form-group'>
            <a>
              <label>Benefit</label>
              <div class="form-control custom-dropdown">
                <div class="btn-group col-xs-12 padding-0 select-icon" dropdown #dropdown="bs-dropdown" [autoClose]="true">
                  <button dropdownToggle type="button" [title]="filterForm.get('benefit').value" class="btn dropdown-toggle form-control"> -->
                    <!-- All -->
                    <!-- {{filterForm.get('benefitName').value ? filterForm.get('benefitName').value : 'All'}}
                  </button>
                  <ul id="dropdown-triggers-manual" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-triggers-manual">
                    <li role="menuitem" class="hand dropdown-item" (click)="selectedValue('benefit', '')">
                      <a class="dropdown-item" [class.active]="filterForm.get('benefit').value == ''">All</a>
                    </li>
                    <li *ngFor="let benefit of filterLookUpDate?.benefit; let i = index;" role="menuitem" class="hand dropdown-item" (click)="selectedValue('benefit', benefit)">
                      <a class="dropdown-item" [class.active]="filterForm.get('benefit').value == benefit.rewardCatalogId">{{benefit.productName}}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </a> -->
            <!-- <a>
                <label>Department</label>
                <select class='form-control' formControlName="department">
                  <option value="" selected>All</option>
                  <option [value]="department" *ngFor="let department of filterLookUpDate?.department">{{department}}</option>
                </select>
              </a> -->
          <!-- </li> -->
          <li class='form-group dropdown'>
              <a>
                <label>Benefit</label>
                <div class="form-control custom-dropdown">
                  <div class="btn-group col-xs-12 padding-0 select-icon" dropdown #dropdown="bs-dropdown" [autoClose]="true">
                    <button dropdownToggle type="button" class="btn dropdown-toggle form-control">
                      {{(filterForm.get('benefitIds').value.indexOf(0) > -1) ? 'All Benefits Selected' : getSelectedStr(filterForm.get('benefitIds').value.length,'Benefit')}}
                    </button>
                    <ul id="dropdown-triggers-manual" *dropdownMenu class="dropdown-menu checkbox-list" role="menu" aria-labelledby="button-triggers-manual">
                      <li role="menuitem" class="hand dropdown-item check-list">
                        <input type='checkbox' id='benefit0' name='benefit' value="benefit0" [checked]="" [checked]="filterForm.get('benefitIds').value.indexOf(0) > -1"
                          (change)="checkSelected($event.target.checked, 'benefitIds', 'benefit', 'rewardCatalogId', 0)" />
                        <label for='benefit0'>
                          <span class='ip-text'>ALL</span>
                          <span class='check-box'></span>
                        </label>
                      </li>
                      <li *ngFor="let benefit of filterLookUpDate?.benefit" role="menuitem" class="hand dropdown-item check-list">
                        <input type='checkbox' [id]='"benefit"+benefit.rewardCatalogId' name='benefit' [value]="benefit.rewardCatalogId" [checked]="filterForm.get('benefitIds').value.indexOf(benefit.rewardCatalogId) >= 0"
                          (change)="checkSelected($event.target.checked, 'benefitIds', 'benefit', 'rewardCatalogId', benefit.rewardCatalogId)" />
                        <label [for]='"benefit"+benefit.rewardCatalogId'>
                          <span class='ip-text'>{{benefit.productNameEN}}</span>
                          <span class='check-box'></span>
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
              </a>
            </li>
          <li class='divider'></li>
          <li class='form-group dropdown'>
            <a>
              <label>Hotel</label>
              <div class="form-control custom-dropdown">
                <div class="btn-group col-xs-12 padding-0 select-icon" dropdown #dropdown="bs-dropdown" [autoClose]="true">
                  <button dropdownToggle type="button" class="btn dropdown-toggle form-control">
                    {{(filterForm.get('hotelIds').value.indexOf(0) > -1) ? 'All Hotels Selected' : getSelectedStr(filterForm.get('hotelIds').value.length,'Hotel')}}
                  </button>
                  <ul id="dropdown-triggers-manual" *dropdownMenu class="dropdown-menu checkbox-list" role="menu" aria-labelledby="button-triggers-manual">
                    <li role="menuitem" class="hand dropdown-item check-list">
                      <input type='checkbox' id='hotel0' name='hotel' value="hotel0" [checked]="" [checked]="filterForm.get('hotelIds').value.indexOf(0) > -1"
                        (change)="checkSelected($event.target.checked, 'hotelIds', 'hotels', 'hotelId', 0)" />
                      <label for='hotel0'>
                        <span class='ip-text'>ALL</span>
                        <span class='check-box'></span>
                      </label>
                    </li>
                    <li *ngFor="let hotel of filterLookUpDate?.hotels" role="menuitem" class="hand dropdown-item check-list">
                      <input type='checkbox' [id]='"hotel"+hotel.hotelId' name='hotel' [value]="hotel.hotelId" [checked]="filterForm.get('hotelIds').value.indexOf(hotel.hotelId) >= 0"
                        (change)="checkSelected($event.target.checked, 'hotelIds', 'hotels', 'hotelId', hotel.hotelId)" />
                      <label [for]='"hotel"+hotel.hotelId'>
                        <span class='ip-text'>{{hotel.hotelName}}</span>
                        <span class='check-box'></span>
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
            </a>
          </li>
          <li class='divider'></li>
          <li class='form-group'>
            <a class="close-position">
              <label>Created Between</label>
              <input type="text" placeholder="Created between" placement="top" formControlName="range" class="form-control search-box"
                onkeydown='return event.which == 8 || event.which == 46 || event.which == 37 || event.which == 39' bsDaterangepicker>
              <button class="close-icon" (click)="clearDate()"></button>
            </a>
          </li>
        </ul>
      </form>
    </div>
    <!-- filter dropdown end -->

    <!-- Action dropdown start -->
    <!-- <div class='col-sm-4 dropdown filter-btn'>
      <button [disabled]="!selectedBenefits.length" [ngClass]="selectedBenefits.length ? 'on' : 'off'" class='btn btn-default dropdown-toggle btn-action'
        data-toggle="dropdown">
        <span>
          <span>Actions</span>
          <span class='glyphicon glyphicon-chevron-down'></span>
        </span>
      </button>
      <ul class="dropdown-menu action-menu">
        <li class='plus pointer' (click)="openStatusModal(this.selectedBenefits, 1)">
          <a class="activate">
            <img src='assets/images/success.svg' class='pull-left' />
            <span class='pull-left activate'>Activate Selected Employees</span>
          </a>
        </li>
        <li class='plus pointer' (click)="openStatusModal(this.selectedBenefits, 0)">
          <a class="delete">
            <img src='assets/images/delete.svg' class='pull-left' />
            <span class='pull-left deactivate'>Deactivate Selected Employees</span>
          </a>
        </li>
      </ul>
      <span [class.hide-ele]="" class='action-text' [hidden]="!isFiltered && !BenefitsList.length">{{selectedBenefits.length}} of {{BenefitsList.length}} selected</span>
    </div> -->
    <!-- Action dropdown end -->

    <div class='col-sm-6 search-text text-right' [ngClass]="" [hidden]="!isFiltered && !BenefitsList.length">
      <span>{{totalCount ? totalCount : 0}} results</span>
  </div>
    <form [formGroup]="searchForm">
      <div class='col-sm-3 form-group pull-right'>
        <input type='search' class='form-control' [class.disabled]="!isFiltered && !BenefitsList.length" placeholder="Search Exchange Benefits"
          formControlName="searchTerm" />
      </div>
      </form>
  </div>

  <!-- rewards empty state with filters -->
  <div class='message text-center' *ngIf="isFiltered && !BenefitsList.length">
    <h3>No results found</h3>
  </div>

  <!-- Empty state -->
  <div class='message text-center' *ngIf="!isFiltered && !BenefitsList.length">
    <h3>You haven’t created any record yet. Click on “+ New Exchange” above to get started.</h3>
  </div>

  <div class="table-responsive" *ngIf="BenefitsList.length">
    <table class="table table-catalog ">
      <thead>
        <tr>
          <!-- <th class='table-checkbox'>
            <input type='checkbox' id='employee0' name='employee' value="employee0" [(ngModel)]="allBenefitsSelected" (change)="selectExchanges('all')"
            />
            <label for='employee0' class='margin-0'>
              <span class='check-box'></span>
            </label>
          </th> -->
          <th *ngFor="let header of tableHeader; let idx = index" class="pointer" (click)="sortTable(header.property)">{{header.key}}
            <span class="sort-icons up pointer" *ngIf="header.direction != null && header.direction == 'asc'"> &#9660;</span>
            <span class="sort-icons down pointer" *ngIf="header.direction != null && header.direction == 'desc'"> &#9650;</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <!-- <tr> -->
          <!-- <td class='table-checkbox'>
            <input type='checkbox' [id]='"exchange0"' name='exchange' value="'exchange0'" (change)="selectExchanges('single')" />
            <label [for]='"exchange0"'>
              <span class='check-box'></span>
            </label>
          </td> -->
          <!-- <td>empCode</td>
          <td>Benefit</td>
          <td>HotelName</td>
          <td>firstName</td>
          <td>lastName</td>
          <td>Status</td>
        </tr> -->
        <tr *ngFor="let benefit of BenefitsList | paginate: { itemsPerPage: size, currentPage: p, totalItems: totalCount }">
          <!-- <td class='table-checkbox'>
            <input type='checkbox' [id]='"benefit"+benefit.loyaltyMemberId' name='promo' value="'employee'+employee.loyaltyMemberId"
              [(ngModel)]="benefit.checked" (change)="selectEmployees('single')" />
            <label [for]='"benefit"+benefit.loyaltyMemberId'>
              <span class='check-box'></span>
            </label>
          </td> -->
          <td>{{benefit.empCode}}</td>
          <td>{{benefit.benefitName}}</td>
          <td>{{benefit.hotelName}}</td>
          <td>{{benefit?.firstName}}</td>
          <td>{{benefit?.lastName2}}</td>
          <td class="">
            <div class='dropdown status'>
              <span [ngClass]="benefit?.exchanged ? 'activate' : 'inactivate'">
                {{benefit?.exchanged ? 'Yes' : 'No'}}
              </span>
              <button type='button' class='btn btn-link dropdown-toggle' data-toggle="dropdown">
                <img src='assets/images/overflow.svg' />
              </button>
              <ul class="dropdown-menu statusList">
                <li class="pointer">
                  <a [routerLink]="['edit', benefit?.id]">
                    <img class='pull-left' src='assets/images/edit.svg' />
                    <span class='pull-left'>Modify</span>
                  </a>
                </li>
                <!-- <li class="pointer" *ngIf="!benefit?.exchanged">
                  <a (click)="printVochuer(benefit)">
                    <img class='pull-left' src='assets/images/voucher.svg' />
                    <span class='pull-left'>Generate Voucher</span>
                  </a>
                </li> -->
              </ul>
            </div>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td class="tfooter" colspan="6">
            <pagination-controls class="pull-right" (pageChange)="pageChanged($event)"></pagination-controls>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</div>
