<div class='col-sm-10 col-sm-offset-2 members-container'>
  <div>
    <h3 class="heading">Smart Agents</h3>
  </div>

  <div class="row menuDiv" *ngIf="!loadingMembers">
    <div class='col-sm-4'>
      <!--Acá iba el filtro de búsqueda. Si se necesita, tomarlo de /members/members-list-->
    </div>
    <div class="col-sm-4 search-text">
      <span>{{totalCount}} results</span>
    </div>
    <div class='col-sm-4 form-group'>
      <form [formGroup]="searchForm">
        <div class="flex">
          <input
            type="search"
            class="form-control"
            placeholder="Search Members"
            formControlName="searchTerm"
          />
          <button (click)="searchTerm()" class='btn btn-default margin-left5 activeBtn'>
            {{'BUTTONS.SEARCH' | translate}}
          </button>
        </div>
      </form>
  </div>
</div>

<!-- Empty state -->
<div class="message text-center" *ngIf="loadingMembers">
  <h3>Loading members. Wait a moment, please...</h3>
</div>

<div class="message text-center" *ngIf="!membersList.length && !loadingMembers">
  <h3>No records found</h3>
</div>

<div class="table-container" *ngIf="membersList.length && !loadingMembers">
  <div class="table-responsive">
    <table class="table table-catalog ">
      <thead>
        <tr>
          <!-- <th class='table-checkbox'> -->
          <!-- <input type='checkbox' id='promo0' name='promo' value="promo0" />
              <label for='promo0' class='margin-0'>
                <span class='check-box'></span>
              </label> -->
          <!-- </th> -->
          <th>Name</th>
          <th>Email/Username</th>
          <th>Last Login</th>
          <th>Creation Date</th>
          <th>Origin</th>
          <th>Referral Code</th>
          <th>Coins available</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let member of membersList | paginate: { itemsPerPage: size, currentPage: p, totalItems: totalCount }">
          <!-- <td class='table-checkbox'> -->
          <!-- <input type='checkbox' id='promo' name='promo' />
              <label for='promo'>
                <span class='check-box'></span>
              </label> -->
          <!-- </td> -->
          <td>
            <span class="error-badge col-sm-1 pull-left" *ngIf="member?.profileUpdate">
              <img class="Error pull-left" src="assets/images/error.svg">
            </span>
            <span class="member-name col-sm-10 padding-0 pull-left">
              &nbsp;{{utilService.getFullNameConcierge(member)}}
            </span>
          </td>
          <td>{{member.email}}</td>
          <td>{{member.last_access_date}}</td>
          <td>{{member.creation_date}}</td>
          <td>{{member.origin}}</td>
          <td>{{member.referral_code}}</td>
          <td>{{member?.total_points}}</td>
          <!-- <td>{{member.status}}</td> -->
          <td class="">
            <div class='dropdown status' [ngClass]="(member.status == 'Repeated user' || member.status == 'Duplicate') ? 'duplicate' : ''">
              <span [ngClass]="member.status =='Active' ? 'activate' : 'inactivate'">{{member.status}}</span>
              <button type='button' class='btn btn-link dropdown-toggle' data-toggle="dropdown">
                <img src='assets/images/overflow.svg' />
              </button>
              <ul class="dropdown-menu statusList">
                <li class="pointer">
                  <a [routerLink]="['modify', member.loyalty_member_id, 'booking']">
                    <img class='pull-left' src='assets/images/edit.svg' />
                    <span class='pull-left'>Modify</span>
                  </a>
                </li>
              </ul>
            </div>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td class="tfooter" colspan="8">
            <pagination-controls class="pull-right" (pageChange)="pageChanged($event)"></pagination-controls>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</div>
</div>
