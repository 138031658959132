<div>
  <div class='row'>
    <div class='col-sm-9 padding-right-10'>
      <ul class='nav nav-tabs rules-tabs'>
        <li class="active">
          <a id="en" *ngIf="!isDirtyEs" data-toggle="tab" href="#cEng" (click)="changeLanguage('en')">
            <span>English</span>
          </a>
          <a *ngIf="isDirtyEs" class="pointer" (click)="changeLanguage('en')">
            <span>English</span>
          </a>
        </li>
        <li class="">
          <a id="es" *ngIf="!isDirtyEn" data-toggle="tab" href="#cSp" (click)="changeLanguage('es')">
            <span>Español</span>
          </a>
          <a *ngIf="isDirtyEn" class="pointer" (click)="changeLanguage('es')">
            <span>Español</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
  <div class="tab-content">
    <!-- English -->
    <div id="cEng" class="tab-pane fade in active">
      <div class="centered-content">
        <div>
          <div class="col-sm-9 tabPaneBorder">
            <div class='col-xs-12'>
              <div class='row'>
                <div class='col-xs-12'>
                  <h3 class='step-heading'>
                    <!-- {{'LANDING-PAGE.STEP3A-HEADING' | translate}} -->
                    Step 2A.) Select Catalog Banner Layout
                  </h3>
                  <p class='heading-text'>
                    <!-- {{'LANDING-PAGE.STEP3A-TEXT' | translate}} -->
                    Choose the style of banner you want to configure
                  </p>
                </div>
              </div>
              <div class='row'>
                <div class=' col-sm-9'>
                  <form [formGroup]="loyaltyFormEn">
                    <div class='form-group margin-top margin-0'>
                      <img src='assets/images/option-card.svg' class='layout' />
                      <label class="radio layout-button">
                        <!-- <input id="radio1" type="radio"  [value]="" [checked]="checked" name='layout' formControlName="layout"> -->
                        <input id="radio1" type="radio" name="layout" value="" checked="checked" />
                        <span class="outer"></span>
                        <span class='radio-text'>
                          <!-- {{'LANDING-PAGE.FEATURED-REWARDS' | translate}} -->
                          Featured Rewards
                        </span>
                      </label>
                    </div>

                    <h3 class='step-heading'>
                      Step 2B.) Catalog Banner Setup
                    </h3>
                    <p class='heading-text'>
                      Set the page heading and subheading for your rewards catalog.
                    </p>
                    <div class="row">
                        <div class="col-xs-12 margin-top form-group">
                          <label class="labelStyle">Heading</label>
                          <textarea class="inputTextStyle form-control" formControlName="header" placeholder="Heading Text..." rows='3'></textarea>
                        </div>
                        <div class="col-xs-12 xmargin-top form-group">
                          <label class="labelStyle">Subheading</label>
                          <textarea class="inputTextStyle form-control margin-bottom-0" formControlName="subheader" placeholder="Subheading Text..." rows='3'></textarea>
                        </div>
                    </div>
                  
                    <p class='heading-text'>
                      Configure the rules for what the banner you chose in the previous step will display to different members.
                    </p>
                    <p class='title'>
                      Rule Configuration
                    </p>
                    <label class="labelStyle">
                      Display Rewards Based On (Check all that apply)
                    </label>
                    <div class='dropdown'>
                      <div class='col-sm-8 dropdown-toggle select-box inputTextStyle' data-toggle='dropdown'>
                        {{loyaltyFormEn.get('rules').value.length}} Rule{{loyaltyFormEn.get('rules').value.length != 1 ? 's' : ''}} selected
                      </div>
                      <ul class='dropdown-menu checkbox-list' (click)="$event.stopPropagation()">
                        <li class='check-list' *ngFor="let rule of rulesListEn">
                          <input type='checkbox' [id]='"rule"+rule.id' name='rule' value="'rule'+rule.id" [checked]="loyaltyFormEn.get('rules').value.indexOf(rule.id) >= 0"
                            (change)="checkSelection($event.target.checked, 'loyaltyFormEn', rule,'fromHTML');" />
                          <label [for]='"rule"+rule.id'>
                            <span class='ip-text'>{{rule.description}}</span>
                            <span class='check-box'></span>
                          </label>
                        </li>
                        <!-- <li class='check-list'>
                          <input type='checkbox' id='rule2' name='cb' />
                          <label for='rule2'>
                            <span class='ip-text'>Priority Level of Rewards.</span>
                            <span class='check-box'></span>
                          </label>
                        </li> -->
                      </ul>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class='col-sm-9 padding-right-0'>
            <div class='row buttons-div clear text-right'>
              <!-- <button class='btn btn-default btn-cancel' (click)="cancel()">
                Cancel
              </button> -->
              <div class="col-sm-6">
                <div class="error col-xs-12 error-align" *ngIf="errorDetails">{{errorDetails}}</div>
                <div class="success col-xs-12 success-align" *ngIf="successMsg">{{successMsg}}</div>
              </div>
              <div class="col-sm-6">
                <button class='btn btn-default btn-save' (click)="saveForm()">
                  <img src='assets/images/save.svg' class='pull-left' />
                  <span class='pull-left'>
                    <!-- {{'BUTTONS.SAVE' | translate}} -->
                    Save
                  </span>
                </button>
              </div>
            </div>
            <!-- <div class="error col-xs-12" *ngIf="errorDetails">{{errorDetails}}</div>
            <div class="success col-xs-12" *ngIf="successMsg">{{successMsg}}</div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- English -->

    <!-- Spanish -->
    <div id="cSp" class="tab-pane fade">
      <div class="centered-content">
        <div>
          <div class="col-sm-9 tabPaneBorder">
            <div class='col-xs-12'>
              <div class='row'>
                <div class='col-xs-12'>
                  <h3 class='step-heading'>
                    <!-- {{'LANDING-PAGE.STEP3A-HEADING' | translate}} -->
                    Paso 2A.) Seleccionar diseño de la pancarta del catálogo
                  </h3>
                  <p class='heading-text'>
                    <!-- {{'LANDING-PAGE.STEP3A-TEXT' | translate}} -->
                    Elija el estilo de banner que desea configurar
                  </p>
                </div>
              </div>
              <div class='row'>
                <div class='col-xs-9'>
                  <form [formGroup]="loyaltyFormEs">
                    <div class='form-group margin-top margin-0'>
                      <img src='assets/images/option-card.svg' class='layout' />
                      <label class="radio layout-button spanish">
                        <input id="radio1" type="radio" name="layout" value="" checked="checked" />
                        <span class="outer"></span>
                        <span class='radio-text'>
                          <!-- {{'LANDING-PAGE.FEATURED-REWARDS' | translate}} -->
                          Recompensas destacadas
                        </span>
                      </label>
                    </div>
                    <h3 class='step-heading'>
                      Paso 2B.) Configuración del banner del catálogo
                    </h3>
                    <p class='heading-text'>
                        Establezca el encabezado de la página y el subtítulo para su catálogo de recompensas.
                      </p>
                      <div class="row">
                        <div class="col-xs-12 margin-top form-group">
                          <label class="labelStyle">Título</label>
                          <textarea class="inputTextStyle form-control" formControlName="header" placeholder="Texto del encabezado..." rows='3'></textarea>
                        </div>
                        <div class="col-xs-12 xmargin-top form-group">
                          <label class="labelStyle">Subtítulo</label>
                          <textarea class="inputTextStyle form-control margin-bottom-0" formControlName="subheader" placeholder="Texto del subtítulo..." rows='3'></textarea>
                        </div>
                      </div>
                    <p class='heading-text'>
                      Configure las reglas para lo que el banner que eligió en el paso anterior se mostrará a los diferentes miembros.
                    </p>
                    <p class='title'>
                      Configuración de reglas
                    </p>
                    <label class="labelStyle">
                      Mostrar recompensas basadas en (marque todas las que apliquen)
                    </label>
                    <div class='dropdown'>
                      <div class='col-sm-8 dropdown-toggle select-box inputTextStyle' data-toggle='dropdown'>
                        {{loyaltyFormEs.get('rules').value.length}} regla{{loyaltyFormEs.get('rules').value.length != 1 ? 's' : ''}} seleccionadas
                      </div>
                      <ul class='dropdown-menu checkbox-list' (click)="$event.stopPropagation()">
                        <li class='check-list' *ngFor="let rules of rulesListEs">
                          <input type='checkbox' [id]='"rules"+rules.id' name='rule' value="'rules'+rules.id" [checked]="loyaltyFormEs.get('rules').value.indexOf(rules.id) >= 0"
                            (change)="checkSelection($event.target.checked, 'loyaltyFormEs', rules,'fromHTML');" />
                          <label [for]='"rules"+rules.id'>
                            <span class='ip-text'>{{rules.description}}</span>
                            <span class='check-box'></span>
                          </label>
                        </li>
                        <!-- <li class='check-list'>
                          <input type='checkbox' id='id' name='cb' />
                          <label for='id'>
                            <span class='ip-text'>Priority Level of Rewards.</span>
                            <span class='check-box'></span>
                          </label>
                        </li> -->
                      </ul>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class='col-sm-9 padding-right-0'>
            <div class='row buttons-div clear text-right'>
              <!-- <button class='btn btn-default btn-cancel' (click)="cancel()">
                Cancelar
              </button> -->
              <div class="col-sm-6">
                <div class="error col-xs-12 error-align" *ngIf="errorDetails">{{errorDetails}}</div>
                <div class="success col-xs-12 success-align" *ngIf="successMsg">{{successMsg}}</div>
              </div>
              <div class="col-sm-6">
                <button class='btn btn-default btn-save' (click)="saveForm()">
                  <img src='assets/images/save.svg' class='pull-left' />
                  <span class='pull-left'>
                    <!-- {{'BUTTONS.SAVE' | translate}} -->
                    Salvar
                  </span>
                </button>
              </div>
            </div>
            <!-- <div class="error col-xs-12" *ngIf="errorDetails">{{errorDetails}}</div>
            <div class="success col-xs-12" *ngIf="successMsg">{{successMsg}}</div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- Spanish -->

  </div>
</div>