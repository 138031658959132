<div>
  <!-- <div class='row'>
    <div class='col-sm-9 padding-right-10'>
      <ul class='nav nav-tabs rules-tabs'>
        <li class="">
          <a data-toggle="tab" href="#eng" (click)="translate.use('en')">
            <span>English</span>
          </a>
        </li>
        <li class="">
          <a data-toggle="tab" href="#eng" (click)="translate.use('es')">
            <span>Español</span>
          </a>
        </li>
      </ul>
    </div>
  </div> -->
  <div class="tab-content">
    <div class="tab-pane active" id="eng">
      <div class="centered-content">
        <div>
          <div class="col-sm-9 tabPaneBorder borderTop">
            <div class='editContainer col-sm-9'>
              <form [formGroup]="loyaltyFormEn">
                <div class='row formDiv margin-top-35'>
                  <div class="col-xs-12">
                    <p class="adminheadingLabelStyle">
                      <!-- {{'LOYALTY-WEBSITE.TAB2-CUSTOMIZATION' | translate}} -->
                      Font & Color Customization
                    </p>
                    <p class='adminPermissionDivText margin-top-20'>
                      <!-- {{'LOYALTY-WEBSITE.TAB2-TEXT' | translate}} -->
                      Input CSS to configure the look and feel of your website.
                    </p>
                  </div>
                </div>
                <div class='row formDiv'>
                  <div class='col-xs-12 form-group margin-top-20'>
                    <label class='labelStyle'>
                      <!-- {{'LOYALTY-WEBSITE.CSS-TEXTSTYLE' | translate}} -->
                      CSS for Text Style
                    </label>
                    <button class='refresh-button pull-right' (click)="resetField('cssTextStyle', 'loyaltyFormEn')">
                      <img src='assets/images/refresh.png' class='refresh-image' title="{{'LOYALTY-WEBSITE.RESET' | translate}}" />
                    </button>
                    <textarea class='inputTextStyle form-control' formControlName='cssTextStyle' placeholder="This CSS customizes Button Style (font, color, shape), Text Styles (Fonts & Color)"></textarea>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class='col-sm-9 padding-right-0'>
      <div class='buttons-div clear text-right row'>
        <!-- <button class='btn btn-default btn-cancel' (click)="cancel()">
          Cancel
        </button> -->
        <div class="col-sm-6">
          <div class="error col-xs-12 error-align" *ngIf="errorDetails">{{errorDetails}}</div>
          <div class="success col-xs-12 success-align" *ngIf="successMsg">{{successMsg}}</div>
        </div>
        <div class="col-sm-6">
          <button class='btn btn-default btn-save' (click)="saveForm()">
            <img src='assets/images/save.svg' class='pull-left' />
            <span class='pull-left'> Save </span>
          </button>
        </div>
        <!-- <button class='btn btn-default btn-save' (click)="openPreview()">
          <span>Preview</span>
        </button> -->
      </div>
      <!-- <div class="error col-xs-12" *ngIf="errorDetails">{{errorDetails}}</div>
      <div class="success col-xs-12" *ngIf="successMsg">{{successMsg}}</div> -->
    </div>
  </div>
</div>