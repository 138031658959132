<!-- Navbar Container -->
<app-navbar></app-navbar>

<!-- Help Container -->
<div class='col-sm-10 col-sm-offset-2 main-container'>
  <div class='head-line text-center'>
      <h2>Need Some Help?</h2>
      <div class="btn-container">
        <a class='btn btn-default text-center' href='https://docs.google.com/document/d/1MMq8BZ5zbZkyfqdIzZeqUMWJsbFYGaN5Rvhl7xrXDzg/edit#' target="_blank">
            User Manual
        </a>
        <a class='btn btn-default btn-outline text-center' href='https://drive.google.com/file/d/1fQEAhPFtvtDBVTfB6KgsjRdmapHxL4-T/view?usp=sharing' target="_blank">
            Video Demo
        </a>
      </div>
  </div>
</div>