<div class="modal-header text-center textWrap" *ngIf="srcObject.mode == 'custom'">
  <h3 class="modal-title" id="exampleModalLabel">{{srcObject.title}}</h3>
  <p xngIf="srcObject.status == 'Active' || srcObject.status == null">{{srcObject.message}}</p>
  <p xngIf="srcObject.status == 'Active' || srcObject.status == null">{{srcObject.message1}}</p>
  <p xngIf="srcObject.status == 'Active' || srcObject.status == null">{{srcObject.message2}}</p>
  <p xngIf="srcObject.status == 'Active' || srcObject.status == null">{{srcObject.message3}}</p>
  <p xngIf="srcObject.status == 'Active' || srcObject.status == null">{{srcObject.message4}}</p>
</div>
<div class="modal-footer" style="text-align:center" *ngIf="srcObject.mode == 'custom'">
  <button type="button" class="btn btn-default"  id="deleteModal" data-dismiss="modal" (click)="confirmClose()">{{srcObject.action2}}</button>
</div>
