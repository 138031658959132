<div class='col-sm-10 col-sm-offset-2'>
  <wizard navBarLocation="top" class="wizardStyle" navigationMode="semi-strict" navBarLayout="large-empty-symbols">
    <!-- loyalty first step start -->
    <wizard-step stepTitle="Brand And Hotel Details" navigationSymbol="1">
      <ng-template wizardStepTitle>
        <div class="d-none d-md-block" class="wizardHeadingTextStyle">Loyalty Program Setup</div>
      </ng-template>
      <div class="centered-content">
        <div>
          <div class="col-md-12 step-1">
            <div class='editContainer'>
              <form [formGroup]="loyalty">
                <h3>Loyalty Program Setup: Step 1.</h3>
                <div class='row'>
                  <div class='col-sm-9'>
                    <div class='formDiv row'>
                      <div class="form-group col-sm-5">
                        <label class="labelStyle">Loyalty Program Name*</label>
                        <input type="text" (focus)="errorDetails = ''" [ngClass]="loyalty.get('programmeName').hasError('required') && loyalty.get('programmeName').touched ? 'err':''"
                          placeholder="" class='form-control inputTextStyle' formControlName="programmeName">
                      </div>
                    </div>
                    <div class='formDiv'>
                      <div class="form-group margin-bottom45">
                        <label class="adminheadingLabelStyle">
                          <strong>Select the type of loyalty program (check at least one)*</strong>
                        </label>
                        <div class='checkbox-list row'>
                          <div *ngFor="let type of programTypes">
                            <input type='checkbox' name="program" [id]="'program'+type.loyaltyProgramTypeId" [value]="type.loyaltyProgramTypeName" (change)="addFormControl('programType', type, $event.target.checked)"
                            />
                            <label [for]="'program'+type.loyaltyProgramTypeId" class='col-sm-3'>
                              <span class='check-box'></span>
                              <span>{{type.loyaltyProgramTypeName}}</span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="form-group margin-top-30" *ngIf="loyalty.controls['programType'].value.indexOf(1) > -1">
                        <label class="adminheadingLabelStyle">
                          <strong>Choose the type of bookings guests are awarded points for (check at least one)*</strong>
                        </label>
                        <div class='checkbox-list row'>
                          <div *ngFor="let type of bookingTypes">
                            <input type='checkbox' name="booking" (focus)="errorDetails = ''" [id]="'booking'+type.bookingTypeId" [value]="type.bookingType"
                              (change)="addFormControl('bookingType', type, $event.target.checked)" />
                            <label [for]="'booking'+type.bookingTypeId" class='col-sm-3'>
                              <span class='check-box'></span>
                              <span>{{type.bookingType}}</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div class='col-xs-12 text-right '>
                    <button type="button" class="btn btn-default cancelButtonStyle" (click)="cancel()">
                      <strong>Cancel</strong>
                    </button>
                    <button type="button" class="btn  buttonStyle" disabled>Previous</button>
                    <button type="button" class="btn btn-default nextButtonStyle" goToStep="1" [class.disabled]="!loyalty.valid" (click)="saveWizard()">Next</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="btn-group" class="buttonDivStyle">
          <button type="button" class="btn btn-default cancelButtonStyle" (click)="cancel()">
            <strong>Cancel</strong>
          </button>
          <button type="button" class="btn  buttonStyle" disabled>Previous</button>
          <button type="button" class="btn btn-default nextButtonStyle" goToStep="1" [class.disabled]="!loyalty.valid" (click)="saveWizard()">Next</button>
        </div>
      </div>
    </wizard-step>
    <!-- loyalty first step end -->

    <!-- loyalty second step start  -->
    <wizard-step>
      <div id="" class="customContainer pull-left">
        <ul class="nav nav-tabs">

          <li #consumerProgram class="">
            <a href="#consumerProgram" data-toggle="tab" [ngClass]="loyalty.controls['programType'].value.indexOf(1) > -1 ? '' : 'disabled'">Consumer Program</a>
          </li>

          <li #employeeProgram>
            <a href="#employeeProgram" data-toggle="tab" [ngClass]="loyalty.controls['programType'].value.indexOf(2) > -1 ? '' : 'disabled'">Employee Program</a>
          </li>

          <li #travelAgent>
            <a href="#travelAgent" data-toggle="tab" [ngClass]="loyalty.controls['programType'].value.indexOf(3) > -1 ? '' : 'disabled'">Travel Agent</a>
          </li>

        </ul>
        <div class="tab-content">

          <!-- loyalty consumer tab -->
          <div #consumerContentProgram class="tab-pane" id="consumerProgram">
            <div class="centered-content">
              <div>
                <div class="col-sm-12 tabPaneBorder step-2">
                  <div class='editContainer col-sm-11'>
                    <div class='row'>
                      <h3 class='col-xs-12'>Loyalty Program Setup: Step 2.</h3>
                      <div class='col-sm-10'>
                        <p class="adminPermHeading">Point Parameters</p>
                        <p class="adminPermissionDivText margin-0">Setup rules for how loyalty points are calculated and which guests can earn them. Points are calculated
                          based on </p>
                        <p class="adminPermissionDivText">(B% of Cost + E% of Cost of Extras) / Number of valid people in booking / Coefficient</p>
                      </div>
                    </div>
                    <form [formGroup]="consumerProgramType">

                      <div *ngIf="loyalty.controls['bookingType'].value.indexOf(1) > -1" formGroupName="directBooking">

                        <div class='formDiv row' formGroupName="points">
                          <p class='padding-left-15 margin-top25'>
                            <strong>Direct Bookings*</strong>
                          </p>

                          <div class="form-group col-sm-4">
                            <label class="labelStyle">% of Cost of Booking (B)</label>
                            <input type="number" onkeypress='return event.charCode >= 48 && event.charCode <= 57' placeholder="" class='form-control inputTextStyle'
                              [ngClass]="consumerProgramType.get('directBooking').get('points').get('costOfBooking').hasError('max') || consumerProgramType.get('directBooking').get('points').get('costOfBooking').hasError('required') && consumerProgramType.get('directBooking').get('points').get('costOfBooking').touched ? 'err':''"
                              formControlName="costOfBooking">
                          </div>

                          <div class="form-group col-sm-4">
                            <label class="labelStyle">% of Cost of Extras (E)</label>
                            <input type="number" onkeypress='return event.charCode >= 48 && event.charCode <= 57' placeholder="" class='form-control inputTextStyle'
                              [ngClass]="consumerProgramType.get('directBooking').get('points').get('costOfExtras').hasError('max') || consumerProgramType.get('directBooking').get('points').get('costOfExtras').hasError('required') && consumerProgramType.get('directBooking').get('points').get('costOfExtras').touched ? 'err':''"
                              formControlName="costOfExtras">
                          </div>

                          <div class="form-group col-sm-4">
                            <label class="labelStyle">% Coefficient (decimal value)</label>
                            <input type="number" placeholder="" class='form-control inputTextStyle' [ngClass]="consumerProgramType.get('directBooking').get('points').get('coeficient').hasError('pattern') || consumerProgramType.get('directBooking').get('points').get('coeficient').hasError('max') || consumerProgramType.get('directBooking').get('points').get('coeficient').hasError('required') && consumerProgramType.get('directBooking').get('points').get('coeficient').touched ? 'err':''"
                              formControlName="coeficient">
                          </div>
                        </div>

                        <div class='formDiv row' formGroupName="guests">
                          <p class='padding-left-15'>
                            <strong>Types of guests that earn points (check at least one)*</strong>
                          </p>
                          <div class='checkbox-list padding-left-15 row'>
                            <input type='checkbox' name="cb" id='chb1' formControlName="isSeniors" />
                            <label for='chb1' class='col-sm-4'>
                              <span class='check-box'></span>
                              <span>Seniors (65+)</span>
                            </label>
                            <input type='checkbox' name="cb" id='chb2' formControlName="isAdults" />
                            <label for='chb2' class='col-sm-4'>
                              <span class='check-box'></span>
                              <span>Adults (18-64)</span>
                            </label>
                            <input type='checkbox' name="cb" id='chb3' formControlName="isYouth" />
                            <label for='chb3' class='col-sm-4'>
                              <span class='check-box'></span>
                              <span>
                                Youth (13-17)
                              </span>
                            </label>
                            <input type='checkbox' name="cb" id='chb4' formControlName="isChild" />
                            <label for='chb4' class='col-sm-4'>
                              <span class='check-box'></span>
                              <span>
                                Children (3-12)
                              </span>
                            </label>
                            <input type='checkbox' name="cb" id='chb5' formControlName="isInfants" />
                            <label for='chb5' class='col-sm-4'>
                              <span class='check-box'></span>
                              <span>Infants (2 and
                                <)</span>
                            </label>
                          </div>
                        </div>
                      </div>


                      <div *ngIf="loyalty.controls['bookingType'].value.indexOf(2) > -1" formGroupName="externalBooking">

                        <div class='formDiv row' formGroupName="points">
                          <p class='padding-left-15 margin-top25'>
                            <strong>External Bookings*</strong>
                          </p>

                          <div class="form-group col-sm-4">
                            <label class="labelStyle">% of Cost of Booking (B)</label>
                            <input type="number" onkeypress='return event.charCode >= 48 && event.charCode <= 57' placeholder="" class='form-control inputTextStyle'
                              [ngClass]="consumerProgramType.get('externalBooking').get('points').get('costOfBooking').hasError('max') || consumerProgramType.get('externalBooking').get('points').get('costOfBooking').hasError('required') && consumerProgramType.get('externalBooking').get('points').get('costOfBooking').touched ? 'err':''"
                              formControlName="costOfBooking">
                          </div>

                          <div class="form-group col-sm-4">
                            <label class="labelStyle">% of Cost of Extras (E)</label>
                            <input type="number" onkeypress='return event.charCode >= 48 && event.charCode <= 57' placeholder="" class='form-control inputTextStyle'
                              [ngClass]="consumerProgramType.get('externalBooking').get('points').get('costOfExtras').hasError('max') || consumerProgramType.get('externalBooking').get('points').get('costOfExtras').hasError('required') && consumerProgramType.get('externalBooking').get('points').get('costOfExtras').touched ? 'err':''"
                              formControlName="costOfExtras">
                          </div>

                          <div class="form-group col-sm-4">
                            <label class="labelStyle">% Coefficient (decimal value)</label>
                            <input type="number" placeholder="" class='form-control inputTextStyle' [ngClass]="consumerProgramType.get('externalBooking').get('points').get('coeficient').hasError('max') || consumerProgramType.get('externalBooking').get('points').get('coeficient').hasError('pattern') || consumerProgramType.get('externalBooking').get('points').get('coeficient').hasError('required') && consumerProgramType.get('externalBooking').get('points').get('coeficient').touched ? 'err':''"
                              formControlName="coeficient">
                          </div>
                        </div>

                        <div class='formDiv row' formGroupName="guests">
                          <p class='padding-left-15'>
                            <strong>Types of guests that earn points (check at least one)*</strong>
                          </p>
                          <div class='checkbox-list row padding-left-15'>
                            <input type='checkbox' name="cb" id='d1' formControlName="isSeniors" />
                            <label for='d1' class='col-sm-4'>
                              <span class='check-box'></span>
                              <span>Seniors (65+)</span>
                            </label>
                            <input type='checkbox' name="cb" id='d2' formControlName="isAdults" />
                            <label for='d2' class='col-sm-4'>
                              <span class='check-box'></span>
                              <span>Adults (18-64)</span>
                            </label>
                            <input type='checkbox' name="cb" id='d3' formControlName="isYouth" />
                            <label for='d3' class='col-sm-4'>
                              <span class='check-box'></span>
                              <span>
                                Youth (13-17)
                              </span>
                            </label>
                            <input type='checkbox' name="cb" id='d4' formControlName="isChild" />
                            <label for='d4' class='col-sm-4'>
                              <span class='check-box'></span>
                              <span>
                                Children (3-12)
                              </span>
                            </label>
                            <input type='checkbox' name="cb" id='d5' formControlName="isInfants" />
                            <label for='d5' class='col-sm-4'>
                              <span class='check-box'></span>
                              <span>Infants (2 and
                                < )</span>
                            </label>
                          </div>
                        </div>

                      </div>

                      <div class='row formDiv'>
                        <p class='padding-left-15 margin-top25'>
                          <strong>Points Distribution*</strong>
                        </p>
                        <div class='form-group col-sm-4'>
                          <label class='labelStyle'>% of points to Buyer</label>
                          <input type='number' class='form-control inputTextStyle' [ngClass]="consumerProgramType.get('buyerPoints').hasError('max') || consumerProgramType.get('buyerPoints').hasError('pattern') || consumerProgramType.get('buyerPoints').hasError('required') && consumerProgramType.get('buyerPoints').touched ? 'err':''"
                            formControlName="buyerPoints" onkeydown='return event.which != 189' />
                        </div>
                        <div class='form-group col-sm-4'>
                          <label class='labelStyle'>% of points to Guest</label>
                          <input type='number' class='form-control inputTextStyle' [ngModel]="consumerProgramType && consumerProgramType.get('buyerPoints').value == 0 ? 100 : consumerProgramType.get('guestPoints').value"
                            formControlName="guestPoints" readOnly/>
                        </div>
                      </div>

                      <div class='formDiv'>
                        <div>
                          <p class="adminPermHeading">How points are earned</p>
                          <div class="adminPermissionDivText">Select which actions awards guests with points</div>
                        </div>
                        <div formArrayName="transactionTypesData">
                          <div class='margin-top' *ngFor="let transaction of consumerProgramType.controls['transactionTypesData']; let i=index">
                            <div formGroupName="{{i}}" class="col-sm-12 padding-left-0">
                              <div class='checkbox-list col-sm-4 points-div'>
                                <input type='checkbox' name="cb" [id]='"c"+i' formControlName="isEnabled" (click)="setTransactionValidations(transaction.value.isEnabled ? false : true, 'consumerProgramType', i)"
                                />
                                <label [for]='"c"+i'>
                                  <span class='check-box'></span>
                                  <span>{{transaction.value.transactionTypeEn }}</span>
                                </label>
                              </div>
                              <div class='checkbox-list col-sm-8  form-group'>
                                <div class='col-sm-6'>
                                  <label class='labelStyle'>Points Earned</label>
                                  <input type='number' onkeypress='return event.charCode >= 48 && event.charCode <= 57' class='form-control inputTextStyle margin-bottom-0'
                                    [ngClass]="consumerProgramType.controls['transactionTypesData'][i].controls['points'].hasError('required')
                                    && consumerProgramType.controls['transactionTypesData'][i].controls['points'].touched ? 'err' : ''"
                                    formControlName="points" [class.disabled]="!transaction.value.isEnabled">
                                  <!-- <input type='number' onkeypress='return event.charCode >= 48 && event.charCode <= 57' class='form-control inputTextStyle margin-bottom-0'
                                  [ngClass]="consumerProgramType.get('birthdayPoints').hasError('required') && consumerProgramType.get('birthdayPoints').touched ? 'err':''"
                                  formControlName="birthdayPoints" [class.disabled]="!consumerProgramType.controls['isBirthdayPoints'].value" -->

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- <div>
                          <div class='checkbox-list col-sm-4  points-div'>
                            <input type='checkbox' name="cb" id='c2' formControlName="isBlogPoints" />
                            <label for='c2'>
                              <span class='check-box'></span>
                              <span>Per Blog Post</span>
                            </label>
                          </div>
                          <div class='checkbox-list col-sm-8 form-group'>
                            <div class='col-sm-6'>
                              <label class='labelStyle'>Points Earned</label>
                              <input type='number' onkeypress='return event.charCode >= 48 && event.charCode <= 57' class='form-control inputTextStyle margin-bottom-0'
                                [ngClass]="consumerProgramType.get('blogPoints').hasError('required') && consumerProgramType.get('blogPoints').touched ? 'err':''"
                                formControlName="blogPoints" [class.disabled]="!consumerProgramType.controls['isBlogPoints'].value"
                              />
                            </div>
                          </div>
                        </div>
                        <div>
                          <div class='checkbox-list col-sm-4  points-div'>
                            <input type='checkbox' name="cb" id='c3' formControlName="isInvitePoints" />
                            <label for='c3'>
                              <span class='check-box'></span>
                              <span>Per Friend Invite</span>
                            </label>
                          </div>
                          <div class='checkbox-list col-sm-8  form-group'>
                            <div class='col-sm-6'>
                              <label class='labelStyle'>Points Earned</label>
                              <input type='number' onkeypress='return event.charCode >= 48 && event.charCode <= 57' class='form-control inputTextStyle margin-bottom-0'
                                [ngClass]="consumerProgramType.get('invitePoints').hasError('required') && consumerProgramType.get('invitePoints').touched ? 'err':''"
                                formControlName="invitePoints" [class.disabled]="!consumerProgramType.controls['isInvitePoints'].value"
                              />
                            </div>
                          </div>
                        </div>
                        <div>
                          <div class='checkbox-list col-sm-4  points-div'>
                            <input type='checkbox' name="cb" id='c4' formControlName="isJoinPoints" />
                            <label for='c4'>
                              <span class='check-box'></span>
                              <span>Per Friend Join</span>
                            </label>
                          </div>
                          <div class='checkbox-list col-sm-8 form-group'>
                            <div class='col-sm-6'>
                              <label class='labelStyle'>Points Earned</label>
                              <input type='number' onkeypress='return event.charCode >= 48 && event.charCode <= 57' class='form-control inputTextStyle'
                                [ngClass]="consumerProgramType.get('joinPoints').hasError('required') && consumerProgramType.get('joinPoints').touched ? 'err':''"
                                formControlName="joinPoints" [class.disabled]="!consumerProgramType.controls['isJoinPoints'].value"
                              />
                            </div>
                          </div>
                        </div>
                        <div>
                          <div class='checkbox-list col-sm-4  points-div'>
                            <input type='checkbox' name="cb" id='c5' formControlName="isSharePoints" />
                            <label for='c5'>
                              <span class='check-box'></span>
                              <span>Per Share</span>
                            </label>
                          </div>
                          <div class='checkbox-list col-sm-8 form-group'>
                            <div class='col-sm-6'>
                              <label class='labelStyle'>Points Earned</label>
                              <input type='number' onkeypress='return event.charCode >= 48 && event.charCode <= 57' class='form-control inputTextStyle'
                                [ngClass]="consumerProgramType.get('sharePoints').hasError('required') && consumerProgramType.get('sharePoints').touched ? 'err':''"
                                formControlName="sharePoints" [class.disabled]="!consumerProgramType.controls['isSharePoints'].value"
                              />
                            </div>
                          </div>
                        </div> -->
                      </div>

                      <div class='row formDiv'>
                        <div class='form-group col-sm-4 down-arrow select-icon'>
                          <label class='labelStyle'>Points Expire After*</label>
                          <select class='form-control  inputTextStyle' formControlName="expirePoints" [ngClass]="consumerProgramType.get('expirePoints').hasError('required') && consumerProgramType.get('expirePoints').touched ? 'err':''">
                            <option value=null disabled="true" selected>Choose Year</option>
                            <option value="Never Expire">Never Expire</option>
                            <option value=1>1 Year</option>
                            <option value=2>2 Years</option>
                            <option value=3>3 Years</option>
                            <option value=4>4 Years</option>
                            <option value=5>5 Years</option>
                            <option value=6>6 Years</option>
                            <option value=7>7 Years</option>
                            <option value=8>8 Years</option>
                            <option value=9>9 Years</option>
                            <option value=10>10 Years</option>
                          </select>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="col-xs-12 step2-footer-buttons text-right">
                  <button type="button" class="btn btn-default cancelButtonStyle" (click)="cancel()">Cancel</button>
                  <button type="button" class="btn btn-default nextButtonStyle prevButton" previousStep>Previous</button>
                  <button type="button" class="btn btn-default nextButtonStyle" nextStep [ngClass]="consumerProgramType.valid && employeeProgramType.valid && travelAgentType.valid ? '' : 'disabled'"
                    (click)="saveWizard()">Next</button>
                </div>
                <div class="buttonDivStyle">
                  <button type="button" class="btn btn-secondary" class="cancelButtonStyle" (click)="cancel()">Cancel</button>
                  <button type="button" class="btn btn-secondary" class="nextButtonStyle  prevButton" previousStep>Previous</button>
                  <button type="button" class="btn btn-secondary" nextStep class="nextButtonStyle" [ngClass]="consumerProgramType.valid && employeeProgramType.valid && travelAgentType.valid ? '' : 'disabled'"
                    (click)="saveWizard()">Next</button>
                </div>
              </div>
            </div>
          </div>
          <!-- loyalty consumer tab -->

          <!-- loyalty employee tab -->
          <div #employeeContentProgram class="tab-pane" id="employeeProgram">
            <div class="centered-content">
              <div>
                <div class="col-sm-12 tabPaneBorder step-2">
                  <div class='editContainer col-sm-10'>
                    <div>
                      <h3>Loyalty Program Setup: Step 2.</h3>
                      <div>
                        <p class="adminPermHeading">Points Earned per booking*</p>
                      </div>
                    </div>
                    <form [formGroup]="employeeProgramType">
                      <div class='row formDiv margin-top25'>
                        <div class='col-sm-4 form-group'>
                          <label class='labelStyle'>% of Cost of Booking</label>
                          <input type='number' onkeypress='return event.charCode >= 48 && event.charCode <= 57' class='form-control inputTextStyle'
                            [ngClass]="employeeProgramType.get('bookingPoints').hasError('max') || employeeProgramType.get('bookingPoints').hasError('required') && employeeProgramType.get('bookingPoints').touched ? 'err':''"
                            formControlName="bookingPoints" />
                        </div>
                      </div>
                      <div class='row formDiv margin-top25'>
                        <div class='form-group'>
                          <!-- <label class='labelStyle'></label> -->
                          <p class='padding-left-15'>
                            <strong>Types of employee referral points (select any one)*</strong>
                          </p>
                          <div class="col-xs-12">
                            <label class="radio" *ngFor="let rule of employeePointRules">
                              <input id="radio1" type="radio" [value]="rule.id" formControlName="pointsRuleId" [ngClass]="employeeProgramType.get('pointsRuleId').hasError('required') && employeeProgramType.get('pointsRuleId').touched ? 'err':''">
                              <span class="outer"></span>
                              <span class='radio-text'>{{rule.ruleName}}</span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class='row formDiv margin-top25' *ngIf="employeeProgramType.get('pointsRuleId').value && (employeeProgramType.get('pointsRuleId').value == 1 || employeeProgramType.get('pointsRuleId').value == 2)">
                        <div class='col-sm-4 form-group'>
                          <label class='labelStyle'>Employee Referral Points</label>
                          <input type='number' maxlength="5" onkeypress='return event.charCode >= 48 && event.charCode <= 57' class='form-control inputTextStyle'
                            [ngClass]="employeeProgramType.get('referralPoints').hasError('max') || employeeProgramType.get('referralPoints').hasError('required') && employeeProgramType.get('referralPoints').touched ? 'err':''"
                            formControlName="referralPoints" />
                        </div>
                      </div>
                      <!-- <div class='formDiv'> -->
                        <!-- <div>
                          <p class="adminPermHeading">How points are earned</p>
                          <div class="adminPermissionDivText">Select which actions awards guests with points</div>
                        </div> -->
                        <!-- <div formArrayName="transactionTypesData">
                          <div class='margin-top'  *ngFor="let transaction of employeeProgramType.controls['transactionTypesData'].controls; let i=index">
                            <div formGroupName="{{i}}">
                            <div class='checkbox-list col-sm-4 points-div'>
                              <input type='checkbox' name="cb" [id]='"e"+i' formControlName="isEnabled" (click)="setTransactionValidations(transaction.value.isEnabled ? false : true, 'employeeProgramType', i)"/>
                              <label [for]='"e"+i'>
                                <span class='check-box'></span>
                                <span>{{transaction.value.transactionTypeEn }}</span>
                              </label>
                            </div>
                            <div class='checkbox-list col-sm-8  form-group'>
                              <div class='col-sm-6'>
                                <label class='labelStyle'>Points Earned</label>
                                <input type='number' onkeypress='return event.charCode >= 48 && event.charCode <= 57' class='form-control inputTextStyle margin-bottom-0'
                                  [ngClass]="employeeProgramType.controls['transactionTypesData'][i].controls['points'].hasError('required') && employeeProgramType.controls['transactionTypesData'][i].controls['points'].touched ? 'err' : ''"
                                  formControlName="points" [class.disabled]="!transaction.value.isEnabled">
                              </div>
                            </div>
                            </div>
                          </div>
                        </div> -->
                        <!-- <div class='margin-top'>
                          <div class='checkbox-list col-sm-4 points-div'>
                            <input type='checkbox' name="cb" id='e1' formControlName="isBirthdayPoints" />
                            <label for='e1'>
                              <span class='check-box'></span>
                              <span>Birthdays</span>
                            </label>
                          </div>
                          <div class='checkbox-list col-sm-8  form-group'>
                            <div class='col-sm-6'>
                              <label class='labelStyle'>Points Earned</label>
                              <input type='number' onkeypress='return event.charCode >= 48 && event.charCode <= 57' class='form-control inputTextStyle margin-bottom-0'
                                [ngClass]="employeeProgramType.get('birthdayPoints').hasError('required') && employeeProgramType.get('birthdayPoints').touched ? 'err':''"
                                formControlName="birthdayPoints" [class.disabled]="!employeeProgramType.controls['isBirthdayPoints'].value"
                              />
                            </div>
                          </div>
                        </div>
                        <div>
                          <div class='checkbox-list col-sm-4  points-div'>
                            <input type='checkbox' name="cb" id='e2' formControlName="isBlogPoints" />
                            <label for='e2'>
                              <span class='check-box'></span>
                              <span>Per Blog Post</span>
                            </label>
                          </div>
                          <div class='checkbox-list col-sm-8 form-group'>
                            <div class='col-sm-6'>
                              <label class='labelStyle'>Points Earned</label>
                              <input type='number' onkeypress='return event.charCode >= 48 && event.charCode <= 57' class='form-control inputTextStyle margin-bottom-0'
                                [ngClass]="employeeProgramType.get('blogPoints').hasError('required') && employeeProgramType.get('blogPoints').touched ? 'err':''"
                                formControlName="blogPoints" [class.disabled]="!employeeProgramType.controls['isBlogPoints'].value"
                              />
                            </div>
                          </div>
                        </div>
                        <div>
                          <div class='checkbox-list col-sm-4  points-div'>
                            <input type='checkbox' name="cb" id='e3' formControlName="isInvitePoints" />
                            <label for='e3'>
                              <span class='check-box'></span>
                              <span>Per Friend Invite</span>
                            </label>
                          </div>
                          <div class='checkbox-list col-sm-8  form-group'>
                            <div class='col-sm-6'>
                              <label class='labelStyle'>Points Earned</label>
                              <input type='number' onkeypress='return event.charCode >= 48 && event.charCode <= 57' class='form-control inputTextStyle margin-bottom-0'
                                [ngClass]="employeeProgramType.get('invitePoints').hasError('required') && employeeProgramType.get('invitePoints').touched ? 'err':''"
                                formControlName="invitePoints" [class.disabled]="!employeeProgramType.controls['isInvitePoints'].value"
                              />
                            </div>
                          </div>
                        </div>
                        <div>
                          <div class='checkbox-list col-sm-4  points-div'>
                            <input type='checkbox' name="cb" id='e4' formControlName="isJoinPoints" />
                            <label for='e4'>
                              <span class='check-box'></span>
                              <span>Per Friend Join</span>
                            </label>
                          </div>
                          <div class='checkbox-list col-sm-8 form-group'>
                            <div class='col-sm-6'>
                              <label class='labelStyle'>Points Earned</label>
                              <input type='number' onkeypress='return event.charCode >= 48 && event.charCode <= 57' class='form-control inputTextStyle'
                                [ngClass]="employeeProgramType.get('joinPoints').hasError('required') && employeeProgramType.get('joinPoints').touched ? 'err':''"
                                formControlName="joinPoints" [class.disabled]="!employeeProgramType.controls['isJoinPoints'].value"
                              />
                            </div>
                          </div>
                        </div>
                        <div>
                          <div class='checkbox-list col-sm-4  points-div'>
                            <input type='checkbox' name="cb" id='e5' formControlName="isSharePoints" />
                            <label for='e5'>
                              <span class='check-box'></span>
                              <span>Per Share</span>
                            </label>
                          </div>
                          <div class='checkbox-list col-sm-8 form-group'>
                            <div class='col-sm-6'>
                              <label class='labelStyle'>Points Earned</label>
                              <input type='number' onkeypress='return event.charCode >= 48 && event.charCode <= 57' class='form-control inputTextStyle'
                                [ngClass]="employeeProgramType.get('sharePoints').hasError('required') && employeeProgramType.get('sharePoints').touched ? 'err':''"
                                formControlName="sharePoints" [class.disabled]="!employeeProgramType.controls['isSharePoints'].value"
                              />
                            </div>
                          </div>
                        </div> -->
                      <!-- </div> -->
                      <!-- <div class='row formDiv'>
                        <div class='form-group col-sm-4 down-arrow select-icon'>
                          <label class='labelStyle'>Points Expire After*</label>
                          <select class='form-control  inputTextStyle' formControlName="expirePoints" [ngClass]="employeeProgramType.get('expirePoints').hasError('required') && employeeProgramType.get('expirePoints').touched ? 'err':''">
                            <option value=null disabled="true" selected>Choose Year</option>
                            <option value="Never Expire">Never Expire</option>
                            <option value=1>1 Year</option>
                            <option value=2>2 Years</option>
                            <option value=3>3 Years</option>
                            <option value=4>4 Years</option>
                            <option value=5>5 Years</option>
                            <option value=6>6 Years</option>
                            <option value=7>7 Years</option>
                            <option value=8>8 Years</option>
                            <option value=9>9 Years</option>
                            <option value=10>10 Years</option>
                          </select>
                        </div>
                      </div> -->
                    </form>
                  </div>
                </div>
                <div class="col-xs-12 step2-footer-buttons text-right">
                  <button type="button" class="btn btn-default cancelButtonStyle" (click)="cancel()">Cancel</button>
                  <button type="button" class="btn btn-default nextButtonStyle prevButton" previousStep>Previous</button>
                  <button type="button" class="btn btn-default nextButtonStyle" nextStep [ngClass]="consumerProgramType.valid && employeeProgramType.valid && travelAgentType.valid ? '' : 'disabled'">Next</button>
                </div>
                <div class="buttonDivStyle">
                  <button type="button" class="btn btn-secondary" class="cancelButtonStyle" (click)="cancel()">Cancel</button>
                  <button type="button" class="btn btn-secondary" class="nextButtonStyle  prevButton" previousStep>Previous</button>
                  <button type="button" class="btn btn-secondary" nextStep class="nextButtonStyle" [ngClass]="consumerProgramType.valid && employeeProgramType.valid && travelAgentType.valid ? '' : 'disabled'">Next</button>
                </div>
              </div>
            </div>
          </div>
          <!-- loyalty employee tab -->

          <!-- loyalty travel agent tab -->
          <div #travelContentAgent class="tab-pane" id="travelAgent">
            <div class="centered-content">
              <div>
                <div class="col-sm-12 tabPaneBorder step-2">
                  <div class='editContainer col-sm-10'>
                    <div>
                      <h3>Loyalty Program Setup: Step 2.</h3>
                      <div>
                        <p class="adminPermHeading">Points Earned per booking*</p>
                      </div>
                    </div>
                    <form [formGroup]="travelAgentType">
                      <div class='row formDiv margin-top25'>
                        <div class='col-sm-4 form-group'>
                          <label class='labelStyle'>% of Cost of Booking</label>
                          <input type='number' onkeypress='return event.charCode >= 48 && event.charCode <= 57' class='form-control inputTextStyle'
                            [ngClass]="travelAgentType.get('bookingPoints').hasError('max') || travelAgentType.get('bookingPoints').hasError('required') && travelAgentType.get('bookingPoints').touched ? 'err':''"
                            formControlName="bookingPoints" />
                        </div>
                      </div>
                      <!-- <div class='formDiv'> -->
                        <!-- <div>
                          <p class="adminPermHeading">How points are earned</p>
                          <div class="adminPermissionDivText">Select which actions awards guests with points</div>
                        </div> -->
                        <!-- <div formArrayName="transactionTypesData">
                          <div class='margin-top'  *ngFor="let transaction of travelAgentType.controls['transactionTypesData'].controls; let i=index">
                            <div formGroupName="{{i}}">
                            <div class='checkbox-list col-sm-4 points-div'>
                              <input type='checkbox' name="cb" [id]='"t"+i' formControlName="isEnabled" (click)="setTransactionValidations(transaction.value.isEnabled ? false : true, 'travelAgentType', i)"/>
                              <label [for]='"t"+i'>
                                <span class='check-box'></span>
                                <span>{{transaction.value.transactionTypeEn }}</span>
                              </label>
                            </div>
                            <div class='checkbox-list col-sm-8  form-group'>
                              <div class='col-sm-6'>
                                <label class='labelStyle'>Points Earned</label>
                                <input type='number' onkeypress='return event.charCode >= 48 && event.charCode <= 57' class='form-control inputTextStyle margin-bottom-0'
                                  [ngClass]="travelAgentType.controls['transactionTypesData'][i].controls['points'].hasError('required') && travelAgentType.controls['transactionTypesData'][i].controls['points'].touched ? 'err' : ''"
                                  formControlName="points" [class.disabled]="!transaction.value.isEnabled">
                              </div>
                            </div>
                            </div>
                          </div>
                        </div> -->
                        <!-- <div class='margin-top'>
                          <div class='checkbox-list col-sm-4 points-div'>
                            <input type='checkbox' name="cb" id='t1' formControlName="isBirthdayPoints" />
                            <label for='t1'>
                              <span class='check-box'></span>
                              <span>Birthdays</span>
                            </label>
                          </div>
                          <div class='checkbox-list col-sm-8  form-group'>
                            <div class='col-sm-6'>
                              <label class='labelStyle'>Points Earned</label>
                              <input type='number' onkeypress='return event.charCode >= 48 && event.charCode <= 57' class='form-control inputTextStyle margin-bottom-0'
                                [ngClass]="travelAgentType.get('birthdayPoints').hasError('required') && travelAgentType.get('birthdayPoints').touched ? 'err':''"
                                formControlName="birthdayPoints" [class.disabled]="!travelAgentType.controls['isBirthdayPoints'].value"
                              />
                            </div>
                          </div>
                        </div>
                        <div>
                          <div class='checkbox-list col-sm-4  points-div'>
                            <input type='checkbox' name="cb" id='t2' formControlName="isBlogPoints" />
                            <label for='t2'>
                              <span class='check-box'></span>
                              <span>Per Blog Post</span>
                            </label>
                          </div>
                          <div class='checkbox-list col-sm-8 form-group'>
                            <div class='col-sm-6'>
                              <label class='labelStyle'>Points Earned</label>
                              <input type='number' onkeypress='return event.charCode >= 48 && event.charCode <= 57' class='form-control inputTextStyle margin-bottom-0'
                                [ngClass]="travelAgentType.get('blogPoints').hasError('required') && travelAgentType.get('blogPoints').touched ? 'err':''"
                                formControlName="blogPoints" [class.disabled]="!travelAgentType.controls['isBlogPoints'].value"
                              />
                            </div>
                          </div>
                        </div>
                        <div>
                          <div class='checkbox-list col-sm-4  points-div'>
                            <input type='checkbox' name="cb" id='t3' formControlName="isInvitePoints" />
                            <label for='t3'>
                              <span class='check-box'></span>
                              <span>Per Friend Invite</span>
                            </label>
                          </div>
                          <div class='checkbox-list col-sm-8  form-group'>
                            <div class='col-sm-6'>
                              <label class='labelStyle'>Points Earned</label>
                              <input type='number' onkeypress='return event.charCode >= 48 && event.charCode <= 57' class='form-control inputTextStyle margin-bottom-0'
                                [ngClass]="travelAgentType.get('invitePoints').hasError('required') && travelAgentType.get('invitePoints').touched ? 'err':''"
                                formControlName="invitePoints" [class.disabled]="!travelAgentType.controls['isInvitePoints'].value"
                              />
                            </div>
                          </div>
                        </div>
                        <div>
                          <div class='checkbox-list col-sm-4  points-div'>
                            <input type='checkbox' name="cb" id='t4' formControlName="isJoinPoints" />
                            <label for='t4'>
                              <span class='check-box'></span>
                              <span>Per Friend Join</span>
                            </label>
                          </div>
                          <div class='checkbox-list col-sm-8 form-group'>
                            <div class='col-sm-6'>
                              <label class='labelStyle'>Points Earned</label>
                              <input type='number' onkeypress='return event.charCode >= 48 && event.charCode <= 57' class='form-control inputTextStyle'
                                [ngClass]="travelAgentType.get('joinPoints').hasError('required') && travelAgentType.get('joinPoints').touched ? 'err':''"
                                formControlName="joinPoints" [class.disabled]="!travelAgentType.controls['isJoinPoints'].value"
                              />
                            </div>
                          </div>
                        </div>
                        <div>
                          <div class='checkbox-list col-sm-4  points-div'>
                            <input type='checkbox' name="cb" id='t5' formControlName="isSharePoints" />
                            <label for='t5'>
                              <span class='check-box'></span>
                              <span>Per Share</span>
                            </label>
                          </div>
                          <div class='checkbox-list col-sm-8 form-group'>
                            <div class='col-sm-6'>
                              <label class='labelStyle'>Points Earned</label>
                              <input type='number' onkeypress='return event.charCode >= 48 && event.charCode <= 57' class='form-control inputTextStyle'
                                [ngClass]="travelAgentType.get('sharePoints').hasError('required') && travelAgentType.get('sharePoints').touched ? 'err':''"
                                formControlName="sharePoints" [class.disabled]="!travelAgentType.controls['isSharePoints'].value"
                              />
                            </div>
                          </div>
                        </div> -->
                      <!-- </div> -->
                      <!-- <div class='row formDiv'>
                        <div class='form-group col-sm-4 down-arrow select-icon'>
                          <label class='labelStyle'>Points Expire After*</label>
                          <select class='form-control  inputTextStyle' formControlName="expirePoints" [ngClass]="travelAgentType.get('expirePoints').hasError('required') && travelAgentType.get('expirePoints').touched ? 'err':''">
                            <option value=null disabled="true" selected>Choose Year</option>
                            <option value="Never Expire">Never Expire</option>
                            <option value=1>1 Year</option>
                            <option value=2>2 Years</option>
                            <option value=3>3 Years</option>
                            <option value=4>4 Years</option>
                            <option value=5>5 Years</option>
                            <option value=6>6 Years</option>
                            <option value=7>7 Years</option>
                            <option value=8>8 Years</option>
                            <option value=9>9 Years</option>
                            <option value=10>10 Years</option>
                          </select>
                        </div>
                      </div> -->
                    </form>
                  </div>
                </div>
                <div class="col-xs-12 step2-footer-buttons text-right">
                  <button type="button" class="btn btn-default cancelButtonStyle" (click)="cancel()">Cancel</button>
                  <button type="button" class="btn btn-default nextButtonStyle prevButton" previousStep>Previous</button>
                  <button type="button" class="btn btn-default nextButtonStyle" nextStep [ngClass]="consumerProgramType.valid && employeeProgramType.valid && travelAgentType.valid ? '' : 'disabled'">Next</button>
                </div>
                <div class="buttonDivStyle">
                  <button type="button" class="btn btn-secondary" class="cancelButtonStyle" (click)="cancel()">Cancel</button>
                  <button type="button" class="btn btn-secondary" class="nextButtonStyle  prevButton" previousStep>Previous</button>
                  <button type="button" class="btn btn-secondary" nextStep class="nextButtonStyle" [ngClass]="consumerProgramType.valid && employeeProgramType.valid && travelAgentType.valid ? '' : 'disabled'">Next</button>
                </div>
              </div>
            </div>
          </div>
          <!-- loyalty travel agent tab -->

        </div>
      </div>
    </wizard-step>
    <!-- loyalty second step end  -->

    <!-- loyalty third step start  -->
    <wizard-step>
      <div id="" class="customContainer">
        <ul class="nav nav-tabs">

          <li #consumerMemberProgram>
            <a href="#consumerMemberProgram" data-toggle="tab" [ngClass]="loyalty.controls['programType'].value.indexOf(1) > -1 ? '' : 'disabled'">Consumer Program</a>
          </li>

          <li #employeeMemberProgram>
            <a href="#employeeMemberProgram" data-toggle="tab" [ngClass]="loyalty.controls['programType'].value.indexOf(2) > -1 ? '' : 'disabled'">Employee Program</a>
          </li>

          <li #travelAgentMember>
            <a href="#travelAgentMember" data-toggle="tab" [ngClass]="loyalty.controls['programType'].value.indexOf(3) > -1 ? '' : 'disabled'">Travel Agent</a>
          </li>

        </ul>
        <div class="tab-content">

          <!-- loyalty membership first step start -->
          <div #consumerMemberContentProgram class="tab-pane" id="consumerMemberProgram" [ngClass]="loyalty.controls['programType'].value.indexOf(1) == 0 ? 'active' : ''">
            <div class="centered-content">
              <div>
                <div class="col-sm-12 tabPaneBorder">
                  <div class='editContainer col-sm-12'>
                    <div class='row'>
                      <h3 class="col-sm-8">Loyalty Program Setup: Step 3.</h3>
                    </div>
                    <div>
                      <form novalidate [formGroup]="consumerMemberProgramType">
                        <div formArrayName="membershipLevelToList">
                          <div *ngFor="let memberShipLevel of consumerMemberProgramType.controls['membershipLevelToList']['controls']; let i=index">
                            <div formGroupName="{{i}}">
                              <div class='row'>
                                <div class='col-sm-6'>
                                  <p class="adminPermHeading">Membership Levels</p>
                                  <p class="adminPermissionDivText">Setup the different membership levels of your consumer loyalty program. Your program must
                                    have at least one level.</p>
                                </div>
                              </div>
                              <div class='formDiv row margin-top'>
                                <div class='form-group col-sm-4'>
                                  <label class="labelStyle">Membership Name*</label>
                                  <input type="text" placeholder="" class='form-control inputTextStyle' [ngClass]="memberShipLevel.get('memberShipName').hasError('uniqueBy') || memberShipLevel.get('memberShipName').hasError('required') && memberShipLevel.get('memberShipName').touched ? 'err':''"
                                    formControlName="memberShipName" />
                                  <p class="error" *ngIf="memberShipLevel.get('memberShipName').hasError('uniqueBy')">Membership Name must be unique</p>
                                </div>
                                <div class='form-group col-sm-4'>
                                  <label class="labelStyle">Membership level*</label>
                                  <input type='text' class='form-control inputTextStyle' [ngModel]="'Level '+ (i+1)" formControlName="memberShipLevel" readonly/>
                                </div>
                                <div class='col-sm-4 padding-top-25 text-right' *ngIf="consumerMemberProgramType.controls['membershipLevelToList'].length > 1">
                                  <button class='btn btn-default remove-btn' (click)="remove('consumerMemberProgramType', 'membershipLevelToList', i, 'membership')">
                                    <img src='assets/images/minus.svg' class='pull-left' />
                                    <span> Remove</span>
                                  </button>
                                </div>
                              </div>
                              <div class='formDiv'>
                                <div class="form-group margin-bottom45">
                                  <label class="adminheadingLabelStyle">
                                    Does the guest have to Opt-In?
                                  </label>
                                  <br>
                                  <label class="radio">
                                    <input id="'gt'+(i+1)" type="radio" [value]="true" formControlName="isGuestOptIn">
                                    <span class="outer"></span>
                                    <span class='radio-text'>Yes</span>
                                  </label>
                                  <label class="radio">
                                    <input id="'gf'+(i+1)" type="radio" [value]="false" formControlName="isGuestOptIn">
                                    <span class="outer"></span>
                                    <span class='radio-text'>No</span>
                                  </label>
                                </div>
                                <div class="form-group margin-bottom45">
                                  <label class="adminheadingLabelStyle">
                                    Is there a cost to join this membership level?
                                  </label>
                                  <br>
                                  <label class="radio">
                                    <input id="'mt'+(i+1)" type="radio" [value]=true formControlName="isCostToJoin" (change)="subscribeMemeberCostChanges($event, 'consumerMemberProgramType', i)">
                                    <span class="outer"></span>
                                    <span class='radio-text'>Yes</span>
                                  </label>
                                  <label class="radio">
                                    <input id="'mf'+(i+1)" type="radio" [value]=false formControlName="isCostToJoin" (change)="subscribeMemeberCostChanges($event, 'consumerMemberProgramType', i)">
                                    <span class="outer"></span>
                                    <span class='radio-text'>No</span>
                                  </label>
                                </div>
                              </div>
                              <div class='formDiv row' *ngIf="consumerMemberProgramType.controls['membershipLevelToList'][i].controls['isCostToJoin'].value">
                                <div class='form-grop col-sm-2'>
                                  <label class="labelStyle">Cost to join*</label>
                                  <input type="number" placeholder="" class='form-control inputTextStyle' [ngClass]="memberShipLevel.get('costToJoin').hasError('pattern') || memberShipLevel.get('costToJoin').hasError('required') && memberShipLevel.get('costToJoin').touched ? 'err':''"
                                    formControlName="costToJoin" onkeydown='return event.which != 189'>
                                </div>
                                <div class='form-grop col-sm-2 down-arrow select-icon'>
                                  <label class="labelStyle">Currency*</label>
                                  <select class='form-control inputTextStyle' formControlName="currencyType" [ngClass]="memberShipLevel.get('currencyType').hasError('required') && memberShipLevel.get('currencyType').touched ? 'err':''">
                                    <option *ngFor="let cy of currencies" [value]="cy.currencyId">{{cy.currencyName}}</option>
                                  </select>
                                </div>
                              </div>
                              <div class='formDiv row margin-top25'>
                                <div class='form-group col-sm-4'>
                                  <label class="labelStyle"># of stays needed to upgrade per year*</label>
                                  <input type="number" onkeypress='return event.charCode >= 48 && event.charCode <= 57' placeholder="" class='form-control inputTextStyle'
                                    [ngClass]="memberShipLevel.get('noOfStaysNeedToUpgrade').hasError('required') && memberShipLevel.get('noOfStaysNeedToUpgrade').touched ? 'err':''"
                                    formControlName="noOfStaysNeedToUpgrade">
                                </div>
                                <div class='form-group col-sm-4'>
                                  <label class="labelStyle"># of nights needed to upgrade per year*</label>
                                  <input type="number" onkeypress='return event.charCode >= 48 && event.charCode <= 57' placeholder="" class='form-control inputTextStyle'
                                    [ngClass]="memberShipLevel.get('noOfNightsNeedToUpgrade').hasError('required') && memberShipLevel.get('noOfNightsNeedToUpgrade').touched ? 'err':''"
                                    formControlName="noOfNightsNeedToUpgrade">
                                </div>
                              </div>
                              <div class='formDiv row'>
                                <div class='form-group col-sm-4'>
                                  <label class="labelStyle"># of points issued on enrollment*</label>
                                  <input type="number" onkeypress='return event.charCode >= 48 && event.charCode <= 57' placeholder="" class='form-control inputTextStyle'
                                    [ngClass]="memberShipLevel.get('noOfPointsIssuedOnEnrolling').hasError('required') && memberShipLevel.get('noOfPointsIssuedOnEnrolling').touched ? 'err':''"
                                    formControlName="noOfPointsIssuedOnEnrolling">
                                </div>
                                <div class='form-group col-sm-4'>
                                  <label class="labelStyle">Color picker*</label>
                                  <input [value]="memberShipLevel.get('colourCode').value" class='form-control inputTextStyle' formControlName="colourCode"
                                    [style.background]="memberShipLevel.get('colourCode').value" [cpOKButton]="true" [cpSaveClickOutside]="true"
                                    (colorPickerChange)="onColorPickerChange($event, 'consumerMemberProgramType', i)" [cpOKButtonClass]="'btn btn-primary btn-sm'"
                                    [ngClass]="memberShipLevel.get('colourCode').hasError('required') && memberShipLevel.get('colourCode').touched ? 'err':''"
                                    [(colorPicker)]="color" />
                                </div>
                                <!-- <div class='form-group col-sm-4'>
                                    <label class="labelStyle"># of points issued for referral*</label>
                                    <input type="number" onkeypress='return event.charCode >= 48 && event.charCode <= 57' placeholder="" class='form-control inputTextStyle' [ngClass]="memberShipLevel.get('noOfPointsIssuedOnReferral').hasError('required') && memberShipLevel.get('noOfPointsIssuedOnReferral').touched ? 'err':''"
                                      formControlName="noOfPointsIssuedOnReferral">
                                  </div> -->
                              </div>
                              <div class="formDiv row">
                                <div class='form-group col-sm-6'>
                                  <p class="adminheadingLabelStyle">Perk Messages*</p>
                                  <!-- <label class='labelStyle'>Perk Heading</label>
                                  <textarea class='form-control inputTextStyle' rows='2' formControlName="perkHeading" (focus)="errorDetails = ''"></textarea>
                                  <label class='labelStyle'>Perk Message</label>
                                  <textarea class='form-control inputTextStyle' rows='2' formControlName="perkMessage" (focus)="errorDetails = ''"></textarea> -->
                                  <div formArrayName="perkConfig">
                                    <div *ngFor="let perk of consumerMemberProgramType.controls['membershipLevelToList'][i].controls['perkConfig']['controls']; let i=index">
                                      <div>
                                        <label class='labelStyle'>
                                          Perk Description
                                        </label>
                                        <input type="text" class='form-control inputTextStyle' rows='1' [ngClass]="memberShipLevel.get('perkConfig')[i].hasError('required') && memberShipLevel.get('perkConfig')[i].touched ? 'err':''"
                                          formControlName="{{i}}" (focus)="errorDetails = ''" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <!-- <div class="col-sm-3">
                                  <button class='btn-file margin-top25'>Add</button>
                                </div> -->
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div class='row  step3-footer-buttons'>
                  <div class='col-sm-5'>
                    <button type="button" class="btn btn-default" class="addButtonStyle step3-btn" (click)="addMembershipLevel('consumerMemberProgramType')" [ngClass]="consumerMemberProgramType.get('membershipLevelToList').value.length == 10 ? 'disabled' : '' ">
                      <img class='pull-left' src='assets/images/plus-white.svg' />Add Memebership
                    </button>
                  </div>
                  <div class='col-sm-7 text-right'>
                    <button type="button" class="btn btn-default cancelButtonStyle" (click)="cancel()">
                      <strong>Cancel</strong>
                    </button>
                    <button type="button" class="btn btn-default nextButtonStyle  prevButton" previousStep>Previous</button>
                    <button type="button" class="btn btn-default nextButtonStyle" nextStep (click)="saveWizard()" [ngClass]="consumerMemberProgramType.valid && employeeMemberProgramType.valid && travelAgentMemberType.valid ? '' : 'disabled'"
                      (click)="saveWizard('doneStep1')">Next</button>
                  </div>
                </div>
              </div>
              <div class="btn-group" class="buttonDivStyle">
                <button type="button" class="btn btn-secondary" class="cancelButtonStyle" (click)="cancel()">Cancel</button>
                <button type="button" class="btn btn-secondary" previousStep class="nextButtonStyle  prevButton">Previous</button>
                <button type="button" class="btn btn-secondary" nextStep class="nextButtonStyle" (click)="saveWizard()" [ngClass]="consumerMemberProgramType.valid && employeeMemberProgramType.valid && travelAgentMemberType.valid ? '' : 'disabled'"
                  (click)="saveWizard('doneStep1')">Next</button>
              </div>
            </div>
          </div>
          <!-- loyalty membership first step end -->

          <!-- loyalty membership second step start -->
          <div #employeeMemberContentProgram class="tab-pane" id="employeeMemberProgram" [ngClass]="loyalty.controls['programType'].value.indexOf(2) == 0 ? 'active' : ''">
            <div class="centered-content">
              <div>
                <div class="col-sm-12 tabPaneBorder">
                  <div class='editContainer col-sm-12'>
                    <div>
                      <h3>Loyalty Program Setup: Step 3.</h3>
                    </div>


                    <!-- <form [formGroup]="employeeMemberProgramType" class='form-group'>
                      <div class='formDiv row padding-bottom-130'>
                        <div class='form-group col-sm-5'>
                          <label class="labelStyle"># of points issued on enrollment*</label>
                          <input type="number" onkeypress='return event.charCode >= 48 && event.charCode <= 57' placeholder="" class='form-control inputTextStyle'
                            [ngClass]="employeeMemberProgramType.get('noOfPointsIssuedOnEnrolling').hasError('required') && employeeMemberProgramType.get('noOfPointsIssuedOnEnrolling').touched ? 'err':''"
                            formControlName="noOfPointsIssuedOnEnrolling">
                        </div> -->
                        <!-- <div class='form-group col-sm-5'>
                            <label class="labelStyle"># of points issued for referral*</label>
                            <input type="number" onkeypress='return event.charCode >= 48 && event.charCode <= 57' placeholder="" class='form-control inputTextStyle' [ngClass]="employeeMemberProgramType.get('noOfPointsIssuedOnReferral').hasError('required') && employeeMemberProgramType.get('noOfPointsIssuedOnReferral').touched ? 'err':''"
                              formControlName="noOfPointsIssuedOnReferral">
                          </div> -->
                      <!-- </div>
                    </form> -->

                    <form novalidate [formGroup]="employeeMemberProgramType">
                        <div formArrayName="membershipLevelToList">
                          <div *ngFor="let memberShipLevel of employeeMemberProgramType.controls['membershipLevelToList']['controls']; let i=index">
                            <div formGroupName="{{i}}">
                              <div class='row'>
                                <div class='col-sm-6'>
                                  <p class="adminPermHeading">Employee Level</p>
                                </div>
                              </div>
                              <div class='formDiv row margin-top'>
                                <div class='form-group col-sm-4'>
                                  <label class="labelStyle">Employee Level Name*</label>
                                  <input type="text" placeholder="" class='form-control inputTextStyle' [ngClass]="memberShipLevel.get('memberShipName').hasError('uniqueBy') || memberShipLevel.get('memberShipName').hasError('required') && memberShipLevel.get('memberShipName').touched ? 'err':''"
                                    formControlName="memberShipName" />
                                  <p class="error" *ngIf="memberShipLevel.get('memberShipName').hasError('uniqueBy')">Employee Level Name must be unique</p>
                                </div>
                                <div class='form-group col-sm-4'>
                                  <label class="labelStyle">Employee level*</label>
                                  <input type='text' class='form-control inputTextStyle' [ngModel]="'Level '+ (i+1)" formControlName="memberShipLevel" readonly/>
                                </div>
                                <div class='col-sm-4 padding-top-25 text-right' *ngIf="employeeMemberProgramType.controls['membershipLevelToList'].length > 1">
                                  <button class='btn btn-default remove-btn' (click)="remove('employeeMemberProgramType', 'membershipLevelToList', i, 'membership')">
                                    <img src='assets/images/minus.svg' class='pull-left' />
                                    <span> Remove</span>
                                  </button>
                                </div>
                              </div>
                              <div class='formDiv'>
                                <div class="form-group margin-bottom45">
                                  <label class="adminheadingLabelStyle">
                                    Does the guest have to Opt-In?
                                  </label>
                                  <br>
                                  <label class="radio">
                                    <input id="'gt'+(i+1)" type="radio" [value]="true" formControlName="isGuestOptIn">
                                    <span class="outer"></span>
                                    <span class='radio-text'>Yes</span>
                                  </label>
                                  <label class="radio">
                                    <input id="'gf'+(i+1)" type="radio" [value]="false" formControlName="isGuestOptIn">
                                    <span class="outer"></span>
                                    <span class='radio-text'>No</span>
                                  </label>
                                </div>
                                <div class="form-group margin-bottom45">
                                  <label class="adminheadingLabelStyle">
                                    Is there a cost to join this employee level?
                                  </label>
                                  <br>
                                  <label class="radio">
                                    <input id="'mt'+(i+1)" type="radio" [value]=true formControlName="isCostToJoin" (change)="subscribeMemeberCostChanges($event, 'employeeMemberProgramType', i)">
                                    <span class="outer"></span>
                                    <span class='radio-text'>Yes</span>
                                  </label>
                                  <label class="radio">
                                    <input id="'mf'+(i+1)" type="radio" [value]=false formControlName="isCostToJoin" (change)="subscribeMemeberCostChanges($event, 'employeeMemberProgramType', i)">
                                    <span class="outer"></span>
                                    <span class='radio-text'>No</span>
                                  </label>
                                </div>
                              </div>
                              <div class='formDiv row' *ngIf="employeeMemberProgramType.controls['membershipLevelToList'][i].controls['isCostToJoin'].value">
                                <div class='form-grop col-sm-2'>
                                  <label class="labelStyle">Cost to join</label>
                                  <input type="number" placeholder="" class='form-control inputTextStyle' [ngClass]="memberShipLevel.get('costToJoin').hasError('pattern') || memberShipLevel.get('costToJoin').hasError('required') && memberShipLevel.get('costToJoin').touched ? 'err':''"
                                    formControlName="costToJoin" onkeydown='return event.which != 189'>
                                </div>
                                <div class='form-grop col-sm-2 down-arrow select-icon'>
                                  <label class="labelStyle">Currency</label>
                                  <select class='form-control inputTextStyle' formControlName="currencyType" [ngClass]="memberShipLevel.get('currencyType').hasError('required') && memberShipLevel.get('currencyType').touched ? 'err':''">
                                    <option *ngFor="let cy of currencies" [value]="cy.currencyId">{{cy.currencyName}}</option>
                                  </select>
                                </div>
                              </div>
                              <div class='formDiv row margin-top25'>
                                <div class='form-group col-sm-4'>
                                  <label class="labelStyle"># of stays needed to upgrade per year</label>
                                  <input type="number" onkeypress='return event.charCode >= 48 && event.charCode <= 57' placeholder="" class='form-control inputTextStyle'
                                    [ngClass]="memberShipLevel.get('noOfStaysNeedToUpgrade').hasError('required') && memberShipLevel.get('noOfStaysNeedToUpgrade').touched ? 'err':''"
                                    formControlName="noOfStaysNeedToUpgrade">
                                </div>
                                <div class='form-group col-sm-4'>
                                  <label class="labelStyle"># of nights needed to upgrade per year</label>
                                  <input type="number" onkeypress='return event.charCode >= 48 && event.charCode <= 57' placeholder="" class='form-control inputTextStyle'
                                    [ngClass]="memberShipLevel.get('noOfNightsNeedToUpgrade').hasError('required') && memberShipLevel.get('noOfNightsNeedToUpgrade').touched ? 'err':''"
                                    formControlName="noOfNightsNeedToUpgrade">
                                </div>
                              </div>
                              <div class='formDiv row'>
                                <div class='form-group col-sm-4'>
                                  <label class="labelStyle"># of points issued on enrollment</label>
                                  <input type="number" onkeypress='return event.charCode >= 48 && event.charCode <= 57' placeholder="" class='form-control inputTextStyle'
                                    [ngClass]="memberShipLevel.get('noOfPointsIssuedOnEnrolling').hasError('required') && memberShipLevel.get('noOfPointsIssuedOnEnrolling').touched ? 'err':''"
                                    formControlName="noOfPointsIssuedOnEnrolling">
                                </div>
                                <div class='form-group col-sm-4'>
                                  <label class="labelStyle">Color picker</label>
                                  <input [value]="memberShipLevel.get('colourCode').value" class='form-control inputTextStyle' formControlName="colourCode"
                                    [style.background]="memberShipLevel.get('colourCode').value" [cpOKButton]="true" [cpSaveClickOutside]="true"
                                    (colorPickerChange)="onColorPickerChange($event, 'employeeMemberProgramType', i)" [cpOKButtonClass]="'btn btn-primary btn-sm'"
                                    [ngClass]="memberShipLevel.get('colourCode').hasError('required') && memberShipLevel.get('colourCode').touched ? 'err':''"
                                    [(colorPicker)]="color" />
                                </div>
                                <!-- <div class='form-group col-sm-4'>
                                    <label class="labelStyle"># of points issued for referral*</label>
                                    <input type="number" onkeypress='return event.charCode >= 48 && event.charCode <= 57' placeholder="" class='form-control inputTextStyle' [ngClass]="memberShipLevel.get('noOfPointsIssuedOnReferral').hasError('required') && memberShipLevel.get('noOfPointsIssuedOnReferral').touched ? 'err':''"
                                      formControlName="noOfPointsIssuedOnReferral">
                                  </div> -->
                              </div>
                              <div class="formDiv row">
                                <div class='form-group col-sm-6'>
                                  <p class="adminheadingLabelStyle">Perk Messages</p>
                                  <!-- <label class='labelStyle'>Perk Heading</label>
                                  <textarea class='form-control inputTextStyle' rows='2' formControlName="perkHeading" (focus)="errorDetails = ''"></textarea>
                                  <label class='labelStyle'>Perk Message</label>
                                  <textarea class='form-control inputTextStyle' rows='2' formControlName="perkMessage" (focus)="errorDetails = ''"></textarea> -->
                                  <div formArrayName="perkConfig">
                                    <div *ngFor="let perk of employeeMemberProgramType.controls['membershipLevelToList'][i].controls['perkConfig']['controls']; let i=index">
                                      <div>
                                        <label class='labelStyle'>
                                          Perk Description
                                        </label>
                                        <input type="text" class='form-control inputTextStyle' rows='1' [ngClass]="memberShipLevel.get('perkConfig')[i].hasError('required') && memberShipLevel.get('perkConfig')[i].touched ? 'err':''"
                                          formControlName="{{i}}" (focus)="errorDetails = ''" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <!-- <div class="col-sm-3">
                                  <button class='btn-file margin-top25'>Add</button>
                                </div> -->
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>

                  </div>
                </div>
                <div class='row  step3-footer-buttons'>
                  <div class='col-sm-12 text-right pull-right'>
                    <button type="button" class="btn btn-default cancelButtonStyle" (click)="cancel()">
                      <strong>Cancel</strong>
                    </button>
                    <button type="button" class="btn btn-default nextButtonStyle  prevButton" previousStep>Previous</button>
                    <button type="button" class="btn btn-default nextButtonStyle" nextStep [ngClass]="consumerMemberProgramType.valid && employeeMemberProgramType.valid && travelAgentMemberType.valid ? '' : 'disabled'"
                      (click)="saveWizard('doneStep1')">Next</button>
                  </div>
                </div>
              </div>
              <div class="btn-group" class="buttonDivStyle">
                <button type="button" class="btn btn-secondary" class="cancelButtonStyle" (click)="cancel()">Cancel</button>
                <button type="button" class="btn btn-secondary" previousStep class="nextButtonStyle  prevButton">Previous</button>
                <button type="button" class="btn btn-secondary" nextStep class="nextButtonStyle" [ngClass]="consumerMemberProgramType.valid && employeeMemberProgramType.valid && travelAgentMemberType.valid ? '' : 'disabled'"
                  (click)="saveWizard('doneStep1')">Next</button>
              </div>
            </div>
          </div>
          <!-- loyalty membership second step end -->

          <!-- loyalty membership third step start -->
          <div #travelAgentContentMember class="tab-pane" id="travelAgentMember" [ngClass]="loyalty.controls['programType'].value.indexOf(3) == 0 ? 'active' : ''">
            <div class="centered-content">
              <div>
                <div class="col-sm-12 tabPaneBorder">
                  <div class='editContainer col-sm-9'>
                    <div>
                      <h3>Loyalty Program Setup: Step 3.</h3>
                    </div>
                    <form [formGroup]="travelAgentMemberType" class='form-group'>
                      <div class='formDiv row padding-bottom-130'>
                        <div class='form-group col-sm-5'>
                          <label class="labelStyle"># of points issued on enrollment*</label>
                          <input type="number" onkeypress='return event.charCode >= 48 && event.charCode <= 57' placeholder="" class='form-control inputTextStyle'
                            [ngClass]="travelAgentMemberType.get('noOfPointsIssuedOnEnrolling').hasError('required') && travelAgentMemberType.get('noOfPointsIssuedOnEnrolling').touched ? 'err':''"
                            formControlName="noOfPointsIssuedOnEnrolling">
                        </div>
                        <!-- <div class='form-group col-sm-5'>
                            <label class="labelStyle"># of points issued for referral*</label>
                            <input type="number" onkeypress='return event.charCode >= 48 && event.charCode <= 57' placeholder="" class='form-control inputTextStyle' [ngClass]="travelAgentMemberType.get('noOfPointsIssuedOnReferral').hasError('required') && travelAgentMemberType.get('noOfPointsIssuedOnReferral').touched ? 'err':''"
                              formControlName="noOfPointsIssuedOnReferral">
                          </div> -->
                      </div>
                    </form>
                  </div>
                </div>
                <div class='row  step3-footer-buttons'>
                  <div class='col-sm-12 text-right pull-right'>
                    <button type="button" class="btn btn-default cancelButtonStyle" (click)="cancel()">
                      <strong>Cancel</strong>
                    </button>
                    <button type="button" class="btn btn-default nextButtonStyle  prevButton" previousStep>Previous</button>
                    <button type="button" class="btn btn-default nextButtonStyle" nextStep [ngClass]="consumerMemberProgramType.valid && employeeMemberProgramType.valid && travelAgentMemberType.valid ? '' : 'disabled'"
                      (click)="saveWizard('doneStep1')">Next</button>
                  </div>
                </div>
              </div>
              <div class="btn-group" class="buttonDivStyle">
                <button type="button" class="btn btn-secondary" class="cancelButtonStyle" (click)="cancel()">Cancel</button>
                <button type="button" class="btn btn-secondary" previousStep class="nextButtonStyle  prevButton">Previous</button>
                <button type="button" class="btn btn-secondary" nextStep class="nextButtonStyle" [ngClass]="consumerMemberProgramType.valid && employeeMemberProgramType.valid && travelAgentMemberType.valid ? '' : 'disabled'"
                  (click)="saveWizard('doneStep1')">Next</button>
              </div>
            </div>
          </div>
          <!-- loyalty membership third step end -->

        </div>
      </div>
    </wizard-step>
    <!-- loyalty third step end  -->

    <!-- Brand and Hotel Step start -->
    <wizard-step stepTitle="Loyalty Program Setup 2" navigationSymbol="2">
      <ng-template wizardStepTitle>
        <div class="d-none d-md-block" class="wizardHeadingTextStyle">Brand & Hotel Details</div>
      </ng-template>
      <div id="" class="customContainer" [formGroup]="loyaltyBrand">
        <p class="error" *ngIf="loyaltyBrand.get('brandsList').hasError('uniqueBy')">Duplicate brand names found.</p>
        <ul class="nav nav-tabs hotel-brand-tabs">
          <li *ngFor="let brand of loyaltyBrand.get('brandsList').value; let i=index;" [ngClass]="i == selectedBrandIndex ? 'active':''">
            <a class="pointer text-center" data-toggle="tab" [id]="'brand'+i" [href]="'#brand'+i" (click)="getBrandDetailsByBrandId(brand, i)">
              <span>{{'Brand '+(i+1)}} </span>
              <span class="pull-right minus" *ngIf="loyaltyBrand.get('brandsList').value.length > 1" (click)="$event.preventDefault();remove('loyaltyBrand', 'brandsList', i, 'brand')">
                <img class="pull-left" src="assets/images/minus.svg">
              </span>
            </a>
          </li>
        </ul>
        <div class="tab-content">
          <div class="tab-pane fade in active" [id]="'brand'+selectedBrandIndex">
            <div>
              <div class="centered-content">
                <div>
                  <div class="col-sm-12 tabPaneBorder">
                    <div class='editContainer col-sm-12'>
                      <div>
                        <h3>Hotel Brand Details</h3>
                      </div>
                      <form novalidate [formGroup]="loyaltyBrand.get('brandsList')[selectedBrandIndex]">
                        <!-- <div class='row'> -->
                        <div class='formDiv row margin-top'>
                          <div class='form-group col-sm-5'>
                            <label class="labelStyle">Hotel Brand Name*</label>
                            <input type="text" placeholder="" class='form-control inputTextStyle' formControlName="brandName" [ngClass]="loyaltyBrand.get('brandsList')[selectedBrandIndex].get('brandName').hasError('uniqueBy')
                            || loyaltyBrand.get('brandsList')[selectedBrandIndex].get('brandName').hasError('brandTaken')
                            || loyaltyBrand.get('brandsList')[selectedBrandIndex].get('brandName').hasError('brandAlreadyTaken')
                            || loyaltyBrand.get('brandsList')[selectedBrandIndex].get('brandName').hasError('required')
                            && loyaltyBrand.get('brandsList')[selectedBrandIndex].get('brandName').touched ? 'err':''">
                            <p class="error" *ngIf="loyaltyBrand.get('brandsList')[(selectedBrandIndex)].get('brandName').hasError('uniqueBy')">Brand name already exists.</p>
                          </div>
                          <div class='form-group col-sm-5 down-arrow  select-icon select-div'>
                            <label class="labelStyle">Available Language* (Choose at least 1)</label>
                            <select class='form-control inputTextStyle' formControlName="languageId" [ngClass]="loyaltyBrand.get('brandsList')[selectedBrandIndex].get('languageId').hasError('required')
                            && loyaltyBrand.get('brandsList')[selectedBrandIndex].get('languageId').touched ? 'err':''">
                              <option value="">Choose Language</option>
                              <option *ngFor="let language of languages" [value]="language.id">{{language.language}}</option>
                            </select>
                            <!-- <label>Available Language* (Choose at least 1)</label>
                              <select type="multiselect" class='form-control' formControlName="languageId" (focus)="errorDetails = ''" [ngClass]="brand.get('languageId').hasError('required') && brand.get('languageId').touched ? 'err':''">
                                <option value="">Choose Language</option>
                                <option *ngFor="let language of languages" [value]="language.id">{{language.language}}</option>
                              </select> -->
                            <!-- <div class='select dropdown-toggle' data-toggle='dropdown' [class.err]="levelsError" (click)="checkError(loyaltyBrand.get('brandsList').at(selectedBrandIndex).get('languagesList'))"
                              [ngClass]="!loyaltyBrand.get('brandsList').at(selectedBrandIndex).get('languagesList').value.length ? 'err':''">
                                {{loyaltyBrand.get('brandsList').at(selectedBrandIndex).get('languagesList').value.length}} Language{{loyaltyBrand.get('brandsList').at(selectedBrandIndex).get('languagesList').value.length == 1 ? '' : 's'}} selected
                            </div>
                            <ul class='dropdown-menu checkbox-list' (click)="$event.stopPropagation()">
                              <li class='check-list' *ngFor="let language of languages">
                                <input type='checkbox' [id]='language.id' name='cb' [value]="language.id" [checked]="loyaltyBrand.get('brandsList').at(selectedBrandIndex).get('languagesList').value.indexOf(language.id) >= 0" />
                                <label [for]='language.id' (click)="$event.preventDefault();toggleSelection(language, selectedBrandIndex)">
                                  <span class='ip-text'>{{language.language}}</span>
                                  <span class='check-box'></span>
                                </label>
                              </li>
                            </ul> -->
                          </div>
                        </div>
                        <div class='formDiv row'>
                          <div class="form-group col-sm-5 margin-bottom45">
                            <label class="labelStyle">
                              Brand Website URL*
                            </label>
                            <textarea class='form-control inputTextStyle' [ngClass]="(loyaltyBrand.get('brandsList')[selectedBrandIndex].get('website').hasError('pattern')
                            || loyaltyBrand.get('brandsList')[selectedBrandIndex].get('website').hasError('required')) && loyaltyBrand.get('brandsList')[selectedBrandIndex].get('website').touched ? 'err':''"
                              formControlName="website"></textarea>
                          </div>
                          <div class="form-group col-sm-5 margin-bottom45">
                            <label class="labelStyle">
                              Brand Blog URL
                            </label>
                            <textarea class='form-control inputTextStyle' [ngClass]="loyaltyBrand.get('brandsList')[selectedBrandIndex].get('blogUrl').hasError('pattern') ? 'err':''"
                              formControlName="blogUrl"></textarea>
                          </div>
                        </div>
                        <div class='formDiv row'>
                          <div class="form-group col-sm-5">
                            <label class="labelStyle">Smart Code*</label>
                            <input type="text" class='form-control inputTextStyle' formControlName="smartCode" (focus)="errorDetails = ''" [ngClass]="loyaltyBrand.get('brandsList')[(selectedBrandIndex)].get('smartCode').hasError('maxlength') || loyaltyBrand.get('brandsList')[(selectedBrandIndex)].get('smartCode').hasError('required') && loyaltyBrand.get('brandsList')[selectedBrandIndex].get('smartCode').touched ? 'err':''" />
                          </div>
                        </div>
                        <div class='formDiv row'>
                          <div class="form-group col-sm-5 row margin-right-15">
                            <div class='col-sm-7'>
                              <label class="labelStyle">Logo (Optional)</label>
                              <p *ngIf='!loyaltyBrand.get("brandsList")[selectedBrandIndex].get("brandLogo").value' class='image-name'>no file chosen</p>
                              <p *ngIf='loyaltyBrand.get("brandsList")[selectedBrandIndex].get("brandLogo").value' class='image-name'>{{loyaltyBrand.get("brandsList")[selectedBrandIndex].get("brandLogoName").value}}</p>
                              <input class='form-control' type='file' #uploadLogoFile (change)="fileChanged($event,'logo', 'brand', selectedBrandIndex)"
                                (focus)="errorDetails = ''" />
                              <button (click)="uploadLogoFile.click()" class='btn-file'>{{loyaltyBrand.get("brandsList")[(selectedBrandIndex)].get("brandLogo").value ? 'Change File'
                                : 'Choose File'}}</button>
                              <p *ngIf='loyaltyBrand.get("brandsList")[selectedBrandIndex].get("logoError").value' class="error upload" (click)="logoError = false">Please upload Image only.</p>
                              <p *ngIf='loyaltyBrand.get("brandsList")[selectedBrandIndex].get("logoSizeError").value' class="error upload" (click)="logoSizeError = false">Image size should be less than 1MB.</p>
                            </div>
                            <div class='col-sm-5 '>
                              <div *ngIf='loyaltyBrand.get("brandsList")[selectedBrandIndex].get("logoUrl").value'>
                                <img class="image" [src]='loyaltyBrand.get("brandsList")[selectedBrandIndex].get("logoUrl").value' />
                                <button class='btn btn-default delete-image'>
                                  <img src='assets/images/delete.svg' (click)="deleteImage(loyaltyBrand.get('brandsList')[selectedBrandIndex], 'brandsList', selectedBrandIndex, 'brand', 'logo')"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                          <div class="form-group col-sm-5 row margin-right-15">
                            <div class='col-sm-7'>
                              <label class="labelStyle">Favicon (Optional)</label>
                              <p *ngIf='!loyaltyBrand.get("brandsList")[selectedBrandIndex].get("favicon").value' class='image-name'>no file chosen</p>
                              <p *ngIf='loyaltyBrand.get("brandsList")[selectedBrandIndex].get("favicon").value' class='image-name'>{{loyaltyBrand.get("brandsList")[selectedBrandIndex].get("faviconName").value}}</p>
                              <input class='form-control' type='file' #uploadFavFile (change)="fileChanged($event,'favicon', 'brand', selectedBrandIndex)"
                                (focus)="errorDetails = ''" />
                              <!-- <button *ngIf='loyaltyBrand.get("brandsList").at(selectedBrandIndex).get("favicon").value' (click)="uploadFile2.click()" class='btn-file'>Change File</button> -->
                              <button (click)="uploadFavFile.click()" class='btn-file'>{{loyaltyBrand.get("brandsList")[selectedBrandIndex].get("favicon").value ? 'Change File'
                                :'Choose File'}}</button>
                              <p *ngIf='loyaltyBrand.get("brandsList")[selectedBrandIndex].get("favError").value' class="error upload" (click)="favError = false">Please upload Image only.</p>
                              <p *ngIf='loyaltyBrand.get("brandsList")[selectedBrandIndex].get("favSizeError").value' class="error upload" (click)="favSizeError = false">Image size should be less than 1MB.</p>
                            </div>
                            <div class='col-sm-5'>
                              <div *ngIf='loyaltyBrand.get("brandsList")[selectedBrandIndex].get("favUrl").value'>
                                <img class="image" [src]='loyaltyBrand.get("brandsList")[selectedBrandIndex].get("favUrl").value' />
                                <button class='btn btn-default delete-image'>
                                  <img src='assets/images/delete.svg' (click)="deleteImage(loyaltyBrand.get('brandsList')[selectedBrandIndex], 'brandsList', selectedBrandIndex, 'brand', 'favicon')"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div formArrayName="hotelsList">
                          <div *ngFor="let hotel of hotelListControl; let i=index">
                            <div formGroupName="{{i}}">
                              <div class='formDiv row'>
                                <div *ngIf='i > 0' class='hotel-divider clear'></div>
                                <div class='col-sm-6'>
                                  <h3>Hotel {{i + 1}} Details</h3>
                                </div>
                                <div class='col-sm-6 text-right padding-top-40'>
                                  <button class='btn btn-default remove-btn' (click)="remove(loyaltyBrand.get('brandsList')[selectedBrandIndex], 'hotelsList', i, 'hotel')">
                                    <img src='assets/images/minus.svg' class='pull-left' />
                                    <span class='pull-right'>Remove</span>
                                  </button>
                                </div>
                              </div>
                              <div class='formDiv row margin-top25'>
                                <div class='form-group col-sm-5'>
                                  <label class="labelStyle">Hotel Name*</label>
                                  <input type="text" placeholder="" class='form-control inputTextStyle' formControlName="hotelName" [ngClass]="hotel.get('hotelName').hasError('uniqueBy') || hotel.get('hotelName').hasError('maxLength') || hotel.get('hotelName').hasError('required') && hotel.get('hotelName').touched ? 'err':''">
                                  <p class="error" *ngIf="hotel.get('hotelName').hasError('uniqueBy')">Hotel Name already exists.</p>
                                </div>
                              </div>
                              <div class='formDiv row'>
                                <div class="form-group col-sm-5">
                                  <label>Contact Email *</label>
                                  <input type='text' class='form-control inputTextStyle' formControlName='contactEmail' [ngClass]="hotel.get('contactEmail').hasError('pattern') || hotel.get('contactEmail').hasError('required') && hotel.get('contactEmail').touched ? 'err':''"
                                    (focus)="errorDetails = ''" />
                                </div>
                                <div class="form-group col-sm-5">
                                  <label class="labelStyle">Description</label>
                                  <input type='text' class='form-control inputTextStyle' formControlName='description' (focus)="errorDetails = ''" />
                                </div>
                              </div>
                              <div class='formDiv row'>
                                <div class="form-group col-sm-5">
                                  <label class="labelStyle">Hotel Code*</label>
                                  <input type='text' class='form-control inputTextStyle' formControlName='hotelCode' (focus)="errorDetails = ''"  [ngClass]="hotel.get('hotelCode').hasError('required') && hotel.get('hotelCode').touched ? 'err':''"/>
                                </div>
                                <div class='form-group col-sm-5'>
                                  <label class="labelStyle">Hotel Website URL</label>
                                  <textarea class='form-control inputTextStyle' formControlName="website" (focus)="errorDetails = ''" [ngClass]="hotel.get('website').hasError('pattern') && hotel.get('hotelName').touched ? 'err':''"></textarea>
                                </div>
                              </div>
                              <div class='formDiv row' formArrayName="seasonsList">
                                <!-- <p class="error" *ngIf="loyaltyBrand.get('brandsList').at(selectedBrandIndex).get('hotelsList')[i].get('isDatesRepeated').value">Selected Date is already previous date range</p>
                                <p class="error" *ngIf="loyaltyBrand.get('brandsList').at(selectedBrandIndex).get('hotelsList')[i].get('dateRepeated').value">DateTo must be greater than dateFrom</p> -->
                                <h4 class="padding-left-15 margin-bottom25">
                                  <b>Create seasons for external booking points</b>
                                </h4>
                                <div class='form-group col-sm-10 row season-row' *ngFor="let season of loyaltyBrand.get('brandsList')[selectedBrandIndex].get('hotelsList')[i].get('seasonsList').controls; let j=index">
                                  <div [formGroupName]="j">
                                    <div class='col-sm-2'>
                                      <label class="labelStyle">Season*</label>
                                      <input type='text' class='form-control inputTextStyle' formControlName="name" [ngClass]="season.get('name').hasError('uniqueBy') || season.get('name').hasError('required') && season.get('name').touched ? 'err':''"
                                      />
                                      <p class="error" *ngIf="season.get('name').hasError('uniqueBy')">Season Name already exists.</p>
                                    </div>
                                    <div class='col-sm-3'>
                                      <label class="labelStyle">Date From*</label>
                                      <my-date-picker name="dateFrom" formControlName="dateFrom" placeholder="Start Date" [options]="myDatePickerOptions" [ngClass]="season.hasError('datesOverlapped') || season.get('dateFrom').hasError('required') && season.get('dateFrom').touched ? 'err' : ''"
                                        (dateChanged)="onDateChanged($event, 'dateFrom', selectedBrandIndex, i, j)"></my-date-picker>
                                      <!-- <input type="date" formControlName="dateFrom" placeholder="MM/DD/YYYY" class="form-control inputTextStyle" onkeydown='return event.which == 8 || event.which == 46 || event.which == 37 || event.which == 39'
                                        [ngClass]="season.get('dateFrom').hasError('required') && season.get('dateFrom').touched ? 'err':''"> -->
                                    </div>
                                    <div class='col-sm-3'>
                                      <label class="labelStyle">Date To*</label>
                                      <my-date-picker name="dateTo" formControlName="dateTo" placeholder="End Date" [options]="myDatePickerOptions" [ngClass]="season.hasError('datesOverlapped')
                                      || season.hasError('dateRangeError') || season.get('dateTo').hasError('required') && season.get('dateTo').touched ? 'err' : ''"
                                        (dateChanged)="onDateChanged($event, 'dateTo', selectedBrandIndex, i, j)"></my-date-picker>
                                      <!-- <input type="date" formControlName="dateTo" class="form-control inputTextStyle" onkeydown='return event.which == 8 || event.which == 46 || event.which == 37 || event.which == 39'
                                        placeholder="End Date" [ngClass]="season.get('dateTo').hasError('required') && season.get('dateTo').touched ? 'err':''"> -->
                                    </div>
                                    <div class='col-sm-2 padding-right-0'>
                                      <label class="labelStyle">
                                        <!-- Points -->
                                        Points per night*
                                      </label>
                                      <input type='number' onkeypress='return event.charCode >= 48 && event.charCode <= 57' class='form-control inputTextStyle'
                                        formControlName="points" [ngClass]="season.get('points').hasError('required') && season.get('points').touched ? 'err':''"
                                      />
                                    </div>
                                    <div class="col-sm-2 text-center" *ngIf="loyaltyBrand.get('brandsList')[selectedBrandIndex].get('hotelsList')[i].get('seasonsList').value.length > 1">
                                      <img src='assets/images/delete-orange.svg' class="season-delete" (click)="remove(loyaltyBrand.get('brandsList')[selectedBrandIndex].get('hotelsList')[i], 'seasonsList', j, 'season')"
                                      />
                                    </div>
                                    <p class="error col-xs-12 text-center" *ngIf="season.hasError('datesOverlapped')">Seasons duration overlaps</p>
                                    <p class="error col-xs-12 text-center" *ngIf="season.hasError('dateRangeError')">Date From must not be greater than Date To</p>
                                  </div>
                                </div>
                              </div>
                              <div class='row formDiv margin-bottom25'>
                                <div class='form-group col-sm-4'>
                                  <button class='btn btn-default btn-add' (click)="addSeason(selectedBrandIndex, i)">Add Season</button>
                                </div>
                              </div>
                              <div class='formDiv row'>
                                <div class="form-group col-sm-5 profilePic row margin-bottom-40">
                                  <div class='col-sm-7'>
                                    <label class="labelStyle">Hotel Hero Banner Image</label>
                                    <p *ngIf='!hotel.get("banner").value' class='image-name'>no file chosen</p>
                                    <p *ngIf='hotel.get("banner").value' class='image-name'>{{hotel.get("bannerName").value}}</p>
                                    <input class='form-control' type='file' #uploadBannerFile (change)="fileChanged($event,'banner', 'hotel', selectedBrandIndex, i)"
                                      (focus)="errorDetails = ''" />
                                    <!-- <button *ngIf='loyaltyBrand.get('brandsList').at(selectedBrandIndex).get("favicon").value' (click)="uploadFile2.click()" class='btn-file'>Change File</button> -->
                                    <button (click)="uploadBannerFile.click()" class='btn-file'>{{hotel.get("banner").value ? 'Change File' :'Choose File'}}</button>
                                    <p *ngIf='hotel.get("bannerError").value' class="error upload" (click)="bannerError = false">Please upload Image only.</p>
                                    <p *ngIf='hotel.get("bannerSizeError").value' class="error upload" (click)="bannerSizeError = false">Image size should be less than 1MB.</p>
                                  </div>
                                  <div class='col-sm-5'>
                                    <div *ngIf='hotel.get("bannerUrl").value'>
                                      <img class="image" [src]='hotel.get("bannerUrl").value' />
                                      <button class='btn btn-default delete-image'>
                                        <img src='assets/images/delete.svg' (click)="deleteImage(hotel, 'hotelsList', i, 'hotel', 'banner')" />
                                      </button>
                                    </div>
                                  </div>

                                </div>
                                <div class="form-group col-sm-5">
                                  <label>Tags</label>
                                  <input [(ngModel)]="tagInputText" [ngModelOptions]="{standalone: true}" style="display: none" />
                                  <tag-input formControlName='tags' [(inputText)]="tagInputText" [onTextChangeDebounce]="300" [theme]="'minimal'" [onlyFromAutocomplete]="true"
                                    [identifyBy]="'hotelTagId'" [displayBy]="'name'" [placeholder]="''" (keydown)="searchTerm$.next($event.target.value)">
                                    <tag-input-dropdown [showDropdownIfEmpty]="false" [displayBy]="'name'" [identifyBy]="'hotelTagId'" [autocompleteObservable]="hotelTags">
                                      <ng-template let-item="item" let-index="index">
                                        {{ item.name }}
                                      </ng-template>
                                    </tag-input-dropdown>
                                  </tag-input>
                                </div>
                              </div>
                              <!-- <div *ngIf='i > 0' class='hotel-divider clear'>{{i}}</div> -->
                            </div>
                          </div>

                        </div>

                      </form>
                    </div>
                  </div>
                  <div class='row  step3-footer-buttons'>
                    <div class='col-sm-6'>
                      <button type="button" class="btn btn-secondary" class="addButtonStyle" (click)="addHotel(selectedBrandIndex)">
                        <img class='pull-left' src='assets/images/plus-white.svg' />Add a Hotel
                      </button>
                      <button type="button" class="btn btn-secondary" class="addButtonStyle" (click)="addLoyaltyBrand()">
                        <img class='pull-left' src='assets/images/plus-white.svg' />Add a Brand
                      </button>
                    </div>
                    <div class='col-sm-6 text-right'>
                      <button type="button" class="btn btn-default cancelButtonStyle" (click)="cancel()">
                        <strong>Cancel</strong>
                      </button>
                      <button type="button" class="btn btn-default nextButtonStyle  prevButton" previousStep>Previous</button>
                      <button type="button" class="btn btn-default nextButtonStyle" nextStep (click)="saveWizard('brandsList')" [ngClass]="!loyaltyBrand.valid ? 'disabled': ''">Next</button>
                    </div>
                  </div>
                </div>
                <div class="btn-group" class="buttonDivStyle">
                  <button type="button" class="btn btn-secondary" class="cancelButtonStyle" (click)="cancel()">Cancel</button>
                  <button type="button" class="btn btn-secondary" previousStep class="nextButtonStyle  prevButton">Previous</button>
                  <button type="button" class="btn btn-secondary" nextStep class="nextButtonStyle" (click)="saveWizard('brandsList')" [ngClass]="!loyaltyBrand.valid ? 'disabled': ''">Next</button>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="tab-pane" id="brand2">
                <div>Employee Program</div>
              </div> -->
        </div>
      </div>
    </wizard-step>
    <!-- Brand and Hotel Step end -->

    <!-- Add Permissions step start -->
    <wizard-step stepTitle="Admin Permissions" navigationSymbol="3">
      <ng-template wizardStepTitle>
        <div class="d-none d-md-block" class="wizardHeadingTextStyle">Admin Permissions</div>
      </ng-template>
      <div class="centered-content">
        <div class="col-sm-12 step4">
          <form [formGroup]="loyaltyAdmin" class='form-group'>
            <div formArrayName="adminsList">
              <div class='row margin-bottom25'>
                <h3>Admin Permissions</h3>
                <p class='adminPermissionDivText'>Setup user access for brand and hotel levels. This can also be configured later in the user management screen
                  under settings.</p>
              </div>
              <div *ngFor="let admin of loyaltyAdmin.controls['adminsList']['controls']; let i=index">
                <div formGroupName="{{i}}">
                  <div class="col-sm-7 text-right"></div>
                  <div class="col-sm-5 text-right">
                    <button class="btn btn-default remove-btn" (click)="remove('loyaltyAdmin', 'adminsList', i, 'admin')">
                      <img class="pull-left" src="assets/images/minus.svg">
                      <span class="pull-right">Remove</span>
                    </button>
                  </div>
                  <div class='formDiv row'>
                    <div class='form-group col-sm-4 down-arrow select-icon'>
                      <label class="labelStyle">Admin Role*</label>
                      <select class='form-control inputTextStyle' formControlName="roleId" (focus)="errorDetails = ''" (change)="selectedRole(i)"
                        [ngClass]="admin.get('roleId').hasError('required') && admin.get('roleId').touched ? 'err':''">
                        <option value="">Choose Role</option>
                        <option *ngFor="let role of hcaRoles" [value]="role.roleId">
                          {{role.roleName}}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class='formDiv row'>
                    <div class='form-group col-sm-4 down-arrow select-icon'>
                      <label class="labelStyle">Hotel Brand* </label>
                      <select class='form-control inputTextStyle' formControlName="brandName" (focus)="errorDetails = ''" (change)="selectedBrands(i, brand)"
                        [ngClass]="admin.get('brandName').hasError('required') && admin.get('brandName').touched ? 'err':''">
                        <option value="" *ngIf="admin.get('roleId').value != 12">Choose Brand</option>
                        <option value="" *ngIf="admin.get('roleId').value == 12">All</option>
                        <option *ngFor="let brand of loyaltyBrand.get('brandsList').value; let j = index;" [value]="brand.brandName">{{brand.brandName}}</option>
                      </select>
                      <!-- <div class='form-control inputTextStyle dropdown-toggle down-arrow' data-toggle='dropdown' [class.disabled]="loyaltyAdmin.get('adminsList')[i].get('roleId').value == 2">
                          {{ admin.get('isAllBrandsSelected').value ? 'All' : templateString('brand', admin.get('brandNames').value.length) }}
                          </div>
                          <ul class='dropdown-menu checkbox-list' onClick='event.stopPropagation();'>
                            <li class='check-list'>
                              <input type='checkbox' [id]='"brandA"+(i+1)' [value]='"brandA"+(i+1)' [name]='"brandA"+(i+1)' (change)="selectedAll($event, 'brand', i)" [checked]="admin.get('isAllBrandsSelected').value"/>
                              <label [for]='"brandA"+(i+1)'>
                                <span class='ip-text'>ALL</span>
                                <span class='check-box'></span>
                              </label>
                            </li>
                            <li class='check-list' *ngFor="let brand of loyaltyBrand.get('brandsList').value; let j = index;">
                            <input [id]='"brand"+(j+i+2)' type='checkbox' [name]='"brand"+(j+i+2)' [value]='"brand"+(i+2)' (change)="selectedBrands($event, 'brand', i, brand)" [checked]="admin.get('brandNames').value.indexOf(brand.brandName) > -1 || admin.get('brandNames').value.indexOf(brand.brandName) > -1"/>
                              <label [for]='"brand"+(j+i+2)'>
                                <span class='ip-text'>{{brand.brandName}}</span>
                                <span class='check-box'></span>
                              </label>
                            </li>
                        </ul> -->
                    </div>
                    <div class='form-group col-sm-4 down-arrow select-icon'>
                      <label class="labelStyle">Hotels* </label>
                      <select class='form-control inputTextStyle dropdown-toggle' (focus)="errorDetails = ''" data-toggle="dropdown" formControlName="hotelName"
                        [ngClass]="admin.get('hotelName').hasError('required') && admin.get('hotelName').touched ? 'err':''">
                        <option value="" *ngIf="admin.get('roleId').value == 14 || admin.get('roleId').value == ''">Choose Hotel</option>
                        <option value="" *ngIf="admin.get('roleId').value == 12 || admin.get('roleId').value == 13">All</option>
                        <option *ngFor="let hotel of hotels" [value]="hotel.hotelName">{{hotel.hotelName}}</option>
                      </select>
                      <!-- <div class='form-control inputTextStyle dropdown-toggle down-arrow' data-toggle='dropdown' [class.disabled]="loyaltyAdmin.get('adminsList')[i].get('roleId').value == 2 || loyaltyAdmin.get('adminsList')[i].get('roleId').value == 3">
                          {{ admin.get('isAllBrandsSelected').value || admin.get('isAllHotelsSelected').value ? 'All' : templateString('hotel', admin.get('hotelNames').value.length) }}
                          </div>
                          <ul class='dropdown-menu checkbox-list' onClick='event.stopPropagation();'>
                            <li class='check-list'>
                              <input type='checkbox' id='hotel0' name='hotel' value="hotel0" />
                              <label for='hotel0'>
                                <span class='ip-text'>ALL</span>
                                <span class='check-box'></span>
                              </label>
                            </li>
                            <li class='check-list'>
                              <input id='cl2' type='checkbox' name='hotel'/>
                              <label for='cl2'>
                                <span class='ip-text'>Sandos Cancon</span>
                                <span class='check-box'></span>
                              </label>
                            </li>
                        </ul> -->
                    </div>
                  </div>
                  <div class='formDiv row'>
                    <div class='form-group col-sm-4'>
                      <label class="labelStyle">First Name*</label>
                      <input type='text' class='form-control inputTextStyle' [ngClass]="admin.get('firstName').hasError('required') && admin.get('firstName').touched ? 'err':''"
                        formControlName="firstName" />
                    </div>
                    <div class='form-group col-sm-4'>
                      <label class="labelStyle">Last Name*</label>
                      <input type='text' class='form-control inputTextStyle' formControlName="lastName" [ngClass]="admin.get('lastName').hasError('required') && admin.get('lastName').touched ? 'err':''"
                      />
                    </div>
                  </div>
                  <div class='formDiv row'>
                    <div class='form-group col-sm-4'>
                      <label class="labelStyle">Email ID*</label>
                      <input type="text" placeholder="" class='form-control inputTextStyle' formControlName="email" [ngClass]="admin.get('email').hasError('uniqueBy') || admin.get('email').hasError('pattern') || admin.get('email').hasError('required') && admin.get('email').touched ? 'err':''">
                      <p class="error" *ngIf="admin.get('email').hasError('uniqueBy')">Email id already exists. Please contact the admin.</p>
                    </div>
                    <div class='form-group col-sm-4'>
                      <label class="labelStyle">Contact Number</label>
                      <input type="tel" placeholder="" class='form-control inputTextStyle' formControlName="mobileNumber" [ngClass]="admin.get('mobileNumber').hasError('pattern') || admin.get('mobileNumber').hasError('minlength') || admin.get('mobileNumber').hasError('maxlength') ? 'err':''">
                    </div>
                  </div>
                  <div class='formDiv row'>
                    <div class='form-group col-sm-4'>
                      <label class="labelStyle">Skype ID</label>
                      <input type="text" placeholder="" class='form-control inputTextStyle' formControlName="skypeId">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>

          <div class='row  step3-footer-button btn-admin-permissions'>
            <div class='col-sm-5'>
              <button type="button" class="btn btn-secondary" class="addButtonStyle step4-btn" (click)="addLoyaltyAdmin()">
                <img class='pull-left' src='assets/images/plus-white.svg' />Add Admin
              </button>
            </div>
            <div class='col-sm-7 text-right' *ngIf="loyaltyAdmin.controls['adminsList'].value.length > 1">
              <button type="button" class="btn btn-default cancelButtonStyle" (click)="cancel()">
                <strong>Cancel</strong>
              </button>
              <button type="button" class="btn btn-default nextButtonStyle  prevButton" previousStep>Previous</button>
              <button type="button" class="btn btn-default nextButtonStyle" nextStep [ngClass]="!loyaltyAdmin.valid ? 'disabled': ''" (click)="saveWizard('loyaltyAdmin')">Next</button>
            </div>
          </div>
        </div>
        <div class="btn-group" class="buttonDivStyle">
          <button type="button" class="btn btn-secondary" class="cancelButtonStyle" (click)="cancel()">Cancel</button>
          <button type="button" class="btn btn-secondary" class="nextButtonStyle  prevButton" previousStep>Previous</button>
          <button type="button" class="btn btn-secondary" nextStep class="nextButtonStyle" [ngClass]="!loyaltyAdmin.valid ? 'disabled': ''"
            (click)="saveWizard('loyaltyAdmin')">Next</button>
        </div>
      </div>
    </wizard-step>
    <!-- Add Permissions step end -->

    <!-- confirmation step start -->
    <wizard-step stepTitle="Review and confirm" navigationSymbol="4">
      <ng-template wizardStepTitle>
        <div class="d-none d-md-block" class="wizardHeadingTextStyle">Review and Confirm</div>
      </ng-template>
      <div class="centered-content">
        <div>
          <div class="col-md-12 ">
            <div class='editContainer confirmation'>
              <div class='margin-bottom25'>
                <h3 class='margin-top-55'>Confirm Details</h3>
                <!-- <p class="adminPermHeading">Loyalty Program Parameters (Consumer): Direct Bookings</p> -->
              </div>
              <form [formGroup]="" class='form-group'>
                <div class='form-group'>
                  <p class="adminPermHeading">Loyalty Program Name</p>
                  <div class="confirmationDivTextStyle"> {{loyalty.get('programmeName').value}}</div>
                </div>
                <!-- consumer -->
                <div class='margin-bottom25 clear' *ngIf="loyalty.controls['programType'].value.indexOf(1) > -1">
                  <p class="adminPermHeading">Loyalty Program Parameters (Consumer): </p>
                  <!-- Direct Bookings -->
                  <div *ngIf="loyalty.controls['bookingType'].value.indexOf(1) > -1">
                    <div class='margin-bottom25 clear'>
                      <p class="adminPermHeading">Direct Bookings:</p>
                    </div>
                    <div class='margin-bottom25'>
                      <div class='row formDiv'>
                        <div class='form-group col-sm-3'>
                          <label class="labelStyle">% Cost of Booking</label>
                          <div class="confirmationDivTextStyle"> {{consumerProgramType.get('directBooking').get('points').get('costOfBooking').value}}</div>
                        </div>
                        <div class='form-group col-sm-3'>
                          <label class="labelStyle">% Cost of Extras</label>
                          <div class="confirmationDivTextStyle"> {{consumerProgramType.get('directBooking').get('points').get('costOfExtras').value}}</div>
                        </div>
                        <div class='form-group col-sm-3'>
                          <label class="labelStyle">Coefficient</label>
                          <div class="confirmationDivTextStyle"> {{consumerProgramType.get('directBooking').get('points').get('coeficient').value}}</div>
                        </div>
                      </div>
                      <div class='row formDiv'>
                        <div class='form-group col-sm-4'>
                          <label class="labelStyle">Types of Guests that can Earn Points</label>
                          <div class="confirmationDivTextStyle">
                            <span>{{getPointsArr(consumerProgramType.get('directBooking').get('guests'))}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- External Bookings -->
                  <div *ngIf="loyalty.controls['bookingType'].value.indexOf(2) > -1">
                    <div class='margin-bottom25 clear'>
                      <p class="adminPermHeading">External Bookings:</p>
                    </div>
                    <div>
                      <div class='row formDiv'>
                        <div class='form-group col-sm-3'>
                          <label class="labelStyle">% Cost of Booking</label>
                          <div class="confirmationDivTextStyle">{{consumerProgramType.get('externalBooking').get('points').get('costOfBooking').value}}</div>
                        </div>
                        <div class='form-group col-sm-3'>
                          <label class="labelStyle">% Cost of Extras</label>
                          <div class="confirmationDivTextStyle">{{consumerProgramType.get('externalBooking').get('points').get('costOfExtras').value}}</div>
                        </div>
                        <div class='form-group col-sm-3'>
                          <label class="labelStyle">Coefficient</label>
                          <div class="confirmationDivTextStyle">{{consumerProgramType.get('externalBooking').get('points').get('coeficient').value}}</div>
                        </div>
                      </div>
                      <div class='row formDiv'>
                        <div class='form-group col-sm-4'>
                          <label class="labelStyle">Types of Guests that can Earn Points</label>
                          <div class="confirmationDivTextStyle">
                            <span>{{getPointsArr(consumerProgramType.get('externalBooking').get('guests'))}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class='row formDiv' *ngFor="let transaction of consumerProgramType.controls['transactionTypesData'].controls; let i=index">
                    <div class='form-group col-sm-3'>
                      <label class="labelStyle">{{transaction.value.transactionTypeEn}}</label>
                      <div class="confirmationDivTextStyle">{{transaction.value.points ? 'Yes' : 'No'}}</div>
                    </div>
                    <div class='form-group col-sm-3'>
                      <label class="labelStyle">Points Earned</label>
                      <div class="confirmationDivTextStyle">{{transaction.value.points ? transaction.value.points : 0}}</div>
                    </div>
                  </div>
                  <!-- <div class='row formDiv'>
                    <div class='form-group col-sm-3'>
                      <label class="labelStyle">Points for Blog Post</label>
                      <div class="confirmationDivTextStyle">{{consumerProgramType.get('isBlogPoints').value ? 'Yes' : 'No'}}</div>
                    </div>
                    <div class='form-group col-sm-3'>
                      <label class="labelStyle">Points Earned</label>
                      <div class="confirmationDivTextStyle">{{consumerProgramType.get('isBlogPoints').value ? consumerProgramType.get('blogPoints').value : 0}}</div>
                    </div>
                  </div>
                  <div class='row formDiv'>
                    <div class='form-group col-sm-3'>
                      <label class="labelStyle">Points Per Friend Invite</label>
                      <div class="confirmationDivTextStyle">{{consumerProgramType.get('isInvitePoints').value ? 'Yes' : 'No'}}</div>
                    </div>
                    <div class='form-group col-sm-3'>
                      <label class="labelStyle">Points Earned</label>
                      <div class="confirmationDivTextStyle">{{consumerProgramType.get('isInvitePoints').value ? consumerProgramType.get('invitePoints').value :
                        0}}
                      </div>
                    </div>
                  </div>
                  <div class='row formDiv'>
                    <div class='form-group col-sm-3'>
                      <label class="labelStyle">Points Per Friend Join</label>
                      <div class="confirmationDivTextStyle">{{consumerProgramType.get('isJoinPoints').value ? 'Yes' : 'No'}}</div>
                    </div>
                    <div class='form-group col-sm-3'>
                      <label class="labelStyle">Points Earned</label>
                      <div class="confirmationDivTextStyle">{{consumerProgramType.get('isJoinPoints').value ? consumerProgramType.get('joinPoints').value : 0}}</div>
                    </div>
                  </div>
                  <div class='row formDiv'>
                    <div class='form-group col-sm-3'>
                      <label class="labelStyle">Points Per Share</label>
                      <div class="confirmationDivTextStyle">{{consumerProgramType.get('isSharePoints').value ? 'Yes' : 'No'}}</div>
                    </div>
                    <div class='form-group col-sm-3'>
                      <label class="labelStyle">Points Earned</label>
                      <div class="confirmationDivTextStyle">{{consumerProgramType.get('isSharePoints').value ? consumerProgramType.get('sharePoints').value : 0}}</div>
                    </div>
                  </div> -->
                  <div class='margin-bottom25 clear'>
                    <p class="adminPermHeading">Points Distribution:</p>
                  </div>
                  <div class='row formDiv'>
                    <div class='form-group col-sm-3'>
                      <label class="labelStyle">% points to Buyer</label>
                      <div class="confirmationDivTextStyle">{{consumerProgramType.get('buyerPoints').value}}</div>
                    </div>
                    <div class='form-group col-sm-3'>
                      <label class="labelStyle">% points to Guests</label>
                      <div class="confirmationDivTextStyle">{{consumerProgramType.get('guestPoints').value}}</div>
                    </div>
                  </div>
                  <div class='row formDiv'>
                    <div class='form-group col-sm-3'>
                      <label class="labelStyle">Points Expire After (Years)</label>
                      <div class="confirmationDivTextStyle">{{consumerProgramType.get('expirePoints').value}}</div>
                    </div>
                  </div>
                </div>

                <!-- Employee Programm -->
                <div class='margin-bottom25 clear' *ngIf="loyalty.controls['programType'].value.indexOf(2) > -1">
                  <p class="adminPermHeading">Loyalty Program Parameters (Employee): </p>
                  <div class='margin-bottom25'>
                    <div class='row formDiv'>
                      <div class='form-group col-sm-3'>
                        <label class="labelStyle">% Cost of Booking</label>
                        <div class="confirmationDivTextStyle"> {{employeeProgramType.get('bookingPoints').value}}</div>
                      </div>
                    </div>
                    <div class='row formDiv'>
                      <div class='form-group col-sm-6'>
                        <label class="labelStyle">Types of employee referral points</label>
                        <div class="confirmationDivTextStyle"> {{employeeProgramType.get('pointsRuleId').value ? getEmployeePointsTypeName(employeeProgramType.get('pointsRuleId').value) : 'N/A'}}</div>
                      </div>
                    </div>
                    <div class='row formDiv' *ngIf="employeeProgramType.get('pointsRuleId').value && (employeeProgramType.get('pointsRuleId').value == 1 || employeeProgramType.get('pointsRuleId').value == 2)">
                      <div class='form-group col-sm-6'>
                        <label class="labelStyle">Employee Referral Points</label>
                        <div class="confirmationDivTextStyle"> {{employeeProgramType.get('referralPoints').value}}</div>
                      </div>
                    </div>
                    <!-- <div class='row formDiv' *ngFor="let transaction of employeeProgramType.controls['transactionTypesData'].controls; let i=index">
                      <div class='form-group col-sm-3'>
                        <label class="labelStyle">{{transaction.value.transactionTypeEn}}</label>
                        <div class="confirmationDivTextStyle">{{transaction.value.points ? 'Yes' : 'No'}}</div>
                      </div>
                      <div class='form-group col-sm-3'>
                        <label class="labelStyle">Points Earned</label>
                        <div class="confirmationDivTextStyle">{{transaction.value.points ? transaction.value.points : 0}}</div>
                      </div>
                    </div> -->
                    <!-- <div class='row formDiv'>
                      <div class='form-group col-sm-3'>
                        <label class="labelStyle">Points for Blog Post</label>
                        <div class="confirmationDivTextStyle">{{employeeProgramType.get('isBlogPoints').value ? 'Yes' : 'No'}}</div>
                      </div>
                      <div class='form-group col-sm-3'>
                        <label class="labelStyle">Points Earned</label>
                        <div class="confirmationDivTextStyle">{{employeeProgramType.get('isBlogPoints').value ? employeeProgramType.get('blogPoints').value : 0}}</div>
                      </div>
                    </div>
                    <div class='row formDiv'>
                      <div class='form-group col-sm-3'>
                        <label class="labelStyle">Points Per Friend Invite</label>
                        <div class="confirmationDivTextStyle">{{employeeProgramType.get('isInvitePoints').value ? 'Yes' : 'No'}}</div>
                      </div>
                      <div class='form-group col-sm-3'>
                        <label class="labelStyle">Points Earned</label>
                        <div class="confirmationDivTextStyle">{{employeeProgramType.get('isInvitePoints').value ? employeeProgramType.get('invitePoints').value
                          : 0}}</div>
                      </div>
                    </div>
                    <div class='row formDiv'>
                      <div class='form-group col-sm-3'>
                        <label class="labelStyle">Points Per Friend Join</label>
                        <div class="confirmationDivTextStyle">{{employeeProgramType.get('isJoinPoints').value ? 'Yes' : 'No'}}</div>
                      </div>
                      <div class='form-group col-sm-3'>
                        <label class="labelStyle">Points Earned</label>
                        <div class="confirmationDivTextStyle">{{employeeProgramType.get('isJoinPoints').value ? employeeProgramType.get('joinPoints').value : 0}}</div>
                      </div>
                    </div>
                    <div class='row formDiv'>
                      <div class='form-group col-sm-3'>
                        <label class="labelStyle">Points Per Share</label>
                        <div class="confirmationDivTextStyle">{{employeeProgramType.get('isSharePoints').value ? 'Yes' : 'No'}}</div>
                      </div>
                      <div class='form-group col-sm-3'>
                        <label class="labelStyle">Points Earned</label>
                        <div class="confirmationDivTextStyle">{{employeeProgramType.get('isSharePoints').value ? employeeProgramType.get('sharePoints').value :
                          0}}
                        </div>
                      </div>
                    </div> -->
                    <div class='row formDiv'>
                      <div class='form-group col-sm-3'>
                        <label class="labelStyle"># of points issued on enrollment</label>
                        <div class="confirmationDivTextStyle"> {{employeeMemberProgramType.get('noOfPointsIssuedOnEnrolling').value}}</div>
                      </div>
                      <!-- <div class='form-group col-sm-3'>
                            <label class="labelStyle"># of points issued for referral</label>
                            <div class="confirmationDivTextStyle"> {{employeeMemberProgramType.get('noOfPointsIssuedOnReferral').value}}</div>
                          </div> -->
                    </div>
                    <!-- <div class='row formDiv'>
                      <div class='form-group col-sm-3'>
                        <label class="labelStyle">Points Expire After (Years)</label>
                        <div class="confirmationDivTextStyle">{{employeeProgramType.get('expirePoints').value}}</div>
                      </div>
                    </div> -->
                  </div>
                </div>

                <!-- Travel Agent -->
                <div class='margin-bottom25 clear' *ngIf="loyalty.controls['programType'].value.indexOf(3) > -1">
                  <p class="adminPermHeading">Loyalty Program Parameters (Travel Agent): </p>
                  <div class='margin-bottom25'>
                    <div class='row formDiv'>
                      <div class='form-group col-sm-3'>
                        <label class="labelStyle">% Cost of Booking</label>
                        <div class="confirmationDivTextStyle"> {{travelAgentType.get('bookingPoints').value}}</div>
                      </div>
                    </div>
                    <!-- <div class='row formDiv' *ngFor="let transaction of travelAgentType.controls['transactionTypesData'].controls; let i=index">
                      <div class='form-group col-sm-3'>
                        <label class="labelStyle">{{transaction.value.transactionTypeEn}}</label>
                        <div class="confirmationDivTextStyle">{{transaction.value.points ? 'Yes' : 'No'}}</div>
                      </div>
                      <div class='form-group col-sm-3'>
                        <label class="labelStyle">Points Earned</label>
                        <div class="confirmationDivTextStyle">{{transaction.value.points ? transaction.value.points : 0}}</div>
                      </div>
                    </div> -->
                    <!-- <div class='row formDiv'>
                      <div class='form-group col-sm-3'>
                        <label class="labelStyle">Points for Blog Post</label>
                        <div class="confirmationDivTextStyle">{{travelAgentType.get('isBlogPoints').value ? 'Yes' : 'No'}}</div>
                      </div>
                      <div class='form-group col-sm-3'>
                        <label class="labelStyle">Points Earned</label>
                        <div class="confirmationDivTextStyle">{{travelAgentType.get('isBlogPoints').value ? travelAgentType.get('blogPoints').value : 0}}</div>
                      </div>
                    </div>
                    <div class='row formDiv'>
                      <div class='form-group col-sm-3'>
                        <label class="labelStyle">Points Per Friend Invite</label>
                        <div class="confirmationDivTextStyle">{{travelAgentType.get('isInvitePoints').value ? 'Yes' : 'No'}}</div>
                      </div>
                      <div class='form-group col-sm-3'>
                        <label class="labelStyle">Points Earned</label>
                        <div class="confirmationDivTextStyle">{{travelAgentType.get('isInvitePoints').value ? travelAgentType.get('invitePoints').value : 0}}</div>
                      </div>
                    </div>
                    <div class='row formDiv'>
                      <div class='form-group col-sm-3'>
                        <label class="labelStyle">Points Per Friend Join</label>
                        <div class="confirmationDivTextStyle">{{travelAgentType.get('isJoinPoints').value ? 'Yes' : 'No'}}</div>
                      </div>
                      <div class='form-group col-sm-3'>
                        <label class="labelStyle">Points Earned</label>
                        <div class="confirmationDivTextStyle">{{travelAgentType.get('isJoinPoints').value ? travelAgentType.get('joinPoints').value : 0}}</div>
                      </div>
                    </div>
                    <div class='row formDiv'>
                      <div class='form-group col-sm-3'>
                        <label class="labelStyle">Points Per Share</label>
                        <div class="confirmationDivTextStyle">{{travelAgentType.get('isSharePoints').value ? 'Yes' : 'No'}}</div>
                      </div>
                      <div class='form-group col-sm-3'>
                        <label class="labelStyle">Points Earned</label>
                        <div class="confirmationDivTextStyle">{{travelAgentType.get('isSharePoints').value ? travelAgentType.get('sharePoints').value : 0}}</div>
                      </div>
                    </div> -->
                    <div class='row formDiv'>
                      <div class='form-group col-sm-3'>
                        <label class="labelStyle"># of points issued on enrollment</label>
                        <div class="confirmationDivTextStyle"> {{travelAgentMemberType.get('noOfPointsIssuedOnEnrolling').value}}</div>
                      </div>
                      <!-- <div class='form-group col-sm-3'>
                            <label class="labelStyle"># of points issued for referral</label>
                            <div class="confirmationDivTextStyle"> {{travelAgentMemberType.get('noOfPointsIssuedOnReferral').value}}</div>
                          </div> -->
                    </div>
                    <!-- <div class='row formDiv'>
                      <div class='form-group col-sm-3'>
                        <label class="labelStyle">Points Expire After (Years)</label>
                        <div class="confirmationDivTextStyle">{{travelAgentType.get('expirePoints').value}}</div>
                      </div>
                    </div> -->
                  </div>
                </div>

                <!-- Membership Level-->
                <div *ngIf="loyalty.controls['programType'].value.indexOf(1) > -1">
                  <div *ngFor="let memberShip of consumerMemberProgramType.get('membershipLevelToList').value; let i = index;">
                    <div class='margin-bottom25 clear'>
                      <p class="adminPermHeading">Membership level {{i + 1}} (Consumer) Details:</p>
                    </div>
                    <div>
                      <div class='row formDiv'>
                        <div class='form-group col-sm-3'>
                          <label class="labelStyle">Membership level Name</label>
                          <div class="confirmationDivTextStyle">{{memberShip.memberShipName}}</div>
                        </div>
                        <div class='form-group col-sm-3'>
                          <label class="labelStyle">Membership level</label>
                          <div class="confirmationDivTextStyle">{{memberShip.memberShipLevel}}</div>
                        </div>
                      </div>
                      <div class='row formDiv'>
                        <div class='form-group col-sm-3'>
                          <label class="labelStyle">Guest Opt_In</label>
                          <div class="confirmationDivTextStyle">{{memberShip.isGuestOptIn ? 'Yes' : 'No'}}</div>
                        </div>
                        <div class='form-group col-sm-3'>
                          <label class="labelStyle">Cost to Join</label>
                          <div class="confirmationDivTextStyle">{{memberShip.isCostToJoin ? memberShip.costToJoin : 0}} {{memberShip.isCostToJoin ? (memberShip.currencyType
                            == 1 ? 'USD' : 'Rupee') : ''}}</div>
                        </div>
                      </div>
                      <div class='row formDiv'>
                        <div class='form-group col-sm-3'>
                          <label class="labelStyle">Stays Needed for an Upgrade</label>
                          <div class="confirmationDivTextStyle">{{memberShip.noOfStaysNeedToUpgrade}}</div>
                        </div>
                        <div class='form-group col-sm-3'>
                          <label class="labelStyle">Nights Needed for an Upgrade</label>
                          <div class="confirmationDivTextStyle">{{memberShip.noOfNightsNeedToUpgrade}}</div>
                        </div>
                      </div>
                      <div class='row formDiv'>
                        <div class='form-group col-sm-3'>
                          <label class="labelStyle">Points issued on Enrollment</label>
                          <div class="confirmationDivTextStyle">{{memberShip.noOfPointsIssuedOnEnrolling}}</div>
                        </div>
                        <div class='form-group col-sm-3'>
                          <label class="labelStyle">ColorPicker</label>
                          <div class="confirmationDivTextStyle" [style.color]="memberShip.colourCode">{{memberShip.colourCode}}</div>
                        </div>
                      </div>
                      <div class='row formDiv'>
                        <div class='form-group col-sm-12'>
                          <label class="labelStyle">Perk Messages</label>
                          <div class="confirmationDivTextStyle">
                            <span *ngFor="let perk of memberShip.perkConfig; let i = index; let isLast=last">{{perk ? perk : 'N/A'}}{{isLast ? '' : ', '}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div *ngIf="loyalty.controls['programType'].value.indexOf(2) > -1">
                    <div *ngFor="let memberShip of employeeMemberProgramType.get('membershipLevelToList').value; let i = index;">
                      <div class='margin-bottom25 clear'>
                        <p class="adminPermHeading">Membership Level {{i + 1}} (Employee) Details:</p>
                      </div>
                      <div>
                        <div class='row formDiv'>
                          <div class='form-group col-sm-3'>
                            <label class="labelStyle">Membership Name</label>
                            <div class="confirmationDivTextStyle">{{memberShip.memberShipName}}</div>
                          </div>
                          <div class='form-group col-sm-3'>
                            <label class="labelStyle">Membership Level</label>
                            <div class="confirmationDivTextStyle">{{memberShip.memberShipLevel}}</div>
                          </div>
                        </div>
                        <div class='row formDiv'>
                          <div class='form-group col-sm-3'>
                            <label class="labelStyle">Guest Opt_In</label>
                            <div class="confirmationDivTextStyle">{{memberShip.isGuestOptIn ? 'Yes' : 'No'}}</div>
                          </div>
                          <div class='form-group col-sm-3'>
                            <label class="labelStyle">Cost to Join</label>
                            <div class="confirmationDivTextStyle">{{memberShip.isCostToJoin ? memberShip.costToJoin : 0}} {{memberShip.isCostToJoin ? (memberShip.currencyType
                              == 1 ? 'USD' : 'Rupee') : ''}}</div>
                          </div>
                        </div>
                        <div class='row formDiv'>
                          <div class='form-group col-sm-3'>
                            <label class="labelStyle">Stays Needed for an Upgrade</label>
                            <div class="confirmationDivTextStyle">{{memberShip.noOfStaysNeedToUpgrade}}</div>
                          </div>
                          <div class='form-group col-sm-3'>
                            <label class="labelStyle">Nights Needed for an Upgrade</label>
                            <div class="confirmationDivTextStyle">{{memberShip.noOfNightsNeedToUpgrade}}</div>
                          </div>
                        </div>
                        <div class='row formDiv'>
                          <div class='form-group col-sm-3'>
                            <label class="labelStyle">Points issued on Enrollment</label>
                            <div class="confirmationDivTextStyle">{{memberShip.noOfPointsIssuedOnEnrolling}}</div>
                          </div>
                          <div class='form-group col-sm-3'>
                            <label class="labelStyle">ColorPicker</label>
                            <div class="confirmationDivTextStyle" [style.color]="memberShip.colourCode">{{memberShip.colourCode}}</div>
                          </div>
                        </div>
                        <div class='row formDiv'>
                          <div class='form-group col-sm-12'>
                            <label class="labelStyle">Perk Messages</label>
                            <div class="confirmationDivTextStyle">
                              <span *ngFor="let perk of memberShip.perkConfig; let i = index; let isLast=last">{{perk ? perk : 'N/A'}}{{isLast ? '' : ', '}}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="loyalty.controls['programType'].value.indexOf(3) > -1">
                      <div *ngFor="let memberShip of travelAgentMemberType.get('membershipLevelToList').value; let i = index;">
                        <div class='margin-bottom25 clear'>
                          <p class="adminPermHeading">Membership Level {{i + 1}} (Travel Agent) Details:</p>
                        </div>
                        <div>
                          <div class='row formDiv'>
                            <div class='form-group col-sm-3'>
                              <label class="labelStyle">Membership Name</label>
                              <div class="confirmationDivTextStyle">{{memberShip.memberShipName}}</div>
                            </div>
                            <div class='form-group col-sm-3'>
                              <label class="labelStyle">Membership Level</label>
                              <div class="confirmationDivTextStyle">{{memberShip.memberShipLevel}}</div>
                            </div>
                          </div>
                          <div class='row formDiv'>
                            <div class='form-group col-sm-3'>
                              <label class="labelStyle">Guest Opt_In</label>
                              <div class="confirmationDivTextStyle">{{memberShip.isGuestOptIn ? 'Yes' : 'No'}}</div>
                            </div>
                            <div class='form-group col-sm-3'>
                              <label class="labelStyle">Cost to Join</label>
                              <div class="confirmationDivTextStyle">{{memberShip.isCostToJoin ? memberShip.costToJoin : 0}} {{memberShip.isCostToJoin ? (memberShip.currencyType
                                == 1 ? 'USD' : 'Rupee') : ''}}</div>
                            </div>
                          </div>
                          <div class='row formDiv'>
                            <div class='form-group col-sm-3'>
                              <label class="labelStyle">Stays Needed for an Upgrade</label>
                              <div class="confirmationDivTextStyle">{{memberShip.noOfStaysNeedToUpgrade}}</div>
                            </div>
                            <div class='form-group col-sm-3'>
                              <label class="labelStyle">Nights Needed for an Upgrade</label>
                              <div class="confirmationDivTextStyle">{{memberShip.noOfNightsNeedToUpgrade}}</div>
                            </div>
                          </div>
                          <div class='row formDiv'>
                            <div class='form-group col-sm-3'>
                              <label class="labelStyle">Points issued on Enrollment</label>
                              <div class="confirmationDivTextStyle">{{memberShip.noOfPointsIssuedOnEnrolling}}</div>
                            </div>
                            <div class='form-group col-sm-3'>
                              <label class="labelStyle">ColorPicker</label>
                              <div class="confirmationDivTextStyle" [style.color]="memberShip.colourCode">{{memberShip.colourCode}}</div>
                            </div>
                          </div>
                          <div class='row formDiv'>
                            <div class='form-group col-sm-12'>
                              <label class="labelStyle">Perk Messages</label>
                              <div class="confirmationDivTextStyle">
                                <span *ngFor="let perk of memberShip.perkConfig; let i = index; let isLast=last">{{perk ? perk : 'N/A'}}{{isLast ? '' : ', '}}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                <!-- Hotel Brand Details -->
                <div>
                  <div *ngFor="let brand of loyaltyBrand.get('brandsList').value; let i = index;">
                    <div class='margin-bottom25 clear'>
                      <p class="adminPermHeading">Hotel Brand {{i+1}} Details:</p>
                    </div>
                    <div class='row formDiv'>
                      <div class='form-group col-sm-3'>
                        <label class="labelStyle">Hotel Brand Name</label>
                        <div class="confirmationDivTextStyle">{{brand.brandName}}</div>
                      </div>
                      <div class='form-group col-sm-3'>
                        <label class="labelStyle">Language(s)</label>
                        <div class="confirmationDivTextStyle">English</div>
                      </div>
                    </div>
                    <div class='row formDiv'>
                      <div class='form-group col-sm-3'>
                        <label class="labelStyle">Brand Website URL</label>
                        <div class="confirmationDivTextStyle">{{brand.website}}</div>
                      </div>
                      <div class='form-group col-sm-3'>
                        <label class="labelStyle">Brand Blog URL</label>
                        <div class="confirmationDivTextStyle">{{brand.blogUrl}}</div>
                      </div>
                    </div>
                    <div class='row formDiv'>
                      <div class='form-group col-sm-3'>
                        <label class="labelStyle">Smart Code</label>
                        <div class="confirmationDivTextStyle">{{brand.smartCode}}</div>
                      </div>
                    </div>

                    <!-- Hotel Details -->
                    <div *ngFor="let hotel of brand.hotelsList; let i = index;">
                      <div class='margin-bottom25 clear'>
                        <p class="adminPermHeading">Hotel {{i + 1}} Details:</p>
                      </div>
                      <div>
                        <div class='row formDiv'>
                          <div class='form-group col-sm-3'>
                            <label class="labelStyle">Hotel Name</label>
                            <div class="confirmationDivTextStyle"> {{hotel.hotelName}}</div>
                          </div>
                          <div class='form-group col-sm-3'>
                            <label class="labelStyle">Contact Email</label>
                            <div class="confirmationDivTextStyle"> {{hotel.contactEmail}}</div>
                          </div>
                        </div>
                        <div class='row formDiv'>
                          <div class='form-group col-sm-3'>
                            <label class="labelStyle">Hotel Website URL</label>
                            <div class="confirmationDivTextStyle">{{hotel.website}}</div>
                          </div>
                          <div class='form-group col-sm-3'>
                            <label class="labelStyle">Description</label>
                            <div class="confirmationDivTextStyle">{{hotel.description}}</div>
                          </div>
                        </div>
                        <div class='row formDiv'>
                          <div class='form-group col-sm-3'>
                            <label class="labelStyle">Hotel Code</label>
                            <div class="confirmationDivTextStyle">{{hotel.hotelCode}}</div>
                          </div>
                          <div class='form-group col-sm-3'>
                            <label class="labelStyle">Tags</label>
                            <div>
                              <span *ngFor="let tag of hotel.tags; let isLast=last" class="confirmationDivTextStyle"> {{tag.name}}{{isLast ? '' : ','}}</span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- season Details -->
                      <div *ngFor="let season of hotel.seasonsList; let i = index;">
                        <div class='margin-bottom25 clear'>
                          <p>Season {{i+1}} Details:</p>
                        </div>
                        <div>
                          <div class='row formDiv'>
                            <div class='form-group col-sm-3'>
                              <label class="labelStyle">Season*</label>
                              <div class="confirmationDivTextStyle">{{season.name}}</div>
                            </div>
                          </div>
                          <div class='row formDiv'>
                            <div class='form-group col-sm-3'>
                              <label class="labelStyle">Date From*</label>
                              <div class="confirmationDivTextStyle">{{season.dateFrom}}</div>
                            </div>
                            <div class='form-group col-sm-3'>
                              <label class="labelStyle">Date To*</label>
                              <div class="confirmationDivTextStyle">{{season.dateTo}}</div>
                            </div>
                          </div>
                          <div class='row formDiv'>
                            <div class='form-group col-sm-3'>
                              <label class="labelStyle">Points*</label>
                              <div class="confirmationDivTextStyle">{{season.points}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>



                <!-- Admin Information -->
                <div *ngFor="let admin of loyaltyAdmin.get('adminsList').value; let i = index;">
                  <div class='margin-bottom25 clear'>
                    <p class="adminPermHeading">Admin {{i + 1}} Information:</p>
                  </div>
                  <div>
                    <div class='row formDiv'>
                      <div class='form-group col-sm-3'>
                        <label class="labelStyle">Admin Role</label>
                        <div class="confirmationDivTextStyle">{{admin.roleId == 12 ? "Hotel Chain Admin" : (admin.roleId == 13 ? "Brand Admin" : (admin.roleId ==
                          14 ? "Hotel Admin" : ''))}}</div>
                      </div>
                    </div>
                    <div class='row formDiv'>
                      <div class='form-group col-sm-3'>
                        <label class="labelStyle">Hotel Brand</label>
                        <div class="confirmationDivTextStyle">
                          {{admin.roleId == 12 ? "All" : admin.brandName}}
                        </div>
                      </div>
                      <div class='form-group col-sm-3'>
                        <label class="labelStyle">Hotels</label>
                        <div class="confirmationDivTextStyle">
                          {{(admin.roleId == 12 || admin.roleId == 13) ? "All" : admin.hotelName}}
                        </div>
                      </div>
                    </div>
                    <div class='row formDiv'>
                      <div class='form-group col-sm-3'>
                        <label class="labelStyle">First Name</label>
                        <div class="confirmationDivTextStyle">{{admin.firstName}}</div>
                      </div>
                      <div class='form-group col-sm-3'>
                        <label class="labelStyle">Last Name</label>
                        <div class="confirmationDivTextStyle">{{admin.lastName}}</div>
                      </div>
                    </div>
                    <div class='row formDiv'>
                      <div class='form-group col-sm-3'>
                        <label class="labelStyle">Skype ID</label>
                        <div class="confirmationDivTextStyle">{{admin.skypeId}}</div>
                      </div>
                      <div class='form-group col-sm-3'>
                        <label class="labelStyle">Email ID</label>
                        <div class="confirmationDivTextStyle">{{admin.email}}</div>
                      </div>
                    </div>
                    <div class='row formDiv'>
                      <div class='form-group col-sm-3'>
                        <label class="labelStyle">Contact Number</label>
                        <div class="confirmationDivTextStyle">{{admin.mobileNumber}}</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="btn-group" class="footerButtonDivStyleNav">
                  <button type="button" class="btn btn-default" class="cancelButtonStyle" (click)="cancel()">
                    <strong>Cancel</strong>
                  </button>
                  <button type="button" class="btn btn-secondary" class="nextButtonStyle" previousStep>Previous</button>
                  <button type="button" class="btn btn-secondary" class="saveButtonStyle" (click)="saveWizard('saveLoyalty')">
                    <img src='assets/images/save.svg' class='pull-left' />
                    <span>Save</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="btn-group" class="buttonDivStyle">
          <button type="button" class="btn btn-secondary" class="nextButtonStyle" previousStep>Previous</button>
          <button type="button" class="btn btn-secondary" class="saveButtonStyle" (click)="saveWizard('saveLoyalty')">
            <img src='assets/images/save.svg' class='pull-left' />
            <span>Save</span>
          </button>

        </div>
        <div class="error col-xs-12" *ngIf="errorDetails">{{errorDetails}}</div>
      </div>
    </wizard-step>
    <!-- confirmation step end -->
  </wizard>
</div>
