<div class='col-sm-9 col-sm-offset-3 main-container' >
  <div class='row head-line'>
    <div class='col-sm-6' >
      <h3 *ngIf='hcaAdminList && hcaAdminList.length'>Admin Account Management</h3>
    </div>
    <div class='col-sm-6 col-xs-12 text-right buttons'>
      <button class='btn btn-default pull-right' [routerLink]="['add']">
        <img  src='assets/images/plus-white.svg' />
        <span>New Admin</span>
      </button>
    </div>
  </div>
  <div class="col-xs-12 table-responsive  tableData" *ngIf='hcaAdminList && hcaAdminList.length'>
    <table class="table table-bordered ">
      <thead>
        <tr>
          <td class='col-sm-2'>Name + Phone Number</td>
          <td class='col-sm-2'>Email ID + Skype ID</td>
          <td class='col-sm-2'>Admin Role</td>
          <td class='col-sm-2'>Brand</td>
          <td class='col-sm-2'>Hotel(s)</td>
          <td class='col-sm-2'>Status</td>
        </tr>
      </thead>
      <tbody >
        <tr *ngFor="let hca of hcaAdminList; let i=index;">
          <td>
            <p>{{hca.firstName}} {{hca.lastName}}</p>
            <p>{{hca.mobileNumber}}</p>
          </td>
          <td>
            <p>{{hca.email}}</p>
            <p>{{hca.skypeId}}</p>
          </td>
          <td>
            <p>{{hca.adminRole}}</p>
          </td>
          <td>
            <p *ngIf="hca.isAllBrands">All</p>
            <!-- <p *ngIf="!isAllBrands" *ngFor="let brand of hca.brands">{{brand.brandName}}</p> -->
            <p *ngIf="!hca.isAllBrands">
              <span *ngFor="let brand of hca.brands">{{brand.brandName}}</span>
            </p>
          </td>
          <td>
            <p *ngIf="hca.isAllHotels">All</p>
            <p *ngIf="!hca.isAllHotels">
              <span *ngFor="let hotel of hca.hotels">{{hotel.hotelName}}</span>
            </p>
          </td>
          <td>
            <div class='statusDiv dropdown'>
              <span class='statusText' [ngClass]="hca.status == 'Active'? 'active':'inactive'">{{hca.status}}</span>
              <button type='button' class='btn btn-link  pull-right dropdown-toggle' data-toggle="dropdown">
                <img src='assets/images/overflow.svg' />
              </button>
              <ul class="dropdown-menu statusList">
                <li class="hand">
                  <a [routerLink]="['edit', hca.userId]" routerLinkActive="active">
                    <img class='pull-left' src='assets/images/edit.svg' />
                    <span class='pull-left'>Modify</span>
                  </a>
                </li>
                <li class="pointer" *ngIf="hca.status == 'Active'" (click)="storeHcaId(hca.userId, hca.roleId, i)">
                  <a class='deactivate' data-toggle="modal" data-target="#myModal">
                    <img class='pull-left' src='assets/images/delete.svg' />
                    <span class='pull-left'>Deactivate</span>
                  </a>
                </li>
                <li class="pointer" *ngIf="hca.status == 'Inactive'" class="hover " (click)="storeHcaId(hca.userId, hca.roleId,i)">
                  <a class='activate' data-toggle="modal" data-target="#myActivateModal">
                    <img class='pull-left' src='assets/images/success.svg' />
                    <span class='pull-left'>Activate</span>
                  </a>
                </li>
              </ul>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- <div *ngIf='hcaAdminList && !hcaAdminList.length' class='emptyTable text-center'>
        No Records
     </div> -->
  </div>
  <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title" id="exampleModalLabel">Deactivate {{roleId == 12 ? 'HCA' : (roleId == 13 ? 'BA' : 'HA')}} Account</h3>
          <p>Are you sure you want to deactivate this account?</p>
        </div>
        <div class="modal-footer text-center">
          <button type="button" class="btn btn-default" id="deleteModal" data-dismiss="modal">Cancel</button>
          <button type="button" (click)="updateUserStatus('Inactive')" class="btn btn-default">Deactivate</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="myActivateModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title" id="exampleModalLabel">Activate {{roleId == 12 ? 'HCA' : (roleId == 13 ? 'BA' : 'HA')}} Account</h3>
          <p>Are you sure you want to activate this account?</p>
        </div>
        <div class="modal-footer text-center">
          <button type="button" class="btn btn-default" id="activateModal" data-dismiss="modal">Cancel</button>
          <button type="button" (click)="updateUserStatus('Activate')" class="btn btn-default">Activate</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class='col-sm-9 col-sm-offset-3 main-container' *ngIf='hcaAdminList && !hcaAdminList.length'>
  <div class='message text-center col-sm-10'>
    <h3>You haven't created any users yet. Click on "+ New Admin" above to get started.</h3>
  </div>
</div>