<app-navbar></app-navbar>
<div class='col-sm-10 col-sm-offset-2 members-container'>
  <div>
    <h3 class="heading">Concierge Pending Items</h3>
  </div>


<!-- Empty state -->
<div class="message text-center" *ngIf="loadingMembers">
  <h3>Loading members. Wait a moment, please...</h3>
</div>

<div class="message text-center" *ngIf="!transactionList.length && !loadingMembers">
  <h3>No records found</h3>
</div>

<div class="table-container" *ngIf="transactionList.length && !loadingMembers">
  <div class="table-responsive">
    <table class="table table-catalog ">
      <thead>
        <tr>
          <th>Name</th>
          <th>Email/Username</th>
          <th>Created at/Status</th>
          <th>Transtype/Hotel</th>
          <th>Coins</th>
          <th>Quantity</th>
          <th>Updated at</th>
          <th>Delivered</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let transaction of transactionList | paginate: { itemsPerPage: size, currentPage: p, totalItems: totalCount }">
          <td>{{transaction.memberName}}</td>
          <td>{{transaction.memberEmail}}</td>
          <td>
            <span>{{transaction.transactionDateStr}}</span>
            <br/>
            <span [ngClass]="(transaction.status?.statusId == 4 || transaction.status?.statusId == 0 || transaction.status?.statusId == 6) ? 'inactive' : (transaction.status?.statusId == 3 ? 'pending' : 'active')">{{transaction.status?.statusDesc}}</span>
          </td>
          <td>
            <span>{{transaction.description}}</span>
          </td>
          <td>{{transaction.points ? transaction.points : 0}}</td>
          <td>{{transaction.quantity ? transaction.quantity : 0}}</td>
          <td>{{transaction.updatedDateStr}}</td>
          <td class="">
            <div class='dropdown status'>
              <span>{{ transaction.delivered ? 'Yes' : 'No' }}</span>
              <button type='button' class='btn btn-link dropdown-toggle' data-toggle="dropdown">
                <img src='assets/images/overflow.svg' />
              </button>
              <ul class="dropdown-menu statusList">
                <li class="pointer">
                  <a [routerLink]="['/concierge-members-search', 'modify', transaction.memberId, 'transaction-history']">
                    <img class='pull-left' src='assets/images/edit.svg' />
                    <span class='pull-left'>Check Delivery</span>
                  </a>
                </li>
              </ul>
            </div>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td class="tfooter" colspan="8">
            <pagination-controls class="pull-right" (pageChange)="pageChanged($event)"></pagination-controls>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</div>
</div>